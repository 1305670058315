import { put, takeLatest } from "redux-saga/effects"
import { GetNFTsExploreFailed, GetNFTsExploreSuccess, GetNftTypeExploreFailed, GetNftTypeExploreSuccess } from "../../../redux/Actions"
import { store } from "../../../redux/Store"
import API from "../../../Utils/Api"
import ENDPOINTS from "../../../Utils/Endpoints"
import * as types from '../../../redux/Types'
import { toast } from "react-toastify"

function* GetExploreNFTsWorker(action: any) {
    let Response: any
    const status: any = action.payload.state
    async function Api() {
        try {
            Response = await API.get(`${ENDPOINTS.ExploreNFT}?${action.payload}`)
        } catch (e: any) {
            console.error('e-MintToBackendWorker', e)
            store.dispatch(GetNFTsExploreFailed({ payload: e }))
            toast.error(e?.response?.data?.message)
        }
    }
    yield Api()
    if (Response?.data?.success === true) {
        yield put(GetNFTsExploreSuccess({ payload: Response }))
    } else {
        yield put(GetNFTsExploreFailed({ payload: Response }))
    }
}


export function* GetExploreNFTsWatcher() {
    yield takeLatest(types.GET_EXPLORE_NFTs_REQUEST, GetExploreNFTsWorker)
}



function* GetTypeExploreWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.get(ENDPOINTS.NftTypeExploreFree)
        } catch (e: any) {
            console.error('e-MintToBackendWorker', e)
            store.dispatch(GetNftTypeExploreFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response?.data?.success === true) {
        yield put(GetNftTypeExploreSuccess({ payload: Response }))
    } else {
        yield put(GetNftTypeExploreFailed({ payload: Response }))
    }
}


export function* GetTypeExploreWatcher() {
    yield takeLatest(types.GET_NFT_TYPE_EXPLORE_REQUEST, GetTypeExploreWorker)
}
