import aboutPhoto from '../../Assets/Img/aboutUs.png'
import { useStyles } from './StyleAboutUs'

function AboutUs(): JSX.Element {
    const classes = useStyles()

    return (
        <div className={classes.aboutUsMain}>
            <div className={classes.container}>
                <div>
                    <h1>Our Story</h1>
                    <p>Where Masterpiece artworks meet noble blocks</p>
                    <p>Established in June 2021, Artaniom is an NFT marketplace for art and luxury. We empower <br />
                        our international community of collectors and connoisseurs to discover, acquire, finance,<br />
                        and consign NFT of fine art and rare objects.<br />
                        Also guided by our forward-thinking spirit of innovation,<br /> we host nonpareil auctions and
                        make a better exprience of art finance and private sales.
                    </p>
                    <p>
                        <span>Why Artaniom? </span> <br />
                        Most of the time, when referring to values of NFT, the focus is on the abilities and use of <br />
                        blockchain, Or it relates to the NFT community's beliefs about its future. But we art Artaniom <br />
                        believe that an NFT itself must be self-sufficiently valuabel, an established and sustained <br />
                        value that no group can deny
                    </p>
                </div>
                <div>
                    <img src={aboutPhoto} alt="about-us" />
                </div>
            </div>
        </div>
    )
}

export default AboutUs