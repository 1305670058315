import { makeStyles } from "@material-ui/core";
import tick from '../../../Assets/Img/tick.png'
import loader from '../../../Assets/Img/loading_circles_rotate.gif'

export const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        justifyContent: 'center',
        margin: "60px 0",
        "& > div:first-child": {
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            columnGap: '20px',
            width: '80%',
            "& > div:nth-child(1)": {
                display: 'flex',
                flexDirection: 'column',
                "& > p:nth-child(1)": {
                    fontSize: "32px",
                    fontWeight: 700,
                    fontFamily: 'plexSansSBold',
                    marginBottom: "4px",
                },
                "& > div": {
                    color: '#000',
                    fontSize: '18px',
                    margin: '5px 0',
                    fontFamily: 'plexSansLight',
                    display: 'flex',
                    flexDirection: 'column',
                    "& > p:first-child": {
                        fontSize: '20px',
                        color: theme.palette.primary.main,
                        fontFamily: 'plexSans',
                        marginBottom: "5px"
                    },
                    "& p:nth-child(3),  p:nth-child(5), p:nth-child(7)": {
                        fontSize: '20px',
                        fontFamily: 'plexSans',
                        marginBottom: "4px",
                        display: 'flex',
                        alignItems: 'center'
                    },

                    "& > div": {
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                    },
                    "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
                        diplay: "inline-block",
                        backgroundColor: '#DFDFDF',
                        border: 'none',
                        borderRadius: '15px',
                        padding: '5px ',
                        marginLeft: '15px',
                    },
                    "& .css-1wc848c-MuiFormHelperText-root": {
                        position: 'absolute',
                        bottom: '-20px',
                        width: 'max-content'
                    },
                }
            }
        }
    },
    Eth: {
        backgroundColor: "#dfdfdf",
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: '15px',
        fontWeight: 600
    },
    timePicker: {
        "& input": {
            diplay: "inline-block",
            backgroundColor: '#DFDFDF',
            border: 'none',
            borderRadius: '7px',
            padding: '0 15px',
            width: '370px',
            height: "60px"
        },
        "& p::before": {
            content: "dd",
            width: '10px',
            backgroundColor: '#000'
        },
        "& div[id='headTitle']": {
            marginTop: '40px'
        }
    },
    description: {
        fontSize: "16px"
    },
    price: {
        color: '#5c5a5b',
        margin: 0,
        marginLeft: '10px',
    },
    submitBtn: {
        border: 'none',
        padding: "10px 20px",
        margin: '15px',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontFamily: 'inherit',
        fontSize: '20px',
        fontWeigth: 600,
        width: '160px',
        borderRadius: '15px'
    },
    itemPhoto: {
        minWidth: "320px",
        "& figure > figcaption": {
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.primary.dark,
            fontSize: '16px',
            marginTop: '3px',
            "& > div": {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            },
            "& span": {
                display: 'flex'
            }
        }
    },
    errorText: {
        fontFamily: 'plexSansLight',
        marginTop: 0,
        color: "red",
        fontSize: "12px !important",
        display: 'inline-block',
        marginLeft: '5px',
        fontWeight: 500,
    },
    popUp: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px',
        alignItems: 'center',
        "& p:nth-child(1)": {
            fontSize: '18px',
            fontWeight: 500
        },
        "& > div[id='hide']": {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
            "& > div:nth-child(1)": {
                width: '80%'
            }
        },
        "& > div[id='show']": {
            display: 'flex',
            flexDirection: 'row-reverse',
            "& p": {
                fontSize: '18px',
                fontWeight: 500,
                marginTop: '5px',
                color: '#5c5a5b'
            },
            "& > div:nth-child(1)": {
                width: '50%'
            },
            "& > div:nth-child(2)": {
                width: '50%',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                "& p": {
                    display: 'flex',
                    "& span": {
                        borderRadius: '50%',
                        padding: '4px',
                        width: '22px',
                        height: '22px',
                        textAlign: 'center',
                        marginRight: '12px',
                    },
                },
                "& p[id='done']": {
                    display: 'flex',
                    '&::before': {
                        content: '""',
                        backgroundImage: `url(${tick})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        display: 'block',
                        width: '30px',
                        height: 'auto'
                    },
                    "& span": {
                        display: 'none',
                    },
                },
                "& p[id='loading']": {
                    display: 'flex',
                    "& span": {
                        content: '"1"',
                        backgroundImage: `url(${loader})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        display: 'block',
                        justifyContent: 'center',
                        height: 'auto'
                    },
                },
                "& p[id='waiting']": {
                    display: 'flex',
                    "& span": {
                        content: '"1"',
                        display: 'block',
                        border: '1px solid #000',
                        color: '#000'
                    },
                },
            }
        },
        "& > div:nth-child(3)": {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            "& a": {
                textAlign: 'center',
                backgroundColor: '#000',
                color: '#fff',
                padding: '10px 15px',
                boxSizing: 'border-box',
                margin: '6px',
                borderRadius: '10px',
                textDecoration: 'none'
            }
        }
    }
}))