import * as types from '../../../redux/Types'
import { INft } from '../../../Utils/Interfaces'

export interface IFixedPrice {
    DataFixedPrice: string[] | null,
    LoadingFixedPrice: boolean,
    ErrorFixedPrice: boolean
}
const initialState = {
    DataFixedPrice: [],
    LoadingFixedPrice: false,
    ErrorFixedPrice: false
}

export default function FixedPriceReducer(state: IFixedPrice = initialState, action: any) {
    switch (action.type) {
        case types.FIXED_PRICE_REQUEST:
            return {
                ...state,
                LoadingFixedPrice: true,
                ErrorFixedPrice: false,
                DataFixedPrice: action.payload
            }
        case types.FIXED_PRICE_SUCCESS:
            return {
                ...state,
                LoadingFixedPrice: false,
                ErrorFixedPrice: false,
                DataFixedPrice: action.payload
            }
        case types.FIXED_PRICE_FAILED:
            return {
                ...state,
                LoadingFixedPrice: false,
                ErrorFixedPrice: true,
                DataFixedPrice: action.payload
            }
        default:
            return state
    }
} 