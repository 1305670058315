import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    buyInLazy: {
        marginTop: '80px',
        "& > div": {
            display: 'grid',
            gridTemplateColumns: '3fr 1.5fr',
            gridColumnGap: '20px',
            "& > div:nth-child(1)": {
                "& > div": {
                    display: 'flex',
                    flexDirection: "column",
                    "& > p:nth-child(1)": {
                        color: theme.palette.primary.main,
                        fontFamily: 'ElMessiri',
                        fontWeight: 700,
                        fontSize: "32px",
                        "& > span": {
                            fontSize: '24px'
                        }
                    },
                    "& > div:nth-child(2)": {
                        display: 'flex',
                        width: "70%",
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '7px',
                        backgroundColor: "#f2f2f2",
                        padding: '8px',
                        height: '60px',
                        boxSizing: 'border-box',
                        alignItems: 'center',
                        "& > p": {
                            fontFamily: 'Raleway',
                            fontSize: '24px',
                            fontWeight: 700,
                            "& > span": {
                                fontSize: '16px',
                                color: '#676767'
                            }
                        },
                    },
                    "& > div:nth-child(3)": {
                        width: '70%',
                        marginTop: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between",
                        padding: '8px',
                        boxSizing: 'border-box',
                        "& > span": {
                            fontFamily: 'Raleway',
                            fontWeight: 500,
                            fontSize: '20px',
                            color: '#676767'
                        }
                    },
                    "& > div:nth-child(4)": {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "space-between",
                        padding: '8px',
                        boxSizing: 'border-box',
                        "& > p": {
                            fontFamily: 'Raleway',
                            fontWeight: 500,
                            fontSize: '18px',
                            color: '#676767'
                        },
                        "& > button": {
                            fontFamily: 'ElMessiri',
                            fontWeight: 700,
                            fontSize: '22px',
                            height: '55px',
                            color: '#FFF',
                            width: '230px',
                            marginTop: '20px',
                            background: "#94794F",
                            borderRadius: '7px',
                            boxShadow: '0px 5px 2px #37254B',
                            cursor: "pointer",
                            transition: 'all 0.15s ease-out',
                            border: "none",
                            "&:disabled": {
                                backgroundColor: 'rgba(96, 64, 131, 0.3)',
                                cursor: 'not-allowed'
                            },
                            "&:active:not([disabled])": {
                                translate: "0 5px",
                                boxShadow: "none",
                            }
                        }
                    }
                }
            }
        }
    },
    lazyMintConfirmation: {
        "& > div": {
            marginTop: "60px",
            "& > p:nth-child(1)": {
                width: "100%",
                textAlign: 'center',
                fontSize: "25px",
                fontWeight: 700,
                fontFamily: 'ElMessiri',
                marginBottom: '0',
            },
            "& > p:nth-child(2)": {
                width: '100%',
                textAlign: 'center',
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: 'Raleway',
                color: '#676767',
                testAlign: 'center',
                marginTop: '0'
            }
        }
    },

    lazyMintCongrats: {
        "& > div": {
            display: 'grid',
            placeItems: 'center',
            "& > div": {
                "& > p:nth-child(1)": {
                    width: "100%",
                    textAlign: 'center',
                    fontSize: "32px",
                    fontWeight: 700,
                    fontFamily: 'Raleway',
                    marginBottom: '0',
                    color: '#287D4A',
                },
                "& > p:nth-child(2)": {
                    width: "100%",
                    textAlign: 'center',
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: 'Raleway',
                    color: '#676767',
                    marginTop: '3px'
                }
            },
            "& > a": {
                fontFamily: 'ElMessiri',
                textDecoration: 'none',
                fontWeight: 700,
                fontSize: '22px',
                padding: '10px 120px',
                color: '#FFF',
                marginTop: '160px',
                background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0385417) 99.99%, rgba(0, 0, 0, 0) 100%), #604083",
                borderRadius: '7px',
                boxShadow: '0px 5px 2px #37254B',
                cursor: "pointer",
                transition: 'all 0.15s ease-out',
                border: "none",
                "&:active:not([disabled])": {
                    translate: "0 5px",
                    boxShadow: "none",
                }
            }
        }
    }
}))