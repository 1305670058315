import { put, takeLatest } from "redux-saga/effects";
import { BuyFixedPriceFailed, BuyFixedPriceSuccess, CancelFixedPriceFailed, CancelFixedPriceSuccess, FailedNFT, SuccessNFT } from "../../redux/Actions";
import { store } from "../../redux/Store";
import * as types from '../../redux/Types';
import API from "../../Utils/Api";
import ENDPOINTS from "../../Utils/Endpoints";

function* NFTWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.get(ENDPOINTS.NFT + `?tokenId=${action.payload.id}&creator=${action.payload.creator}&collection=${action.payload.collection ? action.payload.collection : ''}`)
        } catch (e: any) {
            console.error('e-MintToBackendWorker', e)
            return store.dispatch(FailedNFT({ payload: e }))
        }
    }
    yield Api()
    if (Response && Response?.data?.success === true) {
        yield put(SuccessNFT({ payload: Response }))
    } else {
        yield put(FailedNFT({ payload: Response.data }))
    }
}


export function* NFTWatcher() {
    yield takeLatest(types.GET_NFT, NFTWorker)
}


function* CancelFixPriceWorker(action: any) {

    let Response: any
    async function Api() {
        try {
            Response = await API.patch(ENDPOINTS.CancelFixPrice, action.payload)
        } catch (e: any) {
            console.error('e-CancelFixPriceWorker', e)
            return store.dispatch(CancelFixedPriceFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response && Response?.data?.success === true) {
        yield put(CancelFixedPriceSuccess({ payload: Response }))
    } else {
        yield put(CancelFixedPriceFailed({ payload: Response }))
    }
}


export function* CancelFixPriceWatcher() {
    yield takeLatest(types.CANCEL_FIX_PRICE_REQUEST, CancelFixPriceWorker)
}


function* BuyFixPriceWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.post(ENDPOINTS.BuyFixPrice, action.payload)
        } catch (e: any) {
            console.error('e-BuyFixPriceWorker', e)
            return store.dispatch(BuyFixedPriceFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response && Response?.data?.success === true) {
        yield put(BuyFixedPriceSuccess({ payload: Response }))
    } else {
        yield put(BuyFixedPriceFailed({ payload: Response }))
    }
}


export function* BuyFixPriceWatcher() {
    yield takeLatest(types.BUY_FIX_PRICE_REQUEST, BuyFixPriceWorker)
}