
import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    headerNav: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        "& > div:nth-child(1)": {
            position: 'relative',
            width: '100%',
            [theme.breakpoints.up('xs')]: {
                display: 'none'
            },
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'center',
            },
            "& > div:nth-child(1)": {
                minHeight: '100px',
                width: '100%',
                "& img": {
                    height: '300px',
                    filter: 'opacity(0.6)'
                }
            },
            "& > div:nth-child(2)": {
                marginTop: '10px',
                background: "rgba(255, 255, 255, 0.15)",
                borderRadius: "16px",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(8.5px)",
                webkitBackdropFilter: "blur(8.5px)",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                justifyContent: 'space-between',
                position: 'absolute',
                top: 1,
                width: '80%',
                "& a": {
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000',
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 8px',
                    [theme.breakpoints.up('sm')]: {
                        fontSize: '16px',

                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: '14px',
                        margin: '0 5px',
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: '16px',

                    },
                    [theme.breakpoints.up('xl')]: {
                        fontSize: '18px',

                    },
                    fontFamily: 'ElMessiriMedium',
                    fontWeight: '500',
                    fontSize: "16px"
                },
                "& > div": {
                    margin: '10px 2px 10px 10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between '
                },
                "& > div:nth-child(2)": {
                    margin: '10px 10px 10px 0px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    height: '40px',
                    "& div": {
                        "& div": {
                            display: 'flex',
                            justifyContent: 'center',
                        }
                    }
                },
            }
        },
    },
    searchBar: {
        width: "100%",
        position: 'relative',
        marginLeft: '13px',
        "& > div": {
            width: "41%",
            position: 'fixed',
            zIndex: '1000',
            borderRadius: '7px',
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            [theme.breakpoints.down("lg")]: {
                width: "38%",
            },
            [theme.breakpoints.down("md")]: {
                width: "30%",
            },

            "&:focus-within": {
                background: '#fff'
            },
            top: '10px',
            "& > div": {
                background: 'rgba(255, 255, 255, 0.15)',
                borderRadius: '7px',
                "& > div": {
                    borderRadius: '7px',
                    border: '0.5px solid #A5A4A4',
                    "& input": {
                        fontFamily: 'DellaRespiraMedium !Important',
                        fontSize: '15px',
                        color: 'A5A4A4'
                    },
                    "& fieldset": {
                        border: 'none'
                    }
                }
            }
        }
    },
    darkBackground: {
        color: 'red',
        MuiCssBaseline: {
            backgroundColor: 'black',
            styleOverrides: {
                backgroundColor: 'black',
                body: {
                    backgroundColor: 'black',
                    "& ::before": {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100 %",
                        height: "100 %",
                        backgroundColor: "black",
                        content: "",
                        pointerEvents: "none",
                        zIndex: 100,
                        opacity: 0.5,
                    }
                }
            }
        },
    },
    headerResponsive: {
        flexDirectioin: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
        },
        "& div[id='btn']": {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            "& a": {
                padding: '10px'
            },
            "& div[id='pop']": {
                position: 'fixed',
                width: '100%',
                bottom: 0,
                top: 0,
                backdropFilter: "blur(5px)",
                height: "100vh",
                background: "rgba(0,0,0,0.3)",
                zIndex: "100000",
                color: "aquamarine",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "center",
                "& > div": {
                    width: '100%',
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: 'center',
                    padding: '30px',
                    alignItems: 'center',
                    "& a": {
                        fontFamily: 'ElMessiriMedium',
                        fontSize: '18px',
                        color: '#4B4B4B',
                        textAlign: 'center',
                        textDecoration: 'none'
                    },
                    // "& button": {
                    //     width: '80%',
                    //     background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%)#020202',
                    //     fontFamily: 'ElMessiriMedium',
                    //     fontSize: '18px',
                    //     color: '#fff',
                    //     border: 'none',
                    //     padding: '6px',
                    //     borderRadius: '7px'
                    // }
                }
            },
            "& div": {
                display: 'flex',
                flexDirection: 'row',
            }
        },
        "& div[id='search']": {
            width: '100%',
            display: 'flex',
            padding: '10px',
            columnGap: '10px',
            "& button": {
                border: "1px solid #989898",
                padding: '5px'
            },
            "& div": {
                width: '100%',
                "& div": {
                    "& div": {
                        background: "#d9d9d9",
                        "& input": {
                            width: '100% !important'
                        },
                        "& fieldset": {
                            border: 'none'
                        }
                    }
                }
            }
        }
    },
    searchResult: {
        position: 'fixed',

    },
    connectButton: {
        backgroundColor: "#000",
        color: '#fff !important',
        borderRadius: '15px',
        height: '45px',
        width: '10vw',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '200px',
            whiteSpace: 'nowrap',
            alignItems: 'center',
            height: '32px'
        }
    },
    popUp: {
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            margin: '0',
        },
        padding: '30px',
        margin: '50px',
        "& .css-bdhsul-MuiTypography-root-MuiDialogTitle-root": {
            textAlign: 'center',
            fontFamily: 'inherit',
            fontSize: "22px",
            marginBottom: 0,
            paddingBottom: '2px'
        },

        "& p:nth-child(2)": {
            margin: "10px 60px 10px 60px",
            color: theme.palette.primary.dark,
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: "0",
            }
        },
    },
    selectAWallet: {
        "& > p:nth-child(1)": {
            fontFamily: 'RalewayBold',
            fontSize: '15px',
            color: theme.palette.primary.main,
            textAlign: 'center',
            marginTop: '40px'
        },
        "& > p:nth-child(2)": {
            fontFamily: 'ElMessiriBold',
            fontSize: '24px',
            color: '#000',
            marginTop: '6px',
            marginBottom: "0",

        },
        "& > p:nth-child(3)": {
            fontFamily: 'RalewayBold',
            fontSize: '14px',
            color: "#676767",
            textAlign: 'center',
            marginTop: '0'
        }
    },
    metamaskSec: {
        "& > div": {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '20px',
            "& > a:nth-child(1)": {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontSize: '22px',
                margin: '0 20px 20px',
                cursor: "pointer",
                border: '1px solid #c6c6c6',
                borderRadius: '7px',
                width: '230px',
                justifyContent: 'space-around',
                "& > img": {
                    width: '30px',
                    height: '30px'
                },
                "& p": {
                    color: '#000 !important',
                    boxSizing: 'border-box',
                    fontFamily: 'ElMessiriSemiBold',
                    fontSize: '15px'
                },
                textDecoration: 'none',
            },
            "& p[id='tos']": {
                color: '#676767 !important',
                fontFamily: 'RalewaySemiBold',
                fontSize: '13px',
            },
            "& > a[id='setupWallet']": {
                color: '#676767 !important',
                fontFamily: 'RalewayMedium',
                fontSize: '13px',
                marginBottom: '20px'
            }
        }
    },
    signMsg: {
        "& p:nth-child(1)": {
            color: '#000',
            fontFamily: 'ElMessiriBold',
            fontSize: '22px',
            marginBottom: '10px',
            textAlign: 'center',
            marginTop: '40px',
            [theme.breakpoints.down("sm")]: {
                margin: "40px 5px 0"
            }
        },
        "& p:nth-child(2)": {
            color: '#676767',
            fontFamily: 'Raleway',
            fontSize: '14px',
        },
    },
    signMessageSec: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& > div[id='singning']": {
            width: "230px",
            background: "#ECECEC",
            fontSize: '16px',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            justifyContent: 'center',
            border: '1px solid #C6C6C6',
            boxShadow: '0px 5px 2px rgba(0, 0, 0, 0.25)',
            borderRadius: '7px',
            color: '#000',
            fontFamily: 'ElMessiriSemiBold',
            margin: '60px 0'
        },
        "& > div[id='buttons']": {
            margin: "40px 0 ",
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'

        },
        "& button": {
            margin: '5px 0',
            fontSize: '18px',
            fontWeight: 600,
            color: '#fff',
            border: 'none',
            textAlign: 'center',
            padding: '10px 45px',
            borderRadius: '10px',
            cursor: 'pointer',
            width: '200px',
        },
        "&  button:nth-child(1)": {
            backgroundColor: '#000',
        },
        "&  button:last-child": {
            backgroundColor: theme.palette.primary.dark,
        },
        "& button:hover": {
            backgroundColor: '#000',
        },
    },
    dropDown: {
        color: "#000",
        "&.css-1869usk-MuiFormControl-root": {
            minWidth: '140px'
        },
        "& > div": {
            "& fieldset": {
                border: 'none !important'
            }
        },
    },
    LinkInDropDown: {
        "& a": {
            textDecoration: 'none',
            color: '#000'
        }
    },
    itemInSearch: {
        padding: '0px',
        display: 'flex',
        flexDirection: 'row',
        textDecoration: 'none',
    },
    imgInItemSearch: {
        width: "42px",
        height: '42px',
        marginRight: '3px',
        "&[id='avatar']": {
            borderRadius: '50%'
        },
        "&[id='logo']": {
            borderRadius: '5px'
        }
    },
    titleInItemSearch: {
        fontFamily: 'DellaRespiraMedium',
        fontSize: '14px',
        color: '#000',
        margin: 0,
        textDecoration: 'none'
    },
    title2InItemSearch: {
        fontFamily: 'DellaRespiraMedium',
        fontSize: '13px',
        color: '#6A6A6A',
        margin: 0,
        textDecoration: 'none'
    },
    ImagePlaceholder: {
        width: "42px",
        height: '42px',
        borderRadius: '5px',
        marginRight: '3px',
        background: "linear-gradient(180deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
        "&[id='avatar']": {
            borderRadius: '50%'
        },
        "&[id='logo']": {
            borderRadius: '5px'
        }
    },
    menuProfile: {
        "& a[id='create']": {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        "& button": {
            "& span": {
                "& div": {
                    [theme.breakpoints.down('sm')]: {
                        margin: '0'
                    }
                }
            }
        }
    }
}))