import * as types from '../../redux/Types';
import { IWEB3Reducer } from '../../Utils/Interfaces';

const initialState = {
    Data: [],
    isLoading: false,
    Error: false,
    DataWEB3: [],
    isLoadingWEB3: false,
    ErrorWEB3: false,
    web3: false,
    steps: 0
}

export default function CreateCollectionReducer(state: IWEB3Reducer = initialState, action: any) {
    switch (action.type) {
        case types.CREATE_COLLECTION_REQUEST:
            return {
                ...state,
                isLoading: true,
                Error: false,
                Data: action.payload,
                web3: false,
                steps: 1
            }
        case types.CREATE_COLLECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Error: false,
                Data: action.payload
            }
        case types.CREATE_COLLECITON_FAILED:
            return {
                ...state,
                Error: true,
                isLoading: false,
                Data: action.payload
            }
        case types.CREATE_COLLECTION_WEB3_REQUEST:
            return {
                ...state,
                ErrorWEB3: false,
                isLoadingWEB3: true,
                DataWEB3: action.payload,
                web3: true
            }
        case types.CREATE_COLLECTION_WEB3_SUCCESS:
            return {
                ...state,
                ErrorWEB3: false,
                isLoadingWEB3: false,
                DataWEB3: action.payload,
                web3: true,
                isLoading: false,
                Error: false,
                Data: null,
            }
        case types.CREATE_COLLECTION_WEB3_FAILED:
            return {
                ...state,
                ErrorWEB3: true,
                isLoadingWEB3: false,
                DataWEB3: action.payload,
                web3: false,
                isLoading: false,
                Error: false,
                Data: null,
            }
        case types.CHANGE_WEB3_STATE_IN_CREATE_COLLECTION:
            return {
                ...state,
                ErrorWEB3: false,
                isLoadingWEB3: false,
                DataWEB3: action.payload,
                web3: false,
            }
        case types.CREATE_COLLECTION_STEPS:
            return {
                ...state,
                isLoading: false,
                Error: false,
                Data: null,
                web3: true,
                steps: action.payload
            }
        default:
            return state
    }
} 