import { put, takeLatest } from "redux-saga/effects";
import { FailedNFT, FixedPriceFailed, FixedPriceSuccess, SuccessNFT } from "../../../redux/Actions";
import { history, store } from "../../../redux/Store";
import * as types from '../../../redux/Types';
import API from "../../../Utils/Api";
import ENDPOINTS from "../../../Utils/Endpoints";

function* FixedPriceWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.post(ENDPOINTS.FIX, action.payload)
        } catch (e: any) {
            console.error('e-FixedPriceWorker', e)
            return store.dispatch(FixedPriceFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response && Response?.data?.success === true) {
        yield put(FixedPriceSuccess({ payload: Response }))
        history.push(`${action.payload.param.collection !== undefined ? `/${action.payload.param.creator}/${action.payload.param.collection}/${action.payload.param.id}` : `/${action.payload.param.creator}/${action.payload.param.id}`}`)
    } else {
        yield put(FixedPriceFailed({ payload: Response.data }))
    }
}


export function* FixedPriceWatcher() {
    yield takeLatest(types.FIXED_PRICE_REQUEST, FixedPriceWorker)
}

