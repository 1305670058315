import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './StyleMarketComponents'
import EtherLogo from '../../Assets/Img/EthereumLogo.svg'
import { InputAdornment } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import API from '../../Utils/Api'
import ENDPOINTS from '../../Utils/Endpoints'

function InputWithBackground(props: any) {
    const classes = useStyles()

    const { setPrice, register, errors, minPrice, ethereumExchange, setEthereumExchange, LoadingNFT, defaultValue } = props

    const { data, refetch, isLoading } = useQuery(['EthPrice'], () => {
        return API.get(ENDPOINTS.EthPrice)
    })

    if (defaultValue) {
        GetEthPriceInDollor(defaultValue)
    }

    function GetEthPriceInDollor(value: any) {
        const ethereumPrice: number = data?.data?.data?.ethUsd
        let etherValue = value
        const total = etherValue * ethereumPrice
        const threeDigits: any = total.toFixed(3)
        setEthereumExchange(threeDigits)
        return total
    }

    async function GetEthPrice(e: React.KeyboardEvent<HTMLInputElement>): Promise<any> {
        await refetch()
        const ethereumPrice: number = data?.data?.data?.ethUsd
        let etherValue: number = Number((e.target as HTMLInputElement).value)
        if (!isNaN(etherValue)) {
            setPrice(etherValue)
        }
        const total = etherValue * ethereumPrice
        const threeDigits: any = total.toFixed(3)
        setEthereumExchange(threeDigits)
    }

    return (
        <div className={classes.mainInputWithBackground}>
            <TextField
                disabled={LoadingNFT}
                size="small"
                type="number"
                id="price"
                value={defaultValue}
                label={errors?.price?.message}
                InputProps={{
                    inputProps: {
                        step: 0.1
                    },
                    disableUnderline: true,
                    classes: { root: classes.input },
                    endAdornment:
                        <InputAdornment
                            sx={{
                                width: '120px',
                                fontSize: '24px'
                            }}
                            position="start">{isNaN(ethereumExchange) ? "" : ethereumExchange}$</InputAdornment>,
                }}
                {...register("price", {
                    min: { value: minPrice, message: `Price must be at least ${minPrice} ETH` },
                    required: { value: true, message: 'Enter a value' },
                    onChange: (e: any) => GetEthPrice(e)
                })}
                error={Boolean(errors?.price?.message)}
            />
            <p>ETH</p>
            <img src={EtherLogo} alt="etherLogo" />
        </div>

    )
}

export default InputWithBackground