import { Button, Grid, InputAdornment, Slider, Stack, TextField, Typography, } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import photo from '../../../Assets/Img/defaultAvatar.png'
import FeaturedPhoto from '../../../Assets/Img/featured.png'
import Alerts from '../../../Components/Alert/Alert'
import CircularLoading from '../../../Components/CircularProgress/CircularProgress'
import CircularProgressWithLabel from '../../../Components/CircularProgressBar/CircularProgressBar'
import { CompleteCollectionRequest, GetCollectionRequest } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import API from '../../../Utils/Api'
import ENDPOINTS from '../../../Utils/Endpoints'
import { ICollection, IUploadFile } from '../../../Utils/Interfaces'
import { useStyle } from '../SingleCollection/StyleSingleCollection'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton, styled } from '@material-ui/core'
import EditLayout from '../../../Components/EditLayout/EditLayout'
import activity from '../../../Assets/Img/activity.svg'
import statistics from '../../../Assets/Img/statistics.svg'
import plus from '../../../Assets/Img/plus.svg'
import { Container } from '@mui/system'
import { DeleteForever, Facebook, Instagram, Twitter } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import discord from '../../../Assets/Img/discord.svg'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'
import camera from '../../../Assets/Img/camera.svg'
import TextEditor from '../../Mint/SubComponents/TextEditor/textEditor'
import { convertFromRaw, EditorState } from 'draft-js'
import { PopUp } from '../../../Components/PopUp/PopUp'
import Cropper from 'react-easy-crop'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { getCroppedImg } from '../../../Components/CropImage/cropImage'
import { toast } from 'react-toastify'

const dataTest: any = {
    "blocks": [
        {
            "key": "8i090",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 16,
                    "style": "BOLD"
                }
            ],
            "entityRanges": [],
            "data": {}
        },
    ],
    "entityMap": {}
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        fontSize: '20px',
        textAlign: 'center',
    },
}));

function EditCollection({ collection, setEditCollection }: any) {
    const { GetCollectionData, GetCollectionLoading } = useAppSelector((state) => state.EditCollectionReducer)
    const [avatarImg, setAvatarImg] = useState<IUploadFile>({ previewURL: GetCollectionData?.data?.data?.collection?.logo, URL: GetCollectionData?.data?.data?.collection?.logo })
    const [featuredIMG, setFeaturedIMG] = useState<IUploadFile>({ previewURL: collection?.data?.data?.collection?.image, URL: collection?.data?.data?.collection?.image })
    const [bannerIMG, setBannerIMG] = useState<IUploadFile>({ previewURL: collection?.data?.data?.collection?.banner, URL: collection?.data?.data?.collection?.banner })
    const { Data, Error, isLoading } = useAppSelector((state) => state.EditCollectionReducer)
    const [nameOfInputImage, setNameOfInputImage] = useState<string>('')
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [descriptionRawText, setDescriptionRawText] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [tabToggle, setTabToggle] = useState<string>('profile')
    const [waiting, setWaiting] = useState<boolean>(false)
    const [imageSrc, setImageSrc] = useState<any>()
    const [avatarProgressBar, setAvatarProgressBar] = useState<number>(0)
    const [avatarPreview, setAvatarPreview] = useState<any>()
    const [progressBar, setProgressBar] = useState<number>(0)
    const [bannerPreview, setbannerPreview] = useState<any>()
    const [uploadedAvatarUrl, setUploadedAvatarUrl] = useState<any>()
    const [uploadedBannerUrl, setuploadedBannerUrl] = useState<any>()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [progress, setProgress] = useState<any>({
        avatar: 0,
        featured: 0,
        banner: 0
    })

    const [fileSizeError, setFileSizeError] = useState<any>({
        avatar: "",
        featured: '',
        banner: ''
    })
    const classes = useStyle()
    const location: any = useLocation()
    const dispatch = useAppDispatch()
    const param = useParams()
    const navigate = useNavigate()
    const { handleSubmit, formState: { errors }, register, setValue } = useForm<ICollection>()

    const banner: any = uploadedBannerUrl?.data?.data?.path
    const avatar: any = uploadedAvatarUrl?.data?.data?.path

    const SubmitCollection: SubmitHandler<ICollection> = (data: ICollection) => {
        data.id = GetCollectionData?.data?.data?.collection?._id
        data.logo = avatar ? avatar : avatarPreview
        data.banner = banner ? banner : bannerPreview
        data.description = description
        data.title = param.username
        dispatch(CompleteCollectionRequest({
            payload: { data }
        }))
    }

    useEffect(() => {
        if (setEditCollection) {
            setEditCollection(true)
        }
    }, [Data?.data?.data?.message === "updated collection success!"])

    useEffect(() => {
        dispatch(GetCollectionRequest({ payload: { username: param.username } }))
        setDescription(collection?.data?.data?.collection?.description)
        return (() => {
            clearTimeout(timeOutError)
        })
    }, [location])


    useEffect(() => {
        setAvatarPreview(GetCollectionData?.data?.data?.collection?.logo)
        setbannerPreview(GetCollectionData?.data?.data?.collection?.banner)
        setAvatarImg((prevState: any) => ({ ...prevState, URL: GetCollectionData?.data?.data?.collection?.logo, previewURL: GetCollectionData?.data?.data?.collection?.logo }))
        setBannerIMG((prevState: any) => ({ ...prevState, URL: GetCollectionData?.data?.data?.collection?.banner, previewURL: GetCollectionData?.data?.data?.collection?.banner }))
        setValue('name', GetCollectionData?.data?.data?.collection.name)
        setValue('social.instagram', GetCollectionData?.data?.data?.collection.social[0]?.instagram)
        setValue('social.website', GetCollectionData?.data?.data?.collection.social[0]?.website)
        setValue('social.twitter', GetCollectionData?.data?.data?.collection.social[0]?.twitter)
        setValue('social.discord', GetCollectionData?.data?.data?.collection.social[0]?.discord)
        setValue('social.facebook', GetCollectionData?.data?.data?.collection.social[0]?.facebook)
    }, [GetCollectionData?.data?.data?.collection])

    var timeOutError: any;
    function TimeOut(): void {
        timeOutError = setTimeout(() => setFileSizeError({ ...fileSizeError, avatar: '', featured: '', banner: '' }), 5000)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    // const UploadAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const id: string = e.target.id

    //     const UploadedFile = (props: any) => {

    //         if (id == "avatar") {
    //             setAvatarImg((prevState: any) => ({ ...prevState, URL: props.data.data.path }))
    //         }
    //         if (id == "featured") {
    //             setFeaturedIMG((prevState: any) => ({ ...prevState, URL: props.data.data.path }))
    //         }
    //         if (id == "banner") {
    //             setBannerIMG((prevState: any) => ({ ...prevState, URL: props.data.data.path }))
    //         }
    //     }
    //     if (e.target.files) {
    //         const size: number = e.target.files[0]?.size
    //         const photoUploaded: File = e.target.files[0]
    //         setNameOfInputImage(e.target.name)
    //         if (size > 1e+7) {
    //             setFileSizeError({
    //                 ...fileSizeError, [id]: 'File Must be less than 10MB'
    //             })
    //             TimeOut()
    //         } else {
    //             const formData = new FormData();
    //             formData.append('file', photoUploaded)
    //             const config = {
    //                 onUploadProgress: function (progressEvent: any) {
    //                     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //                     setProgress((prevState: any) => ({ ...prevState, [id]: percentCompleted }))
    //                 }
    //             }
    //             const api = () => {
    //                 API.post(ENDPOINTS.MediaUploader, formData, config).then((Result: any) => UploadedFile(Result))
    //             }
    //             api()
    //             const preview: string = URL.createObjectURL(photoUploaded)
    //             if (id == "avatar") {
    //                 setAvatarImg((prevState: any) => ({ ...prevState, previewURL: preview }))
    //             }
    //             if (id == "featured") {
    //                 setFeaturedIMG((prevState: any) => ({ ...prevState, previewURL: preview }))
    //             }
    //             if (id == "banner") {
    //                 setBannerIMG((prevState: any) => ({ ...prevState, previewURL: preview }))
    //             }
    //         }
    //     }
    // }


    function TabToggle(props: string): void {
        setTabToggle(props)
    }
    const uploadCroppedImage = useCallback(async () => {
        setWaiting(true)
        try {
            const croppedImage: any = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
            )
            const loadingBar = {
                onUploadProgress: function (progressEvent: any) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    if (nameOfInputImage === "avatar") {
                        setAvatarProgressBar(percentCompleted)
                    }
                    if (nameOfInputImage === "banner") {
                        setProgressBar(percentCompleted)
                    }
                }
            }
            croppedImage.toBlob(
                (blob: any) => {
                    const formData = new FormData();
                    formData.append('file', blob)
                    const url = URL.createObjectURL(blob)

                    if (nameOfInputImage === "avatar") {
                        setAvatarPreview(url)
                    }
                    if (nameOfInputImage === "banner") {
                        setbannerPreview(url)
                    }
                    API.post(ENDPOINTS.MediaUploader, formData, loadingBar).then((Result: any) => {
                        toast.success("Image Uploaded Successfully")
                        setWaiting(false)
                        if (nameOfInputImage === "avatar") {
                            setUploadedAvatarUrl(Result)
                        }
                        if (nameOfInputImage === "banner") {
                            setuploadedBannerUrl(Result)
                        }
                    }).catch((e: any) => {
                        console.error(e)
                        setWaiting(false)
                    })
                }
            )
            setOpenPopup(false)
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels])


    const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const size = e.target.files[0]?.size
            if (size > 1e+7) {
                toast.error('File Must be less than 10MB')
                return
            }
            setNameOfInputImage(e.target.name)
            const reader = new FileReader();
            let file: any = e?.target?.files

            if (file.length > 0) {
                reader?.readAsDataURL(e?.target?.files[0])
            }
            reader.addEventListener("load", () => {
                setImageSrc(reader.result)
                if (e.target.name === "avatar") {
                    setAvatarPreview(reader.result)
                    setOpenPopup(true)
                }
                if (e.target.name === "banner") {
                    setbannerPreview(reader.result)
                    setOpenPopup(true)
                }
            })
        }
    }

    /** image preview user select the photo and then a pop up appear user can crop and resize the photo.
     * 
     * @function shows popup
     */
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    function onClosePopup() {
        setOpenPopup(false)
        //setWaiting(false)
        if (nameOfInputImage === "avatar") {
            setAvatarImg({ URL: '', previewURL: '' })
        }
        if (nameOfInputImage === "banner") {
            setBannerIMG({ URL: "", previewURL: '' })
        }
    }

    function removeImage() {
        setAvatarPreview('')
        setWaiting(false)
    }

    function removeBanner() {
        setbannerPreview('')
        setWaiting(false)
    }

    const Description: any = GetCollectionData?.data?.data?.collection?.description
    const contentState = convertFromRaw(Description !== undefined ? JSON.parse(Description) : dataTest)
    const editorState = EditorState.createWithContent(contentState)
    return (
        <EditLayout title="Edit Collection" >
            <ul className={classes.sideButtons}>
                <li><button onClick={() => TabToggle('profile')}><img src={plus} alt='avatar' />Edit Profile</button></li>
                <li> <button onClick={() => navigate('/mint')}><img src={plus} alt='avatar' />Add New Item</button></li>
                <li> <button onClick={() => TabToggle('auction')}><img src={statistics} alt='avatar' />Statistics</button></li>
                <li> <button onClick={() => TabToggle('notification')}><img src={activity} alt='avatar' />Activities</button></li>
            </ul>
            <div id='edit' className={classes.addCollection}>
                <form onSubmit={handleSubmit(SubmitCollection)}>
                    <Container maxWidth={'md'}>
                        <div id="leftSide">
                            <div>
                                <p className={classes.titleCollectionSetting}>
                                    {
                                        isLoading || GetCollectionLoading ? <SkeletonLoading shape="" width='120px' picture={false} text={true} /> : `${GetCollectionData?.data?.data?.collection?.name}`
                                    }
                                </p>
                                <p>{GetCollectionData?.data?.data?.collection?.symbol}</p>
                            </div>
                            <div>
                                <p className={classes.titleCollectionSetting}>Description</p>
                                <TextEditor
                                    editorState={editorState}
                                    setDescription={setDescription}
                                    setDescriptionRawText={setDescriptionRawText}
                                    maxLength={1000}
                                />
                            </div>
                            <div>
                                <label className={classes.titleCollectionSetting} htmlFor="socialMedia">Links</label>
                                <TextField
                                    className={classes.socialMediaInput}
                                    InputProps={{
                                        startAdornment: <InputAdornment className={classes.inputText} position="start"><LanguageIcon sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://</span></InputAdornment>,
                                    }}
                                    {...register("social.website", {
                                        required: false,
                                    }
                                    )}
                                    variant="outlined" size='small' />

                                <TextField
                                    className={classes.socialMediaInput}
                                    InputProps={{
                                        startAdornment: <InputAdornment className={classes.inputText} position="start"><Twitter sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://twitter.com/</span></InputAdornment>,
                                    }}
                                    {...register("social.twitter", {
                                        required: false,
                                    }
                                    )}
                                    variant="outlined" size='small' />

                                <TextField
                                    className={classes.socialMediaInput}
                                    {...register("social.discord", {
                                        required: false,
                                    }
                                    )}
                                    InputProps={{
                                        startAdornment: <InputAdornment className={classes.inputText} position="start" ><img src={discord} style={{ marginRight: '14px', marginLeft: '12px' }} /><span>https://www.discord.com/</span></InputAdornment>,
                                    }}
                                    variant="outlined" size='small' />

                                <TextField
                                    className={classes.socialMediaInput}
                                    {...register("social.facebook", {
                                        required: false,
                                    }
                                    )}
                                    InputProps={{
                                        startAdornment: <InputAdornment className={classes.inputText} position="start" ><Facebook sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://www.facebook.com/</span></InputAdornment>,
                                    }}
                                    variant="outlined" size='small' />

                                <TextField
                                    className={classes.socialMediaInput}
                                    {...register("social.instagram", {
                                        required: false,
                                    }
                                    )}
                                    InputProps={{
                                        startAdornment: <InputAdornment className={classes.inputText} position="start" ><Instagram sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://www.instagram.com/</span></InputAdornment>,
                                    }}
                                    variant="outlined" size='small' />
                            </div>
                            <button className={classes.saveButton} type="submit">{(avatarProgressBar != 0 && avatarProgressBar != 100) || (progressBar != 0 && progressBar != 100) ? <CircularLoading size={25} /> : "Save Changes"}</button>
                        </div>
                        <div id="rightSide">

                            <PopUp onClose={onClosePopup} open={openPopup}>
                                <Grid className={classes.cropContainer}>
                                    <p>Edit Collection Picture </p>
                                    <Cropper
                                        image={nameOfInputImage === "avatar" ? avatarPreview : bannerPreview}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={nameOfInputImage === "avatar" ? 3 / 3 : 12 / 5}
                                        showGrid={true}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                        cropShape="rect"
                                    />
                                    <div className={classes.sliderContainer}>
                                        <Stack spacing={2} width="100%" direction="row" sx={{ mb: 1 }} alignItems="center">
                                            <ZoomOutIcon />
                                            <Slider
                                                size='small'
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-labelledby="Zoom"
                                                onChange={(e, zoom: any) => setZoom(zoom)}
                                            />
                                            <ZoomInIcon />
                                        </Stack>
                                        <Button
                                            onClick={uploadCroppedImage}
                                            variant="contained"
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </Grid>
                            </PopUp>

                            <div>
                                <p className={classes.titleCollectionSetting}>Collection Logo
                                    {/* <BootstrapTooltip title="Recommended 350px * 350px   Max Size: 50MB" placement='top'>
                                        <ErrorOutlineIcon sx={{ color: "#5c5a5b", marginLeft: '5px', width: '15px', verticalAlign: 'middle' }} />
                                    </BootstrapTooltip> */}
                                </p>
                                <input type="file" name="avatar" style={{ display: 'none' }} id="avatar" onChange={uploadImage} />
                                {
                                    !isLoading ?
                                        (
                                            avatarPreview ?
                                                <div className={classes.previewImgContainer}>
                                                    <IconButton onClick={() => removeImage()} >
                                                        <DeleteForever />
                                                    </IconButton>
                                                    <img src={avatarPreview} alt="avatar" width="150px" height="150px" style={{ borderRadius: "7px", filter: avatarProgressBar != 0 && avatarProgressBar != 100 ? "grayscale(1)" : "none" }} />
                                                    {avatarProgressBar != 0 && avatarProgressBar != 100 && <CircularProgressWithLabel value={avatarProgressBar} />}
                                                </div>
                                                :
                                                <label htmlFor="avatar" className={classes.UploadAvatar} >
                                                    <img src={camera} alt="uploadPhoto" />
                                                </label>
                                        )
                                        : <SkeletonLoading text={false} shape='circular' picture={true} width='150px' />
                                }
                                {/* {progress.avatar != 0 && progress.avatar != 100 && <CircularProgressWithLabel value={progress.avatar} />} */}
                            </div>
                            <div >
                                <p className={classes.titleCollectionSetting} > Collection Cover
                                    {/* <BootstrapTooltip title="Recommended 1920px * 300px   Max Size: 50MB" placement='top'>
                                        <ErrorOutlineIcon sx={{ color: "#5c5a5b", marginLeft: '5px', width: "15px", verticalAlign: 'middle' }} />
                                    </BootstrapTooltip> */}
                                </p>
                                <input type="file" name="banner" style={{ display: 'none' }} id="banner" onChange={uploadImage} />
                                {
                                    !isLoading ?
                                        (
                                            bannerPreview ?
                                                <div className={classes.previewImgContainerBanner}>
                                                    <IconButton onClick={() => removeBanner()} >
                                                        <DeleteForever />
                                                    </IconButton>
                                                    <img src={bannerPreview} alt="banner" width="100%" height="100%" style={{ borderRadius: '7px', filter: progressBar != 0 && progressBar != 100 ? "grayscale(1)" : "none" }} />
                                                    {progressBar != 0 && progressBar != 100 && <CircularProgressWithLabel value={progressBar} />}
                                                </div>
                                                :
                                                <label htmlFor="banner" className={classes.UploadBanner}>
                                                    <img src={camera} alt="uploadPhoto" />
                                                </label>
                                        )
                                        :
                                        <SkeletonLoading text={false} shape='rectangular' picture={true} width='150px' />
                                }
                                {/* {progress.banner != 0 && progress.banner != 100 && <CircularProgressWithLabel value={progress.banner} />} */}
                            </div>
                        </div>
                    </Container>
                </form >
            </div>
        </EditLayout >
    )
}

export default EditCollection