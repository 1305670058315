import { Container } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { Card, LazyCard } from '../../../Components/CardSection/CardSection'
import { GetNFT } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import API from '../../../Utils/Api'
import ENDPOINTS from '../../../Utils/Endpoints'
import { ContractInstance, FromWei } from '../../../Utils/Utils'
import { useStyles } from './Styles'
import LAZY_COLLECTION from '../../../Web3/ABI/LazyCollection.json'
import { GetUserWalletBalance } from '../../../Utils/GetUserWalletBalance'

function BuyInLazy() {
    const [functionOperationSteps, setFunctionOperationSteps] = useState<number>(0)
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT, CancelFixPriceData, CancelFixPriceLoading, CancelFixPriceError, BUYFixPriceLoading, BUYFixPriceError, BUYFixPriceData } = useAppSelector(state => state.NFTReducer)
    const param = useParams()
    const price: any = FromWei(DataNFT?.data?.data?.nft?.price)

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [])

    const getVoucherForMintLazy = async (data: any) => {
        const { data: response } = await API.post(ENDPOINTS.mintInLazy, { nft_id: data });
        return response.data;
    };

    const { mutate, isLoading, data } = useMutation(getVoucherForMintLazy, {
        onSuccess: (data: any) => {
            MintLazy(data)

        },
        onError: (e: any) => {
            toast.error(e.message)
        },
    });

    function getVoucher() {
        mutate(DataNFT?.data?.data?.nft?._id)
    }

    const web3 = new Web3(Web3.givenProvider)

    const MintLazy = async (props: any) => {
        const abi: any = LAZY_COLLECTION
        const account: any = localStorage.getItem('account')
        const contract = ContractInstance(abi, props.voucher.collectionAddress)
        const tx = {
            from: account,
        }
        const voucher = [
            `${props.voucher.price}`,
            `${props.voucher.buyer}`,
            `${props.voucher.royaltyFraction}`,
            `${props.voucher.collectionAddress}`,
            `${props.voucher.CID}`,
            `${props.voucher.NFTdb}`,
            `${props.voucher.collaborator_s}`,
            `${props.voucher.signature}`,
        ]

        await contract.methods.lazyMint(voucher).send({ from: account, value: props.voucher.price })
            .once('sending', () => setFunctionOperationSteps(1))
            .on('transactionHash', () => setFunctionOperationSteps(2))
            .on('error', (res: any) => {
                setFunctionOperationSteps(0)
                toast.error(res?.message)
                console.error("lazyMint", res)
            })
            .then((res: any) => {
                toast.success("You bought item successfully")
                setFunctionOperationSteps(3)

            })
            .catch((res: any) => {
                setFunctionOperationSteps(0)
                toast.error(res?.message)
                console.error("lazyMint", res)
            })
    }

    const balance = GetUserWalletBalance()
    const buttonState = Boolean(price > balance)

    return (
        <Container className={classes.buyInLazy}>
            <div>
                <div>

                    {
                        functionOperationSteps === 3 ?

                            <div className={classes.lazyMintCongrats}>
                                <div>
                                    <div>
                                        <p>Congratulations!</p>
                                        <p>Now you are owner of this NFT</p>
                                    </div>
                                    <Link to={`/${param.creator}/${param.collection}/${param.id}`}>Visit Your NFT</Link>
                                </div>
                            </div>

                            :

                            <div>
                                <p>Mint & Buy <span> (Lazy Mint)</span></p>
                                <div>
                                    <p>Price</p>
                                    <p>{price} ETH <span>($3245)</span></p>
                                </div>
                                <div>
                                    <span>Your wallet balance</span><span>{balance.toFixed(3)}</span>
                                </div>

                                {(() => {
                                    switch (functionOperationSteps) {
                                        case 0:
                                            return (
                                                <div>
                                                    <p>
                                                        - You buy and mint this item simultaneously.
                                                        <br />
                                                        - In addition to the buying price, you must pay the minting transaction gas fees on the blockchain.
                                                        <br />
                                                        - Although you pay for the minting transaction gas fees on the blockchain, as you are not the creator
                                                        of the item, you won’t receive royalty fees in the secondary market.
                                                    </p>
                                                    <button disabled={buttonState} onClick={getVoucher}>{buttonState ? "Insufficient fund" : "Mint "}</button>
                                                </div>
                                            )
                                        case 1:
                                            return (
                                                <div className={classes.lazyMintConfirmation}>
                                                    <div>
                                                        <p>
                                                            Confirmation...
                                                        </p>
                                                        <p>
                                                            Confirm this transaction with your
                                                            wallet to continue.
                                                        </p>
                                                    </div>

                                                </div>
                                            )
                                        case 2:
                                            return (
                                                <div className={classes.lazyMintConfirmation}>
                                                    <div>
                                                        <p>
                                                            Minting...
                                                        </p>
                                                        <p>
                                                            Item is minting on Blockchain.
                                                        </p>
                                                    </div>

                                                </div>
                                            )
                                        default:
                                            return null
                                    }
                                })()}
                            </div>
                    }
                </div>
                <div>
                    <LazyCard data={DataNFT?.data?.data} />
                </div>
            </div >
        </Container >
    )
}

export default BuyInLazy