import { Grid } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { LazyCard } from '../../Components/CardSection/CardSection'
import ConfirmingText from '../../Components/MarketComponents/ConfirmingText'
import DescriptionList from '../../Components/MarketComponents/DescriptionList'
import InputComponent from '../../Components/MarketComponents/Input'
import InputTitle from '../../Components/MarketComponents/InputTitle'
import MainMarketLayout from '../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../Components/MarketComponents/MainTitle'
import SubmitButtonGold from '../../Components/MarketComponents/SubmitButtonGold'
import { useAppDispatch, useAppSelector } from '../../redux/Hook'
import { useStyle } from './StyleBuyStandardAuction'

function BuyStandardAuction() {
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm()
    const [steps, setSteps] = useState<number>(1)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<number>(0)
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)

    const [price, setPrice] = useState<number>(0)
    const classes = useStyle()

    function OnSubmit(data: any) {
        console.log('data', data)
    }

    const List = [
        ' Minimum Reserved price is 0.1 ETH.',
        'Reserve auction duration is 72h fixed.',
        'Buyers will not be able to bid below Reserved price.',
        'When the reserve price is met, The auction automatically begins.',
        'A 10% commission fee will be charged based on the final sale price of the item.',
        ' When the auction begins, All rules of a reserve auction are similar to a standard auction. ',
        'You can cancel your auction before the reserve price is met, after that, it’s not possible to stop it.'
    ]

    return (
        <MainMarketLayout>
            <div>
                <MainTitle title="Set a Reserve Auction" />
                <InputTitle title="Reserved Price" />
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <InputComponent
                        errors={errors}
                        setPrice={setPrice}
                        register={register}
                    />
                    <Grid sx={{ marginTop: '280px' }}>
                        <SubmitButtonGold type="submit" title="Set Auction" bgcolor="#94794F" />
                    </Grid>
                </form>
                {/* <ConfirmingText /> */}
                <DescriptionList data={List} />
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>
        </MainMarketLayout>
    )
}

export default BuyStandardAuction