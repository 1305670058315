const ENDPOINTS = {
    Mint: '/api/mint',
    NFT: '/api/nft',
    PROFILE: '/api/profile',
    HEADERPROFILE: '/api/home/header-data',
    CreateCollectionCheckName: '/api/collection/check_name',
    NotifAllowance: '/api/profile/notif_allow',
    CollectionCreated: '/api/collection',
    GetCollection: '/api/collection/mint',
    MediaUploader: '/api/profile/media_uploader',
    EditCollection: '/api/collection/edit',
    ExploreNFT: '/api/explore',
    Home: '/api/home',
    NftTypeExploreFree: '/api/category-web2',
    FIX: '/api/nft/fix',
    CancelFixPrice: '/api/nft/cancel',
    BuyFixPrice: '/api/nft/buy',
    EthPrice: '/api/nft/eth-price',
    Auction: './api/nft/auction',
    Like: './api/nft/like',
    Search: './api/search',
    follow: './api/profile/follow',
    searchUsers: "./api/profile/search-complete-users",
    createLazyCollection: "./api/collection/create-lazy",
    lazyMint: "./api/mint/lazymint",
    SubscribeEmail: "./api/marketing/subscribe",
    mintInLazy: "./api/nft/buy-lazy-mint",
    addCollectionToFavorite: "/api/collection/favorite",
    editLazyPrice: "/api/nft/edit",
    notificationList: '/api/profile/notifications',
    exploreCollections: '/api/explore/collections'
}
export default ENDPOINTS