import { Accordion, AccordionDetails, AccordionSummary, InputAdornment, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CardCollectionInProfile } from '../../../Components/CardSection/CardSection';
import CircularLoading from '../../../Components/CircularProgress/CircularProgress';
import SkeletonLoading from '../../../Components/Skeleton/Skeleton';
import { GetCollectionExploreRequest, GetNftTypeExplore } from '../../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../../redux/Hook';
import { useStyle } from './styleExplore';

type artworksProps = {
    headerData: string,
}

function ExploreCollections(): JSX.Element {
    const [items, setItems] = useState<any>([])
    const [pagination, setPagination] = useState(1)
    const [hasMore, setHasMore] = useState<boolean>(false)

    document.addEventListener('contextmenu', (event: any) => {
        if (event?.target?.tagName === 'IMG' || event?.target?.tagName === 'VIDEO' || event?.target?.tagName === 'LI') {
            event.preventDefault()
        }
    });

    const [sort, setSort] = React.useState<string>('');
    const dispatch = useAppDispatch()
    const { Data, isLoading, Error, DataTypeExplore, isLoadingTypeExplore, ErrorTypeExplore } = useAppSelector((state) => state.ExploreCollectionReducer)
    const nfts: string[] = Data?.data?.data?.collections || []

    useEffect(() => {
        if (Data) {
            setItems((prevItems: any) => [...prevItems, ...nfts]);
        }
    }, [Data])

    const handleSort = (event: any) => {
        setPagination(1)
        setSort(event.target.value);
    };

    //Floor Price=====++++++++++++++++++++++++++++++++++++++++++++++===
    const [floorPrice, setFloorPrice] = React.useState<any>({
        priceMin: 0,
        priceMax: 0,
    });

    const handleFloorPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination(1)
        setFloorPrice({
            ...floorPrice,
            [event.target.name]: event.target.value
        });
    };

    //Total Sales=====++++++++++++++++++++++++++++++++++++++++++++++===
    const [totalSales, setTotalSales] = React.useState<any>({
        min: 0,
        max: 0,
    });

    const handleTotalSales = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination(1)
        setTotalSales({
            ...totalSales,
            [event.target.name]: event.target.value
        });
    };
    //Number of artworks=====++++++++++++++++++++++++++++++++++++++++++++++===
    const [numOfArtworks, setNumOfArtworks] = React.useState<any>({
        min: 0,
        max: 0,
    });

    const handleNumberOfArtworks = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination(1)
        setNumOfArtworks({
            ...numOfArtworks,
            [event.target.name]: event.target.value
        });
    };

    //price=====++++++++++++++++++++++++++++++++++++++++++++++===
    const [price, setPrice] = React.useState<any>({
        priceMin: 0,
        priceMax: 0,
    });

    const handlePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination(1)
        setPrice({
            ...price,
            [event.target.name]: event.target.value
        });
    };




    /** set status and update the state,  */
    const [collecType, setCollectType] = React.useState<any>({
        standard: false,
        lazyMint: false,
    });

    const handleCollecType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination(1)
        setCollectType({
            ...collecType,
            [event.target.name]: event.target.checked
        });
    };

    /**category fetch category and iterate in order to  show it and handle it */
    const [category, setCategory] = React.useState<any>({});

    useEffect(() => {
        setPagination(1)
        DataTypeExplore?.data?.data?.catgory_web2?.forEach((item: any) => {
            category[item.title] = ""
        })

    }, [isLoadingTypeExplore])

    function handleCategory(event: React.ChangeEvent<HTMLInputElement>) {
        setPagination(1)
        if (event.target.checked) {
            setCategory({
                ...category,
                [event.target.name]: event.target.value
            });
        }
        if (!event.target.checked) {
            setCategory({
                ...category,
                [event.target.name]: ""
            });
        }
    }

    const joinCategory = Object.entries(category).filter(([, value]) => value !== "").map(([key, value]) => `${value}`).join(", ");
    const collectionType = Object.entries(collecType).filter(([, value]) => value !== false).map(([key, value]) => `${key}`).join(", ");
    let query = `limit=10&page=${pagination}&sort=${sort}&collectionType=${collectionType}&minNumberOfArtworks=${numOfArtworks.min === 0 ? "" : numOfArtworks.min}&maxNumberOfArtworks=${numOfArtworks.max === 0 ? "" : numOfArtworks.max}&minTotalSales=${totalSales.min === 0 ? "" : totalSales.min}&maxTotalSales=${totalSales.max === 0 ? "" : totalSales.max}&minFloorPrice=${floorPrice.priceMin === 0 ? "" : floorPrice.priceMin}&maxFloorPrice=${floorPrice.priceMax === 0 ? "" : floorPrice.priceMax}&category_web2_ids=${joinCategory}`

    useEffect(() => {
        setItems([])
        if (!isLoading) {
            dispatch(GetCollectionExploreRequest({ payload: query }))
        }
    }, [collecType, sort, price, category, numOfArtworks, totalSales, floorPrice])

    useEffect(() => {
        if (!isLoading) {
            dispatch(GetCollectionExploreRequest({ payload: query }))
        }
        dispatch(GetNftTypeExplore())
    }, [pagination])

    const artworksNumber = Data?.data?.data?.headerData
    const classes = useStyle()
    const skeletonNumber: number = 6
    return (
        <div className={classes.main}>
            <div>
                <div id='headTitle'>
                    <p>Explore Collections</p>
                    <p>Browse Collections</p>
                </div>
                <div>

                    {isLoadingTypeExplore &&
                        <SkeletonLoading text={true} shape='rectangular' picture={false} width="300px" />
                    }
                    {/* {DataTypeExplore?.data?.success == true && DataTypeExplore?.data?.data?.catgory_web2?.map((item: any) => (
                        <a onClick={() => FetchWEB2Category(item._id)} key={item?._id.toString()}>{item.title}</a>
                    ))} */}
                </div>
                <div className={classes.dropDownSort}>
                    <div>
                        <div id="artworks">
                            {/* <p>Artworks {artworksNumber?.collections}</p> */}
                            <p>Collections {artworksNumber?.collections}</p>
                        </div>
                        <FormControl sx={{ minWidth: 200 }} >
                            <InputLabel id="demo-simple-select-label">sort</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sort}
                                onChange={handleSort}
                                variant='outlined'
                                label="sort"
                            >
                                <MenuItem value="owners">owners</MenuItem>
                                <MenuItem value="floorPriceHighest">Highest floor price</MenuItem>
                                <MenuItem value="floorPriceLowest">Lowest floor price</MenuItem>
                                <MenuItem value="newest">Newest</MenuItem>
                                <MenuItem value="oldest">Oldest</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div>
                    <div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Collection Type</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox className={classes.radioBtn} checked={collecType.standard} onChange={handleCollecType} value="standard" name="standard" />
                                            }
                                            label="Standard"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox className={classes.radioBtn} checked={collecType.lazyMint} onChange={handleCollecType} value="lazyMint" name="lazyMint" />
                                            }
                                            label="Lazy Mint"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Number of Artworks</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <TextField
                                            placeholder='Min'
                                            type="number"
                                            name="min"
                                            onChange={handleNumberOfArtworks}
                                        />
                                        <TextField
                                            placeholder='Max'
                                            type="number"
                                            name="max"
                                            onChange={handleNumberOfArtworks}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Total sales</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <TextField
                                            placeholder='Min'
                                            type="number"
                                            name="min"
                                            onChange={handleTotalSales}
                                            InputProps={{
                                                inputProps: {
                                                    step: 0.1
                                                },
                                                endAdornment: < InputAdornment position="end" > ETH</InputAdornment>
                                            }}
                                        />
                                        <TextField
                                            placeholder='Max'
                                            type="number"
                                            name="max"
                                            onChange={handleTotalSales}
                                            InputProps={{
                                                inputProps: {
                                                    step: 0.1
                                                },
                                                endAdornment: < InputAdornment position="end" > ETH</InputAdornment>
                                            }}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Floor price</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <TextField
                                            placeholder='Min'
                                            type="number"
                                            name="priceMin"
                                            onChange={handleFloorPrice}
                                            InputProps={{
                                                inputProps: {
                                                    step: 0.1
                                                },
                                                endAdornment: < InputAdornment position="end" > ETH</InputAdornment>
                                            }}
                                        />
                                        <TextField
                                            placeholder='Max'
                                            type="number"
                                            name="priceMax"
                                            onChange={handleFloorPrice}
                                            InputProps={{
                                                inputProps: {
                                                    step: 0.1
                                                },
                                                endAdornment: < InputAdornment position="end" > ETH</InputAdornment>
                                            }}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Category</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        {
                                            DataTypeExplore?.data?.data?.catgory_web2?.map((item: any) => {
                                                const i = item.title
                                                return (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={category.i} onChange={handleCategory} value={item._id} name={item.title} />
                                                        }
                                                        label={item.title}
                                                    />
                                                )
                                            })
                                        }

                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <InfiniteScroll
                        dataLength={items?.length}
                        next={() => setPagination(pagination + 1)}
                        hasMore={Data ? !Data?.data?.data?.lastPage : false}
                        loader={<CircularLoading size={20} />}
                        refreshFunction={() => setPagination(1)}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={50}
                    >
                        {
                            isLoadingTypeExplore && items.length === 0 &&
                            [...Array(skeletonNumber)].map((i: number) => (
                                <SkeletonLoading text={true} key={i} shape='rectangular' picture={true} width="100%" />
                            ))
                        }
                        <>
                            {
                                React.Children.toArray(
                                    items?.map((item: any) =>
                                        <CardCollectionInProfile data={item} key={item?._id} />
                                    )
                                )
                            }
                        </>
                    </InfiniteScroll>
                </div>
            </div >
        </div>
    )
}

export default ExploreCollections