import { Container, TextField } from '@material-ui/core'
import { InputAdornment } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import Web3 from 'web3'
import { LazyCard } from '../../Components/CardSection/CardSection'
import { GetNFT } from '../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../redux/Hook'
import API from '../../Utils/Api'
import ENDPOINTS from '../../Utils/Endpoints'
import { FromWei, ToWei } from '../../Utils/Utils'
import { useStyle } from './styleOffers'
import EtherLogo from '../../Assets/Img/EthereumLogo.svg'
import { toast } from 'react-toastify'
import { GetUserWalletBalance } from '../../Utils/GetUserWalletBalance'
import marketABI from '../../Web3/ABI/Market.json'
import { ContractInstance } from '../../Utils/Utils'
import { AbiItem } from 'web3-utils';
import { MarketContractInstance } from '../../Web3/ContractInstances/ContractInstances'
import SubmitButtonGold from '../../Components/MarketComponents/SubmitButtonGold'
import SkeletonLoading from '../../Components/Skeleton/Skeleton'

function Offers() {
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [etherPrice, setEtherPrice] = useState<number>()
    const classes = useStyle()
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm()
    const [steps, setSteps] = useState<number>(1)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<number>(0)
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const priceValue: any = FromWei(DataNFT?.data?.data?.nft?.price)
    const baseValueInWei = DataNFT?.data?.data?.nft?.offers[0]?.price
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const highestOffer = DataNFT?.data?.data?.nft?.offers[0]

    const account = localStorage.getItem('account')
    const dispatch = useAppDispatch()
    const param = useParams()
    const { data, refetch } = useQuery(['EthPrice'], () => {
        return API.get(ENDPOINTS.EthPrice)
    })

    let minimumPrice;
    const web3 = new Web3(Web3.givenProvider)

    async function GetEthPrice(e: React.KeyboardEvent<HTMLInputElement>): Promise<any> {
        await refetch()
        const ethereumPrice: number = data?.data?.data?.ethUsd
        minimumPrice = 50 / ethereumPrice
        const etherValue: any = Number((e.target as HTMLInputElement).value)
        if (!isNaN(etherValue)) {
            setPrice(Number(web3.utils.toWei(etherValue.toString())))
        }
        const total = etherValue * ethereumPrice
        const threeDigits: any = total.toFixed(3)
        setEthereumExchange(threeDigits)
    }

    async function MakeOffer(data: any) {
        const updateOffer: number = highestOffer && highestOffer?.address.toUpperCase() === account?.toUpperCase() ? (price - baseValueInWei) : price
        await MarketContractInstance.methods.makeOffer(COLLECTION_ADDRESS, tokenId).send({ from: account, value: updateOffer })
            .once('sending', () => setSteps(2))
            .on('error', function (error: any) {
                console.error('error', error)
                toast.error(error?.message)
                setSteps(1)
            })
            .once('transactionHash', () => setSteps(3))
            .then(() => {
                setSteps(4)
                // toast.success("you offered successfully.")
            })
    }

    async function CancelOffer(data: any) {
        await MarketContractInstance.methods.cancelOffer(COLLECTION_ADDRESS, tokenId).send({ from: account, value: price })
            .once('sending', () => setSteps(2))
            .on('error', function (error: any) {
                console.error('error', error)
                toast.error(error?.message)
                setSteps(1)
            })
            .on('sent', () => setSteps(2))
            .once('transactionHash', () => setSteps(3))
            .then(() => {
                setSteps(4)
                // toast.success("you offered successfully.")
            })
    }

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.collection])

    useEffect(() => {
        // if (highestOffer?.address.toUpperCase() === account?.toUpperCase()) {
        //     setValue("price", FromWei(highestOffer?.price))
        // }
    }, [LoadingNFT])

    return (
        <div className={classes.mainEditLazyPrice}>
            <Container>
                <div id="leftSide">

                    <>
                        <p>{highestOffer && highestOffer?.address.toUpperCase() === account?.toUpperCase() ? "Manage Offer" : "Make an Offer"}</p>
                        <form onSubmit={handleSubmit(MakeOffer)} noValidate>
                            <div id="textField">
                                <TextField
                                    size="small"
                                    type="number"
                                    id="price"
                                    disabled={LoadingNFT}
                                    label={errors?.price?.message}
                                    InputProps={{
                                        inputProps: {
                                            step: 0.1
                                        },
                                        disableUnderline: true,
                                        classes: { root: classes.input },
                                        endAdornment: <InputAdornment sx={{
                                            width: '120px',
                                            fontSize: '24px'
                                        }}
                                            position="start">{ethereumExchange}$</InputAdornment>,
                                    }}
                                    {...register("price", {
                                        min: { value: 0.1, message: `Price must be at least 0.1 ETH` },
                                        required: { value: true, message: 'Enter a value' },
                                        onChange: (e: any) => GetEthPrice(e)
                                    })}
                                    error={Boolean(errors?.price?.message)}
                                />
                                <p>ETH</p>
                                <img src={EtherLogo} alt="etherLogo" />
                            </div>
                            <div id='walletBalance'>
                                <span>
                                    Your wallet Balance
                                </span>
                                <span>
                                    {GetUserWalletBalance().toFixed(5)} ETH
                                </span>
                            </div>

                            {
                                steps === 1 &&
                                <>
                                    <div id='description'>
                                        <ul>
                                            <li>Minimum offer price is 0.05 ETH.</li>
                                            <li>The owner decides whether to accept your offer or not.</li>
                                            <li>If another user offers a higher price, your offer automatically will be withdraw.</li>
                                            <li>If owner didn’t accept or didn’t responce to your offer, you can cancel you offer anytime.</li>
                                            <li>Making or canceling offer is free. But you must pay the transaction gas fees on blockchain.</li>
                                        </ul>
                                    </div>
                                    <>
                                        {
                                            !LoadingNFT ?
                                                <>
                                                    {
                                                        highestOffer && highestOffer?.address.toUpperCase() === account?.toUpperCase() ?
                                                            <div id="cancelUpdateBtnOffer">
                                                                <SubmitButtonGold disabled={!Boolean(price)} type="submit" title="Update Offer" bgcolor="#94794F" />
                                                                <SubmitButtonGold type="button" onClick={CancelOffer} title="Cancel offer" bgcolor="#000" />
                                                            </div>
                                                            :
                                                            <button disabled={!Boolean(price)} >Make Offer</button>
                                                    }
                                                </>
                                                :
                                                <SkeletonLoading text={true} picture={false} shape="rectangular" height={400} width='200px' />
                                        }
                                    </>
                                </>}

                            {
                                steps === 2 &&
                                <div className={classes.confirm}>
                                    <p>Confirmation...</p>
                                    <p>Confirm this transaction with your
                                        wallet to continue.</p>
                                </div>
                            }
                            {
                                steps === 3 &&
                                <div className={classes.confirm}>
                                    <p>Waiting...</p>
                                </div>
                            }
                            {
                                steps === 4 &&
                                <div className={classes.finished}>
                                    <p>Your Successfully Made an Offer</p>
                                    <Link to={param.collection ? "/" + `${param.creator}` + "/" + `${param.collection}` + "/" + `${param.id}` : "/" + `${param.creator}` + "/" + `${param.id}`}>Visit NFT</Link >
                                </div>
                            }
                        </form>
                    </>


                </div>
                <div id="rightSide">
                    <LazyCard data={DataNFT?.data?.data} />
                </div>
            </Container>
        </div>
    )
}

export default Offers