
import { ReducerState } from "../../Utils/Interfaces"
import * as types from '../../redux/Types'

const initialState = {
    Data: [],
    isLoading: false,
    Error: false
}

export default function HomeReducer(state: ReducerState = initialState, action: any) {
    switch (action.type) {
        case types.GET_HOME_REQUEST:
            return {
                ...state,
                isLoading: true,
                Error: false,
            }
        case types.GET_HOME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Error: false,
                Data: action.payload
            }
        case types.GET_HOME_FAILED:
            return {
                ...state,
                isLoading: false,
                Error: true,
                Data: action.payload
            }
        default:
            return state
    }
} 