import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    mainEditProfile: {
        marginTop: "2vh",
        display: 'flex !important',
        flexDirection: 'row',
        marginLeft: '90px !important',
    },
    marginTop: {
        marginTop: '15px'
    },
    EditProfileLeftSide: {
        width: "30%",
        padding: '60px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'flexStart',
        "& button": {
            color: '#000',
            fontSize: '20px',
            fontFamily: 'inherit',
            fontWeight: 500,
            justifyContent: 'flex-start',
            "& svg": {
                fontSize: '30px !important'
            }
        }
    },
    notchedOutline: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row-reverse',
        marginLeft: '0 !important',

    },
    EditProfileRightSide: {
        width: '100%',
        fontFamily: 'inherit',
        display: 'flex',
        flexDirection: 'row',

        "& form > p:nth-child(1)": {
            fontSize: '32px',
            marginTop: 0,
            marginBottom: '10px',
        },
        "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
            margin: '10px 0 0 0 ',
            borderRadius: '15px',
            "& > div": {
                "& > input": {
                    fontFamily: 'DellaRespira'
                }
            }
        }, "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
            borderRadius: '10px'
        },
        "& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root": {
            borderRadius: '10px'
        }
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'RalewaySemiBold',
        color: '#000',
    },
    label2: {
        marginTop: '23px',
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'RalewaySemiBold',
        color: '#000',
    },
    input: {
        border: 'none',
        padding: "5px 25px",
        margin: '4vh 15px 15px 0',
        cursor: 'pointer',
        backgroundColor: "#000",
        color: "#fff",
        fontFamily: 'ElMessiriBold',
        fontSize: '18px',
        width: '150px',
        borderRadius: '7px',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        "& > div": {
            marginLeft: '0'
        },
        "& > span": {
            "& > div": {
                marginLeft: '0'
            }
        }
    },
    uploadSec: {
        display: 'grid',
        alignContent: 'flex-start',
        marginLeft: "15%",
        justifyItems: 'center',
        marginTop: '18px',
        "& p:nth-child(1)": {
            fontSize: '16px',
            fontFamily: 'RalewaySemiBold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        "& img": {
            cursor: 'pointer'
        },
        "& > div:nth-child(2)": {
            marginTop: '158px',
            minWidth: '350px'
        }
    },
    stackStyle: {
        marginTop: '40px'
    },
    NotifSec: {
        "& p:nth-child(1)": {
            fontFamily: 'ElMessiriBold',
            fontSize: '18px',
            marginTop: 0,
            marginBottom: '0'
        },
        "& p:nth-child(2)": {
            fontFamily: 'ElMessiriBold',
            fontSize: '16px',
            marginTop: 0,
            marginBottom: '10px',
            color: '#676767',
        },
        "& div": {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: "10px",
            "& label": {
                marginRight: '25px',
                fontSize: '18px'
            }
        }
    },
    notifText: {
        marginTop: '5px',
        marginBottom: '20px !important',
        "& span:nth-child(1)": {
            paddingTop: '0 !important'
        },
        "& p:nth-child(1)": {
            fontSize: '16px',
            marginBottom: 0,
            fontFamily: 'RalewayBold',
        },
        "& p:nth-child(2)": {
            fontFamily: 'Raleway',
            fontSize: '16px',
            color: "#676767",
            marginTop: "3px",
        }
    },
    GetBackArrow: {
        cursor: 'pointer',
        "& svg": {
            fontSize: '30px',
            color: theme.palette.primary.main
        }
    },
    errorText: {
        fontFamily: 'plexSansLight',
        marginTop: 0,
        color: "red",
        fontSize: "14px",
        display: 'inline-block'
    },
    sideButtons: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        width: '100%',
        justifyContent: 'center',
        padding: 0,
        marginTop: '100px',
        "& a, button": {
            marginLeft: '15%',
            color: "#D3D3D3",
            fontFamily: 'RalewayMedium',
            fontSize: '22px',
            textDecoration: 'none',
            padding: '7px 18px',
            whiteSpace: 'nowrap',
            borderRadius: '7px',
            background: 'none',
            border: 'none',
            textAlign: "left",
            cursor: 'pointer',
            "& img": {
                marginRight: '3px',
                verticalAlign: 'text-bottom'
            },
        },
        "&  button[class='subMenu']": {
            padding: '7px 18px',
            paddingLeft: '40px'
        },
        "& li": {
            marginBottom: '27px'
        },
        "& li:hover": {
            borderLeft: '2px solid #fff',
        },
        "& li:focus-within": {
            borderLeft: '2px solid #fff',
        },
        "& button:focus": {
            backgroundColor: '#424141'
        },
        "& button:hover": {
            backgroundColor: '#424141',
        },
    },
    inputText: {
        backgroundColor: '#D9D9D9B2',
        height: "100% !important",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: '10px',
        maxHeight: 'none !important',
        padding: '5px',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        "& > span:nth-child(3)": {
            width: "200px",
            textAlign: "right",
            color: '#A5A4A4',
            fontSize: '13px',
            fontFamily: 'DellaRespira'
        }
    },
    socialMediaInput: {

        "& > div": {
            paddingLeft: '0 !important',
            marginTop: '10px',
        }
    },
    offers: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '30px',
        width: '90%',
        height: '70vh',
        "& > div": {
            flex: "1 1 50%",
            backgroundColor: '#E6e6e6',
            "& > p:nth-child(1)": {
                fontFamily: 'RalewayBold',
                fontSize: "16px",
                color: '#000',
                width: "100%",
                textAlign: 'center'
            },
            "& > p[id='noItem']": {
                marginTop: '20vh',
                padding: '10px 10px',
                textAlign: 'center',
                width: '200px',
                margin: '0 auto',
                background: 'linear-gradient(88.84deg, rgba(255, 254, 254, 0.4) 3.79%, rgba(92, 92, 92, 0) 99.14%)',
                boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)'
            }
        }
    },
    UploadAvatar: {
        backgroundColor: '#d9d9d9',
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center'
    },
    UploadBanner: {
        backgroundColor: '#d9d9d9',
        width: '350px',
        height: '150px',
        borderRadius: '3px',
        display: 'grid',
        placeItems: 'center'
    },
    cropContainer: {
        width: '400px',
        "& p": {
            fontFamily: 'ElMessiriSemiBold',
            fontSize: '20px',
            color: '#000',
            width: '100%',
            textAlign: 'center'
        },
        "& > div:nth-child(2)": {
            height: '400px',
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: "space-between",
            alignContent: 'space-between',
            alignItems: 'flex-start'
        }
    },
    sliderContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        "& div": {
            width: '250px',
            marginTop: '30px',
            "& span": {
                "&.css-53tbrc-MuiSlider-root": {
                    color: '#000'
                }
            }
        },
        "& button": {
            width: "103px",
            backgroundColor: '#000',
            height: '30px',
            fontFamily: 'ElMessiriSemiBold',
            fontSize: '18px',
            lineHeight: 'inherit',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: 'gray'
            }
        }
    },
    slider: {
        width: "100px",
        flex: '1 1 200px',
        "&:after": {
            content: '#',
        }
    },
    previewImgContainer: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        "& button": {
            position: 'absolute',
            display: 'none'
        },
        "&:hover": {
            "& img": {
                filter: 'grayscale(0.5)',
                opacity: "0.6"
            },
            "& button": {
                display: 'flex',
                zIndex: '1000',
                "& svg": {
                    backgroundColor: '#fff',
                    transition: 'background .35s ease',
                    position: 'absolute',
                    borderRadius: '50%',
                    color: 'gray',
                    padding: '5px'
                }
            }
        }
    },
    listNotification: {
        "& h3": {
            fontSize: '18px',
            fontFamily: 'ElMessiriMedium',
            color: '#373737',
        },
        "& div[id='list']": {
            display: 'flex',
            flexDirection: 'column',
            columnGap: '30px',
            "&  div[id='itemNew']": {
                display: 'flex',
                flexDirection: 'row',
                columnGap: '40px',
                margin: "6px 0",
                border: '1px solid #C9F4AA',
                borderRadius: '7px',
                backgroundColor: "#E5FDD1",
                padding: '10px',
                "& p:nth-child(1)": {
                    fontFamily: 'RalewayMedium',
                    fontSize: '16px',
                    color: '#373737',
                    width: '600px'
                },
                "& p:nth-child(2)": {
                    fontFamily: 'RalewayMedium',
                    fontSize: '16px',
                    color: '#676767'
                }
            },
            "&  div[id='item']": {
                display: 'flex',
                flexDirection: 'row',
                columnGap: '40px',
                margin: "6px 0",
                padding: '10px',
                "& p:nth-child(1)": {
                    fontFamily: 'RalewayMedium',
                    fontSize: '16px',
                    color: '#373737',
                    width: '600px'
                },
                "& p:nth-child(2)": {
                    fontFamily: 'RalewayMedium',
                    fontSize: '16px',
                    color: '#676767'
                }
            }
        }
    }
}))