import * as types from '../../../redux/Types'
import { ICollections } from '../../../Utils/Interfaces'

const initialState = {
    Data: [],
    isLoading: false,
    Error: false,
    GetCollectionData: [],
    GetCollectionError: false,
    GetCollectionLoading: false
}

export default function EditCollectionReducer(state: ICollections = initialState, action: any) {
    switch (action.type) {
        case types.GET_COLLECTION_REQUEST:
            return {
                ...state,
                GetCollectionData: action.payload,
                GetCollectionError: false,
                GetCollectionLoading: true
            }
        case types.GET_COLLECTION_SUCCESS:
            return {
                ...state,
                GetCollectionData: action.payload,
                GetCollectionError: false,
                GetCollectionLoading: false
            }
        case types.GET_COLLECTION_FAILED:
            return {
                ...state,
                GetCollectionData: action.payload,
                GetCollectionError: true,
                GetCollectionLoading: false
            }
        case types.COMPLETE_COLLECTION_REQUEST:
            return {
                ...state,
                isLoading: true,
                Error: false,
            }
        case types.COMPLETE_COLLECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Error: false,
                Data: action.payload
            }
        case types.COMPLETE_COLLECTION_FAILED:
            return {
                ...state,
                isLoading: false,
                Error: true,
                Data: action.payload
            }
        default:
            return state
    }
} 