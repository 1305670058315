import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    tosMain: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        "& div": {
            width: '80%',
            marginTop: '20px',
            "& div": {
                marginBottom: '10px'
            }
        }
    },

}))