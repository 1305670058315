import { makeStyles } from "@material-ui/core/styles";


export const useStyle = makeStyles(theme => ({
    MintMain: {
        margin: '35px 0',
        "& p:nth-child(1)": {
            color: theme.palette.primary.main,
            fontFamily: 'ElMessiri',
            fontSize: '22px',
            textAlign: 'center',
            marginBottom: 0,
            fontWeight: 700,
            lineHeight: '35px'
        },
        "& p:nth-child(2)": {
            lineHeight: "21px",
            fontWeight: 500,
            fontFamily: 'DellaRespira',
            fontSize: '15px',
            textAlign: 'center',
            color: "#000",
            marginTop: 0
        }
    },
    FormBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    nftType: {
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '35px',

        "& a": {
            cursor: 'pointer',
            fontFamily: 'plexSansLight',
            fontWeight: '600'
        }
    },
    uploadFile: {
        border: '1px lightgray solid',
        padding: '15px 5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        margin: '7px 0 0',
        flexDirection: 'column',
        cursor: 'pointer',
        height: 'max-content',
        overflow: 'auto',
        "&:hover": {
            border: '1px solid #000'
        },
        "&:active": {
            border: '2px solid #000'
        },
        "& > p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '350px',
            textAlign: 'center',
        },
        "& label": {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            "& p": {
                color: theme.palette.primary.dark,
                fontSize: '14px',
                marginBottom: '15px',
            },
            "& a": {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                border: 'none',
                padding: '8px 25px',
                fontFamily: 'inherit',
                fontSize: '18px',
                borderRadius: '8px',
                cursor: 'pointer',
                "&:hover": {
                    backgroundColor: '#000'
                }
            }
        }
    },
    placeholderSelect: {
        fontFamily: 'DellaRespira',
        fontSize: '14px',
        color: '#A5A4A4',
    },
    formInfo: {
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'RalewayMedium',
        lineHeight: "18px",
        marginBottom: '6px',
        marginTop: '30px',
        "& span": {
            color: "#676767",
            fontSize: '15px',
            fontWeight: 500,
        },
        "& span::before": {
            content: '"*"',
            color: 'red',
            fontSize: '23px'
        },
    },
    formInfoLazy: {
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'RalewayMedium',
        lineHeight: "18px",
        marginBottom: '6px',
        marginTop: '6px',
        "& span": {
            color: "#676767",
            fontSize: '15px',
            fontWeight: 500,
        },
        "& span::before": {
            content: '"*"',
            color: 'red',
            fontSize: '23px'
        },
    },
    formInfoWithoutStar: {
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'RalewayMedium',
        lineHeight: "18px",
        marginTop: '30px',
        "& span": {
            color: "#676767",
            fontSize: '15px',
            fontWeight: 500,
        },
    },
    formInfo2: {
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'RalewayMedium',
        lineHeight: "18px",
        marginBottom: '6px',
        marginTop: '18px',
        "& span": {
            color: "#676767",
            fontSize: '15px',
            fontWeight: 500,
        },
        "& span::before": {
            content: '"*"',
            color: 'red',
            fontSize: '23px'
        },
    },
    inputSection: {
        margin: '0',
        maxWidth: '957px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: "0",
        "& label": {
            marginBottom: '8px'
        },
        "& #name-helper-text, #description-helper-text": {
            display: 'grid',
            justifyContent: 'flex-end',
            margin: 0
        }
    },
    selectCollection: {
        border: '1px lightgray solid',
        padding: '10px 5px',
        borderRadius: '10px',
        margin: '10px 0',
        backgroundColor: '#fff',
        "& option": {
            fontFamily: 'inherit',
            fontSize: "16px",
            border: 'none'
        },
        "& selectmenu": {
            border: 'none'
        }
    },
    radioButtonSec: {
        marginTop: '50px',
        "& p:nth-child(1), span": {
            fontFamily: 'RalewayMedium',
            color: "#4a4a4a",

            margin: '0',
            padding: '3px',
            display: 'flex',
            alignItems: 'center',
            "& > a": {
                textDecoration: 'none',
                color: 'inherit',
                fontStyle: 'italic',
                "&:hover": {
                    color: '#000'
                }
            }
        },
        "& > div": {
            "& label": {
                "& span:nth-child(1)": {
                    "& input": {

                    }
                },
                "& span:nth-child(2)": {
                    fontFamily: 'RalewayMedium',
                    color: "#4a4a4a",
                    fontSize: '15px',
                }
            }
        },
        "& label": {
            marginLeft: '0',

        }
    },
    submitButton: {
        textAlign: 'center',
        margin: '20px 20px 70px 5px',
        "& input": {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            border: 'none',
            padding: '8px 40px',
            fontFamily: 'inherit',
            fontSize: '18px',
            borderRadius: '8px',
            cursor: 'pointer',
            "&:disabled": {
                opacity: '0.5'
            }
        },
    },
    errorText: {
        fontFamily: 'RalewayMedium',
        color: "red",
        fontSize: "14px",
        display: 'inline-block',
        margin: '0 0 0 20px',
    },
    dialog: {
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '40px',
        "& p:nth-child(1)": {
            color: theme.palette.primary.main,
            fontSize: '20px',
            fontWeight: 600,
            fontFamily: "ElMissiri",
        },
        "&  p:nth-child(3)": {
            color: "#404040",
            fontSize: '16px',
            fontWeight: 500,
            fontFamily: "RalewayMedium",
            marginTop: '30px',
            lineHeight: '18px',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            "& > img": {
                verticalAlign: 'middle',
                width: "20px",
                marginRight: '5px',
            }
        }
    },
    MintedSucceed: {
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '50px',
        fontFamily: 'inherit',
        "& p[id='congrats']": {
            fontSize: '36px',
            margin: "20px 40px 0 40px",
            color: theme.palette.primary.main
        },
        "& p:nth-child(2)": {
            fontSize: '18px',
            margin: '0 10px'
        },
        "& img, video": {
            margin: '10px 0'
        },
        "& a": {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            border: 'none',
            padding: '8px 40px',
            fontFamily: 'inherit',
            fontSize: '18px',
            borderRadius: '8px',
            cursor: 'pointer',
            textDecoration: 'none',
            marginTop: '30px'
        }

    },
    lazySplitSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    splitSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        "& > div": {
            "& fieldset": {
                width: '100%',
                "& p[id='head']": {
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'RalewayMedium',
                    lineHeight: "18px",
                },
                "& > p": {
                    margin: 0,
                    color: theme.palette.primary.light,
                    fontFamily: 'RalewayMedium',
                    fontSize: '15px'
                },
                "& div": {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '90%',
                }
            }
        }
    },
    NFTImagePreview: {
        position: 'relative',
        width: 'auto',
        height: '300px',
        transition: 'opacity .1s ease',
        display: 'grid !important',
        placeItems: "center",
        "& > img, video": {
            width: "auto",
            minHeight: '250px',
            maxHeight: '300px'
        },
        "&:hover": {
            display: 'grid !important',
            transition: 'opacity .35s ease',
            placeItems: "center",
            "& img, video": {
                filter: 'grayscale(1)',
                opacity: "0.4"
            },
            "& button": {
                display: 'flex !important',
                backgroundColor: '#fff',
                transition: 'background .35s ease',
                position: 'absolute'
            }
        }
    },

    deleteNFTImagePreview: {
        position: 'absolute',
        display: 'none !important',
        transition: 'opacity .35s ease',
    },
    collaboratorSection: {
        marginTop: "8px",
        marginBottom: "15px",
        "& > div:nth-child(1)": {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            "& > div": {
                display: 'flex',
                flexDirection: 'row',
                "& > img": {
                    width: "40px",
                    height: '40px',
                    borderRadius: '50%',
                    marginRight: '5px'
                },
                "& > p": {
                    margin: 0,
                    fontFamily: 'DellaRespira',
                    display: 'flex',
                    alignItems: 'center'
                },
                "& > div": {
                    "& > p": {
                        margin: 0,
                        fontFamily: 'DellaRespira',
                    },
                    "& > p:nth-child(2)": {
                        color: theme.palette.primary.light,
                        fontSize: '14px'
                    }
                }
            },

        }
    },
    editSplitButton: {
        display: 'flex',
        flexDirection: "row",
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        "& > a": {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    lazySection: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5px',
        '& > div': {
            marginTop: '5px',
            display: 'flex',
            flexDirection: 'row',
        },
        "& > p": {
            fontSize: "15px",
            fontWeight: 500,
            fontFamily: 'Raleway',
            color: "#676767",
            marginTop: "6px",

        }
    },
    Eth: {
        backgroundColor: "#d9d9d9",
        padding: '5px 8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: '7px',
        fontWeight: 600,
        marginRight: '15px'
    },
    CounterInput: {
        width: '100%',
        textAlign: 'right',
        fontSize: '0.75rem',
        color: 'rgb(103, 103, 103)',
        margin: "2px 0 0"
    },

    InputPlaceholderStyle: {
        width: '100% !important',
        marginTop: '0',
        "& > div": {
            '&.Mui-focused fieldset': {
                borderColor: '#000 !important',
                color: '#000 !important'
            },
        },
        "& label": {
            "&.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                color: '#000 !important'
            }
        },
        "&.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
            color: '#000 !important'
        },
        "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
            fontFamily: 'DellaRespira',
            fontSize: '14px',
            fontWeight: 500,
            color: '#a5a4a4'
        },
        "& div": {
            "&.css-1hb7zxy-IndicatorsContainer": {
                display: 'none'
            },
            "&.css-1pahdxg-control": {
                border: '2px solid #000 !important',
                boxShadow: 'none'
            },
            "&.css-1pahdxg-control:hover": {
                border: '2px solid #000 !important',
                boxShadow: 'none'
            },
            "&.css-1s2u09g-control:hover": {
                border: '1px solid #000 !important',
                boxShadow: 'none'
            },
        }
    },
    TextFieldPlaceholderStyle: {
        "& input::placeholder": {
            fontFamily: 'DellaRespira !important',
            color: '#000 !important'
        },
        // "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
        //     fontFamily: 'DellaRespira',
        //     fontSize: '14px',
        //     fontWeight: 500,
        //     color: '#a5a4a4 !important'
        // },
        '& label.Mui-focused': {
            color: '#000',
        },
        '& .MuiOutlinedInput-root': {
            fontFamily: 'DellaRespira',
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#000',
            },
        },
    },
    notchedOutline: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            }
        },
        borderWidth: "1px !important",
        borderColor: "yellow !important"
    },
    inputText: {

        '& label.Mui-focused': {
            color: '#000',
        },
        '& .MuiOutlinedInput-root': {
            fontFamily: 'DellaRespira',
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#000',
            },
        },
    }
}))