import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    aboutUsMain: {
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        "& h1": {
            color: theme.palette.primary.main,
            fontFamily: 'inherit',
            fontSize: "80px",
            marginBottom: '10px',
        },
        "& div": {
            "& p:nth-child(2)": {
                fontSize: '25px',
                marginTop: '0',
            }
            , "& p": {
                fontSize: "18px",
                fontFamily: 'plexSansLight',
                fontWeight: 500,
                "& span": {
                    fontSize: '20px',
                    fontWeight: 600,
                    marginBottom: '10px'
                }
            }
        }

    }
}))