import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation } from '@tanstack/react-query';
import API from '../../../Utils/Api';
import ENDPOINTS from '../../../Utils/Endpoints';
import Box from '@mui/material/Box';
import { useStyles } from './Styles';
import { toast } from 'react-toastify';

interface IItem {
    _id: number;
    address: number;
    username: string;
    avatar: string;
    share: number;
}

export default function AutocompleteInput({ setUsers, users, totalShare }: any) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<readonly IItem[]>([]);
    const loading = open && options.length === 0;
    const classes = useStyles()

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    const [timer, setTimer] = useState<any>(null)
    const [searchValue, setSearchValue] = useState<string>('')

    function fetchData(e: any) {
        clearTimeout(timer)
        setSearchValue(e.target.value)
        const searchReq = setTimeout(() => { mutation.mutate(searchValue) }, 1000);
        setTimer(searchReq)
    }

    const mutation = useMutation((addToFav: any) => {
        return API.get(`${ENDPOINTS.searchUsers}?userKey=${addToFav}`)

    })

    useEffect(() => {
        if (mutation.isSuccess && !mutation.isLoading) {
            setOptions(mutation.data.data.data.users)
        }

    }, [mutation.isSuccess])
    return (
        <Autocomplete
            size="small"
            id="asynchronous-demo"
            sx={{ width: "100%" }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disabled={totalShare >= 100 || users.length === 5}
            onChange={(event: any, value) => {
                if (event.target.nodeName !== "svg" && value !== null) {
                    if (totalShare === 100) {
                        toast.warn("You reached 100%")
                        return
                    }
                    if (users.length === 5) {
                        toast.warn("you can add 5 people maximum.")
                    } else {
                        if (users.length < 5 && users.some((user: any) => user._id === value._id)) {
                            toast.warn("collaborator is already Added")
                        } else {
                            value.share = 5;
                            setUsers((prevUser: any) => [...prevUser, value])
                        }
                    }
                }
            }}

            isOptionEqualToValue={(option, value) => (option._id === value._id)}

            getOptionLabel={(option: any) => {
                if (searchValue.slice(0, 2) === "0x") {
                    return option.address
                } else {
                    return option.username
                }

            }}

            renderOption={(props, option) => (
                <Box className={classes.splitResult} component="li"  {...props}>
                    <img
                        loading="lazy"
                        width="25"
                        src={option.avatar}
                        srcSet={option.avatar}
                        alt={option.username}
                        className={classes.splitResultAvatar}
                    />
                    <div className={classes.splitResultInfoSection}>
                        <p className={classes.splitResultName}> {option.username} </p>
                        <p className={classes.splitResultAddress}>({option.address})</p>
                    </div>
                </Box>
            )}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    classes={{ root: classes.autoCompleteInput }}
                    {...params}
                    onChange={fetchData}
                    placeholder='Enter a username or Ethereum Address'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}