import Web3 from 'web3'
import { Container, Grid, InputAdornment, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import { GetNFT } from '../../../redux/Actions'
import { AbiItem } from 'web3-utils';
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import { useStyle } from './StyleSellReservedAuction'
import LaunchIcon from '@mui/icons-material/Launch';
import ETHIcon from '../../../Assets/Img/etherSmallLogo.svg'
import { useForm } from 'react-hook-form'
import ENDPOINTS from '../../../Utils/Endpoints'
import API from '../../../Utils/Api'
import InputComponent from '../../../Components/MarketComponents/Input'
import InputTitle from '../../../Components/MarketComponents/InputTitle'
import MainMarketLayout from '../../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../../Components/MarketComponents/MainTitle'
import SubmitButtonGold from '../../../Components/MarketComponents/SubmitButtonGold'
import DescriptionList from '../../../Components/MarketComponents/DescriptionList'
import ConfirmationSection from '../../../Components/MarketComponents/ConfirmationSection'
import ConfirmingText from '../../../Components/MarketComponents/ConfirmingText'
import ResultTitleText from '../../../Components/MarketComponents/ResultTitleText'
import BlackLinkButton from '../../../Components/MarketComponents/BlackLinkButton'
import { toast } from 'react-toastify'
import { MarketContractInstance } from '../../../Web3/ContractInstances/ContractInstances'
import RegularCollectionABI from '../../../Web3/ABI/regularCollection.json';
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'

function SellReservedAuction() {
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")

    const classes = useStyle()
    let minimumPrice;
    const param = useParams()
    const dispatch = useAppDispatch()
    const { register, handleSubmit, reset, formState: { errors } } = useForm()

    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.id])

    const web3 = new Web3(Web3.givenProvider)
    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const NftId = DataNFT?.data?.data?.nft?._id
    const account: any = localStorage.getItem('account')
    const royalty: number = DataNFT?.data?.data?.nft?.royalty


    const collection = new web3.eth.Contract(RegularCollectionABI as AbiItem[], COLLECTION_ADDRESS)

    async function onSubmitGetApprove() {
        setSteps(1)
        await MarketContractInstance.methods.checkTransferPerm(COLLECTION_ADDRESS, tokenId).call({ from: account }).then((result: any) => {
            if (result) {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
                SetReserveAuction()
            } else {
                ApproveCollection()
            }
        }).catch((error: any) => {
            toast.warning(error.message)
            setSteps(0)
        })
    }

    async function ApproveCollection() {
        await collection.methods.approve(process.env.REACT_APP_MARKET, tokenId).send({ from: account })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('sending', () => {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
            })
            .then((result: any) => {
                SetReserveAuction()
            })
    }

    async function SetReserveAuction() {
        const toWei = web3.utils.toWei(`${price}`)

        setSteps(1)
        await MarketContractInstance.methods.setReserveAuction(COLLECTION_ADDRESS, tokenId, toWei).send({ from: account })
            .once('sending', () => {
                setSteps(2)
                setFunctionOperationSteps("Confirming...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('transactionHash', () => {
                setSteps(3)
                setFunctionOperationSteps("Finalizing...")
            })
            .then((res: any) => {
                setSteps(4)
                setFunctionOperationSteps("Finalized")
            })
    }

    const List = [
        "Minimum Reserved price is 0.1 ETH.",
        "Reserve auction duration is 72h fixed.",
        "Buyers will not be able to bid below Reserved price.",
        "When the reserve price is met, The auction automatically begins.",
        "A 10% commission fee will be charged based on the final sale price of the item.",
        "When the auction begins, All rules of a reserve auction are similar to a standard auction.",
        "You can cancel your auction before the reserve price is met, after that, it’s not possible to stop it."
    ]

    return (
        <MainMarketLayout>
            <div>
                {
                    steps === 0 &&
                    <>
                        <MainTitle title="Set a Reserve Auction" />
                        <form onSubmit={handleSubmit(onSubmitGetApprove)} noValidate>
                            <InputTitle title="Reserve Price" />
                            <InputComponent
                                errors={errors}
                                setPrice={setPrice}
                                register={register}
                                setEthereumExchange={setEthereumExchange}
                                ethereumExchange={ethereumExchange}
                                LoadingNFT={LoadingNFT}
                            />
                            <Grid sx={{ marginTop: '80px' }}>
                                {
                                    LoadingNFT ?
                                        <SkeletonLoading text={true} picture={false} shape="rectangular" height={400} width='200px' />
                                        :
                                        <SubmitButtonGold type="submit" title="Set Auction" bgcolor="#94794F" />
                                }
                            </Grid>
                        </form>
                        <DescriptionList data={List} />
                    </>
                }
                {
                    steps > 0 && steps <= 3 &&
                    <>
                        <ConfirmationSection steps={steps} />
                        <Grid sx={{ margin: '80px 0' }}>
                            <ConfirmingText text={functionOperationSteps} />
                        </Grid>
                    </>
                }
                {
                    steps === 4 &&
                    <>
                        <Grid sx={{ display: "grid", placeItems: 'center', marginTop: '80px' }}>
                            <ResultTitleText text="Reserved auction was set." />
                            <BlackLinkButton to={DataNFT?.data?.data?.nft?.category !== '' ? (DataNFT?.data?.data?.nft?.status === "lazy" ? `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?._id}` : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?.tokenId}`) : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT.data?.data?.nft?.tokenId}`} text="Visit Your NFT" />
                        </Grid>
                    </>
                }
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />

            </div>
        </MainMarketLayout>

    )
}
export default SellReservedAuction




// <div className={classes.mainSellReservedAuction}>
//             <Container maxWidth="lg" >
//                 {
//                     steps <= 1 ?
//                         <div id="rightSide">
//                             <p>Set a Reserve Auction</p>
//                             <form onSubmit={handleSubmit(OnSubmit)}>
//                                 <InputTitle title="Reserved Price" />
//                                 <InputComponent
//                                     errors={errors}
//                                     setPrice={setPrice}
//                                     register={register}
//                                     setEthereumExchange={setEthereumExchange}
//                                     ethereumExchange={ethereumExchange}
//                                     LoadingNFT={LoadingNFT}
//                                 />
//                                 {
//                                     steps === 0 &&
//                                     <button disabled={Boolean(errors?.price?.message)} type='submit' >Set Auction</button>
//                                 }
//                             </form>
//                             {
//                                 steps === 1 &&
//                                 <div className={classes.confirming}>
//                                     <p>Confirming...</p>
//                                     <p>
//                                         Confirm this transaction with your
//                                         wallet to continue.
//                                     </p>
//                                 </div>
//                             }
//                             {
//                                 steps === 0 &&
//                                 <ul>
//                                     <li>Minimum Reserved price is 0.1 ETH.</li>
//                                     <li>Reserved auction duration is 72h fixed.</li>
//                                     <li>Buyers will not be able to bid below Reserved price.</li>
//                                     <li>When the reserved price is met, The auction automatically begins.</li>
//                                     <li>A 10% commission fee will be charged based on the final sale price of the item.</li>
//                                     <li>When the auction begins, All rules of a reserve auction are similar to a standard auction.</li>
//                                     <li>You can cancel your auction before the reserve price is met, after that, it’s not possible to stop it.</li>
//                                 </ul>
//                             }
//                         </div>
//                         :
//                         <>
//                             {
//                                 steps === 2 &&
//                                 <div id='rightSide'>
//                                     <div id="finished">
//                                         <p>Reserved auction was set.</p>
//                                         <Link to="/">Visit Your NFT</Link>
//                                     </div>
//                                 </div>
//                             }
//                             {
//                                 steps === 3 &&
//                                 <div id='rightSide'>
//                                     <div id="error">
//                                         <p>Insufficient Funds</p>
//                                         <p>The next steps require small transaction fees, so you

//                                             may have to deposit additional funds to complete them.</p>
//                                     </div>
//                                 </div>
//                             }
//                         </>
//                 }
//                 <div id="leftSide"><LazyCard data={DataNFT?.data?.data} /></div>
//             </Container>
//         </div>