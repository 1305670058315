import * as types from '../../redux/Types'
import { INft, INftItem } from '../../Utils/Interfaces'


const initialState = {
    DataNFT: [],
    LoadingNFT: false,
    ErrorNFT: false,
    CancelFixPriceData: [],
    CancelFixPriceLoading: false,
    CancelFixPriceError: false,
    BUYFixPriceLoading: false,
    BUYFixPriceError: false,
    BUYFixPriceData: []
}

export default function NFTReducer(state: INftItem = initialState, action: any) {
    switch (action.type) {
        case types.GET_NFT:
            return {
                ...state,
                LoadingNFT: true,
                ErrorNFT: false,
                DataNFT: action.payload
            }
        case types.SUCCESS_NFT:
            return {
                ...state,
                LoadingNFT: false,
                ErrorNFT: false,
                DataNFT: action.payload
            }
        case types.FAILED_NFT:
            return {
                ...state,
                LoadingNFT: false,
                ErrorNFT: true,
                DataNFT: action.payload
            }
        case types.CANCEL_FIX_PRICE_REQUEST:
            return {
                ...state,
                CancelFixPriceLoading: true,
                CancelFixPriceError: false,
                CancelFixPriceData: action.payload
            }
        case types.CANCEL_FIX_PRICE_SUCCESS:
            return {
                ...state,
                CancelFixPriceLoading: false,
                CancelFixPriceError: false,
                CancelFixPriceData: action.payload
            }
        case types.CANCEL_FIX_PRICE_FAILED:
            return {
                ...state,
                CancelFixPriceLoading: false,
                CancelFixPriceError: true,
                CancelFixPriceData: action.payload
            }
        case types.BUY_FIX_PRICE_REQUEST:
            return {
                ...state,
                BUYFixPriceLoading: true,
                BUYFixPriceError: false,
                BUYFixPriceData: action.payload
            }
        case types.BUY_FIX_PRICE_SUCCESS:
            return {
                ...state,
                BUYFixPriceLoading: false,
                BUYFixPriceError: false,
                BUYFixPriceData: action.payload
            }
        case types.BUY_FIX_PRICE_FAILED:
            return {
                ...state,
                BUYFixPriceLoading: false,
                BUYFixPriceError: true,
                BUYFixPriceData: action.payload
            }
        default:
            return state
    }
} 