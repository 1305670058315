//FB = short form of (from Bakcend)

export const REQUEST_MINT_NFT_FB = "MINT_NFT_REQUEST_TO_BACKEND"
export const SUCCESS_MINT_NFT_FB = "SUCCESS_MINT_NFT"
export const FAILED_MINT_NFT_FB = "FAILED_MINT_NFT"

export const GET_NFT_TYPE_FB = "GET_NFT_TYPE_FB"
export const SUCCESS_NFT_TYPE_FB = "SUCCESS_NFT_TYPE_FB"
export const FAILED_NFT_TYPE_FB = "FAILED_NFT_TYPE_FB"

export const GET_NFT = "GET_NFT"
export const SUCCESS_NFT = "SUCCESS_NFT"
export const FAILED_NFT = "FAILED_NFT"

export const CREATE_PROFILE_REQUEST = "CREATE_PROFILE_REQUEST"
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS"
export const CREATE_PROFILE_FAILED = "CREATE_PROFILE_FAILED"

export const PROFILE_HEADER_REQUEST = "PROFILE_HEADER_REQUEST"
export const PROFILE_HEADER_SUCCESS = "PROFILE_HEADER_SUCCESS"
export const PROFILE_HEADER_FAILED = "PROFILE_HEADER_FAILED"

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED"

export const CREATE_COLLECTION_REQUEST = "CREATE_COLLECTION_REQUEST"
export const CREATE_COLLECTION_SUCCESS = "CREATE_COLLECTION_SUCCESS"
export const CREATE_COLLECITON_FAILED = "CREATE_COLLECITON_FAILED"

export const SUBMIT_NOTIFICATION_REQUEST = "SUBMIT_NOTIFICATION_REQUEST"
export const SUBMIT_NOTIFICATION_SUCCESS = "SUBMIT_NOTIFICATION_SUCCESS"
export const SUBMIT_NOTIFICATION_FAILED = "SUBMIT_NOTIFICATION_FAILED"

export const CREATE_COLLECTION_WEB3_REQUEST = "CREATE_COLLECTION_WEB3_REQUEST"
export const CREATE_COLLECTION_WEB3_SUCCESS = "CREATE_COLLECTION_WEB3_SUCCESS"
export const CREATE_COLLECTION_WEB3_FAILED = "CREATE_COLLECTION_WEB3_FAILED"

export const FETCH_COLLECTION_REQUEST = "FETCH_COLLECTION_REQUEST"
export const FETCH_COLLECTION_SUCCESS = "FETCH_COLLECTION_SUCCESS"
export const FETCH_COLLECTION_FAILED = "FETCH_COLLECTION_FAILED"

export const CHANGE_WEB3_STATE_IN_CREATE_COLLECTION = "CHANGE_WEB3_STATE"

export const COMPLETE_COLLECTION_REQUEST = 'COMPLETE_COLLECTION_REQUEST'
export const COMPLETE_COLLECTION_SUCCESS = 'COMPLETE_COLLECTION_SUCCESS'
export const COMPLETE_COLLECTION_FAILED = 'COMPLETE_COLLECTION_FAILED'

export const GET_COLLECTION_REQUEST = "GET_COLLECTION_REQUEST"
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS"
export const GET_COLLECTION_FAILED = "GET_COLLECTION_FAILED"

export const GET_EXPLORE_NFTs_REQUEST = 'GET_EXPLORE_NFTs_REQUEST'
export const GET_EXPLORE_NFTs_SUCCESS = 'GET_EXPLORE_NFTs_SUCCESS'
export const GET_EXPLORE_NFTs_FAILED = 'GET_EXPLORE_NFTs_FAILED'


export const GET_EXPLORE_COLLECTION_REQUEST = 'GET_EXPLORE_COLLECTION_REQUEST'
export const GET_EXPLORE_COLLECTION_SUCCESS = 'GET_EXPLORE_COLLECTION_SUCCESS'
export const GET_EXPLORE_COLLECTION_FAILED = 'GET_EXPLORE_COLLECTION_FAILED'

export const GET_HOME_REQUEST = "GET_HOME_REQUEST"
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS"
export const GET_HOME_FAILED = "GET_HOME_FAILED"

export const GET_NFT_TYPE_EXPLORE_REQUEST = "GET_NFT_TYPE_EXPLORE_REQUEST"
export const GET_NFT_TYPE_EXPLORE_SUCCESS = "GET_NFT_TYPE_EXPLORE_SUCCESS"
export const GET_NFT_TYPE_EXPLORE_FAILED = "GET_NFT_TYPE_EXPLORE_FAILED"

export const FIXED_PRICE_REQUEST = "GET_FIXED_PRICE_REQUEST"
export const FIXED_PRICE_SUCCESS = "GET_FIXED_PRICE_SUCCESS"
export const FIXED_PRICE_FAILED = "GET_FIXED_PRICE_FAILED"

export const CANCEL_FIX_PRICE_REQUEST = "CANCEL_FIX_PRICE_REQUEST"
export const CANCEL_FIX_PRICE_SUCCESS = "CANCEL_FIX_PRICE_SUCCESS"
export const CANCEL_FIX_PRICE_FAILED = "CANCEL_FIX_PRICE_FAILED"

export const BUY_FIX_PRICE_REQUEST = "BUY_FIX_PRICE_REQUEST"
export const BUY_FIX_PRICE_SUCCESS = "BUY_FIX_PRICE_SUCCESS"
export const BUY_FIX_PRICE_FAILED = "BUY_FIX_PRICE_FAILED"

export const SELL_IN_AUCTION_RESPONSE_WEB3 = "SELL_IN_AUCTION_RESPONSE_WEB3"
export const SELL_IN_AUCTION_RESPONSE_WEB3_SUCCESS = "SELL_IN_AUCTION_RESPONSE_WEB3_SUCCESS"
export const SELL_IN_AUCTION_RESPONSE_WEB3_FAILED = "SELL_IN_AUCTION_RESPONSE_WEB3_FAILED"

export const LAZY_MINT_REQUEST = "LAZY_MINT_REQUEST"
export const LAZY_MINT_SUCCESS = "LAZY_MINT_SUCCESS"
export const LAZY_MINT_FAILED = "LAZY_MINT_FAILED"

export const CREATE_COLLECTION_STEPS = "CREATE_COLLECTION_STEPS"

export const MINT_STEPS = "MINT_STEPS"


export const NOTIFICATION_LIST_REQUEST = "NOTIFICATION_LIST_REQUEST"
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS"
export const NOTIFICATION_LIST_FAILED = "NOTIFICATION_LIST_FAILED"