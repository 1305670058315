import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: "80%",
        margin: '0 auto',
        position: 'relative',
        "& > div:nth-child(1)": {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "space-between",
            "& div[id='leftSection']": {
                width: '60%',
                "& div[id='ImageSection']": {
                    "& > img": {
                        width: "135px",
                        height: '135px',
                        border: '5px solid #fff',
                        borderRadius: '15px',
                        marginTop: '-140px',
                        position: 'absolute',
                        [theme.breakpoints.down("sm")]: {
                            marginTop: '0',
                            position: 'unset'
                        }
                    },
                    "& p:nth-child(2)": {
                        fontFamily: 'ElMessiriBold',
                        fontSize: '32px',
                        color: '#000',
                        marginTop: "10px",
                        marginBottom: '0'
                    },
                    "& p:nth-child(3)": {
                        fontFamily: 'RalewayMedium',
                        fontSize: '17px',
                        color: '#676767',
                        marginTop: "0"
                    }
                },
                "& div[id='collecInfo']": {
                    marginTop: '20px',
                    "& > ul": {
                        listStyleType: 'none',
                        display: "flex",
                        flexDirection: 'row',
                        columnGap: '20px',
                        padding: '0',
                        "& > li": {
                            display: 'flex',
                            flexDirection: 'column',
                            "& > span:nth-child(1)": {
                                fontFamily: 'RalewayMedium',
                                fontSize: '16px',
                                color: "#141414",
                                whiteSpace: 'nowrap'
                            },
                            "& > span:nth-child(2)": {
                                fontFamily: 'RalewayMedium',
                                fontSize: '12px',
                                color: "#676767",
                                whiteSpace: 'nowrap'
                            }
                        }
                    }
                },
                "& div[id='description']": {
                    marginTop: '25px',
                    width: '600px',
                    "& > div": {
                        position: 'relative',
                        overflow: 'hidden',
                        "& > button": {
                            position: 'absolute',
                            bottom: '0',
                            right: '0px',
                            zIndex: 100,
                            background: '#fff',
                            border: 'none',
                            color: '#676767',
                            cursor: 'pointer',
                            fontFamily: 'RalewayMedium',
                            fontSize: '13px',
                            padding: '2px',

                        }
                    }
                },
                "& div[id='category']": {
                    "& > button": {
                        textDecoration: 'none',
                        fontFamily: 'Raleway',
                        fontSize: '16px',
                        color: '#A5A4A4',
                        margin: '25px 15px 5px 0px',
                        border: 'none',
                        cursor: 'pointer',
                    },
                    "& > button[id='active']": {
                        color: "#000"
                    }

                },
            },
        },

        "& div[id='rightSection']": {
            width: '40%',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-end',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            alignItems: 'flex-end',
            "& > div[id='share']": {
                marginTop: '-25px',
                display: 'flex',
                flexDirection: 'row',
                columnGap: '10px',
                alignItems: 'flex-end',
                "& > div": {
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    padding: '7px',
                    borderRadius: '7px',
                    "& a": {
                        color: '#676767',
                        margin: "2px 3px",
                        "& > img": {
                            width: '22px',
                            padding: '4px'
                        }
                    },
                }
            }
        }
        ,
        "& > div:nth-child(2)": {
            marginTop: '40px',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            rowGap: '50px',
            columnGap: '80px',
            justifyContent: 'space-between',

            [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: 'repeat(1,1fr)',
            }
        },
    },
    addCollectionContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '80px'
    },
    dropDown: {
        boxShadow: '0px 5px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '7px',
        padding: '4px',
        "&:before": {
            borderBottom: "0 !important"
        },
    },
    UploadAvatar: {
        backgroundColor: '#d9d9d9',
        width: '150px',
        height: '150px',
        borderRadius: '7px',
        display: 'grid',
        placeItems: 'center',
        cursor: 'pointer'
    },
    UploadBanner: {
        backgroundColor: '#d9d9d9',
        width: '350px',
        height: '150px',
        borderRadius: '3px',
        display: 'grid',
        placeItems: 'center',
        cursor: 'pointer'
    },
    addCollection: {
        "& > form": {
            marginTop: '10px',
            '& > div': {
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
                columnGap: '30px',
                '& > div': {
                    flex: '1 1 40%'
                },
                "& > div[id='leftSide']": {
                    display: 'flex',
                    flexDirection: 'column',
                    "& button": {
                    },
                    "& > div:nth-child(1)": {
                        display: 'flex',
                        flexDirection: "column",
                        "& p:nth-child(2)": {
                            fontFamily: 'RalewayMedium',
                            fontSize: '14px',
                            color: '#676767',
                            margin: '0',
                        }
                    },
                    "& > div:nth-child(2)": {
                        marginTop: '40px',
                        maxWidth: '400px'
                    },
                    "& > div:nth-child(3)": {
                        display: 'flex',
                        flexDirection: 'column',

                    }
                }
            }
        }
    },
    inputText: {
        backgroundColor: '#D9D9D9B2',
        height: "100% !important",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: '10px',
        maxHeight: 'none !important',
        padding: '5px',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        "& > span:nth-child(3)": {
            width: "180px",
            textAlign: "right",
            color: '#A5A4A4',
            fontSize: '13px',
            fontFamily: 'DellaRespira'
        }
    },
    socialMediaInput: {
        "& > div": {
            paddingLeft: '0 !important',
            // border: 'none',
            // padding: "5px 25px",
            margin: '0.5vh 15px 15px 0',
            // cursor: 'pointer',
            // backgroundColor: "#000",
            // color: "#fff",
            // fontFamily: 'ElMessiriBold',
            // fontSize: '18px',
            // width: '150px',
            borderRadius: '7px',
        }
    },
    titleCollectionSetting: {
        fontFamily: 'RalewaySemiBold',
        fontSize: '16px',
        color: '#000',
        margin: '0',
        marginBottom: '10px',
        marginTop: '20px'
    },
    errorText: {
        fontFamily: 'plexSansLight',
        marginTop: 0,
        color: "red",
        fontSize: "14px",
        display: 'inline-block'
    },
    itemCollectionContainer: {
        marginTop: '-60px',
        margin: '20px 0',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        columnGap: '20px',
        height: 'max-content',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "1fr",
            marginTop: "30px"
        },

    },
    GetBackArrow: {
        cursor: 'pointer',
        "& svg": {
            fontSize: '30px',
            color: theme.palette.primary.main
        }
    },
    sideButtons: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        width: '100%',
        justifyContent: 'center',
        padding: 0,
        marginTop: '100px',
        "& a, button": {
            marginLeft: '15%',
            color: "#D3D3D3",
            fontFamily: 'RalewayMedium',
            fontSize: '22px',
            textDecoration: 'none',
            padding: '7px 18px',
            width: '200px',
            whiteSpace: 'nowrap',
            borderRadius: '7px',
            background: 'none',
            border: 'none',
            textAlign: "left",
            cursor: 'pointer',
            "& img": {
                marginRight: '5px',
                verticalAlign: 'middle'
            }
        },
        "& li": {
            marginBottom: '27px'
        },
        "& li:hover": {
            borderLeft: '2px solid #fff',
        },
        "& li:focus-within": {
            borderLeft: '2px solid #fff',
        },
        "& button:focus": {
            backgroundColor: '#424141'
        },
        "& button:hover": {
            backgroundColor: '#424141',
        },
    },
    saveButton: {
        border: 'none',
        padding: "8px 35px",
        margin: '15px 15px 0',
        cursor: 'pointer',
        backgroundColor: "#000",
        color: "#fff",
        fontFamily: 'ElMessiriBold',
        fontSize: '18px',
        width: '150px',
        borderRadius: '7px',
        whiteSpace: 'nowrap',
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'center',
        "& > div": {
            margin: '0 !important'
        }
    },
    cropContainer: {
        width: '400px',
        "& p": {
            fontFamily: 'ElMessiriSemiBold',
            fontSize: '20px',
            color: '#000',
            width: '100%',
            textAlign: 'center'
        },
        "& > div:nth-child(2)": {
            height: '400px',
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: "space-between",
            alignContent: 'space-between',
            alignItems: 'flex-start'
        }
    },
    sliderContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        "& div": {
            width: '250px',
            marginTop: '30px',
            "& span": {
                "&.css-53tbrc-MuiSlider-root": {
                    color: '#000'
                }
            }
        },
        "& button": {
            width: "103px",
            backgroundColor: '#000',
            height: '30px',
            fontFamily: 'ElMessiriSemiBold',
            fontSize: '18px',
            lineHeight: 'inherit',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: 'gray'
            }
        }
    },
    previewImgContainer: {
        display: 'flex',
        position: 'relative',
        borderRadius: '7px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '150px',
        height: '150px',
        "& button": {
            position: 'absolute',
            display: 'none',
        },
        "&:hover": {
            "& img": {
                filter: 'grayscale(0.5)',
                opacity: "0.6"
            },
            "& button": {
                display: 'flex',
                zIndex: '1000',
                "& svg": {
                    backgroundColor: '#fff',
                    transition: 'background .35s ease',
                    position: 'absolute',
                    color: 'gray',
                    padding: '5px',
                    borderRadius: '50%'
                }
            }
        }
    },
    previewImgContainerBanner: {
        display: 'flex',
        position: 'relative',
        borderRadius: '7px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '350px',
        height: '150px',
        "& button": {
            position: 'absolute',
            display: 'none',
        },
        "&:hover": {
            "& img": {
                filter: 'grayscale(0.5)',
                opacity: "0.6"
            },
            "& button": {
                display: 'flex',
                zIndex: '1000',
                "& svg": {
                    backgroundColor: '#fff',
                    transition: 'background .35s ease',
                    position: 'absolute',
                    color: 'gray',
                    padding: '5px',
                    borderRadius: '50%'
                }
            }
        }
    },
    createItem: {
        display: 'grid',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        minHeight: '420px',
        background: 'linear-gradient(90deg, rgba(191, 191, 191, 0.4) 97.75%, rgba(92, 92, 92, 0) 99.14%)',
        placeItems: 'center',
        marginBottom: '10px',
        "& > a": {
            background: 'rgba(142, 141, 141, 0.15)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '7px',
            padding: "5px 15px",
            textDecoration: 'none',
            fontFamily: 'ElMessiriBold',
            fontSize: '18px',
            color: '#000',
        }
    },
}))