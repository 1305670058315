import { useStyles } from './StylePrivacy'
import privacy from '../../Assets/Img/privacy .jpg'

function Privacy(): JSX.Element {
    const classes = useStyles()

    return (
        <div className={classes.aboutUsMain}>
            <div>
                <img src={privacy} alt="privacy" width="100%" />
            </div>
            <div className={classes.container}>
                <div>
                    <p>This Privacy Policy explains what personal information we collect, how we use and share that information, and your choices concerning our information practices.
                        Before using the Service or submitting any personal information to Artaniom, please review this Privacy Policy carefully and contact us if you have any questions. By using the Service, you agree to the practices described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not access the Site or otherwise use the Service. This Privacy Policy is incorporated into and forms part of our Terms of Service, located at https://artaniom.io/tos. Capitalized terms not defined in this Privacy Policy have the meanings assigned to them in terms of Service.</p>
                </div>
                <div>
                    <h3>1. Personal Information Collection</h3>
                    <h3>Personal Information You Provide: <small>We collect the following categories of information from you:</small></h3>
                    <ul>
                        <li>
                            <h3>Identification Information:</h3>
                            <p>We collect your name, email address, digital wallet address, and any other information you directly provide us on or through the Services.

                            </p>
                        </li>
                        <li>
                            <h3>Communication Information:</h3>
                            <p>
                                We may collect information when you contact us with questions or concerns and when you voluntarily respond to questionnaires, surveys, or requests for market research seeking your opinion and feedback.
                            </p>
                        </li>
                        <li>
                            <h3>Commercial Information:</h3>
                            <p>We may retain a history of the Digital Artwork or Art Content you browse, sell, and/or purchase using the Service.
                            </p>
                        </li>
                        <li>
                            <h3>Social Media Information:</h3>
                            <p>We maintain a social media presence on platforms like Instagram, Twitter, and LinkedIn. When you interact with us on social media, we may receive the personal information you provide or make available based on your settings, such as your profile information. We also collect any social media profile information you directly provide us.
                            </p>
                        </li>
                    </ul>
                    <div>
                        <h3>Internet Activity Information:</h3>
                        <p>When you visit, use, and interact with the Service, we may automatically log the following information: </p>
                    </div>
                    <ul>
                        <li>
                            <h3>Device Information:</h3>
                            <p>The manufacturer and model, operating system, browser type, IP address, and unique identifiers of the device you use to access the Service. The information we collect may vary based on your device type and settings. </p>
                        </li>
                        <li>
                            <h3>Usage Information:</h3>
                            <p>Information about how you use our Services, such as the types of content you view or engage with, the features you use, the actions you take, and the time, frequency, and duration of your activities. We use Google Analytics, a web analytics service provided by Google LLC (“Google”), to help collect and analyze Usage Information.</p>
                        </li>
                        <li>
                            <h3>Location Information:</h3>
                            <p>We may derive a rough estimate of your location from your IP address when you visit the Site. </p>
                        </li>
                        <p>We may use the following technologies to collect Internet Activity Information:</p>
                        <li>
                            <h3>Cookies</h3>
                            <p> are text files stored on your device to uniquely identify your browser or store information or settings in the browser to help you navigate between pages efficiently, remember your preferences, enable functionality, and help us understand user activity and patterns and facilitate online advertising. </p>
                        </li>
                        <li>
                            <h3>Local storage technologies</h3>
                            <p>  like HTML5 provide cookie-equivalent functionality but can store more significant amounts of data on your device outside your browser in connection with specific applications.</p>
                        </li>
                        <h3>
                            Personal Information We Collect from Publicly Available Sources:
                        </h3>
                        <p>We may collect identification information about you from publicly available blockchain networks, such as the Ethereum blockchain.</p>
                    </ul>
                    <div>
                        <h3>2. Personal Information Use</h3>
                        <p>We use your personal information for the following purposes:</p>
                    </div>
                    <ul>
                        <h3>Service Delivery <small>, including:</small></h3>
                        <li>Provide, operate, maintain, and secure the Service;</li>
                        <li>Create, maintain, and authenticate your account; and </li>
                        <li>Process transactions.</li>
                    </ul>
                    <ul>
                        <h3>Communicating with You <small>, including:</small></h3>
                        <li>send you updates about administrative matters such as changes to our terms or policies; and</li>
                        <li>provide user support, and respond to your requests, questions, and feedback.</li>
                    </ul>
                    <ul>
                        <h3>Service Improvement <small>, including: </small></h3>
                        <li>personalize your experience; and </li>
                        <li>create and derive insights from de-identified and aggregated information.</li>
                    </ul>
                    <ul>
                        <h3>Marketing and Advertising<small>, including:</small></h3>
                        <li><h3>Direct Marketing:<small>To send you marketing communications, including notifying you of special promotions, offers, and events via email and other means; and</small></h3></li>
                    </ul>
                    <ul>
                        <h3>Compliance and Protection<small>, including:</small></h3>
                        <li>Comply with applicable laws, lawful requests, and legal processes, such as responding to subpoenas or requests from government authorities;</li>
                        <li>Protect our, your or others’ rights, privacy, safety, or property (including by making and defending legal claims);</li>
                        <li>Audit our compliance with legal and contractual requirements and internal policies; and</li>
                        <li>Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical, or illegal activity, including cyberattacks and identity theft.</li>
                    </ul>
                    <div>
                        <h3>3. Personal Information Sharing</h3>
                        <h3>Our Sharing: <small>We may share personal information with:</small></h3>
                    </div>
                    <ul>
                        <h3>Service Providers,<small>including hosting services, email services, advertising and marketing services, payment processors, customer support services, and analytics services.</small></h3>
                        <h3>Professional Advisors,<small>such as lawyers and accountants, where doing so is necessary to facilitate the services they render to us. </small></h3>
                        <h3>Complainants:<small>If you are a Creator, we may, at our sole discretion, share your personal information with individuals who claim that your Digital Artwork or Art Content may infringe their intellectual property and other proprietary rights. </small></h3>
                    </ul>
                    <div>
                        <h3>Your Sharing: <small>Your transactions through the Service will be recorded on the blockchain and associated with your wallet ID.</small></h3>
                    </div>
                    <div>
                        <h3>5. Links to Other Websites</h3>
                        <p>The Service may contain links to other websites not operated or controlled by Artaniom, including social media services (“Third Party Sites”). The information you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. We do not imply that we endorse or have reviewed these sites by providing these links. Please get in touch with the Third Party Sites directly for information on their privacy practices and policies. </p>
                    </div>
                    <div>
                        <h3>6. Security</h3>
                        <p>We employ several technical, organizational, and physical safeguards to protect the personal information we collect. However, no security measures are failsafe, and we cannot guarantee the security of your personal information. You use the Service at your own risk.</p>
                    </div>
                    <div>
                        <h3>7. Your Choices</h3>
                        <h3>Update or Correct Personal Information: <small>You can contact us and request any updates or corrections to keep your personal information accurate, current, and complete. </small></h3>
                        <h3>Opt-Out of Marketing Communications: <small>You may opt-out of marketing communications by following the unsubscribe instructions in any marketing email we send. Please note, however, that you may continue to receive communications as described in the Communicating with You section after opting out of marketing communications.</small></h3>
                    </div>
                    <div>
                        <h3>8. Changes to the Privacy Policy</h3>
                        <p>The Service and our business may change from time to time. As a result, we may change this Privacy Policy at any time. We will post an updated version on this page when we do unless another type of notice is required by applicable law. By continuing to use our Service or providing us with personal information after posting an updated Privacy Policy or notifying you by other means, you consent to the revised Privacy Policy and its practices.</p>
                    </div>
                    <div>
                        <h3>9. Contact Us</h3>
                        <p>If you have questions about our Privacy Policy or information practices, please get in touch with us at our designated request address: support@artaniom.io</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Privacy