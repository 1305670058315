import React from 'react'
import { useStyles } from './StyleMarketComponents'

type props = { title: string }

function MainTitle({ title }: props) {
    const classes = useStyles()
    return (
        <p className={classes.mainTitle}>{title}</p>
    )
}

export default MainTitle