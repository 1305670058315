import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    mainAuction: {
        display: 'flex',
        margin: '50px 0 ',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    container: {
        width: '80%',
        display: "grid",
        gridTemplateColumns: "1.5fr 1fr 1fr",
        gridTemplateRows: "1fr",
        gridColumnGap: "10px",
        gridRowGap: "1px",
    },
    AucSection1: {
        display: 'flex',
        flexDirection: 'column',
        "& p:only-of-type": {
            fontSize: '45px',
            fontFamily: 'inherit',
            color: "#000",
            margin: '0'
        },
        "& span": {
            color: theme.palette.primary.dark,
            fontSize: "18px"
        },
        "& > div": {
            marginTop: '15px',
            "& p:first-child": {
                fontSize: '20px',
                marginBottom: "5px",
            },
            "& p:nth-child(3)": {
                fontSize: '18px',
                fontFamily: 'plexSansLight',
                fontWeight: 500,
                marginTop: "6px",

            },
            "& button": {
                fontSize: '16px',
                color: "#000",
                fontFamily: 'plexSansSBold',
                cursor: 'pointer'
            }
        }
    },
    historySec: {
        color: '#000',
        fontSize: '18px',
        margin: '5px 0',
        fontFamily: 'plexSansLight',
        "& p:first-child": {
            fontSize: '20px',
            fontFamily: 'plexSans'
        },
        "& P > span": {
            fontWeight: 600,
            margin: '0 4px',
            color: '#000',
        }
    },
    buttonsSec3: {
        position: 'absolute',
        right: 0,
        padding: 0,
        "& span": {
            padding: "0 2px",
            "& svg": {
                color: theme.palette.primary.dark,
            }
        },

    },
    AucSection3: {
        position: 'relative',
        "& > div": {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            "& > div:not([id='AuctionEnd'])": {
                margin: "7px 0",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                "& > img[alt='avatar']": {
                    width: '60px',
                    height: '60px',
                    borderRadius: "50%"
                },
                "& > div": {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginLeft: '20px',
                    padding: '5px',
                    "& > p": {
                        margin: '2px 4px',
                        color: theme.palette.primary.dark
                    },
                }
            }
        }
    }
    ,
    SaleButtons: {
        "& > div": {
            margin: "7px 0",
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flexStart',
            whiteSpace: 'nowrap',
            "& > div": {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginLeft: '20px',
                padding: '5px',
                "& > p": {
                    margin: '2px 4px',
                    color: theme.palette.primary.dark
                },
            }
        },
        "& p:nth-child(1)": {
            fontSize: '16px',
            fontWeight: 500,
            marginBottom: 0
        },
        "& p:nth-child(2)": {
            fontSize: '28px',
            fontWeight: 500,
            marginBottom: 0,
            color: '#000'
        },

    },
    Bidby: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "& > img": {
            width: '30px',
            height: '30px',
            borderRadius: "50%"
        },
        "& > span": {
            marginLeft: '4px',
            color: theme.palette.primary.dark
        }
    },
    time: {
        display: "flex",
        flexDirection: 'row',
        "& span": {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0 5px',
            "& p": {
                margin: 0
            },
            "& p:nth-child(1)": {
                fontSize: '28px',
                textAlign: 'center'
            },
            "& p:nth-child(2)": {
                fontSize: '14px',
                fontWeight: 500,
                color: theme.palette.primary.dark,
                fontFamily: 'plexSansLight'
            }
        }
    },
    bidInfo: {
        display: 'flex',
        flexDirection: "row",
        whiteSpace: 'nowrap',
        "& ul": {
            listStyle: ' none',
            width: '50%',
            "& li": {
                margin: '8px 0'
            }
        }
    },
    bidBtn: {
        width: '100%',
        backgroundColor: '#000',
        color: '#fff',
        border: "none",
        padding: "10px 7px",
        borderRadius: '10px',
        fontSize: '24px',
        fontFamily: 'inherit',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
    bidPopUp: {
        padding: "20px",
        display: 'grid',
        marginTop: "40px",
        gridTemplateColumns: "1fr 3fr",
        columnGap: '15px',
        "& > div:nth-child(1)": {
            "& > p": {
                fontFamily: "plexSanslight",
                fontSize: '17px',
                color: theme.palette.primary.dark,
                margin: 0,
            },
        },
        "& > div:nth-child(2)": {
            "& > div": {
                "& > div": {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    "& > p": {
                        marginBottom: "4px"
                    }
                },
                "& > p": {
                    color: theme.palette.primary.dark,
                    marginTop: '3px',
                    textAlign: "end"
                }
            },
            "& > div:nth-child(2)": {
                "& > div": {
                    "& > p": {
                        marginTop: "3px",
                        color: theme.palette.primary.dark,
                    }
                },
            },
            "& > div:nth-child(3)": {
                marginTop: '20px',
                "& > span": {
                    color: theme.palette.primary.dark,
                    fontFamily: "plexSanslight",
                },
            },
            "& > div:nth-child(4)": {
                marginTop: '20px',
                textAlign: 'right',

                "& > button": {
                    width: '150px',
                    backgroundColor: '#000',
                    color: '#fff',
                    border: "none",
                    padding: "10px 15px",
                    borderRadius: '10px',
                    fontSize: '20px',
                    fontFamily: 'inherit',
                    cursor: 'pointer',
                    "&[disabled]": {
                        color: theme.palette.primary.dark,
                    },
                    "&:hover:not([disabled])": {
                        backgroundColor: theme.palette.primary.main,
                        color: '#fff',
                    },
                },
            }
        },

    },
    bidSubmitMessage: {
        "& > p:nth-child(1)": {
            fontSize: '22px',
            fontWeight: 600,
            color: '#000',
            textAlign: "center"
        },
        "& p:nth-child(2)": {
            marginTop: '40px',
            textAlign: 'center',
            color: theme.palette.primary.dark
        }
    }

}))