import { useLocation } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

interface IChildren {
    children: JSX.Element[] | React.ReactNode
}

function Layout({ children }: IChildren) {
    const location = useLocation()
    return (
        <>
            {
                !location.pathname.includes("edit") &&
                <Header />
            }
            {children}
            <Footer />
        </>
    )
}

export default Layout

