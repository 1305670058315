
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    CreateCollection: {
        "& form": {
            padding: '0 70px 20px ',
        },
        position: 'relative',
        "& h2": {
            color: theme.palette.primary.main,
            textAlign: 'center',
            marginTop: "10px",
            paddingBottom: '2px',
            fontFamily: "ElMessiri",
            fontSize: '20px',
            fontWeight: 600
        },
        "& p": {
            marginBottom: 0,
            fontFamily: 'RalewaySemiBold',
            fontSize: '16px',
            fontWeight: 500,
        },
        "& div:last-child": {
            display: "flex",
            justifyContent: ' center',
            "&  input:last-child": {
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                fontFamily: 'inherit',
                fontSize: "16px",
                border: 'none',
                padding: '8px 20px',
                borderRadius: '8px',
                cursor: 'pointer',
                "&:disabled": {
                    opacity: '0.5'
                }
            }
        },
        "& > label": {
            "& span": {
                color: theme.palette.primary.dark
            },
            "& .css-q8hpuo-MuiFormControl-root": {
                marginTop: '8px',
                marginBottom: "6px"
            },
            "& > p": {
                display: 'flex',
                flexDirection: 'row',
                fontFamily: "Raleway",
                fontSize: '16px',
                fontWeight: 600,
            }
        }
    },
    errorText: {
        fontFamily: 'plexSansLight',
        marginTop: 0,
        color: "red",
        fontSize: "12px",
        display: 'inline-block',
        fontWeight: 400
    },
    web3Collection: {
        width: '400px',
        margin: '25px auto',
        display: 'grid',
        placeContent: 'center',
        padding: '5px 40px 30px ',
    },
    titleCollection: {
        marginTop: '20px',
        marginBottom: '6px !important'
    },
    title: {
        marginTop: '30px',
        marginBottom: '6px !important',
        "& span:nth-child(2)": {
            "& span": {
                "& span": {
                    fontFamily: 'RalewayMedium',
                    fontSize: "14px",
                    margin: "0 0 0 0",
                    display: 'inline-block'
                }
            }
        }
    },
    inputCounterStyle: {
        fontSize: '10px !important',
        width: '100% !important',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: '0px !important',
        fontFamily: 'DellaRespira',
    },

    loadingCreateCollectionStep2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& p:nth-child(2)": {
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: "16px",
            color: "#404040",
            margin: 0,
            textAlign: 'center'
        },
        "& p:nth-child(3)": {
            fontFamily: 'DellaRespira',
            fontWeight: 500,
            fontSize: "15px",
            color: "#676767",
            marginTop: "25px",
            textAlign: 'center',
            whiteSpace: 'nowrap',
            marginBottom: '30px'
        }
    },
    loadingCreateCollection: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        "& > div:nth-child(2)": {
            "& > p:nth-child(1)": {
                fontFamily: 'ElMissiri',
                fontWeight: 600,
                fontSize: "20px",
                color: theme.palette.primary.main,
                marginTop: '2px'
            }
            , "& > p:nth-child(2)": {
                fontFamily: 'Raleway',
                fontWeight: 500,
                fontSize: "16px",
                color: "#404040",
                margin: 0,
                textAlign: 'center'
            }
        },
        "& > div:nth-child(3)": {
            marginTop: '20px',
            "& > p:nth-child(1)": {
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: "16px",
                color: "#060606",
            }
            , "& > p:nth-child(2)": {
                fontFamily: 'DellaRespira',
                fontWeight: 500,
                fontSize: "16px",
                color: "#000",
                margin: 0,
                textAlign: 'center'
            }
        },
        "& > div:nth-child(4)": {
            marginTop: '20px',
            "& > p:nth-child(1)": {
                fontFamily: 'DellaRespira',
                fontWeight: 500,
                fontSize: "15px",
                color: "#000",
            }
        }
    },
    collecCreated: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px',
        "& p:nth-child(2)": {
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: "20px",
            color: theme.palette.primary.main
        },
        "& p:nth-child(3)": {
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: "20px",
            color: "#404040",
            whiteSpace: 'nowrap',
            marginTop: "5px",
            "& > a": {
                verticalAlign: 'middle',
                color: '#000',
                "& > img": {
                    verticalAlign: 'middle',
                    marginLeft: '3px',
                }
            }
        },
        "& > button": {
            backgroundColor: theme.palette.primary.main,
            padding: '10px 70px',
            color: '#fff',
            borderRadius: '7px',
            border: 'none',
            marginTop: '30px',
            fontSize: '16px',
            cursor: 'pointer'
        }
    },
    headerText: {
        marginTop: '0 !important',
        marginBottom: "25px !important",
        fontFamily: 'RalewayMedium !important',
        fontSize: '16px',
        color: "#4A4A4A !important"
    }
}))

