import { ExploreReducer, ReducerState } from "../../../Utils/Interfaces"
import * as types from '../../../redux/Types'

const initialState = {
    Data: [],
    isLoading: false,
    Error: false,
    DataTypeExplore: [],
    isLoadingTypeExplore: false,
    ErrorTypeExplore: false
}

export default function ExploreNFTsReducer(state: ExploreReducer = initialState, action: any) {
    switch (action.type) {
        case types.GET_EXPLORE_NFTs_REQUEST:
            return {
                ...state,
                isLoading: true,
                Error: false,
                Data: action.payload
            }
        case types.GET_EXPLORE_NFTs_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Error: false,
                Data: action.payload
            }
        case types.GET_EXPLORE_NFTs_FAILED:
            return {
                ...state,
                isLoading: false,
                Error: true,
                Data: action.payload
            }
        case types.GET_NFT_TYPE_EXPLORE_REQUEST:
            return {
                ...state,
                isLoadingTypeExplore: true,
                ErrorTypeExplore: false,
                DataTypeExplore: action.payload
            }
        case types.GET_NFT_TYPE_EXPLORE_SUCCESS:
            return {
                ...state,
                isLoadingTypeExplore: false,
                ErrorTypeExplore: false,
                DataTypeExplore: action.payload
            }
        case types.GET_NFT_TYPE_EXPLORE_FAILED:
            return {
                ...state,
                isLoadingTypeExplore: false,
                ErrorTypeExplore: true,
                DataTypeExplore: action.payload
            }
        default:
            return state
    }
} 