import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    mainMarketLayout: {
        display: 'grid',
        gridTemplateColumns: '3fr 1.5fr',
        gridColumnGap: '20px',
        marginTop: "120px"
    },
    mainTitle: {
        fontFamily: 'ElMessiriBold',
        fontSize: "32px",
        color: "#94794F",
        marginBottom: '20px',
        marginTop: "0"
    },
    input: {
        backgroundColor: '#e1e1e1',
        borderRadius: '7px',
        padding: '6px',
        fontSize: '32px',
        fontFamily: 'Raleway'
    },
    mainInput: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "& > div": {
            width: '400px'
        },
        "& > p": {
            fontFamily: 'RalewaySemiBold',
            fontSize: '32px',
            color: '#676767',
            margin: "0 10px 0 20px"
        },
        "& > img": {
            width: '14px'
        }
    },
    mainInputWithBackground: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '510px',
        backgroundColor: '#e1e1e1',
        borderRadius: '7px',
        "& > div": {
            width: '400px'
        },
        "& > p": {
            fontFamily: 'RalewaySemiBold',
            fontSize: '32px',
            color: '#676767',
            margin: "0 10px 0 20px"
        },
        "& > img": {
            width: '14px',
            margin: '10px'
        }
    },
    titleAboveInput: {
        fontFamily: 'RalewaySemiBold',
        fontSize: '20px',
        width: '500px',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#000',
        marginBottom: '8px'
    },
    descriptionList: {
        margin: '70px 0',
        listStyleType: 'none',
        padding: '0',
        "& > li": {
            fontFamily: 'Raleway',
            fontSize: '17px',
            color: '#676767',
            lineHeight: '30px'
        },
        "& li::before": {
            content: '"-"',
            marginRight: '3px'
        }
    },
    confirmingText: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        "& p": {
            textAlign: 'center',
            margin: 0
        },
        "& > p:nth-child(1)": {
            fontFamily: 'ElMessiriBold',
            color: '#000',
            fontSize: '25px',
            marginBottom: '6px'
        },
        "& > p:nth-child(2)": {
            fontFamily: 'RalewayMedium',
            color: '#676767',
            fontSize: '16px',
        }
    },
    resultTitleText: {
        fontFamily: 'ElMessiriBold',
        color: '#000',
        fontSize: '32px',
        margin: '0',
        textAlign: 'center'
    },
    blackLinkButton: {
        fontFamily: 'ElMessiriBold',
        fontSize: '22px',
        padding: '15px 60px',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0385417) 99.99%, rgba(0, 0, 0, 0) 100%), #373737',
        boxShadow: '0px 5px 2px #37254B',
        borderRadius: '7px',
        color: '#fff',
        textDecoration: 'none',
        marginTop: '95px'
    },
    confirmationSection: {
        display: 'flex',
        flexDirection: 'column',
        "& > p": {
            fontFamily: 'ElMessiriBold',
            fontSize: '32px',
            color: '#000'
        },
        "& ul[id='tripleSteps']": {
            display: 'flex',
            flexDirection: 'column',
            listStyleType: 'none',
            padding: '0',
            " & li": {
                marginBottom: '30px',
                fontFamily: 'RalewayMedium',
                fontSize: '24px',
                color: '#000',
                display: 'flex',
                alignItems: 'center',
                "& span": {
                    border: '1px solid #676767',
                    borderRadius: '50%',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50px',
                    height: '50px',
                    marginRight: '20px'
                },
                "& img": {
                    width: '50px',
                    height: '50px',
                    marginRight: '20px'
                }
            }
        }
    },
    lds: {
        "& .lds-ring": {
            display: "inline-block",
            position: "relative",
            width: "50px",
            height: "50px",
            marginRight: '20px',
            "& > div": {
                boxSizing: "border-box",
                display: "block",
                position: "absolute",
                width: "50px",
                height: "50px",
                border: "8px solid #000",
                borderRadius: "50%",
                animation: "lds 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
                borderColor: "#000 transparent transparent transparent",

            },
            "& div:nth-child(1)": {
                animationDelay: "-0.45s"
            },
            "& div:nth-child(2)": {
                animationDelay: "-0.3s"
            },
            "& div:nth-child(3)": {
                animationDelay: "-0.15s"
            },
        },
    },
    '@global': {
        '@keyframes lds': {
            '0%': {
                transform: "rotate(0deg)"
            },
            '100%': {
                transform: "rotate(360deg)"
            }
        }
    },
    walletBalance: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '510px',
        "& span": {
            fontFamily: 'RalewayMedium',
            fontSize: '20px',
            color: '#A5A4A4'
        }
    },
    priceLineBox: {
        width: '500px',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#fff",
        borderRadius: '7px',
        padding: '5px',
        alignItems: 'center',
        "& > div:nth-child(1)": {
            "& > .title": {
                fontFamily: 'RalewaySemiBold',
                fontSize: '22px',
                color: '#000',
                margin: '0'
            }
        },
        "& > div:nth-child(2)": {
            "& > .value": {
                fontFamily: 'RalewaySemiBold',
                fontSize: '32px',
                color: '#000',
                margin: '0'
            },
            "& > .symbol": {
                fontFamily: 'RalewaySemiBold',
                fontSize: '24px',
                color: '#676767',
                margin: '0 5px'
            },
            "& > .inDollor": {
                fontFamily: 'RalewayMedium',
                fontSize: '14px',
                color: '#676767',
                margin: '0 3px'
            },
            "& > img": {
                width: '14px',
                height: "22px"
            }
        }
    },
    CountdownBelowTitle: {
        display: 'flex',
        flexDirection: 'column',
        "& > p": {
            fontFamily: 'RalewayMedium',
            fontSize: '20px',
            color: '#000',
            margin: "0",
            "& > span": {
                fontFamily: 'Raleway',
                fontSize: "14px",
                color: '#676767'
            }
        },
        "& > div": {
            display: 'flex',
            flexDirection: 'row',
            "& > p": {
                fontFamily: 'RalewaySemiBold',
                fontSize: '22px',
                color: '#000',
                margin: "0",
                "& > span": {
                    fontFamily: 'Raleway',
                    fontSize: "14px",
                    color: '#676767',
                    marginRight: '4px'
                }
            },
        }
    },
    CountdownInCard: {
        display: 'flex',
        flexDirection: 'column',
        "& > p": {
            fontFamily: 'RalewayMedium',
            fontSize: '20px',
            color: '#000',
            margin: "0",
            "& > span": {
                fontFamily: 'Raleway',
                fontSize: "14px",
                color: '#676767'
            }
        },
        "& > div": {
            display: 'flex',
            flexDirection: 'row',
            "& > p": {
                fontFamily: 'RalewaySemiBold',
                fontSize: '16px !important',
                color: '#000',
                margin: "0",
                "& > span": {
                    fontFamily: 'Raleway',
                    fontSize: "12px !important",
                    color: '#676767',
                    marginRight: '4px'
                }
            },
        }
    },
    bids: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        "& p": {
            margin: '0',
        },
        "& div[id='right']": {
            "& > p:nth-child(1)": {
                fontFamily: 'RalewayMedium',
                fontSize: '16px',
                color: '#676767',
                "& > span": {
                    color: '#000',
                }
            },
            "& > p:nth-child(2)": {
                fontFamily: 'DellaRespira',
                fontSize: '13px',
                color: '#a5a4a4',
                lineHeight: '30px',
                "& > a": {
                    cursor: 'pointer'
                }
            }
        },
        "& div[id='left']": {
            "& p:nth-child(1)": {
                fontFamily: 'RalewayMedium',
                fontSize: '20px',
                color: '#676767',
                "& > span": {
                    fontFamily: 'RalewayMedium',
                    fontSize: '16px',
                    color: '#676767',
                },
            },
        }
    }
}))


export const useStyleButton = makeStyles(theme => ({
    SubmitButton: {
        width: "220px",
        padding: '12px 0',
        fontFamily: 'ElMessiriBold',
        fontSize: '22px',
        boxShadow: '0px 5px 2px #37254B',
        borderRadius: '7px',
        border: "none",
        color: '#fff',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        whiteSpace: 'nowrap',
        "&:active": {
            transform: "translateY(2px)",
            boxShadow: '0px 3px 1px #37254B',
        }
    }
}))