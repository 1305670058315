import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    mainJoinToArtaniom: {
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        backgroundColor: "#333333",
        width: '100%',
        display: 'flex',
        justifyContent: "center",
        color: "#ffffff",
        paddingY: '20px',
        marginTop: '30px',
        "& > div": {
            width: '80%',
            "& > ul": {
                listStyleType: 'square'
            }
        }
    },
    errorText: {
        fontFamily: 'plexSansLight',
        marginTop: 0,
        color: "red",
        fontSize: "14px",
        display: 'inline-block'
    },
    form: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '30px',
        "& textarea": {
            display: " block",
            width: '60%'
        },
        "& input": {
            width: "60%",
            display: 'block',
            border: "1px lightgray solid",
            borderRadius: '7px',
            height: '28px',
        },
        "& > form": {
            width: '80%',
            "& > div": {
                marginTop: '15px',
            },
            "& > div:last-child": {
                marginTop: '15px',
                width: '60%',
                display: 'flex',
                justifyContent: 'center',
                "& > input": {
                    backgroundColor: theme.palette.primary.main,
                    display: 'flex',
                    width: 'auto',
                    padding: '15px 20px',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    cursor: "pointer",
                    color: '#fff',
                    fontSize: '16px'
                },
            },
        },
        "& label": {
            fontSize: '16px',

        },
    },
    submitSuccessMessage: {
        textAlign: 'center',
        fontSize: '18px',
        color: '#fff'
    },
    completeMessage: {
        width: '80%',
        display: 'grid',
        alignSelf: 'center',
        justifyContent: 'center',
        padding: '60px',
        "& > p": {
            textAlign: 'center',
            margin: 0,
            fontSize: '18px',
        },
        "& > div": {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginTop: '30px',
            "& button, a ": {
                display: 'inline-block',
                width: '150px',
                border: 'none',
                backgroundColor: '#7f7f7f',
                padding: '8px 15px',
                color: '#fff',
                borderRadius: '8px',
                fontSize: '16px',
                cursor: 'pointer'
            },
            "& > a:nth-child(2)": {
                backgroundColor: '#000',
                textAlign: 'center',
                textDecoration: 'none'
            }
        },

    }
}))