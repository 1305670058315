import { Divider, FilledInput, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import photo from '../../Assets/Img/defaultAvatar.png';
import { useStyles } from './styleBuyAuction';

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    classes: any
}

function SimpleDialog(props: SimpleDialogProps) {
    const [bidPrice, setBidPrice] = React.useState<string>('')
    const [isBid, setIsBid] = React.useState<boolean>(false)
    const { onClose, selectedValue, open, classes } = props;
    const handleClose = () => {
        onClose(selectedValue);
    };


    const handleBidPrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setBidPrice(e.target.value)
    }

    const SubmitBid = (): void => {
        setIsBid(true)
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className={classes.bidPopUp}>
                <div>
                    <img src={photo} alt="" width="150px" height="auto" />
                    <p>item Name</p>
                    <p>Collection Name</p>
                </div>
                {isBid ?
                    <div className={classes.bidSubmitMessage}>
                        <p>Your Bid Has Been Submitted</p>
                        <p>you can view your open bid <br /> in your profile</p>
                    </div>
                    :
                    <div>
                        <div>
                            <div>
                                <p>Current Bid</p>
                                <p>1.5 ETH <span>($259)</span></p>
                            </div>
                            <Divider />
                            <p>90ETH <span> ($180000) </span></p>
                        </div>
                        <div>
                            <FilledInput
                                startAdornment={<InputAdornment position="start">|</InputAdornment>}
                                placeholder='Place Your Bid' endAdornment={<InputAdornment position="end">ETH</InputAdornment>}
                                onChange={handleBidPrice}
                            />
                            <div>
                                <p>Your Bid Must Be Atleast</p>
                                <p>1.5 ETH <span>($259)</span></p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p>Your Balance</p>
                                <p>1.5 ETH <span>($259)</span></p>
                            </div>
                            <Divider sx={{ marginBottom: '15px' }} />
                            <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nemo earum aliquam, praesentium exercitationem totam, reiciendis veniam modi aut illum cumque nesciunt repudiandae. Libero consequuntur illo earum. Ipsum molestiae voluptatem molestias.</span>
                        </div>
                        <div>
                            <button onClick={SubmitBid} disabled={bidPrice.length >= 1 ? false : true} >Submit Bid</button>
                        </div>
                    </div>
                }

            </div>
        </Dialog>
    );
}

export default function BidPopUp() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            <button className={classes.bidBtn} onClick={handleClickOpen}>Place a Bid</button>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                classes={classes}
            />
        </div>
    );
}