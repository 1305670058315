import React from 'react'
import { GetUserWalletBalance } from '../../Utils/GetUserWalletBalance'
import { useStyles } from './StyleMarketComponents'

function WalletBalanceWithUi() {
    const balance = GetUserWalletBalance()
    const classes = useStyles()
    return (
        <div className={classes.walletBalance}>
            <span>Your wallet balance</span><span>{balance.toFixed(3)} ETH</span>
        </div>
    )
}

export default WalletBalanceWithUi