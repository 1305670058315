import { ActionTypes } from '@mui/base'
import { AnyAction } from 'redux'
import { actionChannel } from 'redux-saga/effects'
import * as types from './Types'
//FB = short form of (from Backend)

export const RequestMintNftFB = (action: any) => {
    return {
        type: types.REQUEST_MINT_NFT_FB,
        payload: action.payload,
        address: action.address
    }
}

export const SuccessMintNftFB = (action: any) => {
    return {
        type: types.SUCCESS_MINT_NFT_FB,
        payload: action.payload
    }
}

export const FailedMintNftFB = (action: any) => {
    return {
        type: types.FAILED_MINT_NFT_FB,
        payload: action.payload
    }
}

export const GetNftTypeFB = () => {
    return {
        type: types.GET_NFT_TYPE_FB,
    }
}


export const SuccessNftTypeFB = (action: any) => {
    return {
        type: types.SUCCESS_NFT_TYPE_FB,
        payload: action.payload
    }
}

export const FailedNftTypeFB = (action: any) => {
    return {
        type: types.FAILED_NFT_TYPE_FB,
        payload: action.payload
    }
}

export const GetNFT = (action: any) => {
    return ({
        type: types.GET_NFT,
        payload: action.payload
    })
}

export const SuccessNFT = (action: any) => {
    return ({
        type: types.SUCCESS_NFT,
        payload: action.payload
    })
}

export const FailedNFT = (action: any) => {
    return ({
        type: types.FAILED_NFT,
        payload: action.payload
    })
}

export const CreateProfileRequeset = (action: any) => {
    return ({
        type: types.CREATE_PROFILE_REQUEST,
        payload: action.payload
    })
}

export const CreateProfileSuccess = (action: any) => {
    return ({
        type: types.CREATE_PROFILE_SUCCESS,
        payload: action.payload
    })
}

export const CreateProfileFailed = (action: any) => {
    return ({
        type: types.CREATE_PROFILE_FAILED,
        payload: action.payload
    })
}

export const ProfileHeaderRequest = () => {
    return ({
        type: types.PROFILE_HEADER_REQUEST,
    })
}

export const ProfileHeaderSuccess = (action: any) => {
    return ({
        type: types.PROFILE_HEADER_SUCCESS,
        payload: action.payload
    })
}

export const ProfileHeaderFailed = (action: any) => {
    return ({
        type: types.PROFILE_HEADER_FAILED,
        payload: action.payload
    })
}

export const GetProfile = (action: any) => {
    return ({
        type: types.GET_PROFILE_REQUEST,
        payload: action?.payload
    })
}

export const GetProfileSuccess = (action: any) => {
    return ({
        type: types.GET_PROFILE_SUCCESS,
        payload: action.payload
    })
}

export const GetProfileFailed = (action: any) => {
    return ({
        type: types.GET_PROFILE_FAILED,
        payload: action.payload
    })
}

export const CreateCollection = (action: any) => {
    return ({
        type: types.CREATE_COLLECTION_REQUEST,
        payload: action.payload
    })
}

export const CreateCollectionSuccess = (action: any) => {
    return ({
        type: types.CREATE_COLLECTION_SUCCESS,
        payload: action.payload
    })
}

export const CreateCollectionFailed = (action: any) => {
    return ({
        type: types.CREATE_COLLECITON_FAILED,
        payload: action.payload
    })
}


export const SubmitNotificationRequest = (action: any) => {
    return ({
        type: types.SUBMIT_NOTIFICATION_REQUEST,
        payload: action.payload
    })
}


export const SubmitNotificationSuccess = (action: any) => {
    return ({
        type: types.SUBMIT_NOTIFICATION_SUCCESS,
        payload: action.payload
    })
}

export const SubmitNotificationFailed = (action: any) => {
    return ({
        type: types.SUBMIT_NOTIFICATION_FAILED,
        payload: action.payload
    })
}


export const CreateCollectionWEB3Request = (action: any) => {
    return ({
        type: types.CREATE_COLLECTION_WEB3_REQUEST,
        payload: action.payload
    })
}


export const CreateCollectionWEB3Success = (action: any) => {
    return ({
        type: types.CREATE_COLLECTION_WEB3_SUCCESS,
        payload: action.payload
    })
}


export const CreateCollectionWEB3Failed = (action: any) => {
    return ({
        type: types.CREATE_COLLECTION_WEB3_FAILED,
        payload: action.payload
    })
}


export const FetchCollectionRequest = (action: any) => {
    return ({
        type: types.FETCH_COLLECTION_REQUEST,
        payload: action.payload
    })
}


export const FetchCollectionSuccess = (action: any) => {
    return ({
        type: types.FETCH_COLLECTION_SUCCESS,
        payload: action.payload
    })
}


export const FetchCollectionFailed = (action: any) => {
    return ({
        type: types.FETCH_COLLECTION_FAILED,
        payload: action.payload
    })
}


export const ChangeWeb3State = (action: any) => {
    return ({
        type: types.CHANGE_WEB3_STATE_IN_CREATE_COLLECTION,
        payload: action.payload
    })
}


export const CompleteCollectionRequest = (action: any) => {
    return ({
        type: types.COMPLETE_COLLECTION_REQUEST,
        payload: action.payload
    })
}

export const CompleteCollectionSuccess = (action: any) => {
    return ({
        type: types.COMPLETE_COLLECTION_SUCCESS,
        payload: action.payload
    })
}

export const CompleteCollectionFailed = (action: any) => {
    return ({
        type: types.COMPLETE_COLLECTION_FAILED,
        payload: action.payload
    })
}

export const GetCollectionRequest = (action: any) => {
    return ({
        type: types.GET_COLLECTION_REQUEST,
        payload: action.payload
    })
}

export const GetCollectionSuccess = (action: any) => {
    return ({
        type: types.GET_COLLECTION_SUCCESS,
        payload: action.payload
    })
}


export const GetCollectionFailed = (action: any) => {
    return ({
        type: types.GET_COLLECTION_FAILED,
        payload: action.payload
    })
}

export const GetNFTsExploreRequest = (action: any) => {
    return ({
        type: types.GET_EXPLORE_NFTs_REQUEST,
        payload: action.payload
    })
}


export const GetNFTsExploreSuccess = (action: any) => {
    return ({
        type: types.GET_EXPLORE_NFTs_SUCCESS,
        payload: action.payload
    })
}

export const GetNFTsExploreFailed = (action: any) => {
    return ({
        type: types.GET_EXPLORE_NFTs_FAILED,
        payload: action.payload
    })
}

export const GetHomeRequest = () => {
    return ({
        type: types.GET_HOME_REQUEST,
    })
}


export const GetHomeSuccess = (action: any) => {
    return ({
        type: types.GET_HOME_SUCCESS,
        payload: action.payload
    })
}


export const GetHomeFailed = (action: any) => {
    return ({
        type: types.GET_HOME_FAILED,
        payload: action.payload
    })
}


export const GetNftTypeExplore = () => {
    return ({
        type: types.GET_NFT_TYPE_EXPLORE_REQUEST,

    })
}


export const GetNftTypeExploreSuccess = (action: any) => {
    return ({
        type: types.GET_NFT_TYPE_EXPLORE_SUCCESS,
        payload: action.payload
    })
}


export const GetNftTypeExploreFailed = (action: any) => {
    return ({
        type: types.GET_NFT_TYPE_EXPLORE_FAILED,
        payload: action.payload
    })
}


export const FixedPriceRequest = (action: any) => {
    return ({
        type: types.FIXED_PRICE_REQUEST,
        payload: action.payload
    })
}


export const FixedPriceSuccess = (action: any) => {
    return ({
        type: types.FIXED_PRICE_SUCCESS,
        payload: action.payload
    })
}


export const FixedPriceFailed = (action: any) => {
    return ({
        type: types.FIXED_PRICE_FAILED,
        payload: action.payload
    })
}

export const CancelFixedPriceRequest = (action: any) => {
    return ({
        type: types.CANCEL_FIX_PRICE_REQUEST,
        payload: action.payload
    })
}

export const CancelFixedPriceSuccess = (action: any) => {
    return ({
        type: types.CANCEL_FIX_PRICE_SUCCESS,
        payload: action.payload
    })
}

export const CancelFixedPriceFailed = (action: any) => {
    return ({
        type: types.CANCEL_FIX_PRICE_FAILED,
        payload: action.payload
    })
}


export const BuyFixedPriceRequest = (action: any) => {
    return ({
        type: types.BUY_FIX_PRICE_REQUEST,
        payload: action.payload
    })
}

export const BuyFixedPriceSuccess = (action: any) => {
    return ({
        type: types.BUY_FIX_PRICE_SUCCESS,
        payload: action.payload
    })
}

export const BuyFixedPriceFailed = (action: any) => {
    return ({
        type: types.BUY_FIX_PRICE_FAILED,
        payload: action.payload
    })
}


export const SellInAuctionResponseWeb3 = (action: any) => {
    return ({
        type: types.SELL_IN_AUCTION_RESPONSE_WEB3,
        payload: action.payload
    })
}


export const SellInAuctionResponseWeb3Success = (action: any) => {
    return ({
        type: types.SELL_IN_AUCTION_RESPONSE_WEB3_SUCCESS,
        payload: action.payload
    })
}


export const SellInAuctionResponseWeb3Failed = (action: any) => {
    return ({
        type: types.SELL_IN_AUCTION_RESPONSE_WEB3_FAILED,
        payload: action.payload
    })
}

export const SubmitLazyMint = (action: any) => {
    return ({
        type: types.LAZY_MINT_REQUEST,
        payload: action.payload
    })
}

export const SubmitLazyMintSuccess = (action: any) => {
    return ({
        type: types.LAZY_MINT_SUCCESS,
        payload: action.payload
    })
}

export const SubmitLazyMintFailed = (action: any) => {
    return ({
        type: types.LAZY_MINT_FAILED,
        payload: action.payload
    })
}


export const CreateCollectionSteps = (action: any) => {
    return ({
        type: types.CREATE_COLLECTION_STEPS,
        payload: action.payload
    })
}


export const MintSteps = (action: any) => {
    return ({
        type: types.MINT_STEPS,
        payload: action.payload
    })
}


export const NotificationListRequest = () => {
    return ({
        type: types.NOTIFICATION_LIST_REQUEST,
    })
}

export const NotificationListSuccess = (action: any) => {
    return ({
        type: types.NOTIFICATION_LIST_SUCCESS,
        payload: action.payload
    })
}

export const NotificationListFailed = (action: any) => {
    return ({
        type: types.NOTIFICATION_LIST_FAILED,
        payload: action.payload
    })
}


export const GetCollectionExploreRequest = (action: any) => {
    return ({
        type: types.GET_EXPLORE_COLLECTION_REQUEST,
        payload: action.payload
    })
}


export const GetCollectionExploreSuccess = (action: any) => {
    return ({
        type: types.GET_EXPLORE_COLLECTION_SUCCESS,
        payload: action.payload
    })
}

export const GetCollectionExploreFailed = (action: any) => {
    return ({
        type: types.GET_EXPLORE_COLLECTION_FAILED,
        payload: action.payload
    })
}