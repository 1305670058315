import { ErrorSharp, Facebook, LinkedIn, ResetTv } from '@mui/icons-material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../Assets/Img/MainLogo.png';
import API from '../../Utils/Api';
import ENDPOINTS from '../../Utils/Endpoints';
import CircularLoading from '../CircularProgress/CircularProgress';
import { useStyle } from './FooterStyle';

function Footer(): JSX.Element {
    const location = useLocation()
    const styleInEditPages = {
        marginTopFooter: '0',
    }
    const styleGeneral = {
        marginTopFooter: '100px',
    }
    const classes = useStyle(location.pathname.includes('edit') ? styleInEditPages : styleGeneral)
    const [subscribe, setSubscribe] = useState('')
    const { handleSubmit, reset, register, formState: { errors } } = useForm()
    const queryClient = useQueryClient()

    const submit = async (data: any) => {
        const { data: response } = await API.post(ENDPOINTS.SubscribeEmail, data);
        return response.data;
    };

    const { mutate, isLoading, data } = useMutation(submit, {
        onSuccess: (data: any) => {
            const message = "success"
            reset({ email: '' })
            toast.success("Thanks for Subscribing.</br> Please check your email to confirm your subscription.")
        },
        onError: (e: any) => {
            toast.error(e.message)
        },
    });

    function SubscribeEmail(data: any): void {
        mutate(data)
    }
    return (
        <div className={classes.footer}>
            <div>
                <div>
                    <p>Get The Latest Artaniom Updates.</p>
                </div>
                <div className={classes.iconSection}>
                    <form onSubmit={handleSubmit(SubscribeEmail)} noValidate>
                        <TextField
                            variant='standard'
                            // value={subscribe}
                            type="email"
                            label={errors?.royalty?.message}
                            error={Boolean(errors?.email?.message)}
                            helperText={errors?.royalty?.message}
                            {...register('email',
                                {
                                    required: "Required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    },
                                    //   onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSubscribe(e.target.value)
                                })}
                        />
                        <button type='submit' >
                            {isLoading ? <CircularLoading size={20} /> : "Subscribe"}
                        </button>
                    </form>
                    <hr className={classes.Line1} />
                    <span className='SocialMedia'>
                        <a target="_blank" rel='noreferrer' href="https://twitter.com/Artaniomio"><TwitterIcon fontSize='large' className={classes.Icons} /></a>
                        <a target="_blank" rel='noreferrer' href="https://www.linkedin.com/company/artaniom/"><LinkedIn fontSize='large' className={classes.Icons} /></a>
                        <a target="_blank" rel='noreferrer' href="https://www.facebook.com/Artaniom/"><Facebook fontSize='large' className={classes.Icons} /></a>
                        <a target="_blank" rel='noreferrer' href="https://www.instagram.com/artaniom.io/"><InstagramIcon fontSize='large' className={classes.Icons} /></a>
                    </span>
                </div>
                <hr className={classes.Line} />
                <div className={classes.LinkSection}>
                    <span>
                        <p><span>Artaniom</span> is a pioneer Art NFT Marketplace that provides <br /> special services in creating digital twins of masterpiece <br />artworks and promoting those in the NFT market.</p>
                    </span>
                    <span>
                        <a target="_blank" href="https://help.artaniom.io/faq/"> FAQs</a>
                        <a href="/contactUs"> Contact</a>
                        <a target="_blank" href="https://help.artaniom.io/"> Help Center</a>
                        <a href="/aboutUs"> About Artaniom</a>
                        {/* <a href="#"> Blog</a> */}
                    </span>
                </div>
            </div>
            <div className={classes.copyright}>
                <div>
                    <span className={classes.AllRightSection}>
                        <img src={Logo} alt="MainLogo-Artaniom" width='35px' height="35px" />
                        <p>&#169; 2023 All Right Reserved Artaniom</p>
                    </span>
                    <span className={classes.AllRightSection}>
                        <a href="/privacyAndPolicy"> Privacy</a>
                        <a href="/TermsOfServices"> Terms of Service</a>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer
