import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useStyles } from './StyleJoinArtaniom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Checkbox, Container, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from '@mui/material'
import { toast } from 'react-toastify'
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'

function JoinArtaniom() {
    const classes = useStyles()
    const { handleSubmit, register, control, formState: { errors } } = useForm()
    const [collection, setCollection] = useState<any>('');
    const [country, setCountry] = useState<any>('')
    const [formCompleted, setFormCompleted] = useState<boolean>(true)
    const submitForm: SubmitHandler<any> = (data: any) => {
        if (!country.phone) {
            toast.warning("Enter your number")
        } else if (collection === '') {
            toast.warning("Select your role")
        }
        else {
            handleSuccessMessage()
        }
    }

    const handleCollection = (event: any): void => {
        const job: any = event?.target?.value
        setCollection(job)
    };

    const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);
    const handleCloseSuccessMessage = () => {
        setOpenSuccessMessage(false);
    };
    const handleSuccessMessage = () => {
        setOpenSuccessMessage(!openSuccessMessage);
    };

    return (
        <div className={classes.mainJoinToArtaniom}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openSuccessMessage}
                onClick={handleCloseSuccessMessage}
            >
                <p className={classes.submitSuccessMessage}>Thank you! <br /> we will be in touch soon.</p>
            </Backdrop>
            <div className={classes.header}>
                <div>
                    <h3>Become a MakersPlace artist to begin selling your creations.</h3>
                    <p>Digitally sign your work by creating a tokenized certificate.</p>
                    <ul>
                        <li>Before you have access to mint NFTs, we need to verify your identity and ownership rights.</li>
                        <li>Before applying, please ensure you have filled in all applicable information about yourself in your Artaniom profile (with your cover and profile picture).</li>
                        <li>Duplicate email addresses and profiles will be unsuccessful. Please only apply once.</li>
                        <li>We receive hundreds of applications, so please be patient if you do not hear back from us immediately.</li>
                    </ul>
                </div>
            </div>
            {
                formCompleted ?
                    <div className={classes.completeMessage}>
                        <p>You successfully complete this form.</p>
                        <p>We will review your information as soon as possible and let you know.</p>
                        <div>
                            <button onClick={() => setFormCompleted(false)}>
                                Edit
                            </button>
                            <Link to="/">
                                Home Page
                            </Link>
                        </div>
                    </div>
                    :
                    <div className={classes.form}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div>
                                <label htmlFor="profileURL">Your Artaniom profile URL *</label>
                                <input type="text" id="profileURL"
                                    {...register("profileURL", {
                                        required: 'ProfileURL Is Required',
                                    })}
                                />
                                {errors.profileURL && <p className={classes.errorText}>{errors.profileURL.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="name">Your full name *</label>
                                <input type="text" id="name"
                                    {...register("name", {
                                        required: 'Name Is Required',
                                        maxLength: {
                                            value: 50,
                                            message: 'Name Must Be maximum 50 Character'
                                        }
                                    })}
                                />
                                {errors.name && <p className={classes.errorText}>{errors.name.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="email">Your email *</label>
                                <input type="text" id="email"
                                    {...register("email", {
                                        required: 'Email Is Required',
                                        maxLength: {
                                            value: 50,
                                            message: 'Email Must Be maximum 50 Character'
                                        }
                                    })}
                                />
                                {errors.email && <p className={classes.errorText}>{errors.email.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="phoneNumber">Your Phone Number *</label>
                                <PhoneInput
                                    country={'us'}
                                    value={country.phone}
                                    onChange={phone => setCountry({ phone })}
                                />
                            </div>
                            <div>
                                <FormControl sx={{ width: '60% !important', marginTop: '10px', display: 'flex' }}
                                    size='small'>
                                    <label id="job">Your are a/an *</label>
                                    <Select
                                        labelId="job"
                                        value={collection}
                                        onChange={handleCollection}
                                    >
                                        <MenuItem value={"artist"}>Artist</MenuItem>
                                        <MenuItem value={"artCollector"}>Art Collector</MenuItem>
                                        <MenuItem value={"artistFoundation"}>Artist Foundation</MenuItem>
                                    </Select>
                                </FormControl>
                                {errors.job && <p className={classes.errorText}>{errors.job.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="portfolio">Website or portfolio links</label>
                                <input type="text" id="portfolio"
                                    placeholder='Please provide a link to a collection of your portfolio.'
                                />
                            </div>
                            <div>
                                <label htmlFor="description">Tell us about your work and process</label>
                                <textarea rows={5}
                                    {...register("description", {
                                        maxLength: {
                                            value: 1500,
                                            message: 'Description Must Be maximum 1500 Character'
                                        }
                                    })}
                                    id="description" />
                                {errors.description && <p className={classes.errorText}>{errors.description.message}</p>}
                            </div>

                            <FormGroup >
                                <FormControlLabel
                                    control={
                                        <Controller control={control}
                                            {...register("rights", {
                                                required: 'It Is Required!',
                                            })}
                                            name='rights' render={({ field: { value, ...field } }) => (
                                                <Checkbox defaultChecked={false} {...field} checked={!!value} />
                                            )} />}
                                    label="I approve that I'm the owner or have the rights of publication and sale."
                                />
                                {errors.rights && <p className={classes.errorText}>{errors.rights.message}</p>}

                                <FormControlLabel
                                    control={
                                        <Controller control={control}
                                            {...register("terms", {
                                                required: 'It Is Required!',
                                            })}
                                            name='terms' render={({ field: { value, ...field } }) => (
                                                <Checkbox defaultChecked={false} {...field} checked={!!value} />
                                            )} />
                                    } label="I approve Artaniom's Terms & Conditions." />
                                {errors.terms && <p className={classes.errorText}>{errors.terms.message}</p>}
                            </FormGroup>
                            <div>
                                <input type="submit" value='Apply to join' />
                            </div>
                        </form>
                    </div>
            }
        </div>
    )
}

export default JoinArtaniom