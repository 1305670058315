import { makeStyles } from "@material-ui/core";
import { url } from "inspector";

export const useStyle = makeStyles(theme => ({
    mainSellReservedAuction: {
        display: "flex",
        width: '100%',
        marginTop: '150px',
        marginBottom: '150px',
        "& > div": {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '135px',
            "& div[id='rightSide']": {
                flex: '3',
                "& > p": {
                    width: '100%',
                    textAlign: "lrft",
                    fontFamily: 'ElMessiriBold',
                    fontSize: '32px',
                    color: "#94794F",
                    marginBottom: '40px',
                    marginTop: '0'
                },
                "& form": {
                    "& > label": {
                        fontFamily: 'RalewaySemiBold',
                        fontSize: '20px',
                        color: '#000',
                        marginBottom: '10px',
                        display: 'block'
                    },
                    "& div": {
                        display: 'flex',
                        alignItems: 'center',
                        "& > div": {
                            backgroundColor: '#e1e1e1'
                        },
                        "& > span": {
                            paddingLeft: '5px',
                            fontFamily: 'RalewaySemiBold',
                            fontSize: '24px',
                            color: '#676767',

                            "& img": {
                                verticalAlign: "baseline"
                            }
                        }
                    },
                    "& button": {
                        backgroundColor: '#94794F',
                        boxShadow: '0px 5px 2px #37254B',
                        padding: '15px 60px',
                        color: '#fff',
                        border: "none",
                        borderRadius: '7px',
                        fontFamily: 'ElMessiriBold',
                        fontSize: '22px',
                        marginTop: '300px',
                        cursor: "pointer",
                        "&:disabled": {
                            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%), #94794F",
                            opacity: '0.3',
                            cursor: 'auto'
                        }
                    }
                },
                "& ul": {
                    marginTop: '90px',
                    padding: "0",
                    "& li": {
                        listStyleType: 'none',
                        fontFamily: 'Raleway',
                        fontSize: '17px',
                        color: '#676767'
                    },
                    "& li::before": {
                        content: '"-"',
                        marginRight: '5px'
                    }
                },
                "& > div[id='finished']": {
                    display: 'grid',
                    placeItems: 'center',
                    width: ' 100%',
                    marginTop: '150px',
                    "& > p": {
                        fontFamily: 'ElMessiriBold',
                        fontSize: '32px',
                        color: '#000',
                        margin: '0'
                    },
                    "& > a": {
                        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0385417) 99.99%, rgba(0, 0, 0, 0) 100%), #000000',
                        boxShadow: '0px 5px 2px #37254B',
                        borderRadius: '7px',
                        color: '#fff',
                        fontFamily: 'ElMessiriBold',
                        fontSize: '22px',
                        textDecoration: 'none',
                        padding: '15px 40px',
                        marginTop: '100px',
                    }
                },
                "& > div[id='error']": {
                    display: 'grid',
                    placeItems: 'center',
                    width: ' 100%',
                    marginTop: '150px',
                    "& > p:nth-child(1)": {
                        fontFamily: 'ElMessiriBold',
                        fontSize: '32px',
                        color: '#000',
                        margin: '0'
                    },
                    "& > p:nth-child(2)": {
                        fontFamily: 'RalewayMedium',
                        fontSize: '20px',
                        color: '#000',
                        width: '530px',
                        marginTop: '60px'
                    }
                }
            },
            "& div[id='leftSide']": {
                flex: '1.5'
            }
        }
    },
    confirming: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '300px',
        width: '100%',
        alignItems: 'center',
        "& p:nth-child(1)": {
            fontFamily: 'ElMessiriBold',
            fontSize: '25px',
            color: '#000',
            margin: '0'
        },
        "& p:nth-child(2)": {
            fontFamily: 'Raleway',
            fontSize: '16px',
            color: '#676767',
            marginTop: '0'
        }
    },
    input: {
        border: 'none',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
        }
    },
}))