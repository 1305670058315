import { ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from "react-dom/client";
import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './redux/Store';
import reportWebVitals from './reportWebVitals';
import { theme } from './Utils/Themes';
import ErrorBoundary from './Components/ErrorBoundry/ErrorBoundry';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  < React.StrictMode >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode >
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
