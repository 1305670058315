import React from 'react'
import { useStyles } from './StyleMarketComponents'

function DescriptionList(props: any) {
    const classes = useStyles()
    return (
        <ul className={classes.descriptionList}>
            {
                props.data.map((item: string, i: number) => {
                    return <li key={i} > {item}</li>
                })
            }
        </ul >
    )
}

export default DescriptionList;