import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useStyleButton, useStyles } from './StyleMarketComponents'

function SubmitButtonGold(props: any) {

    const { title, type, bgcolor, color } = props
    const [style, setStyle] = useState('')

    useEffect(() => {
        setStyle(bgcolor)
    })

    let classes = useStyleButton({ backgroundColor: style })

    return (
        <button {...props} style={{ backgroundColor: bgcolor, color: color ? color : "#fff" }} type={type} className={classes.SubmitButton}>{title}</button>
    )
}

export default SubmitButtonGold