import { toast } from "react-toastify"
import { put, takeLatest } from "redux-saga/effects"
import { CreateProfileFailed, CreateProfileSuccess, NotificationListFailed, NotificationListSuccess, ProfileHeaderRequest, SubmitNotificationFailed, SubmitNotificationSuccess } from "../../../redux/Actions"
import { history, store } from "../../../redux/Store"
import * as types from '../../../redux/Types'
import API from "../../../Utils/Api"
import ENDPOINTS from "../../../Utils/Endpoints"

function* CreateProfileWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.put(ENDPOINTS.PROFILE, action.payload)
        } catch (e: any) {
            console.error('e-CreateProfileWorker', e)
            store.dispatch(CreateProfileFailed({ payload: e }))
            toast.error(e.message)
        }
    }
    yield Api()
    if (Response?.data?.success) {
        yield put(CreateProfileSuccess({ payload: Response }))
        history.push('/profile')
        store.dispatch(ProfileHeaderRequest())
        toast.success("Profile updated successfully.")
    } else {
        toast.error(Response?.message)
        yield put(CreateProfileFailed({ payload: Response }))
    }
}

export function* CreateProfileWatcher() {
    yield takeLatest(types.CREATE_PROFILE_REQUEST, CreateProfileWorker)
}


function* SubmitNotificationWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.put(ENDPOINTS.NotifAllowance, action.payload)
        } catch (e: any) {
            console.error('e-CreateProfileWorker', e)
            store.dispatch(SubmitNotificationFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response?.success) {
        yield put(SubmitNotificationSuccess({ payload: Response }))
    } else {
        yield put(SubmitNotificationFailed({ payload: Response }))
    }
}

export function* SubmitNotificationWatcher() {
    yield takeLatest(types.SUBMIT_NOTIFICATION_REQUEST, SubmitNotificationWorker)
}


function* GetNotificationListWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.get(ENDPOINTS.notificationList)
        } catch (e: any) {
            console.error('e-CreateProfileWorker', e)
            store.dispatch(SubmitNotificationFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response?.success) {
        yield put(NotificationListSuccess({ payload: Response }))
    } else {
        yield put(NotificationListFailed({ payload: Response }))
    }
}

export function* GetNotificationListWatcher() {
    yield takeLatest(types.NOTIFICATION_LIST_REQUEST, GetNotificationListWorker)
}
