import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    faqMain: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& figure": {
            width: '100%',
            margin: 0,
            "& figcaption": {
                textAlign: 'center',
                margin: '20px 0 ',
                fontSize: '22px',
                fontWeight: 600,

            }
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: "40px",
        width: "80%",
        justifyContent: 'space-between',
        "& > div:nth-child(1)": {
            width: '30%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            "& button:hover": {
                transform: 'scale(1.05)'
            },
            "& button": {
                margin: '15px 0',
                padding: '5px 8px',
                width: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                border: 'none',
                "& svg": {
                    marginRight: '4px'
                }
            }
        },
        "& > div:nth-child(2)": {
            width: '70%',
            "& > div": {
                marginTop: '10px',
            }
        }
    }
}))