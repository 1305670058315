import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import DeleteForever from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LanguageIcon from '@mui/icons-material/Language';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button, Grid, IconButton, InputAdornment, Slider, Stack, TextField } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import auction from '../../../Assets/Img/auction.svg';
import avatarImg from '../../../Assets/Img/avatarEdit.svg';
import camera from '../../../Assets/Img/camera.svg';
import discord from '../../../Assets/Img/discord.svg';
import logout from '../../../Assets/Img/logout.svg';
import notification from '../../../Assets/Img/notification.svg';
import offer from '../../../Assets/Img/offer.svg';
import Alerts from '../../../Components/Alert/Alert';
import CircularLoading from '../../../Components/CircularProgress/CircularProgress';
import CircularProgressWithLabel from '../../../Components/CircularProgressBar/CircularProgressBar';
import { getCroppedImg } from '../../../Components/CropImage/cropImage';
import EditLayout from '../../../Components/EditLayout/EditLayout';
import { PopUp } from '../../../Components/PopUp/PopUp';
import SkeletonLoading from '../../../Components/Skeleton/Skeleton';
import { CreateProfileRequeset, GetProfile, SubmitNotificationRequest } from '../../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../../redux/Hook';
import API from '../../../Utils/Api';
import ENDPOINTS from '../../../Utils/Endpoints';
import { IProfileSubmit, NotificationFormValues } from '../../../Utils/Interfaces';
import AuctionTabInProfile from './AuctionTabInProfile';
import Offers from './Offers';
import { useStyles } from './styleEditProfile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ListNotification from './ListNotification';

const defaultValues = {
    auction: false,
    itemSold: false,
    bidActivity: false,
    successfulPurchase: false,
    NewFollowerNotification: false,
    newNftListing: false,
    ownedItemUpdate: false,
    email: undefined,
    ens: null
};

function EditProfile(): JSX.Element {
    const [tabToggle, setTabToggle] = useState<string>('profile')
    const [avatarSizeError, setAvatarSizeError] = useState<string>('')
    const [avatarProgressBar, setAvatarProgressBar] = useState<number>(0)
    const [avatarPreview, setAvatarPreview] = useState<any>()
    const [uploadedAvatarUrl, setUploadedAvatarUrl] = useState<any>()
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [fileSizeError, setFileSizeError] = useState<string>('')
    const [progressBar, setProgressBar] = useState<number>(0)
    const [bannerPreview, setbannerPreview] = useState<any>()
    const [uploadedBannerUrl, setuploadedBannerUrl] = useState<any>()
    const [name, setName] = useState<string>('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [nameOfInputImage, setNameOfInputImage] = useState<string>('')
    const [imageSrc, setImageSrc] = useState<any>()
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [userName, setUserName] = useState<string>('')
    const [bio, setBio] = useState<string>('')
    const [notifSection, setNotifSection] = useState<boolean>(true)

    const param = useParams()
    const location = useLocation()
    console.log("locatiojn", location)
    console.log("param", param)
    const history = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { DataGetProfile, isLoadingGetProfile, ErrorGetProfile } = useAppSelector(state => state.GetProfileReducer)
    const { CreateProfileData, CreateProfileisLoading, CreateProfileError } = useAppSelector(state => state.CreateProfileReducer)

    useEffect(() => {
        if (history.pathname.includes('edit')) {
            dispatch(GetProfile({ payload: param.username }))
        }
    }, [history.pathname])



    useEffect(() => {
        const listNotifTab: any = document.getElementById("listNotif")
        const profileTab: any = document.getElementById("profile")
        if (location?.state && location?.state?.value === "listNotification") {
            setNotifSection(true)
            setTabToggle("listNotification")
            listNotifTab?.focus()
        }
        if (!location?.state) {
            setTabToggle("profile")
            profileTab?.focus()
            setNotifSection(false)
        }
    }, [location])

    useEffect(() => {
        setbannerPreview(DataGetProfile?.data?.data?.user?.banner)
        setAvatarPreview(DataGetProfile?.data?.data?.user?.avatar)
    }, [isLoadingGetProfile])

    useEffect(() => {
        setValue('email', DataGetProfile?.data?.data?.user?.email)
        setValue('username', DataGetProfile?.data?.data?.user?.username)
        setValue('name', DataGetProfile?.data?.data?.user?.name)
        setValue('bio', DataGetProfile?.data?.data?.user?.bio)
        setValue('ens', DataGetProfile?.data?.data?.user?.ens)
        setValue('social.instagram', DataGetProfile?.data?.data?.user?.social[0]?.instagram)
        setValue('social.website', DataGetProfile?.data?.data?.user?.social[0]?.website)
        setValue('social.twitter', DataGetProfile?.data?.data?.user?.social[0]?.twitter)
        setValue('social.discord', DataGetProfile?.data?.data?.user?.social[0]?.discord)
        setValue('social.facebook', DataGetProfile?.data?.data?.user?.social[0]?.facebook)
    }, [DataGetProfile?.data?.data?.user])

    const { handleSubmit, register, formState: { errors }, setValue } = useForm<IProfileSubmit>()

    const {
        handleSubmit: handleSubmit2,
        register: register2,
        reset: reset2,
        control: control2,
        formState: { errors: errors2 }
    } = useForm<NotificationFormValues>({
        defaultValues
    });

    const banner: any = uploadedBannerUrl?.data?.data?.path
    const avatar: any = uploadedAvatarUrl?.data?.data?.path

    const SubmitProfileForm: SubmitHandler<IProfileSubmit> = (data: any, e: any) => {
        e.preventDefault()
        data.banner = banner ? banner : bannerPreview
        data.avatar = avatar ? avatar : avatarPreview
        dispatch(CreateProfileRequeset({ payload: data }))
    }

    const SubmitProfileNotification: SubmitHandler<NotificationFormValues> = (data: any, e: any) => {
        e.preventDefault()
        data.notif_allow = [data]
        dispatch(SubmitNotificationRequest({ payload: data }))
    }

    function TabToggle(props: string): void {
        setTabToggle(props)
    }

    const test = DataGetProfile?.data?.data?.user?.social[0]
    for (var key in test) {
        if (test.hasOwnProperty(key)) {
        }
    }

    const classes = useStyles()

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const [waiting, setWaiting] = useState<boolean>(false)
    const uploadCroppedImage = useCallback(async () => {
        setWaiting(true)
        try {
            const croppedImage: any = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
            )
            const loadingBar = {
                onUploadProgress: function (progressEvent: any) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    if (nameOfInputImage === "avatar") {
                        setAvatarProgressBar(percentCompleted)
                    }
                    if (nameOfInputImage === "banner") {
                        setProgressBar(percentCompleted)
                    }

                }
            }

            croppedImage.toBlob(
                (blob: any) => {
                    const formData = new FormData();
                    formData.append('file', blob)
                    const url = URL.createObjectURL(blob)

                    if (nameOfInputImage === "avatar") {
                        setAvatarPreview(url)
                    }
                    if (nameOfInputImage === "banner") {
                        setbannerPreview(url)
                    }
                    API.post(ENDPOINTS.MediaUploader, formData, loadingBar).then((Result: any) => {
                        toast.success("Image Uploaded Successfully")
                        setWaiting(false)
                        if (nameOfInputImage === "avatar") {
                            setUploadedAvatarUrl(Result)
                        }
                        if (nameOfInputImage === "banner") {
                            setuploadedBannerUrl(Result)
                        }
                    }).catch((e: any) => {
                        console.error(e)
                        setWaiting(false)
                    })
                }
            )
            setOpenPopup(false)
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels])



    const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const size = e.target.files[0]?.size
            if (size > 1e+7) {
                toast.error('File Must be less than 10MB')
                return
            }
            setNameOfInputImage(e.target.name)
            const reader = new FileReader();
            let file: any = e?.target?.files
            if (file) {
                reader?.readAsDataURL(e?.target?.files[0])
            }

            reader.addEventListener("load", () => {
                setImageSrc(reader.result)
                if (e.target.name === "avatar") {
                    setAvatarPreview(reader.result)
                    setOpenPopup(true)
                }
                if (e.target.name === "banner") {
                    setbannerPreview(reader.result)
                    setOpenPopup(true)
                }
            })
        }
    }


    function onClosePopup() {
        setOpenPopup(false)
        setWaiting(false)
        if (nameOfInputImage === "avatar") {
            setAvatarPreview('')
        }
        if (nameOfInputImage === "banner") {
            setbannerPreview('')
        }
    }

    function removeImage() {
        setAvatarPreview('')
        setWaiting(false)
    }

    function removeBanner() {
        setbannerPreview('')
        setWaiting(false)
    }

    return (
        <EditLayout title="Profile Setting">
            <ul className={classes.sideButtons}>
                <li><button id="profile" onClick={() => TabToggle('profile')}><img src={avatarImg} alt='avatar' />Edit Profile</button></li>
                <li> <button onClick={() => TabToggle('offers')}><img style={{ marginBottom: '5px' }} src={offer} alt='avatar' />Offers</button></li>
                <li> <button onClick={() => TabToggle('auction')}><img src={auction} alt='avatar' />Auctions</button></li>
                <li> <button onClick={() => setNotifSection(!notifSection)}><img src={notification} alt='avatar' />Notifications</button></li>
                {
                    notifSection &&
                    <>
                        <li><button id="listNotif" className='subMenu' onClick={() => TabToggle('listNotification')}>- Notification List</button></li>
                        <li><button className='subMenu' onClick={() => TabToggle('notification')}>- Manage Notification</button> </li>
                    </>
                }
                <li> <button onClick={() => navigate("/")}><img src={logout} alt='avatar' />Log Out</button></li>
            </ul>
            <Grid >
                <div className={classes.mainEditProfile}>
                    <div className={classes.EditProfileRightSide}>
                        {tabToggle === "profile" &&
                            <>
                                <form onSubmit={handleSubmit(SubmitProfileForm)} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <Stack direction='column'>
                                        <Tooltip title="go back" placement="top-start">
                                            <a className={classes.GetBackArrow} onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></a>
                                        </Tooltip>
                                        {/* <img src={imageSrc} width="200px" height="200px" alt="rwear" /> */}
                                        <label className={classes.label} htmlFor="Name">Display Name {isLoadingGetProfile && <CircularLoading size={20} />}</label>
                                        <TextField
                                            helperText={`${userName.length}/30`}
                                            {...register("name", {
                                                maxLength: {
                                                    value: 30,
                                                    message: 'username must be maximum 30 characters'
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9$@$!%*?&#^-_. +-]+$/,
                                                    message: 'Please Type in English',
                                                },
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)
                                            })}
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: classes.notchedOutline
                                                }
                                            }}
                                            error={Boolean(errors?.username?.message)}
                                            label={errors?.username?.message}
                                            id="outlined-basic"
                                            placeholder="How you want to be known to the community"
                                            variant="outlined"
                                            size='small'
                                        />
                                        <label className={classes.label} htmlFor="Name">User Name</label>
                                        <TextField
                                            helperText={`${userName.length}/30`}
                                            {...register("username", {
                                                maxLength: {
                                                    value: 30,
                                                    message: 'username must be maximum 30 characters'
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9$@$!%*?&#^-_. +-]+$/,
                                                    message: 'Please Type in English',
                                                },
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setUserName(e.target.value)
                                            })}
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: classes.notchedOutline
                                                }
                                            }}
                                            error={Boolean(errors?.username?.message)}
                                            label={errors?.username?.message}
                                            id="outlined-basic"
                                            placeholder="How you want to be known to the community"
                                            variant="outlined"
                                            size='small'
                                        />
                                        <label className={classes.label} htmlFor="Bio">Bio</label>
                                        <TextField
                                            helperText={`${bio.length}/150`}
                                            {...register("bio", {
                                                maxLength: {
                                                    value: 150,
                                                    message: 'bio must be maximum 150 characters'
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9$@$!%*?&#^-_. +-]+$/,
                                                    message: 'Please Type in English',
                                                },
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setBio(e.target.value)
                                            })}
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: classes.notchedOutline
                                                }
                                            }}
                                            error={Boolean(errors?.bio?.message)}
                                            label={errors?.bio?.message}
                                            id="outlined-basic" placeholder="Tell about yourself" variant="outlined" size='small' />
                                        <label className={classes.label} htmlFor="Email">Email Address</label>
                                        <TextField
                                            {...register("email", {
                                                required: false,
                                            }
                                            )}
                                            id="outlined-basic" placeholder='Enter your Email' variant="outlined" size='small' />
                                        {errors.email && <p className={classes.errorText}>{errors.email.message}</p>}

                                        <label className={classes.label2} htmlFor="Email">ENS</label>
                                        <TextField
                                            {...register("ens", {
                                                required: false,
                                            }
                                            )}
                                            id="outlined-basic" placeholder='Enter your Ethereum Name Service (ENS) address' variant="outlined" size='small' />

                                        <label className={classes.label2} htmlFor="socialMedia">Links</label>

                                        <TextField
                                            className={classes.socialMediaInput}
                                            InputProps={{
                                                startAdornment: <InputAdornment className={classes.inputText} position="start"><LanguageIcon sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://</span></InputAdornment>,
                                            }}
                                            {...register("social.website", {
                                                required: false,
                                            }
                                            )}
                                            variant="outlined" size='small' />

                                        <TextField
                                            className={classes.socialMediaInput}
                                            InputProps={{
                                                startAdornment: <InputAdornment className={classes.inputText} position="start"><Twitter sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://twitter.com/</span></InputAdornment>,
                                            }}
                                            {...register("social.twitter", {
                                                required: false,
                                            }
                                            )}
                                            variant="outlined" size='small' />

                                        <TextField
                                            className={classes.socialMediaInput}
                                            {...register("social.discord", {
                                                required: false,
                                            }
                                            )}
                                            InputProps={{
                                                startAdornment: <InputAdornment className={classes.inputText} position="start" ><img src={discord} style={{ marginRight: '14px', marginLeft: '12px' }} /><span>https://www.discord.com/</span></InputAdornment>,
                                            }}
                                            variant="outlined" size='small' />

                                        <TextField
                                            className={classes.socialMediaInput}
                                            {...register("social.facebook", {
                                                required: false,
                                            }
                                            )}
                                            InputProps={{
                                                startAdornment: <InputAdornment className={classes.inputText} position="start" ><Facebook sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://www.facebook.com/</span></InputAdornment>,
                                            }}
                                            variant="outlined" size='small' />

                                        <TextField
                                            className={classes.socialMediaInput}
                                            {...register("social.instagram", {
                                                required: false,
                                            }
                                            )}
                                            InputProps={{
                                                startAdornment: <InputAdornment className={classes.inputText} position="start" ><Instagram sx={{ marginRight: '10px', marginLeft: '10px' }} /><span>https://www.instagram.com/</span></InputAdornment>,
                                            }}
                                            variant="outlined" size='small' />

                                        {

                                            <button type="submit" disabled={waiting} className={classes.input} >
                                                {waiting ? <span><CircularLoading size={25} /></span> : "save changes"}
                                            </button>
                                        }
                                    </Stack>
                                    <div className={classes.uploadSec}>
                                        <PopUp onClose={onClosePopup} open={openPopup}>
                                            <Grid className={classes.cropContainer}>
                                                <p>Edit Profile Picture </p>
                                                <Cropper
                                                    image={nameOfInputImage === "avatar" ? avatarPreview : bannerPreview}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={nameOfInputImage === "avatar" ? 3 / 3 : 12 / 5}
                                                    showGrid={true}
                                                    onCropChange={setCrop}
                                                    onCropComplete={onCropComplete}
                                                    onZoomChange={setZoom}
                                                    cropShape={nameOfInputImage === "avatar" ? 'round' : "rect"}
                                                />
                                                <div className={classes.sliderContainer}>
                                                    <Stack spacing={2} width="100%" direction="row" sx={{ mb: 1 }} alignItems="center">
                                                        <ZoomOutIcon />
                                                        <Slider
                                                            size='small'
                                                            value={zoom}
                                                            min={1}
                                                            max={3}
                                                            step={0.1}
                                                            aria-labelledby="Zoom"
                                                            onChange={(e, zoom: any) => setZoom(zoom)}
                                                        />
                                                        <ZoomInIcon />
                                                    </Stack>
                                                    <Button
                                                        onClick={uploadCroppedImage}
                                                        variant="contained"
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </PopUp>

                                        <div >
                                            <p>Profile Image
                                                <BootstrapTooltip title="Recommended 350px * 350px   Max Size: 50MB" placement='top'>
                                                    <InfoIcon sx={{ color: "#5c5a5b", marginLeft: '5px', width: "15px" }} />
                                                </BootstrapTooltip>
                                            </p>
                                            {avatarSizeError !== '' && <Alerts message={avatarSizeError} severity="error" />}
                                            <input type="file" accept="image/*" name="avatar" style={{ display: 'none' }} id="avatar" onChange={uploadImage} />
                                            {
                                                !isLoadingGetProfile ?
                                                    (
                                                        avatarPreview ?
                                                            <div className={classes.previewImgContainer}>
                                                                <IconButton onClick={() => removeImage()} >
                                                                    <DeleteForever />
                                                                </IconButton>
                                                                <img src={avatarPreview} alt="avatar" width="150px" height="150px" style={{ borderRadius: '50%', filter: avatarProgressBar != 0 && avatarProgressBar != 100 ? "grayscale(1)" : "none" }} />
                                                                {avatarProgressBar != 0 && avatarProgressBar != 100 && <CircularProgressWithLabel value={avatarProgressBar} />}
                                                            </div>
                                                            :
                                                            <label htmlFor="avatar" >
                                                                <div className={classes.UploadAvatar} >
                                                                    <img src={camera} alt="uploadPhoto" />
                                                                </div>
                                                            </label>
                                                    )
                                                    :
                                                    <SkeletonLoading text={false} shape='circular' picture={true} width='150px' />
                                            }
                                        </div>
                                        <div >
                                            <p>Banner Image
                                                <BootstrapTooltip title="Recommended 1920px * 300px   Max Size: 50MB" placement='top'>
                                                    <InfoIcon sx={{ color: "#5c5a5b", marginLeft: '5px', width: "15px" }} />
                                                </BootstrapTooltip>
                                            </p>

                                            {fileSizeError !== '' && <Alerts message={fileSizeError} severity="error" />}
                                            <input type="file" name="banner" accept="image/*" style={{ display: 'none' }} id="banner" onChange={uploadImage} />
                                            {
                                                !isLoadingGetProfile ?
                                                    (
                                                        bannerPreview ?
                                                            <div className={classes.previewImgContainer}>
                                                                <IconButton onClick={() => removeBanner()} >
                                                                    <DeleteForever />
                                                                </IconButton>
                                                                <img src={bannerPreview} alt="banner" width="280px" height="160px" style={{ filter: progressBar != 0 && progressBar != 100 ? "grayscale(1)" : "none" }} />
                                                                {progressBar != 0 && progressBar != 100 && <CircularProgressWithLabel value={progressBar} />}
                                                            </div>
                                                            :
                                                            <label htmlFor="banner">
                                                                <div className={classes.UploadBanner}>
                                                                    <img src={camera} alt="uploadPhoto" />
                                                                </div>
                                                            </label>
                                                    )
                                                    :
                                                    <SkeletonLoading text={false} shape='rectangular' picture={true} width='350px' />
                                            }
                                        </div>
                                    </div>
                                </form>
                            </>
                        }
                        {
                            tabToggle === "notification" &&
                            <form onSubmit={handleSubmit2(SubmitProfileNotification)}>
                                <div className={classes.NotifSec}>
                                    <p>Notification</p>
                                    <p>Email notifications</p>
                                    <Stack className={classes.stackStyle} direction="column">
                                        <div className={classes.notifText}>
                                            <Controller
                                                name="auction"
                                                control={control2}
                                                render={({ field }: any) => <BpCheckbox checked={true} {...field} />
                                                }
                                            />
                                            <span>
                                                <p>Auction</p>
                                                <p>Receive email notifications when an auction was started, when you won an auction, when you have been outbid, when an auction has ended and when you withdraw your NFT or ethers.</p>
                                            </span>
                                        </div>
                                        <div className={classes.notifText}>
                                            <Controller
                                                name="fix"
                                                control={control2}
                                                render={({ field }) => <BpCheckbox checked={true} {...field} />}
                                            />
                                            <span>
                                                <p>Fixed Price</p>
                                                <p>Receive email notifications when  someone purchased one of your items and when you successfully buy an item in fixed price market.</p>
                                            </span>
                                        </div>
                                        <div className={classes.notifText}>
                                            <Controller
                                                name="offer"
                                                control={control2}
                                                render={({ field }) => <BpCheckbox checked={true} {...field} />}
                                            />
                                            <span>
                                                <p>Make an Offers</p>
                                                <p>Receive email notifications when your offer was accepted, canceled, and became outbid. Also, you receive notifications when other users make offers on your items.</p>
                                            </span>
                                        </div>
                                        <div className={classes.notifText}>
                                            <Controller
                                                name="profile"
                                                control={control2}
                                                render={({ field }) => <BpCheckbox checked={true} {...field} />}
                                            />
                                            <span>
                                                <p>Profile</p>
                                                <p>Receive email notifications for any updates in profiles you follow and when someone starts following you.</p>
                                            </span>
                                        </div>
                                        <div className={classes.notifText}>
                                            <Controller
                                                name="favorite"
                                                control={control2}
                                                render={({ field }) => <BpCheckbox checked={true} {...field} />}
                                            />
                                            <span>
                                                <p>Watchlists & Favorits</p>
                                                <p>Receive email notifications for any updates in your favorite collections and items.</p>
                                            </span>
                                        </div>

                                        {
                                            isLoadingGetProfile ? <CircularLoading size={30} />
                                                :
                                                <input type="submit" disabled={isLoadingGetProfile} value="Save changes" className={classes.input} />
                                        }

                                    </Stack>
                                </div>
                            </form>
                        }

                        {
                            tabToggle === "offers" &&
                            <Offers />
                        }
                        {
                            tabToggle === "auction" &&
                            <AuctionTabInProfile />
                        }
                        {
                            tabToggle === "listNotification" &&
                            <ListNotification />
                        }
                    </div>
                </div>
            </Grid >
        </EditLayout >
    )
}
export default EditProfile



const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        fontSize: '15px',
        textAlign: 'center',
        boxShadow: '0px 5px 2px rgba(0, 0, 0, 0.25)',
        color: '#676767',
        fontFamily: 'RalewayMedium',
        width: '250px'
    },
}));


function BpCheckbox(props: CheckboxProps) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
                color: "#000",
                "& > svg": {
                    color: '#000 !important'
                }
            }}
            disableRipple
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}
