import { makeStyles } from "@material-ui/core"

export const useStyle = makeStyles((theme) => ({
    counter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        "& p": {
            color: '#000',
            whiteSpace: 'nowrap',
            fontSize: '20px',
            fontFamily: 'RalewaySemiBold',
        },
        "& div": {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "center",
            "& p": {
                textAlign: 'center',
                fontSize: '24px',
                fontFamily: 'RalewaySemiBold',
                marginTop: 0,
                display: "flex",
                flexDirection: 'row',
                alignItems: 'flex-end',
                color: '#000',
                margin: "0 10px"
            },
            "& span": {
                display: 'flex',
                flexDirection: 'column',
                color: '#676767',
                fontSize: '14px',
                fontFamily: 'RalewaySemiBold',

            },
        }
    }
}))