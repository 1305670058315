import React from 'react'
import { useStyles } from './styleFallback'
import ETHGIF from "../../Assets/Img/Eth.gif"
function Fallbackpage() {
    const classes = useStyles()

    return (
        <div className={classes.mainFallback}>
            <p id="fallback">
                <img src={ETHGIF} />
            </p>
        </div>
    )
}

export default Fallbackpage