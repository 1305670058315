import { all } from 'redux-saga/effects'
import { CreateCollectionWatcher } from '../Components/CreateCollection/SagaCreateCollection'
import { ProfileHeaderWatcher } from "../Components/Header/SagaHeaderProfile"
import { CompleteCollectionWatcher, GetCollectionWatcher } from '../Pages/Collections/EditCollection/SagaEditCollection'
import { GetExploreNFTsWatcher, GetTypeExploreWatcher } from '../Pages/Explore/ExploreNFTs/SagaExploreNFTs'
import { HomeWatcher } from '../Pages/Home/SagaHome'
import { FetchCollectionWatcher, GetNftTypeWatcher, LazyMintWatcher, MintToBackendWatcher } from "../Pages/Mint/SagaMint"
import { BuyFixPriceWatcher, CancelFixPriceWatcher, NFTWatcher } from "../Pages/NFTItem/SagaNFT"
import { CreateProfileWatcher, GetNotificationListWatcher, SubmitNotificationWatcher } from "../Pages/Profile/EditProfile/SagaProfile"
import { GetProfileWatcher } from '../Pages/Profile/SagaProfile'
import { FixedPriceWatcher } from '../Pages/FixedPriced/SetFixedPrice/SagaFixedPrice'
import { SellInAuctionWatcher } from '../Pages/Auction/SellStandardAuction/SagaSellInAuction'
import { GetCollectionExploreWatcher } from '../Pages/Explore/ExploreCollections/SagaExploreCollection'

export default function* rootSaga() {
    yield all([
        MintToBackendWatcher(),
        GetNftTypeWatcher(),
        NFTWatcher(),
        CreateProfileWatcher(),
        ProfileHeaderWatcher(),
        GetProfileWatcher(),
        CreateCollectionWatcher(),
        SubmitNotificationWatcher(),
        FetchCollectionWatcher(),
        CompleteCollectionWatcher(),
        GetCollectionWatcher(),
        GetExploreNFTsWatcher(),
        HomeWatcher(),
        GetTypeExploreWatcher(),
        FixedPriceWatcher(),
        CancelFixPriceWatcher(),
        BuyFixPriceWatcher(),
        SellInAuctionWatcher(),
        LazyMintWatcher(),
        GetNotificationListWatcher(),
        GetCollectionExploreWatcher()
    ])
    // yield fork(saga2)
    // yield fork(saga3)
    // code after fork-effect
}