import React from 'react'
import { useStyles } from './StyleMarketComponents'

interface IProps {
    text?: string
}

function ConfirmingText(props: IProps) {
    const { text } = props
    const classes = useStyles()
    return (
        <div className={classes.confirmingText}>
            <p>
                {
                    text ? text : "Confirmation..."
                }
            </p>
            <p>
                Confirm this transaction with your
                wallet to continue.
            </p>
        </div>
    )
}

export default ConfirmingText