import React from 'react'
import { useStyle } from './StyleBanUsers'
import defaultImage from '../../Assets/Img/hero.jpg'

function CardBanUser() {
    const classes = useStyle()
    return (
        <div className={classes.card}>
            <div><img src={defaultImage} alt='itemPhoto' /></div>
            <div>
                <div id='firstRow'>
                    <div id='name'>
                        <p>item name</p>
                        <p>collection name</p>
                        <div>
                            <img src={defaultImage} alt="creatorPhoto" />
                            <p>@Creator</p>
                        </div>
                    </div>
                    <div id='offer'>
                        <p>
                            Your Offer: 0.5 ETH
                        </p>
                        <p>Dec 7, 2022</p>
                    </div>
                </div>
                <div id="btn">
                    <button>Cancel your Offer</button>
                </div>
            </div>
        </div>
    )
}

export default CardBanUser