import { put, takeLatest } from "redux-saga/effects";
import { SellInAuctionResponseWeb3Failed, SellInAuctionResponseWeb3Success } from "../../../redux/Actions";
import { store } from "../../../redux/Store";
import * as types from '../../../redux/Types';
import API from "../../../Utils/Api";
import ENDPOINTS from "../../../Utils/Endpoints";

function* SellInAuctionWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.post(ENDPOINTS.Auction, action.payload)
        } catch (e: any) {
            console.error('e-SellInAuctionWorker', e)
            return store.dispatch(SellInAuctionResponseWeb3Failed({ payload: e }))
        }
    }
    yield Api()
    if (Response && Response?.data?.success === true) {
        yield put(SellInAuctionResponseWeb3Success({ payload: Response }))
    } else {
        yield put(SellInAuctionResponseWeb3Failed({ payload: Response.data }))
    }
}


export function* SellInAuctionWatcher() {
    yield takeLatest(types.SELL_IN_AUCTION_RESPONSE_WEB3, SellInAuctionWorker)
}

