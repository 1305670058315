import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    mainEditLazyPrice: {
        marginTop: '80px',
        marginBottom: '100px',
        "& > div": {
            display: 'grid',
            gridTemplateColumns: '3fr 1.5fr',
            gridColumnGap: '20px',
            "& > div[id='leftSide']": {
                "& > p:nth-child(1)": {
                    fontFamily: 'ElMessiriBold',
                    fontSize: "32px",
                    color: "#000",
                    marginBottom: '20px'
                },
                "& > form": {
                    "& > div[id='textField']": {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        "& > div": {
                            width: '400px'
                        },
                        "& > p": {
                            fontFamily: 'RalewaySemiBold',
                            fontSize: '32px',
                            color: '#000',
                            margin: "0 10px 0 20px"
                        },
                        "& > img": {
                            width: '14px'
                        }
                    },
                    "& > div[id='description']": {
                        "& ul": {
                            marginTop: '140px',
                            listStyleType: 'none',
                            padding: '0',
                            margin: '0',
                            "& > li": {
                                fontFamily: 'Raleway',
                                fontSize: '17px',
                                color: '#676767',
                                lineHeight: '30px'
                            },
                            "& li::before": {
                                content: '"-"',
                                marginRight: '3px'

                            }
                        }
                    },
                    "& > button": {
                        padding: '10px 50px',
                        fontFamily: 'ElMessiriBold',
                        fontSize: '22px',
                        backgroundColor: "#94794F",
                        boxShadow: '0px 5px 2px #37254B',
                        borderRadius: '7px',
                        border: "none",
                        color: '#fff',
                        cursor: 'pointer',
                        marginTop: '35px'
                    }
                }
            }
        },

    },
    input: {
        backgroundColor: '#e1e1e1',
        borderRadius: '7px',
        padding: '6px',
        fontSize: '32px',
        fontFamily: 'Raleway'
    },
    confirm: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '230px',
        "& p": {
            textAlign: 'center',
            margin: 0
        },
        "& > p:nth-child(1)": {
            fontFamily: 'ElMessiriBold',
            color: '#000',
            fontSize: '25px',
            marginBottom: '6px'
        },
        "& > p:nth-child(2)": {
            fontFamily: 'RalewayMedium',
            color: '#676767',
            fontSize: '16px',
        }
    },
    finished: {
        display: 'grid',
        placeItems: 'center',
        marginTop: '150px',
        "& > p:nth-child(1)": {
            fontFamily: 'ElMessiriBold',
            fontSize: '28px'
        },
        "& > a:nth-child(2)": {
            fontFamily: 'ElMessiriBold',
            fontSize: '22px',
            padding: '15px 40px',
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0385417) 99.99%, rgba(0, 0, 0, 0) 100%), #94794F",
            boxShadow: '0px 5px 2px #37254B',
            borderRadius: '7px',
            color: '#fff',
            textDecoration: 'none',
            marginTop: '95px'
        }
    }
}))