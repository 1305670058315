import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { LazyCard } from '../../Components/CardSection/CardSection'
import BlackLinkButton from '../../Components/MarketComponents/BlackLinkButton'
import ConfirmationSection from '../../Components/MarketComponents/ConfirmationSection'
import ConfirmingText from '../../Components/MarketComponents/ConfirmingText'
import DescriptionList from '../../Components/MarketComponents/DescriptionList'
import InputComponent from '../../Components/MarketComponents/Input'
import MainMarketLayout from '../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../Components/MarketComponents/MainTitle'
import ResultTitleText from '../../Components/MarketComponents/ResultTitleText'
import SubmitButtonGold from '../../Components/MarketComponents/SubmitButtonGold'
import SkeletonLoading from '../../Components/Skeleton/Skeleton'
import { GetNFT } from '../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../redux/Hook'
import { FromWei } from '../../Utils/Utils'
import { MarketContractInstance } from '../../Web3/ContractInstances/ContractInstances'
import { AbiItem } from 'web3-utils';
import RegularCollectionABI from '../../../src/Web3/ABI/regularCollection.json';

function AcceptOffer() {

    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm()
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const highestOffer = DataNFT?.data?.data?.nft?.offers[0]
    const [price, setPrice] = useState<number>(0)
    const param = useParams()
    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.username])

    const List = [
        "The Offerer can cancel the offer anytime.",
        "By accepting the offer, you will pay the transaction gas fees on blockchain.",
        "You don’t need doing any action to reject this offer. If you don’t accept the bid, the offerer must pay the gas fee to cancel and withdraw it.",
    ]

    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const NftId = DataNFT?.data?.data?.nft?._id
    const account: any = localStorage.getItem('account')
    const navigate = useNavigate()

    const web3 = new Web3(Web3.givenProvider)
    const royalty: number = DataNFT?.data?.data?.nft?.royalty

    const collection = new web3.eth.Contract(RegularCollectionABI as AbiItem[], COLLECTION_ADDRESS)
    async function onSubmitGetApprove() {
        setSteps(1)
        await MarketContractInstance.methods.checkTransferPerm(COLLECTION_ADDRESS, tokenId).call({ from: account }).then((result: any) => {
            if (result) {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
                AcceptOffer()
            } else {
                ApproveCollection()
            }
        }).catch((error: any) => {
            toast.warning(error.message)
            setSteps(0)
        })
    }

    async function ApproveCollection() {
        await collection.methods.approve(process.env.REACT_APP_MARKET, tokenId).send({ from: account })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('sending', () => {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
            })
            .then((result: any) => {
                AcceptOffer()
            })
    }

    async function AcceptOffer() {
        await MarketContractInstance.methods.acceptOffer(COLLECTION_ADDRESS, tokenId).send({ from: account })
            .once('sending', () => {
                setSteps(2)
                setFunctionOperationSteps("Confirming...")
            })
            .once('transactionHash', () => {
                setSteps(3)
                setFunctionOperationSteps("Finalizing...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .then((res: any) => {
                setSteps(4)
                toast.success("Succeed")
            })
    }

    return (
        <MainMarketLayout>
            <div>
                <>
                    <MainTitle title="Accept Offer" />
                    <form onSubmit={handleSubmit(onSubmitGetApprove)}>
                        <InputComponent
                            errors={errors}
                            setPrice={setPrice}
                            register={register}
                            setValue={setValue}
                            setEthereumExchange={setEthereumExchange}
                            ethereumExchange={ethereumExchange}
                            LoadingNFT={true}
                            defaultValue={FromWei(highestOffer?.price)}
                        />

                        {
                            LoadingNFT &&
                            <SkeletonLoading text={true} picture={false} shape="rectangular" height={400} width='200px' />
                        }

                        {
                            !LoadingNFT && steps === 0 &&
                            <Grid sx={{ marginTop: '280px', display: 'flex', justifyContent: 'space-between', width: '65%' }}>
                                <SubmitButtonGold type="submit" title="Accept" bgcolor="#94794F" />
                                <SubmitButtonGold type="button" onClick={() => navigate(-1)} title="Ignore" bgcolor="#fff" color="#000" />
                            </Grid>
                        }
                        {
                            !LoadingNFT && steps >= 1 && steps <= 3 &&
                            <>
                                <ConfirmationSection steps={steps} />
                            </>
                        }
                        {
                            steps === 4 &&
                            <>
                                <Grid sx={{ display: "grid", placeItems: 'center', marginTop: '80px' }}>
                                    <ResultTitleText text="You accepted the Offer successfully!" />
                                    <BlackLinkButton to={DataNFT?.data?.data?.nft?.category !== '' ? (DataNFT?.data?.data?.nft?.status === "lazy" ? `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?._id}` : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?.tokenId}`) : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT.data?.data?.nft?.tokenId}`} text="Visit Your NFT" />
                                </Grid>
                            </>
                        }
                    </form>
                    {
                        steps === 0 &&
                        <DescriptionList data={List} />
                    }
                </>
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>
        </MainMarketLayout>
    )
}

export default AcceptOffer