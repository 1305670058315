import React from 'react'
import { useStyles } from './StyleMarketComponents'
import GreenTick from '../../Assets/Img/tick.png'
import BlackCircularLoading from './BlackCircularLoading'

function ConfirmationSection(props: any) {
    const classes = useStyles()
    const { steps } = props

    return (
        <div className={classes.confirmationSection}>
            <p>Compelete Your Listing</p>
            <ul id="tripleSteps">
                <li className={classes.lds}>
                    {
                        steps < 1 && <span>1</span>
                    }
                    {
                        steps === 1 && <BlackCircularLoading />
                    }
                    {
                        steps > 1 && <img src={GreenTick} alt="tick" />
                    }
                    Approve
                </li>
                <li>
                    {
                        steps < 2 && <span>2</span>
                    }
                    {
                        steps === 2 && <BlackCircularLoading />
                    }
                    {
                        steps > 2 && <img src={GreenTick} alt="tick" />
                    }
                    Confirm
                </li>
                <li>
                    {
                        steps < 3 && <span>2</span>
                    }
                    {
                        steps === 3 && <BlackCircularLoading />
                    }
                    {
                        steps > 3 && <img src={GreenTick} alt="tick" />
                    }
                    Finalize
                </li>
            </ul>
        </div>
    )
}

export default ConfirmationSection