import { Container, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import photo1 from '../../../Assets/Img/1avatar.png'
import { Card, CardItemInCollection } from '../../../Components/CardSection/CardSection'
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'
import { GetCollectionRequest } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import ShareButtons from '../../NFTItem/Subcomponents/ShareButtons'
import EditCollection from '../EditCollection/EditCollection'
import { useStyle } from './StyleSingleCollection'
import star from '../../../Assets/Img/star.svg'
import filledStar from '../../../Assets/Img/filledStar.svg'
import StarBorderIcon from '@mui/icons-material/StarBorder';
import API from '../../../Utils/Api'
import ENDPOINTS from '../../../Utils/Endpoints'
import { useMutation } from '@tanstack/react-query'
import StarIcon from '@mui/icons-material/Star'
import { Facebook, Instagram, Twitter } from '@mui/icons-material'
import LanguageIcon from '@mui/icons-material/Language';
import { FromWei } from '../../../Utils/Utils'
import { PopUp } from '../../../Components/PopUp/PopUp'
import Report from '../../NFTItem/Subcomponents/Report'
import { Editor, EditorState, convertFromRaw } from "draft-js";
import etherscan from "../../../Assets/Img/etherscan.svg"
import discord from '../../../Assets/Img/discord.svg'

const dataTest: any = {
    "blocks": [
        {
            "key": "8i090",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 16,
                    "style": "BOLD"
                }
            ],
            "entityRanges": [],
            "data": {}
        },
    ],
    "entityMap": {}
}

function SingleCollection() {
    const [open, setOpen] = useState<boolean>(false)
    const [popUpState, setPopUpState] = useState<string>("")
    const [allowPopupClose, setAllowPopupClose] = useState<boolean>(false)
    const [editCollection, setEditCollection] = useState<boolean>(false)
    const { GetCollectionData, GetCollectionLoading } = useAppSelector((state) => state.EditCollectionReducer)
    const account = localStorage.getItem('account')
    document.addEventListener('contextmenu', (event: any) => {
        if (event?.target?.tagName === 'IMG' || event?.target?.tagName === 'VIDEO' || event?.target?.tagName === 'LI') {
            event.preventDefault()
        }
    });
    const Description: any = GetCollectionData?.data?.data?.collection?.description
    const contentState = convertFromRaw(!Description ? dataTest : JSON.parse(Description))
    const editorState = EditorState.createWithContent(contentState)

    const textState = editorState.getCurrentContent()
    const textLength = textState.getPlainText('').length
    const dispatch = useAppDispatch()
    const param = useParams()

    useEffect(() => {
        dispatch(GetCollectionRequest({ payload: { username: param.username } }))
    }, [param.username])

    useEffect(() => {
        Liker(GetCollectionData)
    }, [GetCollectionLoading])

    const [age, setAge] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    const classes = useStyle()

    const [liked, setLiked] = useState<boolean>(false)
    const [likeNum, setLikeNum] = useState<number>(0)

    const getVoucherForMintLazy = async (data: any) => {
        setLiked(!liked)
        const { data: response } = await API.post(ENDPOINTS.addCollectionToFavorite, data);
        return response.data;
    };

    const { mutate, isLoading, data } = useMutation(getVoucherForMintLazy, {
        onSuccess: () => {
        },
        onError: () => {
            console.error("error like")
            setLiked(false)
        },
    });

    console.log("GetCollectionData",GetCollectionData)


    function Liker(props: any) {
        if (props !== undefined) {
            props?.data?.data?.collection?.favorite_list?.filter((item: any) => {
                if (item?.address?.toUpperCase() === account?.toUpperCase()) {
                    setLiked(true)
                }
                else {
                    setLiked(false)
                }
            })
        } else {
            setLiked(false)
        }
    }


    const item = GetCollectionData?.data?.data?.collection?.social[0]

    function MapSocial() {
        if (item) {
            const enteries = Object?.entries(item!)
            let data = enteries.map(([key, value]) => {
                if (key == 'instagram') {
                    if (value) {
                        return (
                            <a href={`https://www.instagram.com/${value as string}`} target="_blank">
                                <Instagram fontSize='medium' />
                            </a>
                        )
                    }
                }
                if (key == 'twitter') {
                    if (value) {
                        return (
                            <a href={`https://twitter.com/${value as string}`} target="_blank">
                                <Twitter fontSize='medium' />
                            </a>
                        )
                    }
                }
                if (key == 'facebook') {
                    if (value) {
                        return (
                            <a href={`https://www.facebook.com/${value as string}`} target="_blank">
                                <Facebook fontSize='medium' />
                            </a>
                        )
                    }
                }
                if (key == 'website') {
                    if (value) {
                        return (
                            <a href={`https://${value as string}`} target="_blank" >
                                <LanguageIcon fontSize='medium' />
                            </a >
                        )
                    }
                }
                if (key == 'discord') {
                    if (value) {
                        return (
                            <a style={{ marginTop: '4px' }} href={`https://www.instagram.com/${value as string}`} target="_blank">
                                <img src={discord} style={{ width: '20px' }} />
                            </a>
                        )
                    }
                }
            })
            return data
        }
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const creator: string = GetCollectionData?.data?.data?.collection?.ownerData?.name
    const createdDate: string = GetCollectionData?.data?.data?.collection?.time_created
    const artworks: string = GetCollectionData?.data?.data?.collection?.time_created
    const owners: number = GetCollectionData?.data?.data?.CollectionOwners?.length
    const floorPrice: string = GetCollectionData?.data?.data?.min
    const highestPrice: string = GetCollectionData?.data?.data?.max
    const totalSale: string = GetCollectionData?.data?.data?.totalSales
    const collectionName: string = GetCollectionData?.data?.data?.collection?.name
    const collectionSymbol: string = GetCollectionData?.data?.data?.collection?.symbol
    const isOwner: string = GetCollectionData?.data?.data?.isOwner

    const date = new Date(createdDate)
    const skeletonNumber: number = 6;


    const collection = {
        name: collectionName
    }

    const [sorting, setSorting] = useState<string>("")
    const [filtering, setFiltering] = useState<string>('')

    function setSort(e: SelectChangeEvent<unknown>) {
        setSorting(e.target.value as string)
        dispatch(GetCollectionRequest({ payload: { username: param.username, sort: e.target.value, filter: filtering } }))
    }

    function setFilter(e: React.ChangeEvent<any>) {
        setFiltering(e.target.value as string)
        dispatch(GetCollectionRequest({ payload: { username: param.username, sort: sorting, filter: e.target.value } }))
    }

    const handleClosePopUp = () => {
        if (!allowPopupClose) {
            setOpen(false);
        }
    };
    const [showMore, setShowMore] = useState<boolean>(false)
    function toggleShowTextEditor() {
        setShowMore(!showMore)
        const element: any = document.getElementById("textEditorContainer")
        element.style.height = showMore ? "44px" : "fit-content"
    }

    return (
        <div className={classes.main}>
            <div>
                <div id='leftSection'>
                    <div id='ImageSection'>
                        {
                            GetCollectionLoading
                                ?
                                <SkeletonLoading picture={true} shape='circular' width='50%' text={false} />
                                :
                                <img src={GetCollectionData?.data?.data?.collection?.logo ? GetCollectionData?.data?.data?.collection?.logo : photo1} alt="photo" />
                        }
                        <p>{collectionName}</p>
                        <p>{collectionSymbol}</p>
                    </div>
                    <div id='collecInfo'>
                        <ul>
                            {
                                GetCollectionLoading ? <SkeletonLoading picture={false} text={true} width="350px" shape="" /> :
                                    <>
                                        <li>
                                            <span>
                                                {creator}
                                            </span>
                                            <span>
                                                Creator
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {GetCollectionLoading ? <SkeletonLoading picture={false} text={true} width="50px" shape="" /> :
                                                    months[date.getMonth()] + " " + date.getFullYear()
                                                }
                                            </span>
                                            <span>
                                                Created
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {GetCollectionLoading ? <SkeletonLoading picture={false} text={true} width="50px" shape="" /> :
                                                   GetCollectionData?.data?.data?.artWork?.title
                                                }
                                            </span>
                                            <span>
                                                Artworks
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {owners}
                                            </span>
                                            <span>
                                                Owners
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {floorPrice ? FromWei(floorPrice) : 0}
                                            </span>
                                            <span>
                                                Floor Price
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {highestPrice ? FromWei(highestPrice) : 0}
                                            </span>
                                            <span>
                                                Highest Price
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {totalSale && FromWei(totalSale)}
                                            </span>
                                            <span>
                                                Total Sales
                                            </span>
                                        </li>
                                    </>
                            }
                        </ul>
                    </div>
                    <div id='description'>
                        <div id="textEditorContainer" style={{ height: '45px' }}>
                            <Editor onChange={() => console.log("test Editor")} editorState={editorState} readOnly={true} />
                            {
                                textLength > 170 &&
                                <button onClick={toggleShowTextEditor}>{showMore ? "Show less" : "Show more"}</button>
                            }
                        </div>
                    </div>
                    <div id="category" >
                        <button onClick={setFilter} id={filtering === "liveAuction" ? "active" : ''} value="liveAuction">Live Auction </button>
                        <button onClick={setFilter} id={filtering === "buyNow" ? "active" : ''} value="buyNow">Buy Now </button>
                        <button onClick={setFilter} id={filtering === "offer" ? "active" : ''} value="offer">Active Offers </button>
                    </div>
                </div>
                <div id="rightSection">

                    <div id="share">
                        <div>

                            <a href='/' target="_blank">
                                <img src={etherscan} />
                            </a>
                            {MapSocial()}
                        </div>
                        <div>
                            {
                                !GetCollectionData?.data?.data?.isOwner &&
                                <IconButton onClick={() => mutate({ collectionId: GetCollectionData?.data?.data?.collection?._id })}>
                                    {
                                        liked ? <StarIcon /> : <StarBorderIcon />
                                    }
                                </IconButton>
                            }
                            <ShareButtons setPopUpState={setPopUpState} setOpen={setOpen} isOwner={isOwner} />
                        </div>
                    </div>
                    <div>
                        <FormControl sx={{ minWidth: 180 }} size="small" variant="standard">
                            <Select
                                className={classes.dropDown}
                                onChange={setSort}
                                value={sorting}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}

                            >
                                <MenuItem value="" style={{ fontFamily: '' }}>
                                    None
                                </MenuItem>
                                <MenuItem value="listed" >Recently Listed</MenuItem>
                                <MenuItem value="created" >Recently Created</MenuItem>
                                <MenuItem value="sold" >Recently Sold</MenuItem>
                                <MenuItem value="lowToHigh" >Price low to high</MenuItem>
                                <MenuItem value="highToLow" >Price high to low</MenuItem>
                                <MenuItem value="viewed" >Most viewed</MenuItem>
                                <MenuItem value="favorited" >Most favorited</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div>
                {
                    GetCollectionData?.data?.data?.nfts?.map((nft: any) => {
                        nft.collection = collection
                        return (<CardItemInCollection data={nft} />)
                    })
                }
                {
                    GetCollectionLoading && [...Array(skeletonNumber)].map((i: number) => (
                        <SkeletonLoading text={true} key={i} shape='rectangular' picture={true} width="100%" />
                    ))
                }
                 <div className={classes.createItem}>
                                <Link to="/mint">Create new item</Link>
                 </div>
            </div>
            <PopUp onClose={handleClosePopUp} open={open}>
                {
                    popUpState === 'report' &&
                    <Report />
                }
            </PopUp>
        </div >
    )
}

export default SingleCollection





// <div>
//                 <div>
//                     {
//                         GetCollectionLoading
//                             ?
//                             <SkeletonLoading picture={true} shape='circular' width='50%' text={false} />
//                             :
//                             <img src={GetCollectionData?.data?.data?.collection?.logo ? GetCollectionData?.data?.data?.collection?.logo : photo1} alt="photo" />
//                     }
//                     {/* {
//                         GetCollectionData?.data?.data?.isOwner === true && <a onClick={() => setEditCollection(!editCollection)}>{editCollection ? "Collection's Item" : "Edit Collection"}</a>
//                     } */}
//                     <p>{GetCollectionData?.data?.data?.collection?.name}</p>
//                     <p>{GetCollectionData?.data?.data?.collection?.symbol}</p>
//                     <table>
//                         <tr>
//                             <td>Number of Items</td>
//                             <td>{GetCollectionData?.data?.data?.nfts?.length}</td>
//                         </tr>
//                         <tr>
//                             <td>Number of Owners</td>
//                             <td>0</td>
//                         </tr>
//                         <tr>
//                             <td>Floor Price</td>
//                             <td>0 ETH</td>
//                         </tr>
//                         <tr>
//                             <td>Highest Sale</td>
//                             <td>0 ETH</td>
//                         </tr>
//                         <tr>
//                             <td>Volume Traded</td>
//                             <td>0 ETH</td>
//                         </tr>
//                     </table>
//                     <div>
//                         <p>Description</p>
//                         {
//                             GetCollectionLoading
//                                 ?
//                                 <SkeletonLoading picture={false} shape='circular' width='100%' text={true} />
//                                 :
//                                 <p>{GetCollectionData?.data?.data?.collection?.description}</p>
//                         }
//                     </div>
//                 </div>
//                 {
//                     editCollection
//                         ?
//                         <EditCollection collection={GetCollectionData} setEditCollection={setEditCollection} />
//                         :
//                         <div className={classes.itemCollectionContainer}>
//                             {GetCollectionData && GetCollectionData?.data?.data?.nfts.length == 0 && <p>No NFT has been Minted!</p>}
//                             {
//                                 GetCollectionData && GetCollectionData?.data?.data?.nfts.map((item: any) => (
//                                     <CardItemInCollection data={item} key={item._id} GetCollectionData={GetCollectionData} />
//                                 ))
//                             }
//                         </div>
//                 }
//             </div>