import { toast } from "react-toastify"
import { put, takeLatest } from "redux-saga/effects"
import Web3 from "web3"
import { CreateCollectionFailed, CreateCollectionSteps, CreateCollectionSuccess, CreateCollectionWEB3Failed, CreateCollectionWEB3Request, CreateCollectionWEB3Success, FetchCollectionRequest } from "../../redux/Actions"
import { store } from "../../redux/Store"
import * as types from '../../redux/Types'
import API from "../../Utils/Api"
import ENDPOINTS from "../../Utils/Endpoints"
import Secondary_Token_Factory from '../../Web3/ABI/SecondaryTokenFactory.json'

function* CreateCollectionWorker(action: any) {
    const web3 = new Web3(Web3.givenProvider)
    let ResponseCreateCollection: any
    async function CollectionsWEB3Succeed({ data }: any) {
        const collectionInfo = {
            name: action.payload.name,
            symbol: action.payload.symbol,
            collectionType: action.payload.collectionType,
            category_web2_id: action.payload.type,
            contract_address: data?.events?.CreatedCollection?.returnValues?.Collection
        }
        try {
            ResponseCreateCollection = await API.post(ENDPOINTS.CollectionCreated, collectionInfo)
        } catch (e: any) {
            console.error('e-CreateCollectionWorker', e)
            store.dispatch(CreateCollectionWEB3Failed({ payload: e }))
        }
        if (ResponseCreateCollection.data.success) {
            store.dispatch(CreateCollectionWEB3Success({ payload: ResponseCreateCollection }))
        }
    }

    function CollectionWEB3Failed({ data }: any) {
        store.dispatch(CreateCollectionWEB3Failed({ payload: data }))
    }

    const CreateCollection = async () => {
        const address = process.env.REACT_APP_SECONDARYTOKENFACTORY
        const abi: any = Secondary_Token_Factory
        const account = localStorage.getItem('account')
        const NFT = new web3.eth.Contract(abi, address)
        await NFT.methods.createCollection(1, `${action.payload.name}`, `${action.payload.symbol}`, `${action.payload.categoryOfArtwork}`).send({ from: account })
            .once('sending', () => store.dispatch(CreateCollectionSteps({ payload: 1 })))
            .on('transactionHash', () => store.dispatch(CreateCollectionSteps({ payload: 2 })))
            .on('error', (res: any) => store.dispatch(CreateCollectionWEB3Failed({ payload: res })))
            .then((res: any) => {
                store.dispatch(CreateCollectionWEB3Success({ payload: res }))
                store.dispatch(CreateCollectionSteps({ payload: 3 }))
                store.dispatch(FetchCollectionRequest({ payload: action.payload.categoryOfArtwork }))
            })
            .catch((res: any) => store.dispatch(CreateCollectionWEB3Failed({ payload: res })))

    }

    let createLazyCollectionResponse: any;
    async function CreateLazyCollection() {
        try {
            createLazyCollectionResponse = await API.get(`${ENDPOINTS.createLazyCollection + '?name=' + `${action.payload.name}` + "&symbol=" + `${action.payload.symbol}` + "&catWeb2_id=" + `${action.payload.categoryOfArtwork}`}`)
            if (createLazyCollectionResponse?.data?.success === true) {
                createLazyCollectionWeb3(createLazyCollectionResponse)

            }
        }
        catch (e: any) {
            console.error('e-CreateCollectionWorker', e)
            toast.error(e.message)
            store.dispatch(CreateCollectionFailed({ payload: e }))
        }
    }

    async function createLazyCollectionWeb3(props: any) {
        const address = process.env.REACT_APP_SECONDARYTOKENFACTORY
        const abi: any = Secondary_Token_Factory
        const account = localStorage.getItem('account')
        const NFT = new web3.eth.Contract(abi, address)
        const voucher = [
            props.data.data.voucher.owner,
            props.data.data.voucher.maximumSupply,
            props.data.data.voucher.name,
            props.data.data.voucher.symbol,
            props.data.data.voucher.catWeb2,
            props.data.data.voucher.signature
        ]
        await NFT.methods.createLazyCollection(voucher).send({ from: account })
            .once('sending', () => store.dispatch(CreateCollectionSteps({ payload: 1 })))
            .on('transactionHash', () => store.dispatch(CreateCollectionSteps({ payload: 2 })))
            .on('error', (res: any) => {
                store.dispatch(CreateCollectionWEB3Failed({ payload: res }))
                toast.error(res.message)
            })
            .then((res: any) => {
                store.dispatch(CreateCollectionWEB3Success({ payload: res }))
                store.dispatch(CreateCollectionSteps({ payload: 3 }))
            })
            .catch((res: any) => store.dispatch(CreateCollectionWEB3Failed({ payload: res })))

    }

    let Response: any
    async function Api() {
        try {
            Response = await API.post(ENDPOINTS.CreateCollectionCheckName, action.payload)
        } catch (e: any) {
            console.error('e-CreateCollectionWorker', e)
            store.dispatch(CreateCollectionFailed({ payload: e }))
            toast.warn(Response.data.message)
        }
    }
    yield Api()
    if (Response?.data?.success) {
        yield put(CreateCollectionWEB3Request({ payload: 'loading' }))
        yield put(CreateCollectionSuccess({ payload: Response }))
        if (action.payload.collectionType === "lazy") {
            yield CreateLazyCollection()
        }
        if (action.payload.collectionType === "standard") {
            yield CreateCollection()
        }
        if (action.payload.collectionType === undefined) {
            yield CreateCollection()
        }
    } else {
        yield put(CreateCollectionFailed({ payload: Response }))
        toast.warn(Response.data.message)
    }
}

export function* CreateCollectionWatcher() {
    yield takeLatest(types.CREATE_COLLECTION_REQUEST, CreateCollectionWorker)
}

