import React from 'react'
import { useStyles } from './styleEditProfile'

function Offers() {
    const classes = useStyles()
    return (
        <div className={classes.offers}>
            <div>
                <p id='title'>Offers you made</p>
                <p id='noItem'>There is no data history </p>
            </div>
            <div>
                <p>Offers on your item</p>
                <p id='noItem'>There is no data history </p>
            </div>
        </div>
    )
}

export default Offers