import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import CreateCollectionReducer from '../Components/CreateCollection/ReducerCreateCollection';
import ProfileHeaderReducer from '../Components/Header/ReducerHeaderProfile';
import EditCollectionReducer from '../Pages/Collections/EditCollection/ReducerEditCollection';
import ExploreNFTsReducer from '../Pages/Explore/ExploreNFTs/ReducerNFTsReducer';
import HomeReducer from '../Pages/Home/ReducerHome';
import MintReducer from '../Pages/Mint/ReducerMint';
import NFTReducer from '../Pages/NFTItem/ReducerNFT';
import CreateProfileReducer from '../Pages/Profile/EditProfile/ReducerProfile';
import GetProfileReducer from '../Pages/Profile/ReducerProfile';
import FixedPriceReducer from '../Pages/FixedPriced/SetFixedPrice/ReducerFixedPrice';
import SellInAuctionReducer from '../Pages/Auction/SellStandardAuction/ReducerSellInAuction';
import ExploreCollectionReducer from '../Pages/Explore/ExploreCollections/ReducerExploreCollection';

export const rootReducer = (history: any) => combineReducers({
    router: connectRouter(history),
    MintReducer,
    NFTReducer,
    CreateProfileReducer,
    ProfileHeaderReducer,
    GetProfileReducer,
    CreateCollectionReducer,
    EditCollectionReducer,
    ExploreNFTsReducer,
    HomeReducer,
    FixedPriceReducer,
    SellInAuctionReducer,
    ExploreCollectionReducer,
});