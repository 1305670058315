import React, { useState } from 'react'
import banner from '../../Assets/Img/faq.jpg'
import { useStyle } from './StyleFAQ';
import PublicIcon from '@mui/icons-material/Public';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import HandymanIcon from '@mui/icons-material/Handyman';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Faq() {
    const classes = useStyle()
    const [tab, setTab] = useState<string>('general')
    const FAQ = [
        { title: 'test', description: 'description' },
        { title: 'test2 ', description: 'descr2' }
    ]
    const FAQ1 = [
        { title: 'test333', description: 'description' },
        { title: 'test2222 ', description: 'descr2' }
    ]
    const FAQ2 = [
        { title: 'test444', description: 'description' },
        { title: 'test244 ', description: 'descr2' }
    ]
    return (
        <div className={classes.faqMain}>
            <figure>
                <img src={banner} alt="bannerFAQ" width="100%" height="600px" />
                <figcaption>
                    Everything you need to know about Artaniom
                </figcaption>
            </figure>
            <div className={classes.body}>
                <div>
                    <button onClick={() => setTab('general')}>
                        <PublicIcon />
                        GENERAL
                    </button>
                    <button onClick={() => setTab('technical')}>
                        <ArchitectureIcon />
                        TECHNICAL INFORAMATION
                    </button>
                    <button onClick={() => setTab('practical')}>
                        <HandymanIcon />
                        PRACTICAL INFORMATION
                    </button>
                </div>
                <div>
                    {
                        tab && tab === "general" &&
                        FAQ.map((item: any) =>
                            <Accordions title={item.title} description={item.description} />
                        )
                    }
                    {
                        tab && tab === "technical" &&
                        FAQ1.map((item: any) =>
                            <Accordions title={item.title} description={item.description} />
                        )
                    }
                    {
                        tab && tab === "practical" &&
                        FAQ2.map((item: any) =>
                            <Accordions title={item.title} description={item.description} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Faq

interface IAccordions {
    title: string,
    description: string
}

function Accordions({ title, description }: IAccordions) {
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {description}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}