import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'
import { NotificationListRequest, ProfileHeaderRequest } from '../../../redux/Actions'
import { useAppSelector } from '../../../redux/Hook'
import { useStyles } from '../EditProfile/styleEditProfile'
import CreateProfileReducer from './ReducerProfile'
import TimeAgo from 'react-timeago'

function ListNotification() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { NotificationListData, NotificationListIsLoading, NotificationListError } = useAppSelector(state => state.CreateProfileReducer)
    console.log("NotificationListData", NotificationListData)

    useEffect(() => {
        dispatch(NotificationListRequest())
    }, [])

    useEffect(() => {
        dispatch(ProfileHeaderRequest())
    }, [NotificationListData])

    return (
        <div className={classes.listNotification}>
            <h3>Notifications list</h3>
            <div id="list">
                {
                    NotificationListIsLoading ?
                        <SkeletonLoading shape={"reactangular"} picture={false} text={true} width={"300px"} />
                        :
                        (NotificationListData?.data?.data?.notificationsTemp?.map((item: any) => (
                            <div id={item?.seen ? "item" : "itemNew"}>
                                <p>{item.message}</p>
                                <p><TimeAgo date={item.time * 1000} /></p>
                            </div>
                        )))
                }
            </div>
        </div>
    )
}

export default ListNotification
