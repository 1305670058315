import React, { Component } from 'react';
import { EditorState, getDefaultKeyBinding, RichUtils, Modifier, convertToRaw, convertFromRaw } from 'draft-js'
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import "./textEditorStyle.css"
import '../../../../../node_modules/draft-js/dist/Draft.css'
import '../../../../../node_modules/@draft-js-plugins/anchor/lib/plugin.css'
import createCounterPlugin from '@draft-js-plugins/counter';
import LinkEditor from './Link';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    CodeButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    CodeBlockButton,
} from '@draft-js-plugins/buttons';
import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';

const toolbarPlugin = createToolbarPlugin()
const { Toolbar } = toolbarPlugin;
const counterPlugin = createCounterPlugin();
const { CharCounter } = counterPlugin;
const linkPlugin = createLinkPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const plugins = [inlineToolbarPlugin, linkPlugin, counterPlugin, toolbarPlugin];
const text = ``;

class TextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editorState: !props.editorState ? EditorState.createEmpty() : props.editorState };
        this.focus = () => this.editor.focus();
        this.onChange = (editorState) => {
            props.setDescription(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
            props.setDescriptionRawText(editorState.getCurrentContent().getPlainText())
            this.setState({ editorState })
        };
        this.toggleColor = (toggledColor) => this._toggleColor(toggledColor);
        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    onChange = (editorState) => {

        this.setState({ editorState });
        this.props.setEditorStateLength(CharCounter)
    };

    focus = () => {
        this.editor.focus();
    };

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }
    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }
    _getLengthOfSelectedText = () => {
        const currentSelection = this.state.editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = this.state.editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);

            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                };
            }
        }

        return length;
    }
    _handleBeforeInput = () => {

        const currentContent = this.state.editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = this._getLengthOfSelectedText();
        if (currentContentLength - selectedTextLength > this.props.maxLength - 1) {

            toast.warn(`you can't type more than ${this.props.maxLength} characters.`)
            return 'handled';
        }
    }

    _handlePastedText = (pastedText) => {
        const currentContent = this.state.editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = this._getLengthOfSelectedText();
        if (currentContentLength + pastedText.length - selectedTextLength > this.props.maxLength) {

            toast.warn(`you can type ${this.props.maxLength} characters maximum.`)
            return 'handled';
        }
    }



    render() {
        const { editorState } = this.state;


        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }
        return (
            <div className="RichEditor-root">
                {/* <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                /> */}
                <Toolbar>
                    {
                        // may be use React.Fragment instead of div to improve perfomance after React 16
                        (externalProps) => (
                            <div className='wrapperButton'>
                                <BoldButton {...externalProps} />
                                <ItalicButton {...externalProps} />
                                <UnderlineButton {...externalProps} />
                                <CodeButton {...externalProps} />
                                <Separator {...externalProps} />
                                <HeadlineOneButton {...externalProps} />
                                <HeadlineTwoButton {...externalProps} />
                                <HeadlineThreeButton {...externalProps} />
                                <UnorderedListButton {...externalProps} />
                                <OrderedListButton {...externalProps} />
                            </div>
                        )
                    }
                </Toolbar>
                < div style={styles.editor} onClick={this.focus} >
                    <Editor
                        editorState={editorState}
                        onChange={this.onChange}
                        placeholder="Enter Description"
                        ref={(ref) => this.editor = ref}
                        handleBeforeInput={this._handleBeforeInput}
                        handlePastedText={this._handlePastedText}
                        plugins={plugins}
                    />
                    <InlineToolbar>
                        {
                            (externalProps) => (
                                <div>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                    <linkPlugin.LinkButton {...externalProps} />
                                </div>
                            )
                        }
                    </InlineToolbar>

                    <div style={styles.counter}>
                        <CharCounter limit={50} /> /{this.props.maxLength}
                    </div>
                </div >
            </div >
        );
    }
}
class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const styles = {
    root: {
        fontFamily: 'DellaRespira',
        fontSize: 14,
        width: '100%',
        background: "#fff",
        border: "1px solid #ddd",
        padding: "15px",
        boxSizing: 'border-box',
    },
    editor: {
        borderTop: '1px solid #ddd',
        cursor: 'text',
        fontSize: 16,
        marginTop: 20,
        minHeight: 100,
        paddingTop: 20,
        position: 'relative',
        fontFamily: 'DellaRespira',
    },
    controls: {
        fontFamily: '\'Helvetica\', sans-serif',
        fontSize: 14,
        marginBottom: 10,
        userSelect: 'none',
    },
    styleButton: {
        color: '#999',
        cursor: 'pointer',
        marginRight: 16,
        padding: '2px 0',
    },
    counter: {
        position: 'absolute',
        bottom: '-45px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        color: "#676767",
        fontFamily: "DellaRespira",
        fontSize: '0.75rem',
        right: '-16px'
    }
};

const BLOCK_TYPES = [
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' },
];
const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};
export default TextEditor;