import * as types from '../../redux/Types';
import { ReducerStateGetProfile } from '../../Utils/Interfaces';

const initialState = {
    DataGetProfile: [],
    isLoadingGetProfile: false,
    ErrorGetProfile: false,
}

export default function GetProfileReducer(state: ReducerStateGetProfile = initialState, action: any) {
    switch (action.type) {
        case types.GET_PROFILE_REQUEST:
            return {
                ...state,
                isLoadingGetProfile: true,
                ErrorGetProfile: false,
                DataGetProfile: action.payload
            }
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                isLoadingGetProfile: false,
                ErrorGetProfile: false,
                DataGetProfile: action.payload
            }
        case types.GET_PROFILE_FAILED:
            return {
                ...state,
                isLadingGetProfile: false,
                ErrorGetProfile: true,
                DataGetProfile: action.payload
            }
        default:
            return state
    }
} 