import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton
} from "react-share";
import IosShare from '../../../Assets/Img/iosshare.svg';
import MenuButton from '../../../Components/menuButton/Menu';

import { ContentCopy } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import copyToClipboard from '../../../Utils/CopyToClipboard';

function ShareButtons({ setOpen, setPopUpState, statusOfItem, isOwner }: any) {
    const share = [
        "Copy Link",
        "Twitter",
        "Facebook",
        "Telegram",
        "Whatsapp",
        "Email"
    ];

    const more = [
        "Transfer",
        "Report",
        "Burn",
    ];

    const moreInLazy = [
        "Transfer",
        "Report",
    ]

    const moreInCollectionNotOwner = [
        "Report",
        "Edit"
    ]
    const moreInCollectionOwner = [
        "Edit"
    ]
    const report = [
        "Report"
    ]
    const [moreButtonValue, setMoreButtonValue] = useState<string>('')
    const param = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    function handleMoreButton(props: any) {
        setMoreButtonValue(props)
        if (props === "Report") {
            setOpen(true)
            setPopUpState("report")
        }
        if (props === "Burn") {
            setOpen(true)
            setPopUpState("burn")
        }
        if (props === "Transfer") {
            setOpen(true)
            setPopUpState("transfer")
        }
        if (props === "Edit") {
            if (location.pathname.includes('collection')) {
                navigate(`/collections/edit/${param.username}`)
            }
        }
    }

    const [shareButtonValue, setShareButtonValue] = useState<string>('')

    function handleShareButton(props: any) {
        setShareButtonValue(props)
        if (props === "Twitter") {
            alert("Twitter")
        }
    }
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickMoreButton = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMoreButton = () => {
        setAnchorEl(null);
    };
    const fullUrl = window?.location?.href
    return (
        <>
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClickMoreButton}
                >
                    <img src={IosShare} />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}

                    onClose={handleCloseMoreButton}
                    PaperProps={{
                        style: {
                            maxHeight: 80 * 4.5,
                            width: 'max-content',
                            fontFamily: "RalewayMedium",
                            color: '#676767',
                            fontSize: '15px',
                        },
                    }}
                >
                    <MenuItem key="email" onClick={() => {
                        copyToClipboard(fullUrl)
                        setAnchorEl(null);
                    }}>
                        <ContentCopy />
                        Copy Link
                    </MenuItem>
                    <MenuItem key="email" onClick={handleCloseMoreButton}>
                        <EmailIcon />
                        <EmailShareButton subject="Check out this item on Artaniom" body='test' separator=':' url={fullUrl} children='email' />
                    </MenuItem>
                    <MenuItem key="facebook" onClick={handleCloseMoreButton}>
                        <FacebookIcon />
                        <FacebookShareButton quote='Check out this item on Artaniom: ' hashtag='artaniom' url={fullUrl} children='facebook' />
                    </MenuItem>
                    <MenuItem key="twitter" onClick={handleCloseMoreButton}>
                        <TwitterIcon />
                        <TwitterShareButton title="Check out this item on Artaniom: " hashtags={['artaniom']} url={fullUrl} children='twitter' />
                    </MenuItem>
                    <MenuItem key="linkedin" onClick={handleCloseMoreButton}>
                        <LinkedInIcon />
                        <LinkedinShareButton title="Check out this item on Artaniom: " source='artaniom.io' url={fullUrl} children='linkedin' />
                    </MenuItem>
                    <MenuItem key="telegram" onClick={handleCloseMoreButton}>
                        <TelegramIcon />
                        <TelegramShareButton title="Check out this item on Artaniom: " url={fullUrl} children='telegram' />
                    </MenuItem>
                    <MenuItem key="whatsapp" onClick={handleCloseMoreButton}>
                        <WhatsAppIcon />
                        <WhatsappShareButton title="Check out this item on Artaniom: " url={fullUrl} children='whatsapp' />
                    </MenuItem>
                </Menu>
            </div>
            {/* <MenuButton options={share} handleValue={handleShareButton}>
                <img src={IosShare} />
            </MenuButton> */}
            <MenuButton options={
                isOwner ? (location.pathname.includes("collection") ? (isOwner ? moreInCollectionOwner : moreInCollectionNotOwner) : (statusOfItem === "lazy" ? (isOwner ? report : moreInLazy) : more)) : report} handleValue={handleMoreButton}>
                <MoreVertIcon sx={{ color: " rgba(0, 0, 0, 0.54)" }} />
            </MenuButton>
        </>
    )
}

export default ShareButtons