import React from 'react'
import { useStyles } from './styleEditProfile'

function AuctionTabInProfile() {
    const classes = useStyles()
    return (
        <div className={classes.offers}>
            <div>
                <p id='title'>The auctions you have held</p>
                <p id='noItem'>There is no data history </p>
            </div>
            <div>
                <p>Auctions that you place a bid</p>
                <p id='noItem'>There is no data history </p>
            </div>
        </div>
    )
}

export default AuctionTabInProfile