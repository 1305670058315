import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '80px',
        minHeight: '60vh',
        "& > div": {
            width: '80%',
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            columnGap: '20px',
            "& >  div:nth-child(1)": {
                backgroundColor: '#dfdfdf',
                height: "150px",
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'center',
                color: "#000",
                alignItems: 'flex-start',
                "& button": {
                    color: '#000',
                    fontSize: '18px',
                    fontFamily: 'inherit'
                }
            },
            "& >  div:nth-child(2)": {
                backgroundColor: '#e5e5e5',

                "& > p:first-child": {
                    marginTop: '-30px',
                    fontSize: '22px',
                    marginBottom: 0,
                },
                "& div": {
                    padding: '20px 20px 0 20px',
                    "& a": {
                        textDecoration: 'none',
                        fontSize: "22px",
                        color: "#5d4b87"
                    },
                    "& p": {
                        margin: '0',
                        color: theme.palette.primary.dark,
                        fontSize: '15px',
                        fontFamily: 'plexSansLight',
                        fontWeight: 600
                    }
                }
            }
        }
    }
}))