import { put, takeLatest } from "redux-saga/effects"
import * as types from '../../../redux/Types'
import { CompleteCollectionFailed, CompleteCollectionSuccess, GetCollectionFailed, GetCollectionRequest, GetCollectionSuccess } from "../../../redux/Actions"
import { history, store } from "../../../redux/Store"
import API from "../../../Utils/Api"
import ENDPOINTS from "../../../Utils/Endpoints"
import { toast } from "react-toastify"

function* CompleteCollectionWorker(action: any) {

    let Response: any
    async function Api() {
        try {
            Response = await API.put(ENDPOINTS.EditCollection, action.payload.data)
        } catch (e: any) {
            console.error('e-MintToBackendWorker', e)
            store.dispatch(CompleteCollectionFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response?.data?.success) {
        yield put(CompleteCollectionSuccess({ payload: Response }))
        history.push(`/collection/${action.payload.data.title}`)
        toast.success("Your collection updated successfully.")
        store.dispatch(GetCollectionRequest({ payload: Response?.data?.data?.collection?.name }))
    } else {
        yield put(CompleteCollectionFailed({ payload: Response }))
    }
}


export function* CompleteCollectionWatcher() {
    yield takeLatest(types.COMPLETE_COLLECTION_REQUEST, CompleteCollectionWorker)
}



function* GetCollectionWorker(action: any) {

    let Response: any
    async function Api() {
        try {
            Response = await API.get(`${ENDPOINTS.CollectionCreated}?name=${action.payload.username}${action.payload.sort ? `&sort=${action.payload.sort}` : ''}${action.payload.filter ? `&filter=${action.payload.filter}` : ''} `)
        } catch (e: any) {
            console.error('e-MintToBackendWorker', e)
            store.dispatch(GetCollectionFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response?.data?.success) {
        const response: any = JSON.stringify(Response?.data?.data)
        localStorage.setItem("collectionInfo", response)
        yield put(GetCollectionSuccess({ payload: Response }))
    } else {
        yield put(GetCollectionFailed({ payload: Response }))
    }
}


export function* GetCollectionWatcher() {
    yield takeLatest(types.GET_COLLECTION_REQUEST, GetCollectionWorker)
}
