import React from 'react'
import { useStyles } from './StyleMarketComponents'

function BlackCircularLoading() {
    const classes = useStyles()
    return (
        <div className={classes.lds}>
            <div className='lds-ring'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default BlackCircularLoading