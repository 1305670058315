import { Container } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import { useStyle } from './StyleSettleAuction'
import GavelIcon from '@mui/icons-material/Gavel';
import { Outbox } from '@mui/icons-material'
import LaunchIcon from '@mui/icons-material/Launch';
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import { GetNFT } from '../../../redux/Actions'
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'

function SettleAuction() {
    const classes = useStyle()
    const param = useParams()
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)

    const isAuctionable: boolean = DataNFT?.data?.data?.permisionNft?.auction
    const isReserveable: boolean = DataNFT?.data?.data?.permisionNft?.reserve

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.id])

    const forRoute = {
        creator: DataNFT?.data?.data?.nft?.creator,
        id: DataNFT?.data?.data?.nft?._id,
        collection: DataNFT?.data?.data?.nftCollection?.name
    }

    return (
        <div className={classes.mainSettleAuction}>
            <Container maxWidth="lg">
                <div id="rightSide">
                    <p>Select the Auction Type</p>
                    <div>
                        {
                            isAuctionable &&
                            <div>
                                <>
                                    <div>
                                        <GavelIcon /><Link aria-disabled={!isAuctionable} id='standardAuction' to={DataNFT?.data?.data?.nftCollection !== undefined ? `/Auction/setStandard/${forRoute.creator}/${forRoute.collection}/${forRoute.id}` : `/Auction/setStandard/${forRoute.creator}/${forRoute.id}`}>Standard Auction</Link>
                                    </div>
                                    <div>
                                        <p>You set the starting price, starting time, and ending time of the auction.</p>
                                    </div>
                                </>
                            </div>
                        }
                        {
                            LoadingNFT &&
                            <SkeletonLoading text={true} picture={false} shape="rectangular" height={400} width='200px' />
                        }
                        {
                            isReserveable &&
                            <div>
                                <>
                                    <div>
                                        <Link id='reservedAuction' to={DataNFT?.data?.data?.nftCollection !== undefined ? `/Auction/setReserved/${forRoute.creator}/${forRoute.collection}/${forRoute.id}` : `/Auction/setReserved/${forRoute.creator}/${forRoute.id}`}>Reserved Auction</Link> <GavelIcon />
                                    </div>
                                    <div>
                                        <p>You set the starting price, starting time, and ending time of the auction.</p>
                                    </div>
                                </>
                            </div>
                        }
                        {
                            LoadingNFT &&
                            <SkeletonLoading text={true} picture={false} shape="rectangular" height={400} width='200px' />
                        }
                    </div>
                    <a id="readMore" href='/'>Read more about auction types<LaunchIcon /></a>
                </div>
                <div id="leftSide"><LazyCard data={DataNFT?.data?.data} /></div>
            </Container>
        </div>
    )
}

export default SettleAuction