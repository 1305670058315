import { Link } from 'react-router-dom'
import ethImg from '../../Assets/Img/ETH.jpg'
import metamaskImg from '../../Assets/Img/metamask.jpg'
import web3Img from '../../Assets/Img/webp.webp'
import { useStyle } from './CardStyles'


function BrowseMarket(props: any) {
    const classes = useStyle()
    return (
        <div className={classes.browseMarket}>
            <div>
                <Link id="button" to="/ExploreNFTs">Browse The Market</Link>
                <a href="/">How to Get Started</a>
            </div>
            <div>
                <a href="/">
                    <img src={metamaskImg} alt="ethImg" />
                    <p>Setup a Metamask Wallet</p>
                </a>
                <a href="/">
                    <img src={ethImg} alt="metamaskImg" />
                    <p>Fund Metamask With ETH</p>
                </a>
                <a href="/">
                    <img src={web3Img} alt="web3Img" />
                    <p>Browse Market For Treasured NFTs</p>
                </a>
            </div>
        </div>
    )
}

export default BrowseMarket