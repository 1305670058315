import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    resultSectionMsg: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& p:nth-child(2)": {
            fontFamily: 'RalewaySemiBold',
            color: '#676767',
            fontSize: '22px',
            margin: '0',
        }
    }
}))