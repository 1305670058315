import { TabPanelUnstyled, TabsListUnstyled, TabsUnstyled, TabUnstyled } from '@mui/base';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LanguageIcon from '@mui/icons-material/Language';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Container, Grid, IconButton, Menu, MenuItem, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton
} from "react-share";
import photo1 from '../../Assets/Img/1avatar.png';
import discord from '../../Assets/Img/discord.svg';
import etherSmallLogo from '../../Assets/Img/etherSmallLogo.svg';
import { Card, CardCollectionInProfile } from '../../Components/CardSection/CardSection';
import CreateCollections from '../../Components/CreateCollection/CreateCollection';
import SkeletonLoading from '../../Components/Skeleton/Skeleton';
import { ChangeWeb3State, GetProfile } from '../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../redux/Hook';
import API from '../../Utils/Api';
import copyToClipboard from '../../Utils/CopyToClipboard';
import ENDPOINTS from '../../Utils/Endpoints';
import EpochConvertToDate from '../../Utils/EpochConvertToDate';
import { useStyles } from './StyleProfile';

import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ReportIcon from '@mui/icons-material/Report';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';




const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontSize: "16px",
        fontFamily: 'Raleway',
        fontWeight: 500,
    },
}));
function Profile(): JSX.Element {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const { DataGetProfile, isLoadingGetProfile, ErrorGetProfile } = useAppSelector(state => state.GetProfileReducer)
    const param: any = useParams()
    const Prof: any = JSON.stringify(DataGetProfile)

    if (DataGetProfile?.data?.success === true) {
        sessionStorage.setItem('profileCreator', Prof)
    }

    useEffect(() => {
        dispatch(GetProfile({ payload: param?.username ? param?.username : '' }))
    }, [param.username])

    return (
        <div className={classes.mainProfile}>
            <Container className={classes.container}  >
                <LeftSide data={DataGetProfile?.data} isLoading={isLoadingGetProfile} />
                <RightSide data={DataGetProfile?.data} isLoading={isLoadingGetProfile} Error={Error} />
            </Container>
        </div>
    )
}

export default Profile




const LeftSide = ({ data, isLoading }: any) => {
    const refBtn: any = useRef(null)
    const classes = useStyles()

    const item = data?.data?.user?.social[0]
    const owner = data?.data?.isOwner

    function MapSocial() {
        if (item) {
            const enteries = Object?.entries(item!)
            let data = enteries.map(([key, value]) => {
                if (key == 'instagram') {
                    if (value) {
                        return (
                            <a href={`https://www.instagram.com/${value as string}`} target="_blank">
                                <Instagram fontSize='medium' />
                            </a>
                        )
                    }
                }
                if (key == 'twitter') {
                    if (value) {
                        return (
                            <a href={`https://twitter.com/${value as string}`} target="_blank">
                                <Twitter fontSize='medium' />
                            </a>
                        )
                    }
                }
                if (key == 'facebook') {
                    if (value) {
                        return (
                            <a href={`https://www.facebook.com/${value as string}`} target="_blank">
                                <Facebook fontSize='medium' />
                            </a>
                        )
                    }
                }
                if (key == 'website') {
                    if (value) {
                        return (
                            <a href={`https://${value as string}`} target="_blank" >
                                <LanguageIcon fontSize='medium' />
                            </a >
                        )
                    }
                }
                if (key == 'discord') {
                    if (value) {
                        return (
                            <a style={{ marginTop: '4px' }} href={`https://www.instagram.com/${value as string}`} target="_blank">
                                <img src={discord} style={{ width: '20px' }} />
                            </a>
                        )
                    }
                }
            })
            return data
        }
    }

    const setAvatarOnError = () => {
        var img: any = document.getElementById('avatar')
        if (img.src) {
            img.src = photo1
        }
    }

    const account: any = localStorage.getItem('account')

    const [isFollowing, setIsFollowing] = useState<boolean>(false)

    const mutation = useMutation((userAddress: any) => {
        setIsFollowing(!isFollowing)
        return API.post(ENDPOINTS.follow, userAddress)
    })

    useEffect(() => {
        setFollowing(data)
    }, [isLoading])

    function setFollowing(props: any) {
        if (props?.data?.user?.followers !== undefined) {
            props?.data?.user?.followers?.filter((item: any) => {
                if (item?.address?.toUpperCase() === account?.toUpperCase()) {
                    setIsFollowing(true)
                }
                else {
                    setIsFollowing(false)
                }
            })
        } else {
            setIsFollowing(false)
        }
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickMoreButton = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMoreButton = () => {
        setAnchorEl(null);
    };
    const fullUrl = window?.location?.href

    const userName = data?.data?.user?.username
    const ens = data?.data?.user?.ens
    return (
        <div className={classes.leftSide}>
            <div className={classes.userProfile}>
                <div ref={refBtn} id="infoSection"  >
                    {isLoading ? <SkeletonLoading shape="circular" text={false} width="40%" picture={true} /> :
                        <>
                            {
                                data?.data?.user?.avatar ?
                                    <img onError={setAvatarOnError} id="avatar" src={data?.data?.user?.avatar} alt="avatar" />
                                    :
                                    <div id='defaultAvatar' className={classes.avatar}></div>
                            }
                            <p id="name">{data?.data?.user?.name}</p>
                        </>
                    }
                    <div id="first">
                        <p>
                            <LightTooltip title={<div className={classes.tooltipEns}>
                                <div>
                                    <p><img src={etherSmallLogo} alt="eth" /> {`${data?.data?.user?.address.slice(0, 4)}...${data?.data?.user?.address.slice(-4)}`}</p>
                                    <IconButton onClick={() => copyToClipboard(data?.data?.user?.address)}> <ContentCopyIcon /></IconButton>
                                </div>
                                <div>
                                    <p><img src={etherSmallLogo} alt="eth" />{ens && ens}</p>
                                    <IconButton size='small' onClick={() => copyToClipboard(ens ? ens : "")}> <ContentCopyIcon /></IconButton>
                                </div>
                            </div>} placement="bottom" >
                                <p>{ens ? ens : userName && `@${userName}`}</p>
                            </LightTooltip>
                        </p>
                        <p>Followers: {data?.data?.user?.followers?.length} </p>
                    </div>
                    <div id="second">
                        {data?.success && data?.data?.user?.time_update ?
                            <p>
                                Joined: {EpochConvertToDate(data?.data?.user?.time_update)}
                            </p>
                            :
                            <SkeletonLoading text={true} picture={false} width="100px" shape="" />
                        }
                        <p>Following: {data?.data?.user?.following?.length} </p>
                    </div>
                    <div className={classes.bioSec}>
                        <p>
                            {data?.data?.user?.bio}
                        </p>
                    </div>
                </div>
                <div className={classes.socialMediaContainer}>
                    <div className={classes.socialMediaSec}>
                        {data?.data && MapSocial()}
                        <div>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClickMoreButton}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}

                                onClose={handleCloseMoreButton}
                                PaperProps={{
                                    style: {
                                        maxHeight: 80 * 4.5,
                                        width: 'max-content',
                                        fontFamily: "RalewayMedium",
                                        color: '#676767',
                                        fontSize: '15px',
                                    },
                                }}
                                className={classes.shareButtons}
                            >
                                {
                                    !owner &&
                                    <MenuItem key="report" onClick={handleCloseMoreButton}>
                                        <ReportIcon />  Report
                                    </MenuItem>
                                }
                                <MenuItem key="email" onClick={handleCloseMoreButton}>
                                    <EmailIcon />
                                    <EmailShareButton subject="Check out this profile on Artaniom" body='test' separator=':' url={fullUrl} children='email' />
                                </MenuItem>
                                <MenuItem key="facebook" onClick={handleCloseMoreButton}>
                                    <FacebookIcon />
                                    <FacebookShareButton quote='Check out this profile on Artaniom: ' hashtag='artaniom' url={fullUrl} children='facebook' />
                                </MenuItem>
                                <MenuItem key="twitter" onClick={handleCloseMoreButton}>
                                    <TwitterIcon />
                                    <TwitterShareButton title="Check out this profile on Artaniom: " hashtags={['artaniom']} url={fullUrl} children='twitter' />
                                </MenuItem>
                                <MenuItem key="linkedin" onClick={handleCloseMoreButton}>
                                    <LinkedInIcon />
                                    <LinkedinShareButton title="Check out this profile on Artaniom: " source='artaniom.io' url={fullUrl} children='linkedin' />
                                </MenuItem>
                                <MenuItem key="telegram" onClick={handleCloseMoreButton}>
                                    <TelegramIcon />
                                    <TelegramShareButton title="Check out this profile on Artaniom: " url={fullUrl} children='telegram' />
                                </MenuItem>
                                <MenuItem key="whatsapp" onClick={handleCloseMoreButton}>
                                    <WhatsAppIcon />
                                    <WhatsappShareButton title="Check out this profile on Artaniom: " url={fullUrl} children='whatsapp' />
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div>
                        {
                            data?.data?.isOwner &&
                            <Link className={classes.ButtonFollowEdit} to={`/profile/edit/${data?.data?.user?.username ? data?.data?.user?.username : data?.data?.user?.address}`}>Edit Collection</Link>
                        }
                        {
                            !isLoading && !data?.data?.isOwner &&
                            <button className={classes.ButtonFollowEdit} onClick={() => { mutation.mutate({ userAddress: data?.data?.profileOwner }) }}>{isFollowing ? "Following" : "Follow"}</button>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}


export function RightSide({ data, isLoading, Error }: any): JSX.Element {
    const theme = useTheme();
    const [value, setValue] = useState<number>(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const classes = useStyles()
    const RepeatSkeleton: any = 3;

    const Tab = styled(TabUnstyled)`
  font-family: RalewayMedium;
  color: #000;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  background-color: transparent;
  width: 100%;
  padding: 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #fff;
  }

  &:focus {
    color: #94794F;
    outline: 2px solid #80BFFF;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #94794F;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

    const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

    const TabsList = styled(TabsListUnstyled)(
        ({ theme }) => `
  min-width: 400px;
  background-color:  #E3E3E3;
  border-radius: 2px;
  margin-bottom: 16px;
  font-size: 20px;
  font-family: Raleway;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;`
    );
    const createdLength = data?.data?.created?.length
    const collectionLength = data?.data?.collection?.length
    const collectedLength = data?.data?.collected?.length
    const splitLength = data?.data?.split?.length

    return (
        <Box sx={{ bgcolor: 'background.paper' }} >
            <TabsUnstyled defaultValue={0}>
                <TabsList>
                    <Tab>Created ({createdLength})</Tab>
                    <Tab>Collection ({collectionLength})</Tab>
                    <Tab>Collected ({collectedLength})</Tab>
                    <Tab>Split ({splitLength})</Tab>
                </TabsList>
                <TabPanel value={0}>
                    <Grid className={classes.cardContainer}>
                        {isLoading && [...Array(RepeatSkeleton)]?.map((i) => (
                            <SkeletonLoading key={i} shape="rectangular" text={true} width="100%" picture={true} />
                        ))}

                        {data?.data?.created?.map((item: any, i: number) => (
                            <Card data={item} key={item?.data?._id} />
                        ))}
                        {data?.data?.isOwner &&
                            <div className={classes.createItem}>
                                <Link to="/mint">Create new item</Link>
                            </div>
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={1}>
                    <Grid className={classes.cardContainer}>
                        {isLoading && [...Array(RepeatSkeleton)]?.map((i) => (
                            <SkeletonLoading key={i} shape="rectangular" text={true} width="100%" picture={true} />
                        ))}
                        {
                            data?.data?.collection?.map((item: any, i: number) =>
                                <CardCollectionInProfile data={item} key={item?.data?._id} />
                            )
                        }
                        {data?.data?.isOwner && <CardCreateCollection />}
                    </Grid>
                </TabPanel>
                <TabPanel value={2}>
                    <Grid className={classes.cardContainer}>
                        {isLoading && [...Array(RepeatSkeleton)]?.map((i) => (
                            <SkeletonLoading key={i} shape="rectangular" text={true} width="100%" picture={true} />
                        ))}

                        {data?.data?.collected?.map((item: any, i: number) => (
                            <Card data={item} key={item?.data?._id} />
                        ))}
                    </Grid>
                    {collectedLength === 0 &&
                        <div className={classes.noItemCard}>
                            <p>You don’t have any NFTs yet.</p>
                            <p>Get started by collecting your first artworks</p>
                            <Link to="/ExploreNFTs">Explore</Link>
                        </div>
                    }

                </TabPanel>
                <TabPanel value={3}>
                    <Grid className={classes.cardContainer}>
                        {isLoading && [...Array(RepeatSkeleton)]?.map((i) => (
                            <SkeletonLoading key={i} shape="rectangular" text={true} width="100%" picture={true} />
                        ))}

                        {data?.data?.split?.map((item: any, i: number) => (
                            <Card data={item} key={item?.data?._id} />
                        ))}
                    </Grid>
                    {splitLength === 0 &&
                        <div className={classes.noItemCard}>
                            <p>You don’t have any NFTs yet.</p>
                            <p>Get started by collecting your first artworks</p>
                            <Link to="/ExploreNFTs">Explore</Link>
                        </div>
                    }
                </TabPanel>
            </TabsUnstyled>
        </Box >
    )
}



const CardCreateCollection = (props: any): JSX.Element => {
    const [open, setOpen] = useState(false);
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const handleClickOpen = () => {
        setOpen(true);
        dispatch(ChangeWeb3State({ payload: '' }))
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(ChangeWeb3State({ payload: '' }))
    };
    return (
        <>
            <div style={{
                minHeight: '300px',
                marginBottom: '20px',
                height: 'auto'
            }}>
                <a className={classes.CreateCollection} onClick={handleClickOpen}>
                    <AddCircleOutlineIcon fontSize='inherit' />
                    <p>Create New Collection</p>
                </a>
            </div>
            <CreateCollections open={open} setOpen={handleClose} />
        </>
    )
}