import { InputAdornment, TextField } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useStyles } from './StyleContactUs'

interface formData {
    name: string,
    email: string,
    message: string,
}
function ContactUs(): JSX.Element {
    const classes = useStyles()
    const { handleSubmit, register, formState: { errors } } = useForm<formData>()

    const SubmitForm: SubmitHandler<formData> = (data: formData): void => {
        console.log('data', data)
    }
    return (
        <div className={classes.contactUs}>
            <div>
                <p className={classes.title}>Get In Touch</p>
                <form onSubmit={handleSubmit(SubmitForm)}>
                    <TextField
                        {...register("name", {
                            required: 'Name is required',
                            minLength: {
                                value: 3,
                                message: "Name must be atleast 3 characters"
                            }
                        })}
                        fullWidth
                        sx={{ m: 1, width: '25ch' }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Name: </InputAdornment>,
                        }}
                    />
                    {errors.name && <p className={classes.errorText}>{errors.name.message}</p>}
                    <TextField
                        fullWidth
                        {...register("email", {
                            required: 'Email is required',
                        })}
                        sx={{ m: 1, width: '25ch' }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">E-Mail: </InputAdornment>,
                        }}
                    />
                    {errors.email && <p className={classes.errorText}>{errors.email.message}</p>}
                    <TextField
                        fullWidth
                        {...register("message", {
                            required: 'Message is required'
                        })}
                        sx={{ m: 1, width: '25ch' }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Message: </InputAdornment>,
                        }}
                    />
                    {errors.message && <p className={classes.errorText}>{errors.message.message}</p>}
                    <input type="submit" className={classes.submitBtn} />
                </form>
            </div>
        </div>
    )
}

export default ContactUs