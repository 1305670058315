import { makeStyles } from "@material-ui/core";


export const useStyle = makeStyles(theme => ({
    footer: {
        marginTop: (props: any) => props.marginTopFooter,
        padding: '30px 0 20px 0',
        backgroundColor: "#1e1e1e",
        boxSizing: 'border-box',
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            paddingBottom: '50px',
        },
        "& form": {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        "& > div:nth-child(1)": {
            width: '80%',
            "&  p": {
                color: '#fff',
                fontSize: '18px',
                fontWeight: 500,
                [theme.breakpoints.down('sm')]: {
                    fontFamily: 'DellaRespiraMedium',
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            "& .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after": {
                borderBottom: 0
            },
            "& .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before": {
                borderBottom: "none"
            },
            "& input:nth-child(1)": {
                width: '400px',
                padding: '12px',
                borderRadius: "10px",
                // border: 'none',
                margin: '5px 0',
                backgroundColor: '#313131',
                color: '#fff',
                [theme.breakpoints.down('md')]: {
                    width: '300px'
                },
                [theme.breakpoints.down('sm')]: {
                    width: '240px',
                    padding: '3px'
                },
            },
            "& button:nth-child(2)": {
                width: '100px',
                padding: '11px',
                margin: '0 20px',
                borderRadius: "10px",
                border: 'none',
                color: '#fff',
                backgroundColor: '#6c6c6c',
                fontFamily: 'inherit',
                fontSize: '18px',
                boxSizing: 'border-box',
                cursor: 'pointer',
                [theme.breakpoints.down('sm')]: {
                    width: '80px',
                    padding: '5px',
                    fontFamily: 'ElMessiriMedium',
                    fontSize: '12px',
                    margin: '0 0 0 10px'
                },
                "&:hover": {
                    backgroundColor: '#fff',
                    color: '#000'
                }
            }
        },

    },
    iconSection: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'none',
            flexDirection: 'column'
        },
    },
    Icons: {
        color: '#fff',
        padding: '0 10px',
        [theme.breakpoints.down('sm')]: {
            width: '20px !important'
        },
    },
    Line: {
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    Line1: {
        color: '#fff',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    LinkSection: {
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'block',
            margin: "0"
        },
        "& span:nth-child(1)": {
            width: '60%',
            color: "#fff",
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            "& p": {
                margin: 0
            },
            "& p > span": {
                color: theme.palette.primary.main,
                fontSize: "22px"
            }
        },
        "& span:nth-child(2)": {
            display: 'flex',
            flexDirection: 'column',
            "& a": {
                textDecoration: 'none',
                color: '#fff',
                margin: '5px',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                fontSize: '18px',
                textAlign: 'right',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '13px',
                    fontFamily: 'Raleway'
                },
            }
        }
    },
    copyright: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#000',
        height: 'auto',
        padding: '5px',
        width: '100%',
        boxSizing: 'border-box',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        "& div": {
            display: 'flex',
            width: '80%',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    },
    AllRightSection: {
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        alignItems: 'center',
        "& > p": {
            fontSize: '12px',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px'
            },
        },
        "& > img": {
            width: '35px',
            height: '35px',
            [theme.breakpoints.down('sm')]: {
                width: '25px',
                height: '25px',
            },
        },
        "& a": {
            margin: '0 0 0 20px',
            textDecoration: 'none',
            color: '#fff',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                margin: "0 0 0 5px"
            },
        }
    }
}))