import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    Report: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        width: '500px',
        "& > div[id='head']": {
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            "& > p:nth-child(1)": {
                textAlign: 'center',
                width: "80%",
                fontFamily: 'ElMessiri',
                fontSize: '20px',
                fontWeight: 600,
                color: theme.palette.primary.main,
                whiteSpace: 'nowrap',
                marginTop: 0,
            },
            "& > p:nth-child(2)": {
                textAlign: 'left',
                fontFamily: 'Raleway',
                fontSize: '16px',
                fontWeight: 500,
                color: "#676767"
            }
        },
        "& > form:nth-child(2)": {
            width: '80%',
            "& > div": {
                width: "100%",
                "& > label": {
                    color: '#000',
                    fontFamily: 'Raleway',
                    fontWeight: 600,
                    fontSize: '16px',
                    marginBottom: '5px',
                    marginTop: '20px'
                },
                "& > div": {
                    "& > label": {
                        fontFamily: 'Raleway',
                        fontWeight: 500,
                        fontSize: "16px",
                        color: '#676767',
                    }
                }
            },
            "& button": {
                backgroundColor: theme.palette.primary.main,
                margin: '40px auto 0',
                display: 'block',
                border: 'none',
                borderRadius: '7px',
                padding: '10px 60px',
                color: '#fff',
                fontFamily: "ElMessiri",
                fontSize: '20px',
                cursor: 'pointer'
            },
            "& button[id='disabled']": {
                backgroundColor: 'rgba(96, 64, 131, 0.3)'
            }
        },
    },
    email: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        "& > div": {
            "& > p": {
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: '16px',
                color: '#676767'
            },
            "& > div": {
                "&.MuiInputBase-formControl": {
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '7px'
                }
            }
        },
        "& > div:nth-child(2)": {
            marginTop: '30px',
        }
    },
    successSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
        "& img": {
            width: '50px',
            marginTop: "30px",
            marginBottom: '20px',
        },
        "& > p": {
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: "16px",
            textAlign: 'center',
            color: '#000',
            margin: "10px",
            whiteSpace: 'nowrap'
        }
    },




    burn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 10px ',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '480px',
        "& > div:nth-child(1)": {
            width: '70%',
            "& > p:nth-child(2)": {
                fontFamily: 'Raleway',
                fontWeight: 500,
                fontSize: '16px',
                color: "#4a4a4a",
                marginTop: '5px'
            }
        },
        "& div:nth-child(2)": {
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            "& img, video": {
                width: '250px',
                height: '240px'
            },
            "& > div": {
                width: '250px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '10px',
                "& > p": {
                    marginTop: '3px',
                    fontFamily: 'Raleway',
                    fontWeight: 500,
                    fontSize: '13px',
                    color: '#4a4a4a',

                }
            },
        },
        "& > div:nth-child(3)": {
            width: '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            "& > button": {
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                width: '250px',
                border: 'none',
                padding: '8px',
                borderRadius: '7px',
                fontSize: '20px',
                fontWeight: 600,
                fontFamily: 'ElMessiri',
                cursor: 'pointer'
            },
            "& > p[id='burnDescription']": {
                fontFamily: 'Raleway',
                fontWeight: 500,
                fontSize: '15px',
                color: '#4a4a4a',
                marginTop: '20px'
            },
            "& > div[id='approving']": {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                "& > img": {
                    width: '71px',
                    verticalAlign: 'middle'
                },
                "& > p": {
                    fontFamily: 'Raleway',
                    fontWeight: 500,
                    fontSize: "16px",
                    color: '#4a4a4a',
                }
            },
            "& > div[id='burning']": {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                "& > img": {
                    width: '71px',
                    verticalAlign: 'middle'
                },
            },
            "& > p[id='burned']": {
                fontFamily: "Raleway",
                fontWeight: 500,
                fontSize: '16px',
                color: '#4a4a4a',
                "& img": {
                    verticalAlign: 'middle',
                    marginLeft: '2px'
                }
            }
        }
    },
    purpleText: {
        fontFamily: 'ElMessiri',
        fontWeight: 600,
        fontSize: '20px',
        color: theme.palette.primary.main,
        textAlign: 'center',
        marginBottom: "10px"
    },
    transferSectionTwo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: " center",
        "& > form": {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            "& > p": {
                margin: 0,
                fontFamily: 'Raleway',
                fontWeight: 500,
                fontSize: '16px',
                color: '#4a4a4a'
            },

            "& > div": {
                margin: "10px 0",
                "& > div": {
                    "&.MuiInputBase-formControl": {
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '7px',
                        border: '1px solid #d9d9d9'
                    },
                    "& fieldset": {
                        border: 'none'
                    }
                }
            },
            "& > button": {
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                width: '250px',
                border: 'none',
                padding: '8px',
                borderRadius: '7px',
                fontSize: '20px',
                fontWeight: 600,
                fontFamily: 'ElMessiri',
                cursor: 'pointer',
                margin: "10px auto"
            },
            "& > p[id='transferTransaction']": {
                fontFamily: 'Raleway',
                fontWeight: 500,
                fontSize: '15px',
                color: '#4a4a4a',
                margin: '20px'
            }
        },
        "& > div[id='approving']": {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            "& > img": {
                width: '71px',
                verticalAlign: 'middle'
            },
            "& > p": {
                fontFamily: 'Raleway',
                fontWeight: 500,
                fontSize: "16px",
                color: '#4a4a4a',
            }
        },
        "& > div[id='burning']": {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            "& > img": {
                width: '71px',
                verticalAlign: 'middle'
            },
        },
        "& > p[id='burned']": {
            fontFamily: "Raleway",
            fontWeight: 500,
            fontSize: '16px',
            color: '#4a4a4a',
            textAlign: 'center',
            "& img": {
                verticalAlign: 'middle',
                marginLeft: '2px'
            }
        }
    },
    collaboratorsTooltip: {
        "& ul": {
            "& li": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: 'center',
                width: "350px",
                margin: "5px",
                "& > div": {
                    "& a": {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        textDecoration: 'none',
                        "& > img": {
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            margin: '10px',
                        },
                        "& > p": {
                            fontFamily: 'RalewayMedium',
                            color: "#000",
                            fontSize: '16px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '110px',
                        }
                    }
                },
                "& > p": {
                    fontFamily: 'DellaRespira',
                    fontSize: '13px',
                    color: '#676767'
                },
                "& > button": {
                    width: '90px',
                    boxShadow: '0px 5px 2px rgba(0, 0, 0, 0.25)',
                    borderRadius: '7px',
                    fontFamily: 'ElMessiri',
                    fontSize: '14px',
                    color: '#000',
                    border: "none",
                    cursor: 'pointer',
                    transition: "all 0.2s ease",
                    "&:active": {
                        transform: "translateY(2px)"
                    },
                    "&:disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        cursor: 'none'
                    }
                }
            }
        }

    }
}))