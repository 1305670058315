import React from 'react'
import { useStyles } from './Style404'
import NotFound from '../../Assets/Img/404.jpg'

function PageNotFound() {
    const classes = useStyles()
    return (
        <div className={classes.main}>
            <div>
                <img src={NotFound} alt="404" width="100%" height="auto" />
                <a href='/'>Back to Homepage</a>
            </div>
        </div>
    )
}

export default PageNotFound