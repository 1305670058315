import { MoreVert, Share } from '@mui/icons-material';
import { Button, Divider, IconButton, Stack } from '@mui/material';
import Etherscan from '../../../src/Assets/Img/Etherscan.png';
import fav from '../../../src/Assets/Img/fav.png';
import view from '../../../src/Assets/Img/view.png';
import AuctionPhoto from '../../Assets/Img/defaultAvatar.png';
import ipfs from '../../Assets/Img/ipfs.png';
import BidPopUp from './BidPopUp';
import { useStyles } from './styleBuyAuction';

function BuyInAuction(): JSX.Element {
    const classes = useStyles()
    return (
        <div className={classes.mainAuction}>
            <div className={classes.container}>
                <div className={classes.AucSection1}>
                    <p>Item Name</p>
                    <span>Collection Name</span>
                    <div>
                        <p>Description</p>
                        <Divider />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nemo eum. Nostrum, mollitia commodi praesentium quae et dolorem tenetur itaque dolores non quos eveniet, pariatur officia soluta omnis? Tempora, molestias?</p>
                    </div>
                    <div>
                        <p>Details</p>
                        <Divider />
                        <Stack direction="column" sx={{
                            "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root": {
                                justifyContent: 'flex-start',
                                marginTop: 0,
                                paddingTop: 0
                            }
                        }}>
                            <Button variant="text" startIcon={<img src={Etherscan} width="30px" />}>
                                View on Etherscan
                            </Button>
                            <Button variant="text" startIcon={<img src={ipfs} width="30px" />}>
                                View on IPFS
                            </Button>
                        </Stack>
                    </div>
                    <div className={classes.historySec}>
                        <p>History</p>
                        <Divider />
                        <p>Listed<span>@you</span>test 29/12/2022 - 12:30</p>
                        <p>Canceled<span>@you</span>test 29/12/2022 - 12:30</p>
                    </div>
                </div>
                <div>
                    <img src={AuctionPhoto} alt="AuctionPhoto" width="100%" height="auto" style={{ minWidth: '200px' }} />
                </div>
                <div className={classes.AucSection3}>
                    <span className={classes.buttonsSec3}>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <MoreVert />
                        </IconButton>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <Share />
                        </IconButton>
                    </span>
                    <div>
                        <div>
                            <img src={AuctionPhoto} alt="avatar" />
                            <div>
                                <p>@you</p>
                                <p>Owner</p>
                            </div>
                        </div>
                        <div>
                            <img src={AuctionPhoto} alt="avatar" />
                            <div>
                                <p>@you</p>
                                <p>Owner</p>
                            </div>
                        </div>
                    </div>
                    <Divider sx={{ borderWidth: '1px', borderColor: "rgba(0, 0, 0, 0.3)", margin: "7px 0" }} />
                    <div>
                        <div>
                            <img src={fav} alt="favorite" width="30px" />
                            <div>
                                <p>0</p>
                                <p>Favorite</p>
                            </div>
                        </div>
                        <div>
                            <img src={view} alt="views" width="30px" />
                            <div>
                                <p>0</p>
                                <p>Views</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.SaleButtons}>
                        <div id="AuctionEnd">
                            <div>
                                <p>Current Bid</p>
                                <p>1.5 ETH</p>
                                <div className={classes.Bidby}>
                                    <img src={AuctionPhoto} alt="avatar" />
                                    <span>@you</span>
                                </div>
                            </div>
                            <div >
                                <p>Auction Ends In</p>
                                <div className={classes.time}>
                                    <span>
                                        <p>11</p>
                                        <p>hours</p>
                                    </span>
                                    <span>
                                        <p>15</p>
                                        <p>minutes</p>
                                    </span>
                                    <span>
                                        <p>45</p>
                                        <p>seconds</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <BidPopUp />
                    </div>
                    <Divider sx={{ marginTop: "20px" }} />
                    <span className={classes.bidInfo}>
                        <ul>
                            <li>Max Price 13 ETH</li>
                            <li>Min Price 3 ETH</li>
                        </ul>
                        <ul>
                            <li>Total Sales 3</li>
                            <li>Date Of Birth 13/10/2022</li>
                        </ul>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default BuyInAuction