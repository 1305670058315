import { put, takeLatest } from "redux-saga/effects"
import { ProfileHeaderFailed, ProfileHeaderSuccess } from "../../redux/Actions"
import { store } from "../../redux/Store"
import * as types from '../../redux/Types'
import API from "../../Utils/Api"
import ENDPOINTS from "../../Utils/Endpoints"

function* ProfileHeaderWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.get(ENDPOINTS.HEADERPROFILE)

        } catch (e: any) {
            console.error('e-MintToBackendWorker', e)
            store.dispatch(ProfileHeaderFailed({ payload: e }))
        }
    }
    yield Api()
    if (Response?.success) {
        yield put(ProfileHeaderSuccess({ payload: Response }))
    } else {
        yield put(ProfileHeaderFailed({ payload: Response }))
    }
}


export function* ProfileHeaderWatcher() {
    yield takeLatest(types.PROFILE_HEADER_REQUEST, ProfileHeaderWorker)
}
