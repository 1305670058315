import React from 'react'
import { convertToRaw, CompositeDecorator, Editor, EditorState, RichUtils } from 'draft-js'
class LinkEditor extends React.Component {
    constructor(props) {
        super(props);

        const decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link,
            },
        ]);

        this.state = {
            editorState: EditorState.createEmpty(decorator),
            showURLInput: false,
            urlValue: '',
        };

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => this.setState({ editorState });
        this.logState = () => {
            const content = this.state.editorState.getCurrentContent();
        };

        this.promptForLink = this._promptForLink.bind(this);
        this.onURLChange = (e) => this.setState({ urlValue: e.target.value });
        this.confirmLink = this._confirmLink.bind(this);
        this.onLinkInputKeyDown = this._onLinkInputKeyDown.bind(this);
        this.removeLink = this._removeLink.bind(this);
    }

    _promptForLink(e) {
        e.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }

            this.setState({
                showURLInput: true,
                urlValue: url,
            }, () => {
                setTimeout(() => this.refs.url.focus(), 0);
            });
        }
    }

    _confirmLink(e) {
        e.preventDefault();
        const { editorState, urlValue } = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: urlValue }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        this.setState({
            editorState: RichUtils.toggleLink(
                newEditorState,
                newEditorState.getSelection(),
                entityKey
            ),
            showURLInput: false,
            urlValue: '',
        }, () => {
            setTimeout(() => this.refs.editor.focus(), 0);
        });
    }

    _onLinkInputKeyDown(e) {
        if (e.which === 13) {
            this._confirmLink(e);
        }
    }

    _removeLink(e) {
        e.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            this.setState({
                editorState: RichUtils.toggleLink(editorState, selection, null),
            });
        }
    }

    render() {
        let urlInput;
        if (this.state.showURLInput) {
            urlInput =
                <div style={styles.urlInputContainer}>
                    <input
                        onChange={this.onURLChange}
                        ref="url"
                        style={styles.urlInput}
                        type="text"
                        value={this.state.urlValue}
                        onKeyDown={this.onLinkInputKeyDown}
                    />
                    <button onMouseDown={this.confirmLink}>
                        Confirm
                    </button>
                </div>;
        }

        return (
            <div style={styles.root}>
                <div style={{ marginBottom: 10 }}>
                    Select some text, then use the buttons to add or remove links
                    on the selected text.
                </div>
                <div style={styles.buttons}>
                    <button
                        onMouseDown={this.promptForLink}
                        style={{ marginRight: 10 }}>
                        Add Link
                    </button>
                    <button onMouseDown={this.removeLink}>
                        Remove Link
                    </button>
                </div>
                {urlInput}
                <div style={styles.editor} onClick={this.focus}>
                    <Editor
                        editorState={this.state.editorState}
                        onChange={this.onChange}
                        placeholder="Enter some text..."
                        ref="editor"
                    />
                </div>
                <input
                    onClick={this.logState}
                    style={styles.button}
                    type="button"
                    value="Log State"
                />
            </div>
        );
    }
}

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}

const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} style={styles.link}>
            {props.children}
        </a>
    );
};

const styles = {
    root: {
        fontFamily: '\'Georgia\', serif',
        padding: 20,
        width: 600,
    },
    buttons: {
        marginBottom: 10,
    },
    urlInputContainer: {
        marginBottom: 10,
    },
    urlInput: {
        fontFamily: '\'Georgia\', serif',
        marginRight: 10,
        padding: 3,
    },
    editor: {
        border: '1px solid #ccc',
        cursor: 'text',
        minHeight: 80,
        padding: 10,
    },
    button: {
        marginTop: 10,
        textAlign: 'center',
    },
    link: {
        color: '#3b5998',
        textDecoration: 'underline',
    },
};

export default LinkEditor;




// import React, { Component } from 'react';

// import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';

// import createToolbarPlugin, {
//     Separator,
// } from '@draft-js-plugins/static-toolbar';
// import {
//     ItalicButton,
//     BoldButton,
//     UnderlineButton,
//     CodeButton,
//     HeadlineOneButton,
//     HeadlineTwoButton,
//     HeadlineThreeButton,
//     UnorderedListButton,
//     OrderedListButton,
//     BlockquoteButton,
//     CodeBlockButton,
// } from '@draft-js-plugins/buttons';
// import editorStyles from './editorStyles.module.css';

// class HeadlinesPicker extends Component {
//     componentDidMount() {
//         setTimeout(() => {
//             window.addEventListener('click', this.onWindowClick);
//         });
//     }

//     componentWillUnmount() {
//         window.removeEventListener('click', this.onWindowClick);
//     }

//     onWindowClick = () =>
//         // Call `onOverrideContent` again with `undefined`
//         // so the toolbar can show its regular content again.
//         this.props.onOverrideContent(undefined);

//     render() {
//         const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
//         return (
//             <div>
//                 {buttons.map((Button, i) => (
//                     // eslint-disable-next-line
//                     <Button key={i} {...this.props} />
//                 ))}
//             </div>
//         );
//     }
// }

// class HeadlinesButton extends Component {
//     onClick = () =>
//         // A button can call `onOverrideContent` to replace the content
//         // of the toolbar. This can be useful for displaying sub
//         // menus or requesting additional information from the user.
//         this.props.onOverrideContent(HeadlinesPicker);

//     render() {
//         return (
//             <div className={editorStyles.headlineButtonWrapper}>
//                 <button onClick={this.onClick} className={editorStyles.headlineButton}>
//                     H
//                 </button>
//             </div>
//         );
//     }
// }

// const toolbarPlugin = createToolbarPlugin();
// const { Toolbar } = toolbarPlugin;
// const plugins = [toolbarPlugin];
// const text =
//     'In this editor a toolbar shows up once you select part of the text …';

// export default class CustomToolbarEditor extends Component {
//     state = {
//         editorState: createEditorStateWithText(text),
//     };

//     onChange = (editorState) => {
//         this.setState({
//             editorState,
//         });
//     };

//     componentDidMount() {
//         // fixing issue with SSR https://github.com/facebook/draft-js/issues/2332#issuecomment-761573306
//         // eslint-disable-next-line react/no-did-mount-set-state
//         this.setState({
//             editorState: createEditorStateWithText(text),
//         });
//     }

//     focus = () => {
//         this.editor.focus();
//     };

//     render() {
//         return (
//             <div>
//                 <div className={editorStyles.editor} onClick={this.focus}>
//                     <Editor
//                         editorState={this.state.editorState}
//                         onChange={this.onChange}
//                         plugins={plugins}
//                         ref={(element) => {
//                             this.editor = element;
//                         }}
//                     />
//                     <Toolbar>
//                         {
//                             // may be use React.Fragment instead of div to improve perfomance after React 16
//                             (externalProps) => (
//                                 <div>
//                                     <BoldButton {...externalProps} />
//                                     <ItalicButton {...externalProps} />
//                                     <UnderlineButton {...externalProps} />
//                                     <CodeButton {...externalProps} />
//                                     <Separator {...externalProps} />
//                                     <HeadlinesButton {...externalProps} />
//                                     <UnorderedListButton {...externalProps} />
//                                     <OrderedListButton {...externalProps} />
//                                     <BlockquoteButton {...externalProps} />
//                                     <CodeBlockButton {...externalProps} />
//                                 </div>
//                             )
//                         }
//                     </Toolbar>
//                 </div>
//             </div>
//         );
//     }
// }