import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    editLayout: {
        display: 'flex',
        flexDirection: 'row',
        "& > div[id='rightSide']": {
            display: 'flex',
            flexDirection: 'column',
            width: "100%",
        },
        "& > aside": {
            backgroundColor: '#000',
            minHeight: '100vh',
            minWidth: '23vw',
            color: "#fff",
            "& > div[id='logoAndTitle']": {
                "& img[id='logo']": {
                    width: '40px',
                    display: 'block',
                    margin: '0 auto',
                    marginTop: '20px',
                },
                "& > p[id='title']": {
                    color: '#fff',
                    fontFamily: 'ElMessiriBold',
                    fontSize: '30px',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '50px'
                }
            }
        },

    }
}))