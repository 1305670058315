import { ClassNames } from '@emotion/react'
import { Button } from '@mui/material'
import React from 'react'
import { theme } from '../../Utils/Themes'
import { useStyles } from './styleNotificaiton'

function Notification(): JSX.Element {
    const classes = useStyles()
    return (
        <div className={classes.main}>
            <div>
                <div>
                    <Button variant="text">Edit Your Email</Button>
                </div>
                <div>
                    <p>Notification</p>
                    <div>
                        <a href="#">@testtest</a>
                        <p>1hour ago</p>
                    </div>
                    <div>
                        <a href="#">@testtest</a>
                        <p>1hour ago</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification