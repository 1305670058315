import { Container } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { JsxElement } from 'typescript'
import { useStyles } from './StyleMarketComponents'

type Props = { children: ReactNode }

function MainMarketLayout({ children }: Props) {
    const classes = useStyles()
    return (
        <Container>
            <div className={classes.mainMarketLayout}>
                {children}
            </div>
        </Container>
    )
}

export default MainMarketLayout