import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    makeSureContainer: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '60px',
        alignItems: 'start-flex',
        marginTop: '180px',
        "& p": {
            fontFamily: 'RalewaySemiBold',
            color: '#101010',
            fontSize: '24px',
            margin: '0',
            marginBottom: "60px"
        },
        "& > div": {
            width: '60%',
            display: 'flex',
            justifyContent: "space-between"
        }
    },
    unlistButtonSec: {
        marginTop: ' 270px'
    },
    confirmSection: {
        marginTop: "150px"
    }
}))