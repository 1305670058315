import React from 'react'
import tosBanner from '../../Assets/Img/tos.jpg'
import { useStyle } from './StyleTermsOfServices'

function TermsOfServices() {
    const classes = useStyle()
    return (
        <div className={classes.tosMain}>
            <img src={tosBanner} alt="tosBanner" width="100%" height="650px" />
            <div className={classes.tosMain}>
                <div>
                    <h3>Welcome</h3>
                    <p>Welcome to Artaniom! Artaniom is a decentralized application and digital art marketplace operating on the Ethereum blockchain. The Artaniom Platform uses specially-developed smart contracts that enable Artists to sell their digital creations to Collectors on a peer-to-peer digital marketplace. Collectors may trade, transfer, sell, or proudly display their collected Artaniom pieces on the Artaniom Marketplace.</p>
                </div>
                <div>
                    <h3>Artaniom Terms of Services</h3>
                    <p>These Terms of Service (“Terms”) constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Artaniom Marketplace, Inc. ("Company", “App”, “we”, “us”, or “our”, and together with “you”, the “Parties” and each individually, a “Party”), through its website, platform, and marketplace located at www.artaniom.io (the “Platform”), subject to the following Terms of Service (as amended from time to time, the “Terms”). By signing up for an account on the Platform or otherwise using or accessing the Platform, you acknowledge that you have read and agree to these Terms. The Privacy Policy and all such additional terms, guidelines, and rules as set forth on the Platform are hereby incorporated by reference into these Terms and expressly agreed to and acknowledged by the User.
                        If you have any questions about the Site or these Terms please contact us at support@Artaniom.io.
                        THESE TERMS SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE OR PLATFORM. BY ACCESSING OR USING THE SITE, SERVICES, OR SMART CONTRACTS YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, YOU ARE PROHIBITED FROM ACCESSING, USING, OR TRANSACTING ON THE SITE OR PLATFORM. YOU FURTHER REPRESENT AND WARRANT THAT YOU ARE OTHERWISE LEGALLY PERMITTED TO USE THE SERVICES IN YOUR JURISDICTION AND THAT THE COMPANY IS NOT LIABLE FOR YOUR COMPLIANCE WITH SUCH APPLICABLE LAWS.
                        You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms. If you do not agree with all of these Terms, then you are expressly prohibited from using the Site and you must discontinue use immediately.
                        Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of these Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms to stay informed of updates. You will be subject to and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms by your continued use of the Site after the date such revised Terms are posted.
                        The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                </div>
                <div>
                    <h3>Definitions</h3>
                    <p>
                        The terms defined below shall have the following meanings between the parties:
                        “Artwork”: refers to the digital image of the original artwork (which could be either a physical artwork or a digital artwork), available within the Service;
                        “Bid”: refers to a bid placed by a User in accordance with the TCS, in order to purchase an Artwork on the Marketplace.
                        “Buyer”: refers to the User acquiring an Artwork on the Marketplace;
                        “Cryptocurrency”: refers to any digital asset designed to work as a medium of exchange and accepted in payment throughout the Services;
                        “Marketplace”: refers to the marketplace published by Artaniom and on which Users can exchange and/or sell Artworks among themselves;
                        “NFT”: refers to the Non-Fungible Token which represents on blockchain the Artwork and certifies that each Artwork is unique and not interchangeable;
                        Seller: refers to each User offering to sell his/her Artwork on the Marketplace;
                        Services: refers to the services accessible on www.artaniom.io to all Users having a User Account;
                        "User": refers to any natural person using the Services or visiting the Marketplace;
                        "User Account": designates a private virtual space that the User may have on the Website and which can be accessed by means of a login and password combination;
                        “User Profile”: refers to the profile of a User that has a User Account, and which can be seen by other Users;
                        "TCS": refers to these Terms and Conditions;
                        "Personal Data": designates all data that directly or indirectly identifies a natural person;
                        “Wallet”: refers to an electronic wallet, which allows you to purchase, store, and engage in transactions using virtual currency;
                        "Website": refers to the website accessible at the URL www. Artaniom.io.
                    </p>
                </div>
                <div>
                    <h3>What is Artaniom:</h3>
                    <p>
                        While Artaniom offers a marketplace for Digital Artwork, it does not buy, sell, or ever take custody or possession of any Digital Artwork. The Platform facilitates User collection of Digital Artwork, but neither Artaniom nor the Platform is custodians of any Digital Artwork. The User understands and acknowledges that the Smart Contracts do not give Artaniom custody, possession, or control of any Digital Artwork or cryptocurrency at any time for the purpose of facilitating transactions on the Platform. You affirm that you are aware and acknowledge that Artaniom is a non-custodial service provider and has designed this Platform to be directly accessible by the Users without any involvement or actions taken by Artaniom or any third party.
                        So, Artaniom is not involved in the actual transaction between buyers and sellers in the marketplace. We are an administrative platform that facilitates transactions between a buyer and a seller. The contract for the sale is directly between buyer and seller.
                        As a marketplace, Artaniom cannot make any representation or guarantee that Creators will achieve any particular outcome as a result of listing their Digital Artwork on Artaniom.
                    </p>
                </div>
                <div>
                    <h3>How can I use Artaniom.io?</h3>
                    <p>
                        Access to the Website is not conditional on the creation of a User Account. Any User can thus visit the Marketplace without creating a User Account. But the creation of a User Account is a necessary step to enjoy the Services offered by Artaniom, i.e. to buy and sell, as well as to make collections.
                    </p>
                </div>
                <div>
                    <h3>1. Accounts</h3>
                    <h4>1-1. Standard Accounts: Any User can create a User Account on the Website to purchase an Artwork </h4>
                    <p>
                        You will not be able to engage in any transactions on the App other than through your web3 compatible browser. The App will only recognize you as a user, and you will only be able to interact with the App if your wallet is connected and unlocked through your MetaMask account. There is no other way to sign up as a user or to interact directly with the App.
                        By creating an account in Artaniom, users can place a bid on NFTs and there is no limit for registering their purchase order in the fixed price and auction market. The only limitation of standard users is that they do not have access to the possibility of generating NFT, and for this, they must have a verified user account.
                        Although standard users cannot mint NFTs, they can sell the NFTs they purchased from verified users, on the secondary market of Artaniom Marketplace.
                        We retain all ownership, title, and intellectual property rights in all usernames on the Site. You acknowledge and agree that we may delete, remove or re-assign your username at any time without prior notice or compensation to you.
                        The User may choose to choose and make a username. If the User chooses not to make a username, her/his User Profile available to other Users will only display her/his public key address.
                        Transactions that take place on the Platform are managed and confirmed via the Ethereum blockchain. The User understands that your Ethereum public address will be made publicly visible whenever you engage in a transaction on the Platform. We neither own nor control MetaMask, Google Chrome, the Ethereum network, or any other third-party site, product, or service that you might access, visit, or use for the purpose of enabling you to use the various features of the Platform. We will not be liable for the acts or omissions of any such third parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other interaction with any such third parties.
                    </p>
                </div>
                <div>
                    <h3>1-2. Verified Accounts: Who can mint NFT in Artaniom?</h3>
                    <p>
                        The Artaniom Protocol is an open-source protocol that is accessible by anyone. Each token minted on the Artaniom Protocol exists on the Ethereum blockchain as a cryptographic token that cannot be copied or replicated and can only be controlled by the person who holds the Ethereum private key that corresponds to the token’s Ethereum address.
                        Each Artwork available for purchase on the Marketplace is created and initially made available on the Marketplace by a User, with the prior authorization of Artaniom.
                        In order to mint an NFT and create an Artwork on the Marketplace, the User must access the “Create NFT” section available from her/his User Account and accept additional terms and conditions. The possibility of minting NFT by a user depends on authentication. This means that only users whose accounts have been approved can access the Mint page. For this purpose, after creating a standard user account on the site, the user needs to contact Artaniom to get the necessary advice and guidance to review him and his works.
                        Like all the users of Web3, we believe in its decentralized nature. So, Identity authentication for NFT minting is not to establish the legitimacy of a customer's identity but to ensure that the user who intends to mint owns the artwork and its image rights.
                        So, By Minting an Item, Artists expressly represent and warrant that their Work is an original creation. Artists are prohibited from Minting Works consisting of unlicensed or unauthorized copyrighted content, including any imagery, design, audio, video, human likeness, or other unoriginal content not created by the Artist, not authorized for use by the Artist, not in the public domain, or otherwise without a valid claim of fair use. Artist can only mint works that they personally created and must refrain from minting works to which copyright ownership is unknown or disputed (i.e., commissioned works or “works made for hire”).                    </p>
                </div>
                <div>
                    <h3>1-3. Account Security</h3>
                    <p>
                        You understand that you are solely responsible for maintaining the security of your account and control over any usernames, private keys, or any other authentications or codes that you use to access the Services. Any unauthorized access to your account could result in the loss or theft of Tokens and/or funds in any linked accounts. You understand and agree that you will not hold us responsible for managing and maintaining the security of your account and your MetaMask, wallet (or other Ethereum wallets and accounts). You further understand and agree that we are not responsible (and you will not hold us responsible) for any unauthorized access to, or use of, your account or your MetaMask wallet (or other Ethereum wallets and accounts).
                        You alone are responsible for monitoring your account. If you notice any unauthorized or suspicious activity in your account, please notify us immediately at report@artaniom.io.
                        Note that the deletion of the User Account has no impact on the ability of the former User to access her/his Wallet, from which she/he can access her/his Artworks.
                    </p>
                </div>
                <div>
                    <h3>Intellectual Property Rights</h3>
                    <p>
                        Buying an Artwork on the Platform does not mean that the User becomes the holder of the Intellectual property rights attached to the Artwork. If the Artwork relates to a physical object the User has no rights of ownership in that physical object.
                        The User is granted a limited, worldwide, non-assignable, non-sublicensable, royalty free license to display the Artwork legally owned and property obtained on the Marketplace. This right includes the right to display or perform the Artwork privately or publicly for the purpose of promoting or sharing the User’s ownership or interest in the Artwork for example on social media platforms, websites, blogs, digital galleries, or for the purpose of sharing, promoting, discussing or commenting on the Artwork.
                        Users have the right to exhibit their Artworks online for a noncommercial purpose, and/or sell and/or transfer their Artworks but cannot make any commercial use of the Artwork including for example by selling copies, selling access to an Artwork, selling derivative works embodying the Artwork, or otherwise commercially exploiting the Artwork.
                        Users shall not nor permit any third party to:
                        modify, distort, mutilate, or perform any other modification on the Artwork;
                        use the Artwork to advertise, market, or sell any products or services;
                        Use the Artwork in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others;
                        Incorporate the Artwork in movies, videos, video games, or any other forms of media for a commercial purpose, except to the limited extent that such use is expressly permitted by these TCS or solely for the User’s personal, non-commercial use;
                        Sell, distribute for commercial gain, or otherwise commercialize merchandise that includes, contains, or consists of the Artwork;
                        Attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Artwork;
                        Attempt to mint, tokenize, or create an additional cryptographic token representing the same Artwork;
                        Falsify, misrepresent, or conceal the authorship of the Artwork.
                        Any subsequent transfer of an Artwork will immediately terminate the former owner’s rights to the Artwork, which will automatically be transferred to the new owner.
                        The User grants Artaniom the exclusive, transferable, sublicensable, free of charge right, for the whole word, to represent and/or reproduce any content that she/he published on the Website.
                        All intellectual property rights attached to any content published on the Website, excluding the content generated by the Users, are the property of Artaniom or its partners and are provided free of charge to Users, within the exclusive framework of the use of the Website. Therefore, any unauthorized use of these contents is unlawful.
                        Artaniom is the database producer of all databases published on the Website. The User is forbidden to extract any qualitatively and/or quantitatively substantial data without prior authorization from Artaniom.
                        Unless otherwise indicated, the Artaniom.io website is our proprietary property and all source code, databases, functionality, software, usernames, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) are owned or controlled by us or licensed to us. For the avoidance of doubt, we do not have any ownership rights or control over Artaniom Protocol or any tokens or content. All of Artaniom Protocol is completely open source. The Content provided on the Artaniom.io website is provided “AS IS” for your information and personal use only. Except as expressly provided in these Terms, no part of the Site and no Content may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                        Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site. We reserve all rights not expressly granted to you in and to the Site and the Content.
                        Each creator or artist that mints content using the Site and/or Artaniom Protocol reserves all intellectual property rights in and to such content unless specifically granted.
                    </p>
                </div>
                <div>
                    <h3>Prohibited Activities:</h3>
                    <p>
                        You may not access or use the Site for any purpose other than that for which we make the Site available.
                        As a user of the Site, you agree not to:
                        1. Use the Site in a manner inconsistent with any applicable laws or regulations, including using the Site to interact with the Artaniom Protocol in a manner that is illegal or otherwise prohibited by law or regulation in any jurisdiction, or that is intended to harass, abuse, intimidate or violate the legal rights or privacy of any person.
                        2. Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
                        3. Circumvent, disable, or otherwise interfere with security-related features of the Site
                        4. Make improper use of our support services or submit false reports of abuse or misconduct.
                        5. Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.
                        6. Attempt to impersonate another user or person or use the username of another user.
                        7. Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site including denial of service attacks.
                        8. Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.
                        9. Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.
                    </p>
                </div>
                <div>
                    <h3>No Investment Advice</h3>
                    <p>
                        The Site is not intended to provide legal, tax, investment, or insurance advice. Nothing on the Site should be construed as an offer to sell, a solicitation of an offer to buy, or a recommendation for any financial instrument by us or any third party. Certain investment planning tools, case studies, demonstrations, sample scenarios, hypotheticals, or similar information available on the Website may provide general investment education based on your input. You are solely responsible for determining whether any investment, investment strategy, or related transaction is appropriate for you based on your investment objectives, financial circumstances, and risk tolerance. You should consult your legal, investment or tax professional regarding your specific situation. We do not make any representations regarding the benefits or results that you or any third party shall receive from the Website or any services offered or referenced therein.
                    </p>
                </div>
                <div>
                    <h3>Site and Content Management</h3>
                    <p>
                        We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise prevent from displaying, all files and content that are illegal or otherwise prohibited by law or regulation in any jurisdiction, or are intended to harass, intimidate or violate the legal rights or privacy of any person; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
                        Notwithstanding the above, we accept no responsibility or liability for our failure to remove or restrict access to any content whatsoever. As a condition of accessing the Site, you accept and agree to view all content generated by other users.                    </p>
                </div>
                <div>
                    <h3>Modification and Interruption</h3>
                    <p>
                        We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
                        We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.
                    </p>
                </div>
                <div>
                    <h3>Limitation of Liability</h3>
                    <p>
                        In no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the Site, even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to the lesser of the amount paid, if any, by you to us or $100.00 USD. Certain US state laws and international laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers or limitations may not apply to you, and you may have additional rights.
                        We are not liable for any failure or delay in performance of our obligations under these Terms arising out of or caused, directly or indirectly, by circumstances beyond our reasonable control including without limitation acts of God; earthquakes; fires; floods; wars; civil or military disturbances; acts of terrorism; sabotage; strikes; epidemics; pandemics, riots; power failures; computer failure and any such circumstances beyond our reasonable control as may cause interruption, loss or malfunction of utility, computer (hardware or software) or telephone communication service; accidents; labor disputes; acts of civil or military authority; governmental actions.
                        Notwithstanding any provision in these Terms to the contrary, no Party shall be liable hereunder for any consequential, special, or punitive damages (including lost profits).
                        You agree, to the fullest extent permitted by law, to limit our liability to you for any and all claims, losses, costs, expenses, or damages of any nature whatsoever caused by or directly or indirectly related to, your failure, in whole or in part, to fulfill your obligations under these Terms.
                    </p>
                </div>
                <div>
                    <h3>Indemnification</h3>
                    <p>
                        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms; (3) any breach of your representations and warranties set forth in these Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                    </p>
                </div>
                <div>
                    <h3>User Data</h3>
                    <p>
                        We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.                    </p>
                </div>
                <div>
                    <h3>Electronic Communications and Transactions and Signatures</h3>
                    <p>
                        Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email, and on the Site, satisfy any legal requirement that such communication be in writing. You hereby agree to the use of electronic signatures, contracts, orders, and other records, and to the electronic delivery of notices, policies, and records of transactions initiated or completed by us or via the Site. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                    </p>
                </div>
                <div>
                    <h3>Miscellaneous</h3>
                    <p>
                        These Terms and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms or use of the Site. You agree that these Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms and the lack of signing by the parties hereto to execute these Terms.                    </p>
                </div>
                <div>
                    <h3>Contact Us</h3>
                    <p>
                        In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at support@artaniom.io
                    </p>
                </div>
            </div>
        </div >
    )
}

export default TermsOfServices