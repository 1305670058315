import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    mainSettleAuction: {
        display: "flex",
        width: '100%',
        marginTop: '150px',
        marginBottom: '150px',
        "& > div": {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '135px',
            "& div[id='rightSide']": {
                flex: '3',
                "& > p": {
                    width: '100%',
                    textAlign: "center",
                    fontFamily: 'ElMessiriBold',
                    fontSize: '32px',
                    color: "#94794F",
                    marginBottom: '100px',
                },
                "& > div": {
                    display: 'flex',
                    columnGap: '150px',
                    flexDirection: 'row',
                    "& > div": {
                        flex: '1 1 50%',
                        width: '150px',
                        "& div:nth-child(1)": {
                            "& a": {
                                fontFamily: 'RalewayBold',
                                fontSize: '26px',
                                color: '#000'
                            }
                        },
                        "& div:nth-child(2)": {
                            fontFamily: 'RalewayMedium',
                            fontSize: '18px',
                            color: '#676767',
                            width: '100%',
                        }

                    }
                },
                "& > a[id='readMore']": {
                    width: '100%',
                    textAlign: "center",
                    fontFamily: 'RalewayMedium',
                    fontSize: '18px',
                    color: "#676767",
                    display: 'block',
                    marginTop: '150px',
                    textDecoration: 'none',
                    "& svg": {
                        verticalAlign: 'middle',
                        width: '15px',
                        marginLeft: '3px'
                    }
                }
            },
            "& div[id='leftSide']": {
                flex: '1.5'
            }
        }
    }
}))
