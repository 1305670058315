import { createTheme } from "@material-ui/core";


export const theme = createTheme({

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: "#94794F",
            dark: '#5c5a5b',
            light: "#676767"
        }
    }

});