import { useStyle } from './style'


type props = {
    Title: string,
    days: number,
    hours: number,
    minutes: number,
    seconds: number
}

function CountDownTimer({ Title, days, hours, minutes, seconds }: props): JSX.Element {
    const classes = useStyle()
    return (
        <div style={{ width: '510px' }} className={classes.counter}>
            <p>{Title}</p>
            <div>
                <p>{days}
                    <span>days</span>
                </p>
                <p>{hours}
                    <span>hours</span>
                </p>
                <p>{minutes}
                    <span>minutes</span>
                </p>
                <p>{seconds}
                    <span>seconds</span>
                </p>
            </div>
        </div>
    )
}

export default CountDownTimer