import { put, takeLatest } from 'redux-saga/effects'
import Web3 from "web3"
import { FailedMintNftFB, FailedNftTypeFB, FetchCollectionFailed, FetchCollectionSuccess, MintSteps, SubmitLazyMintFailed, SubmitLazyMintSuccess, SuccessMintNftFB, SuccessNftTypeFB } from "../../redux/Actions"
import { store } from '../../redux/Store'
import * as types from '../../redux/Types'
import API from "../../Utils/Api"
import ENDPOINTS from "../../Utils/Endpoints"
import { IMint, IReturnResToBackEnd } from "../../Utils/Interfaces"
import REGULAR_COLLECTION from '../../Web3/ABI/regularCollection.json'
import NFT_ABI from '../../Web3/ABI/NFT.json'
import { toast } from 'react-toastify'

function* MintToBackendWorker(action: any) {

    const web3 = new Web3(Web3.givenProvider)

    const MintOnArtaniom = async ({ data }: IMint) => {
        const Hash = data.data.newNft.uri_cid
        const id = data.data.newNft._id
        const address = process.env.REACT_APP_NFT
        const abi: any = NFT_ABI
        const account: any = localStorage.getItem('account')
        const royalty = data.data.newNft.royalty.toString()
        const NFT = new web3.eth.Contract(abi, address)
        let collaborators: any
        let collab: string[] = []
        if (data.data.newNft.collaborators.length !== 0) {
            collaborators = data.data.newNft.collaborators
        }
        if (collaborators && collaborators.length >= 1) {
            collab = collaborators.map((item: any) => {
                const share = item.share.toString()
                return [share, item.address]
            })
        }
        const tx = {
            from: account,
            to: process.env.REACT_APP_NFT,
        }
        await NFT.methods.mintNFT(royalty, Hash, id, collab).send(tx)
            .once('sending', () => store.dispatch(MintSteps({ payload: 1 })))
            .on('transactionHash', () => store.dispatch(MintSteps({ payload: 2 })))
            .on('error', (res: any) => {
                store.dispatch(FailedMintNftFB({ payload: res }))
                toast.error(res?.message)
                console.error('error in mintNft saga artaniom token', res)
            })
            .then((res: any) => {
                store.dispatch(SuccessMintNftFB({ payload: res }))
                store.dispatch(MintSteps({ payload: 3 }))
                toast.success("Your item Minted successfully")
            })
            .catch((res: any) => {
                store.dispatch(FailedMintNftFB({ payload: res }))
                toast.error(res?.massage)
                console.error('error in mintNft saga artaniom token', res)
            })
    }

    const MintOnCollection = async ({ data }: IMint) => {
        const Hash = data.data.newNft.uri_cid
        const id = data.data.newNft._id
        const royalty = data.data.newNft.royalty.toString()

        let collaborators: any
        let collab: string[] = []
        if (data.data.newNft.collaborators.length !== 0) {
            collaborators = data.data.newNft.collaborators
        }
        if (collaborators && collaborators.length >= 1) {
            collab = collaborators.map((item: any) => {
                const share = item.share.toString()
                return [share, item.address]
            })
        }
        const abi: any = REGULAR_COLLECTION
        const account: any = localStorage.getItem('account')
        const NFT = new web3.eth.Contract(abi, action.address)
        const tx = {
            from: account,
            to: action.address,
        }
        await NFT.methods.mintNFT(royalty, Hash, id, collab).send(tx)
            .once('sending', () => store.dispatch(MintSteps({ payload: 1 })))
            .on('transactionHash', () => store.dispatch(MintSteps({ payload: 2 })))
            .on('error', (res: any) => {
                store.dispatch(FailedMintNftFB({ payload: res }))
                toast.error(res?.message)
                console.error('error in mintNft saga collection', res)
            })
            .then((res: any) => {
                store.dispatch(SuccessMintNftFB({ payload: res }))
                store.dispatch(MintSteps({ payload: 3 }))
                toast.success("Your item Minted successfully")
            })
            .catch((res: any) => {
                store.dispatch(FailedMintNftFB({ payload: res }))
                toast.error(res?.message)
                console.error('error in mintNft saga collection', res)
            })
    }

    let Response: any

    async function Api() {
        try {
            store.dispatch(MintSteps({ payload: 1 }))
            Response = await API.post(ENDPOINTS.Mint, action.payload)
        } catch (e: any) {
            console.error('e-MintToBackendWorker', e)
            store.dispatch(FailedMintNftFB({ payload: e }))
            toast.error(e?.message)
        }
    }
    yield Api()
    if (Response?.data?.success === true) {
        if (action.address === '') {
            yield MintOnArtaniom(Response)

        } else {
            yield MintOnCollection(Response)
        }
    } else {
        yield put(FailedMintNftFB({ payload: Response }))
        toast.error(Response?.data?.message)
        console.error(Response)
    }
}


export function* MintToBackendWatcher() {
    yield takeLatest(types.REQUEST_MINT_NFT_FB, MintToBackendWorker)
}






function* GetNftTypeWorker(action: any) {

    let Response: any
    async function api() {
        try {
            Response = await API.get(ENDPOINTS.Mint)
        } catch (e: any) {
            console.error('e-GetNftTypeWorker', e.message)
            store.dispatch(FailedNftTypeFB({ payload: e }))
        }
    }
    yield api()
    if (Response?.data?.success === 200) {
        yield put(SuccessNftTypeFB({ payload: Response }))
    } else {
        yield put(FailedNftTypeFB({ payload: Response }))
    }
}

export function* GetNftTypeWatcher() {
    yield takeLatest(types.GET_NFT_TYPE_FB, GetNftTypeWorker)
}




function* FetchCollectionWorker(action: any) {

    let Response: any
    async function api() {
        try {
            Response = await API.get(ENDPOINTS.GetCollection + "?category_web2_id=" + action.payload)
            return Response.data
        } catch (e: any) {
            console.error('e-GetNftTypeWorker', e.message)
            store.dispatch(FetchCollectionFailed({ payload: e }))
            return Response.data
        }
    }
    yield api()
    if (Response.status === 200) {
        yield put(FetchCollectionSuccess({ payload: Response }))
    } else {
        yield put(FetchCollectionFailed({ payload: Response }))
    }
}

export function* FetchCollectionWatcher() {
    yield takeLatest(types.FETCH_COLLECTION_REQUEST, FetchCollectionWorker)
}



function* LazyMintWorker(action: any) {
    let Response: any
    async function api() {
        try {
            Response = await API.post(ENDPOINTS.lazyMint, action.payload)

        } catch (e: any) {
            console.error('e-GetNftTypeWorker', e.message)
            store.dispatch(SubmitLazyMintFailed({ payload: e }))
            toast.error(e?.message)
            return
        }
    }
    yield api()
    if (Response?.data?.success) {
        yield put(SubmitLazyMintSuccess({ payload: Response }))
        toast.success("Your NFT has been uploaded and ready to be minted")
        return
    } else {
        yield put(SubmitLazyMintFailed({ payload: Response }))
        toast.error(Response?.message)
        return
    }
}

export function* LazyMintWatcher() {
    yield takeLatest(types.LAZY_MINT_REQUEST, LazyMintWorker)
}
