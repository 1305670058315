
export default function Sign() {
    try {

        const token: string | null = localStorage.getItem('HashSignMessage');

        if (!token) return null as any;

        return {
            sign: token
        };
    }

    catch (error) { return null as any };
}