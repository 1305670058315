import Dialog from '@mui/material/Dialog';
import * as React from 'react';

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface PopUpProps {
    open: boolean;
    onClose: () => void;
}

interface Props {
    children: JSX.Element[] | React.ReactNode;
    open: boolean;
    onClose: () => void;
    props?: any
}

export function PopUp({ children, open, onClose, props }: Props) {

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog {...props} open={open} onClose={handleClose} >
            {children}
        </Dialog>
    );
}