import { Container } from '@material-ui/core'
import React from 'react'
import { useStyle } from './StyleBanUsers'
import sign from '../../Assets/Img/sign.svg'

type Props = {
    toggleScreen: () => void
}
function WarnMessage({ toggleScreen }: Props) {

    const classes = useStyle()

    return (
        <div className={classes.main}>
            <div>
                <img src={sign} alt="alert" />
                <p id="acc">Your account has been disabled</p>
                <p id="tos">
                    Your account has been disabled due to activity against our <a href="/TermsOfServices" target="_blank"><em>Terms of Service.</em></a><br />
                    It means you can no longer access Artaniom with your account.
                </p>
                <p>If you think there's been a mistake, you can contact our support team for help.</p>
                <p>While you can not access your collection and NFTs on Artaniom, you still have<br />
                    access to your wallet and NFTs in your wallet through other services.</p>
                <p>You can cancel any active offers and listings you made using Artaniom below.</p>
                <button onClick={toggleScreen}>Cancel listing & offers</button>
            </div>
        </div>
    )
}

export default WarnMessage