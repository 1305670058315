import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, InputAdornment, LinearProgress, makeStyles, Switch, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Creatable from 'react-select/creatable';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ethIcon from '../../Assets/Img/eth.png';
import EthereumLogo from '../../Assets/Img/EthereumLogo.svg';
import CircularLoading from '../../Components/CircularProgress/CircularProgress';
import CreateCollections from '../../Components/CreateCollection/CreateCollection';
import { PopUp } from '../../Components/PopUp/PopUp';
import { ChangeWeb3State, FetchCollectionRequest, GetNftTypeFB, MintSteps, RequestMintNftFB, SubmitLazyMint } from '../../redux/Actions';
import { useAppDispatch } from '../../redux/Hook';
import API from '../../Utils/Api';
import ENDPOINTS from '../../Utils/Endpoints';
import { IMintForm } from '../../Utils/Interfaces';
import { useStyle } from './MintStyle';
import SplitPopup from './SubComponents/SplitPopup';
import TextEditor from './SubComponents/TextEditor/textEditor';
import Loading from '../../Assets/Img/loading.gif'
import styled from '@emotion/styled';

declare var window: any

/**
 * this is mint page here we create new item and functionality is divided in two section
 *  -web3 section function is handled in saga 
 * and web2 is here 
 * -form validating with useform
 * fetching data handled by saga 
 * and in some new feature like fetch ethereum price i used react-query
 * @returns jsx elements
 */

function Mint() {
    const [nftType, setNftType] = useState<string>('')
    const [file, setFile] = useState<File | null>()
    const [collection, setCollection] = useState<any>('');
    const [tag, setTag] = useState<string>('')
    const [contractAddress, setContractAddress] = useState<any>('')
    const [collectionName, setCollectionName] = useState<string>('')
    const [web2Category, setWeb2Category] = useState<string>('')
    const [minPrice, setMinPrice] = useState<number>(0)
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [openCreateCollection, setOpenCreateCollection] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<any>()
    const [descriptionRawText, setDescriptionRawText] = useState<string>('')
    const [royalty, setRoyalty] = useState<number>(0)
    const [createSplit, setCreateSplit] = useState<boolean>(false)
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
    const [Collaborators, setCollaborators] = useState<string[]>([])
    const [collectionType, setCollectionType] = useState<string>('')
    const [categoryOfArtwork, setCategoryOfArtwork] = useState<string[]>([])
    const [tosIsChecked, setTosChecked] = useState<boolean>(true)
    const ownerAddress = localStorage.getItem("account")
    const classes = useStyle()
    const dispatch = useAppDispatch()
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm<IMintForm>({ mode: 'onChange', defaultValues: { terms: false, rights: false } })
    const { lazyMintData, lazyMintError, mintSteps, lazyMintLoading, loadingMint, errorMint, dataMint, Minted, nftTypeData, nftTypeLoading, FetchCollectionData, FetchCollectionLoading } = useSelector((state: any) => state.MintReducer)
    const [open, setOpen] = React.useState(false);

    let urlNftPhoto: any;
    if (file) {
        urlNftPhoto = URL.createObjectURL(file!)
    }

    if (Minted && lazyMintError && open) {
        setOpen(false)
    }
    useEffect(() => {
        dispatch(GetNftTypeFB())
    }, [Minted])

    // useEffect(() => {
    //     if (open && !loadingMint && errorMint) {
    //         setOpen(false)
    //     }
    // },)

    /**
     * @function submithandler - create a new item which dispatch and then saga take the action and calls api 
     * @param IMintForm - all the data which is mentioned in interface
     */



    const SubmitForm: SubmitHandler<IMintForm> = async (data): Promise<void> => {

        if (descriptionRawText.length < 3) {
            toast.warn('Description is required')
            return
        }
        if (file === undefined) {
            toast.warn('Please, select a file!')
            return
        }
        if (collectionName === '') {
            toast.warn('Please, select a collection!')
            return
        }

        if (collectionType === "lazy") {
            setOpen(true)
            const formData = new FormData()
            formData.append('file', file!)
            formData.append('category_web2_id', web2Category)
            formData.append('name', data.name)
            formData.append('royalty', String(royalty))
            formData.append('price', String(price))

            if (Collaborators.length === 0) {
                const owner: any = { share: "100", address: ownerAddress }
                formData.append('_collaborators', JSON.stringify(owner))
            }
            if (Collaborators.length !== 0) {
                formData.append('_collaborators', JSON.stringify(Collaborators))
            }
            formData.append('description', descriptionRawText)
            formData.append('description_ui', description)
            formData.append('tags', JSON.stringify(tag))
            formData.append('category_id', collection == "artaniom" ? '' : collection)
            dispatch(SubmitLazyMint({ payload: formData }))
            return
        }
        if (collectionType === "normal" || "artaniom") {
            setOpen(true)
            const formData = new FormData()
            formData.append('file', file!)
            formData.append('category_web2_id', web2Category)

            formData.append('name', data.name)
            if (Collaborators.length === 0) {
                const ownerAddress = localStorage.getItem("account")
                const owner: any = { share: "100", address: ownerAddress }
                formData.append('_collaborators', JSON.stringify(owner))
            }
            if (Collaborators.length !== 0) {
                formData.append('_collaborators', JSON.stringify(Collaborators))
            }
            formData.append('royalty', String(royalty))
            formData.append('description', descriptionRawText)
            formData.append('description_ui', description)
            formData.append('tags', JSON.stringify(tag))
            formData.append('category_id', collection == "artaniom" ? '' : collection)
            dispatch(RequestMintNftFB({ payload: formData, address: contractAddress }))
        }
    }

    const handleClickOpen = () => {
        setOpenCreateCollection(true);
        dispatch(ChangeWeb3State({ payload: '' }))
    };
    const handleClose = () => {
        setOpenCreateCollection(false);
        dispatch(ChangeWeb3State({ payload: '' }))
    };

    const UploadFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files) {
            const size = e.target.files[0]?.size
            if (size > 5e+7) {
                toast.error('File Must be less than 50MB')
            } else {
                setFile(e.target.files[0])
            }
        }
    }

    function isTypeSelected() {
        if (nftType == '') {
            toast.error('At first, please select a type.')
        } else {
            return
        }
    }

    async function GetCollectionByName(name: string) {

        if (!FetchCollectionLoading) {
            await FetchCollectionData?.data?.data?.collections.map((item: any) => {
                if (item.name === name) {
                    setContractAddress(item.contract_address)
                    setCollectionName(item.name)
                    setCollectionType(item.type)
                    setCollection(item._id)
                }
            })
        }
    }

    const handleCollection = (id: any): void => {
        if (id === "artaniom") {
            setCollectionType('artaniom')
            setCollectionName("artaniom")
            setContractAddress('')
        }
        FetchCollectionData?.data?.data?.collections.map((item: any) => {
            if (item._id == id) {
                setContractAddress(item.contract_address)
                setCollectionName(item.name)
                setCollectionType(item.type)
            }
        })
        setCollection(id);
    };


    const refSelect: React.RefObject<any> = useRef()

    const OncloseDialog = () => {
        if (!loadingMint) {
            setOpen(false)
        }
        if (!loadingMint && Minted) {
            setOpen(false)
            reset()
            setWeb2Category('')
            setTag('')
            setCollection('')
            reset()
            setCollection('')
            setFile(undefined)
            setNftType('')
            refSelect.current.clearValue()
        }
    }

    function fetchCollection(e: SelectChangeEvent<string>) {
        const selectedItem: any = (e.target as HTMLSelectElement).value
        setNftType(selectedItem)
        setWeb2Category(selectedItem)
        dispatch(FetchCollectionRequest({ payload: selectedItem }))
    }

    function handleToggleSplitPopup(): void {
        if (Collaborators.length === 0) {
            setCreateSplit(!createSplit)
            setIsPopupOpen(!isPopupOpen)
        }
        if (Collaborators.length !== 0) {
            setCreateSplit(false)
            setIsPopupOpen(false)
            setCollaborators([])
        }
    }
    function onClosePopup(): void {
        if (Collaborators.length === 0) {
            setCreateSplit(!createSplit)
            setIsPopupOpen(!isPopupOpen)
        }
        if (Collaborators.length !== 0) {
            setCreateSplit(true)
            setIsPopupOpen(false)
        }
    }

    const { data, refetch, isLoading } = useQuery(['EthPrice'], () => {
        return API.get(ENDPOINTS.EthPrice)
    })

    let minimumPrice;
    /** 
     * @function getEthPrice - promise function calls on onkeydown and fetch ethereum price in dollor each time 
     * @param event.target.value in lazy section > price input 
     * @returns set three state to have minimum allowed eth price, 
    */
    async function GetEthPrice(e: React.KeyboardEvent<HTMLInputElement>): Promise<any> {
        const etherValue: number = Number((e.target as HTMLInputElement).value)
        await refetch()
        const ethereumPrice: number = data?.data?.data?.ethUsd
        minimumPrice = 50 / ethereumPrice
        const total = etherValue * ethereumPrice
        const threeDigits: any = total.toFixed(3)
        setEthereumExchange(threeDigits)
    }

    function changePrice(e: React.KeyboardEvent<HTMLInputElement>) {
        const web3 = new Web3(Web3.givenProvider)
        const etherValue: number = Number((e.target as HTMLInputElement).value)
        if (!isNaN(etherValue)) {
            setPrice(Number(web3.utils.toWei(etherValue.toString())))
        }
    }

    return (
        <>
            <div className={classes.FormBox}>
                <div className={classes.MintMain}>
                    <p>Create NFT</p>
                    <p>Mint Single Item On ERC721</p>
                </div>
                <div>
                    <form onSubmit={handleSubmit(SubmitForm)} autoComplete="false">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "baseline" }}>
                            <label className={classes.formInfo} htmlFor="tags">Artwork<span> (Category of artwork that you mint on the Ethereum blockchain)</span>
                            </label>
                            {nftTypeLoading && <CircularLoading size={18} />}
                        </div>
                        <FormControl className={classes.InputPlaceholderStyle} size='small' disabled={nftTypeLoading} >
                            {/* <InputLabel id="demo-simple-select-label">Select a type</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                value={nftType}
                                onChange={fetchCollection}
                                placeholder="test"
                                displayEmpty
                                sx={{ fontFamily: 'DellaRespira' }}
                            >
                                <MenuItem value='' ><span className={classes.placeholderSelect}>Select a type</span></MenuItem>
                                {nftTypeData && nftTypeData.data?.data?.category_web2.map((type: any) => (
                                    <MenuItem sx={{ fontFamily: 'DellaRespira' }} onClick={() => setCategoryOfArtwork(type)} key={type._id} value={type._id}>{type.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "baseline" }}>
                            <label className={classes.formInfo} htmlFor="tags">Collection<span> (You can deploy a smart contract to showcase your NFTs or mint in Artaniom collection)</span>
                            </label>
                            {FetchCollectionLoading && <CircularLoading size={18} />}
                        </div>
                        <FormControl className={classes.InputPlaceholderStyle} size='small'
                            disabled={FetchCollectionLoading}
                        >
                            {/* <InputLabel id="demo-simple-select-label">
                                Select a Collection
                            </InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                value={collection}
                                onChange={(e: any) => handleCollection(e.target.value)}
                                displayEmpty
                                sx={{ fontFamily: 'DellaRespira' }}
                            >
                                <MenuItem sx={{ fontFamily: 'DellaRespira' }} value=''><span className={classes.placeholderSelect}>Select a collection</span></MenuItem>
                                <MenuItem sx={{ fontFamily: 'DellaRespira' }} style={{ padding: '5px 16px', cursor: 'pointer' }} value="#" onClick={handleClickOpen}>+ Create Collection</MenuItem>
                                <MenuItem sx={{ fontFamily: 'DellaRespira' }} value="artaniom">Artaniom's Collection</MenuItem>
                                {FetchCollectionData?.data?.data?.collections?.length >= 1 &&
                                    <ListSubheader sx={{ fontFamily: 'DellaRespira', color: '#A5A4A4' }}>
                                        Standard Collections
                                    </ListSubheader>}
                                {FetchCollectionData && FetchCollectionData?.data?.data?.collections.map((collection: any) => {
                                    if (collection.type === "normal") {
                                        return <MenuItem sx={{ fontFamily: 'DellaRespira', }} key={collection._id} value={collection._id}>{collection.name}</MenuItem>
                                    }
                                })}
                                {FetchCollectionData?.data?.data?.collections?.length >= 1 && <ListSubheader sx={{ fontFamily: 'DellaRespira', color: '#A5A4A4' }}>Lazy Collections</ListSubheader>}
                                {FetchCollectionData && FetchCollectionData?.data?.data?.collections.map((collection: any) => {
                                    if (collection.type === "lazy") {
                                        return <MenuItem sx={{ fontFamily: 'DellaRespira' }} key={collection._id} value={collection._id}>{collection.name}</MenuItem>
                                    }
                                })}

                            </Select>
                        </FormControl>

                        <p className={classes.formInfo}>Upload File<span> (Max Size: 50MB)</span></p>
                        <div className={classes.uploadFile}>
                            <label onClick={isTypeSelected}>
                                {file === undefined &&
                                    <>
                                        <p>JPG, PNG, GIF, SVG, MP4, MP3, WAV, PDF, GLB, gITF, WebP</p>
                                        <a>Choose File</a>
                                    </>
                                }
                                <input accept="image/*, video/*, .pdf" disabled={nftType === '' ? true : false} id="upload" type="file" onChange={UploadFile} style={{ display: 'none' }} />
                            </label>
                            <p>{file && file.name}</p>
                            {file !== undefined && file !== null &&
                                <div className={classes.NFTImagePreview}>
                                    {
                                        file.type.includes("video") ?
                                            <video width="100%" height="auto" autoPlay controls controlsList='nodownload' >
                                                <source src={URL.createObjectURL(file)} type="video/mp4"></source>
                                            </video>
                                            :
                                            <img src={URL.createObjectURL(file)} alt="Mint-photo" />
                                    }
                                    <IconButton className={classes.deleteNFTImagePreview} onClick={() => setFile(undefined)}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            }
                        </div>

                        <div className={classes.inputSection}>
                            <label className={classes.formInfo} htmlFor="name">Name<span> (Choose a unique name for your NFT)</span></label>
                            <TextField
                                size="small"
                                type="text"
                                id="name"
                                value={name}
                                className={classes.inputText}
                                label={errors?.name?.message}
                                helperText={`${name.length}/50`}
                                {...register("name", {
                                    required: 'Name is required',
                                    maxLength: {
                                        value: 50,
                                        message: 'Name must be maximum 50 characters'
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9$@$!%*?&#^-_. +-]+$/,
                                        message: 'Please Type in English',
                                    },
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)
                                })}
                                inputProps={{
                                    classes: {

                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                                error={Boolean(errors?.name?.message)}
                            />
                        </div>
                        <div className={classes.inputSection}>
                            <label className={classes.formInfo2} htmlFor="description">Description<span> (Describe your NFT in details, for example if it’s a digital version of a physical artwork, mention artist name, size & ... )</span></label>
                            <TextEditor setDescription={setDescription} setDescriptionRawText={setDescriptionRawText} maxLength={1500} />
                        </div>
                        <div className={classes.inputSection}>
                            <label className={classes.formInfo} htmlFor="name">Royalties<span> (Percentage that you will recieve for every sale in secondary market)</span></label>
                            <TextField
                                size="small"
                                type="number"
                                id="royalty"
                                placeholder="Suggested: 0%, 5%, 10%, Maximum is 20%."
                                label={errors?.royalty?.message}
                                className={classes.TextFieldPlaceholderStyle}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                                {...register("royalty", {
                                    required: 'Royalty is required',
                                    min: {
                                        value: 0,
                                        message: 'Royalty must be at least 0'
                                    },
                                    max: {
                                        value: 20,
                                        message: 'The maximum royalty fee amount is 20%'
                                    },
                                    onChange: (e: React.ChangeEvent<any>) => setRoyalty(e.target.value)
                                })}
                                error={Boolean(errors?.royalty?.message)}
                            />
                        </div>
                        <div className={classes.inputSection}>
                            <label className={classes.formInfoWithoutStar} id="tag" htmlFor="tags">Tags<span> (Tags will help other users to find your NFT easier)</span></label>
                            <Creatable
                                className={classes.InputPlaceholderStyle}
                                id="tags"
                                isMulti
                                value={tag}
                                placeholder="Tags"
                                noOptionsMessage={() => "Enter Tags"}
                                //isDisabled={tag.length > 7}
                                isOptionDisabled={() => tag.length > 6}
                                // isValidNewOption={() => tag.length > 6}
                                defaultValue={tag}
                                onChange={(tag: any) => setTag(tag)}
                                backspaceRemovesValue
                                isClearable
                                ref={refSelect}
                            />
                            <p className={classes.CounterInput}>{tag.length}/7</p>
                        </div>
                        <div className={classes.lazySplitSection}>
                            <div className={classes.splitSection}>
                                <div>
                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" row>
                                            <p id="head">Create a Split</p>
                                            <FormControlLabel
                                                control={<Switch checked={createSplit} color="primary" value={createSplit} onChange={handleToggleSplitPopup} />}
                                                label=""
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                        <p>Enable splits to automaticly divide the funds earned<br />
                                            from this NFT with up to five recipients, including<br />
                                            yourself.</p>
                                    </FormControl>
                                </div>
                                <div>
                                    {
                                        Collaborators.length > 1 &&
                                        <>
                                            <div className={classes.editSplitButton}>
                                                <a onClick={() => setIsPopupOpen(true)}>Edit Split</a>
                                            </div>
                                            {
                                                Collaborators.map((test: any) =>
                                                    <div className={classes.collaboratorSection}>
                                                        <div>
                                                            <div>
                                                                <img src={test.avatar} alt="collaborator" />
                                                                <div>
                                                                    <p>{test.username}</p>
                                                                    <p>{test.address}</p>
                                                                </div>

                                                            </div>
                                                            <div>
                                                                <p>{test.share}%</p>
                                                            </div>
                                                        </div>
                                                        <Box sx={{ width: '100%' }}>
                                                            <LinearProgress variant="determinate" value={test.share} />
                                                        </Box>
                                                    </div>
                                                )}
                                        </>
                                    }
                                </div>
                            </div>
                            {
                                collectionType === "lazy" &&
                                <div className={classes.lazySection}>
                                    <label className={classes.formInfoLazy} htmlFor="lazyPrice">Set a List Price<span> (Fixed Price)</span></label>
                                    <div>
                                        <span className={classes.Eth}>
                                            <img src={ethIcon} width="30px" alt="ethIcon" />
                                            ETH
                                        </span>
                                        <TextField
                                            size="small"
                                            type="number"
                                            id="lazyPrice"
                                            label={errors?.price?.message}
                                            InputProps={{
                                                inputProps: {
                                                    step: 0.01
                                                },
                                                endAdornment: <InputAdornment sx={{ width: '80px' }} position="start">{ethereumExchange}$</InputAdornment>,
                                            }}
                                            {...register("price", {
                                                min: { value: 0.05, message: `Price must be at least 0.05 ETH` },
                                                required: { value: true, message: 'Enter a value' },
                                                onChange: (e: any) => changePrice(e)
                                            })}
                                            error={Boolean(errors?.price?.message)}
                                            onKeyDown={GetEthPrice}
                                        />
                                    </div>
                                    <p>Artaniom commission fee in primary market is 10%</p>
                                </div>
                            }

                        </div>

                        <div className={classes.radioButtonSec}>
                            <p>Note, the process of minting an NFT is an irreversible process, make sure all the above details are right.</p>
                            <FormGroup >
                                <FormControlLabel
                                    control={
                                        <Controller control={control}
                                            {...register("rights", {
                                                required: 'Required',
                                            })}
                                            name='rights' render={({ field: { value, ...field } }) => (
                                                <Checkbox sx={{
                                                    "& svg": {
                                                        color: "#000"
                                                    }
                                                }} defaultChecked={false} {...field} checked={!!value} />
                                            )} />}
                                    label={
                                        <>
                                            <p>I approve that I'm the owner or have the rights of publication and sale.</p>
                                            {errors.rights && <p className={classes.errorText}>{errors.rights.message}</p>}
                                        </>
                                    } />

                                <FormControlLabel
                                    control={
                                        <Controller control={control}
                                            {...register("terms", {
                                                required: 'Required',
                                            })}
                                            name='terms'

                                            render={({ field: { value, ...field } }) => (
                                                <Checkbox
                                                    sx={{
                                                        "& svg": {
                                                            color: "#000"
                                                        }
                                                    }}
                                                    defaultChecked={false}
                                                    {...field}
                                                    checked={!!value}
                                                />
                                            )} />
                                    } label={<p>I approve Artaniom's <a href="/TermsOfServices" target="_blank">Terms & Conditions</a>.
                                        {errors.terms && <p className={classes.errorText}>{errors.terms.message}</p>}
                                    </p>} />

                            </FormGroup>
                        </div>
                        <div className={classes.submitButton}>
                            <input type="submit" value='Create' />
                        </div>
                    </form>


                    <PopUp open={isPopupOpen} onClose={onClosePopup}>
                        <SplitPopup onClose={handleToggleSplitPopup} Collaborators={Collaborators} setCreateSplit={setCreateSplit} setIsPopupOpen={setIsPopupOpen} setCollaborators={setCollaborators} />
                    </PopUp>


                    <CreateCollections open={openCreateCollection} setOpen={handleClose} categoryOfArtwork={categoryOfArtwork}
                        setNftType={setNftType}
                        setWeb2Category={setWeb2Category}
                        GetCollectionByName={GetCollectionByName}
                    />
                    <Dialog open={open} onClose={OncloseDialog} disableEscapeKeyDown={true} >
                        {
                            Minted && collectionType === "lazy" ?
                                <div className={classes.MintedSucceed}>
                                    {
                                        lazyMintLoading ?
                                            <>
                                                <img width="80px" src={Loading} alt="loading..." />
                                                <p>Your NFT is being created...</p>
                                            </>
                                            :
                                            <>
                                                <p id="congrats">Congratulations!</p>
                                                <p>Your NFT has been uploaded
                                                    and ready to be minted</p>
                                            </>
                                    }
                                    {
                                        urlNftPhoto && file !== undefined && file?.type.includes("video") ?
                                            <video width="100%" height="auto" autoPlay >
                                                <source src={urlNftPhoto} type="video/mp4"></source>
                                            </video>
                                            :
                                            <img src={urlNftPhoto} alt="Mint-photo" width="300px" />
                                    }
                                    {
                                        !lazyMintLoading && <Link to={`${"/" + ownerAddress + "/" + collectionName + "/" + lazyMintData?.data?.data?.newNft?._id}`}>Visit Your NFT</Link>
                                    }
                                </div>
                                :

                                <div>
                                    {mintSteps === 3 &&
                                        <div className={classes.MintedSucceed}>
                                            <p id="congrats">Congratulations!</p>
                                            <p>Your NFT has been Minted!</p>

                                            {
                                                urlNftPhoto && file !== undefined && file?.type.includes("video") ?
                                                    <video width="100%" height="auto" autoPlay >
                                                        <source src={urlNftPhoto} type="video/mp4"></source>
                                                    </video>
                                                    :
                                                    <img src={urlNftPhoto} alt="Mint-photo" width="300px" />
                                            }
                                            {
                                                contractAddress !== ''
                                                    ?
                                                    <Link to={`${"/" + ownerAddress + "/" + collectionName + "/" + dataMint?.events?.Transfer?.returnValues?.tokenId}`}>View Your NFT</Link>
                                                    :
                                                    <Link to={`${"/" + ownerAddress + "/" + dataMint?.events?.Transfer?.returnValues?.tokenId}`}>View Your NFT</Link>
                                            }
                                        </div>
                                    }
                                    {
                                        mintSteps <= 2 &&
                                        <div>
                                            <div className={classes.dialog}>
                                                {mintSteps === 1 ?
                                                    <p>Waiting for confirmation...</p>
                                                    :
                                                    <p>Your NFT is being minted...</p>
                                                }


                                                {
                                                    urlNftPhoto && file !== undefined && file?.type.includes("video") ?
                                                        <video width="100%" height="auto" autoPlay >
                                                            <source src={urlNftPhoto} type="video/mp4"></source>
                                                        </video>
                                                        :
                                                        <img src={urlNftPhoto} alt="Mint-photo" width="300px" />
                                                }

                                                {mintSteps === 1 ?

                                                    <p> <img src={EthereumLogo} />
                                                        Confirm this transaction in your wallet...
                                                    </p>
                                                    :
                                                    <p> <img src={EthereumLogo} />
                                                        Your artwork is being minted as an
                                                        NFT on the Ethereum Blockchain.
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                        }
                    </Dialog>
                </div>
            </div >
        </>
    )
}

export default Mint
