import React from 'react'
import { useStyle } from './styles'
import logo from '../../Assets/Img/MainLogo.png'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'

function EditLayout({ children, title }: any): JSX.Element {
    const classes = useStyle()
    return (
        <div className={classes.editLayout}>
            <aside>
                <div id='logoAndTitle'>
                    <Link to="/">
                        <img id="logo" src={logo} alt="logoArtaniom" />
                    </Link>
                    <p id='title'>{title}</p>
                    {children[0]}
                </div>
            </aside>
            <div id='rightSide'>
                <Header />
                <section>{children[1]}</section>
            </div>
        </div>
    )
}

export default EditLayout