import * as types from '../../redux/Types'
import { IInitialState } from '../../Utils/Interfaces'


const initialState = {
    loadingMint: false,
    errorMint: false,
    dataMint: [],
    nftTypeData: [],
    nftTypeError: false,
    nftTypeLoading: false,
    Minted: false,
    FetchCollectionData: [],
    FetchCollectionLoading: false,
    FetchCollectionError: false,
    lazyMintData: [],
    lazyMintError: false,
    lazyMintLoading: false,
    mintSteps: 1,
}

export default function MintReducer(state: IInitialState = initialState, action: any) {
    switch (action.type) {
        case types.LAZY_MINT_REQUEST:
            return {
                ...state,
                lazyMintData: action.payload,
                lazyMintError: false,
                lazyMintLoading: true,
                Minted: true,
            }
        case types.LAZY_MINT_SUCCESS:
            return {
                ...state,
                lazyMintData: action.payload,
                lazyMintError: false,
                lazyMintLoading: false,
                Minted: true,
            }
        case types.LAZY_MINT_FAILED:
            return {
                ...state,
                lazyMintData: action.payload,
                lazyMintError: true,
                lazyMintLoading: false,
                Minted: true,
            }
        case types.GET_NFT_TYPE_FB:
            return {
                ...state,
                nftTypeLoading: true,
                nftTypeError: false,
                nftTypeData: null
            }
        case types.SUCCESS_NFT_TYPE_FB:
            return {
                ...state,
                nftTypeLoading: false,
                nftTypeError: false,
                nftTypeData: action.payload
            }
        case types.FAILED_NFT_TYPE_FB:
            return {
                ...state,
                nftTypeLoading: false,
                nftTypeError: true,
                nftTypeData: action.payload
            }
        case types.REQUEST_MINT_NFT_FB:
            return {
                ...state,
                loadingMint: true,
                errorMint: false,
                dataMint: null,
                Minted: true,
                mintSteps: 1,
            }
        case types.SUCCESS_MINT_NFT_FB:
            return {
                ...state,
                loadingMint: false,
                errorMint: false,
                dataMint: action.payload,
                Minted: true,
            }
        case types.FAILED_MINT_NFT_FB:
            return {
                ...state,
                loadingMint: false,
                errorMint: true,
                dataMint: action.payload,
                Minted: false,
            }
        case types.FETCH_COLLECTION_REQUEST:
            return {
                ...state,
                FetchCollectionLoading: true,
                FetchCollectionError: false,
                FetchCollectionData: action.payload,
            }
        case types.FETCH_COLLECTION_SUCCESS:
            return {
                ...state,
                FetchCollectionLoading: false,
                FetchCollectionError: false,
                FetchCollectionData: action.payload,
            }
        case types.FETCH_COLLECTION_FAILED:
            return {
                ...state,
                FetchCollectionLoading: false,
                FetchCollectionError: true,
                FetchCollectionData: action.payload,
            }
        case types.MINT_STEPS:
            return {
                ...state,
                Minted: true,
                mintSteps: action.payload,
                loadingMint: false,
                errorMint: false,
            }
        default:
            return state
    }
} 