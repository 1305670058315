import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import CountDownTimer from '../../../Components/CountDownTimer/CountDownTimer'
import { useCountdown } from '../../../Components/Counter/CountDown'
import BidAtLeastPrice from '../../../Components/MarketComponents/BidAtLeatPrice'
import ConfirmingText from '../../../Components/MarketComponents/ConfirmingText'
import DescriptionList from '../../../Components/MarketComponents/DescriptionList'
import InputComponent from '../../../Components/MarketComponents/Input'
import InputWithBackground from '../../../Components/MarketComponents/InputWithBackground'
import MainMarketLayout from '../../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../../Components/MarketComponents/MainTitle'
import PriceLineBox from '../../../Components/MarketComponents/PriceLineBox'
import SubmitButtonGold from '../../../Components/MarketComponents/SubmitButtonGold'
import WalletBalanceWithUi from '../../../Components/MarketComponents/WalletBalanceWithUi'
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'
import { GetNFT } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import { FromWei } from '../../../Utils/Utils'
import { MarketContractInstance } from '../../../Web3/ContractInstances/ContractInstances'

function PlaceBidReservedAuction() {
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")
    const param = useParams()
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm()
    const baseValue: any = FromWei(DataNFT?.data?.data?.nft?.reserveData?.base_value)
    const navigate = useNavigate()
    const web3 = new Web3(Web3.givenProvider)
    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const NftId = DataNFT?.data?.data?.nft?._id
    const account: any = localStorage.getItem('account')
    const royalty: number = DataNFT?.data?.data?.nft?.royalty
    const reservedObj = DataNFT?.data?.data?.nft?.reserveData
    const suggestion = DataNFT?.data?.data?.nft?.listedData?.suggestions[0]

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.id])

    const endTime = DataNFT?.data?.data?.nft?.listedData?.time_end * 1000
    const [daysAucDurationCountDown, hoursAucDurationCountDown, minutesAucDurationCountDown, secondsAucDurationCountDown] = useCountdown(endTime)

    var currentPrice: number = Number(FromWei(DataNFT?.data?.data?.nft?.listedData?.suggestions[0]?.bid))

    let minPrice: any;
    if (currentPrice) {
        minPrice = ((currentPrice * (0.1)) + currentPrice).toFixed(3)
    }

    if (!currentPrice) {
        minPrice = baseValue
    }

    async function PlaceBid(data: any) {
        const toWei: any = web3.utils.toWei(`${price}`)

        const updatePrice = suggestion ? (toWei - suggestion?.bid) : toWei

        await MarketContractInstance.methods.placeBid(COLLECTION_ADDRESS, tokenId).send({ from: account, value: updatePrice })
            .once('sending', () => {
                setSteps(2)
                setFunctionOperationSteps("Confirming...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('transactionHash', () => {
                setSteps(3)
                setFunctionOperationSteps("Finalizing...")
            })
            .then((res: any) => {
                setSteps(4)
                setFunctionOperationSteps("Finalized")
                navigate(param.collection ? `/${param.creator}/${param.collection}/${param.id}` : `/${param.creator}/${param.id}`)
            })
    }

    const List = [
        `This NFT royalty fee is ${royalty} % `,
        "Reserve auction duration is 72h fixed.",
        "Buyers will not be able to bid below reserved price.",
        "Bids placed during an auction cannot be withdrawn.",
        "The new bid must be at least 10% more than the previous one.",
        "When the reserved price is met, The auction automatically begins.",
        "In the reserve auction, the first bidder pays the gas fee for setting up the auction.",
        "When the auction begins, All rules of a reserve auction are similar to a standard auction."
    ]

    return (
        <MainMarketLayout>
            <div>
                <form onSubmit={handleSubmit(PlaceBid)} noValidate>
                    <MainTitle title="Place a Bid" />
                    {
                        reservedObj && reservedObj.suggestions.length !== 0 ?
                            <>
                                <CountDownTimer
                                    Title="Auction ends in"
                                    days={daysAucDurationCountDown}
                                    hours={hoursAucDurationCountDown}
                                    minutes={minutesAucDurationCountDown}
                                    seconds={secondsAucDurationCountDown}
                                />
                                <PriceLineBox title="Starting Price" price={baseValue} />
                            </>
                            :
                            null
                    }
                    <Grid sx={{ marginTop: '50px' }}>
                        <InputWithBackground
                            errors={errors}
                            setPrice={setPrice}
                            register={register}
                            setEthereumExchange={setEthereumExchange}
                            ethereumExchange={ethereumExchange}
                            LoadingNFT={LoadingNFT}
                            minPrice={minPrice}
                        />
                    </Grid>
                    <Grid sx={{ marginTop: '50px' }}>
                        <WalletBalanceWithUi />
                        <BidAtLeastPrice title="Your must bid at least" value={minPrice} />
                    </Grid>
                    {
                        steps == 0 &&
                        <Grid sx={{ marginTop: '80px' }}>
                            {
                                LoadingNFT ?
                                    <SkeletonLoading text={true} picture={false} shape="rectangular" height={400} width='200px' />
                                    :
                                    <SubmitButtonGold type="submit" title="Place a Bid" bgcolor="#94794F" />
                            }
                        </Grid>
                    }
                    {
                        steps > 1 && steps <= 3 &&
                        <>
                            <Grid sx={{ margin: '100px 0' }}>
                                <ConfirmingText text={functionOperationSteps} />
                            </Grid>
                        </>
                    }
                </form>
                {
                    steps == 0 &&
                    <DescriptionList data={List} />
                }
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>
        </MainMarketLayout>
    )
}

export default PlaceBidReservedAuction