
import { makeStyles } from '@material-ui/core/styles';
import { PropaneSharp } from '@mui/icons-material';

export const useStyle = makeStyles(theme => ({
    bannerContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '45px'
    },
    banner: {
        width: '80%',
    },
    bannerTitle: {
        fontSize: '100px',
        fontFamily: 'plexSans',
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontFamily: 'inherit',
        marginBottom: '30px',
        color: "#504f4f",
        fontSize: '14px'
    },
    CardPhoto: {
        width: "100%",
        marginBottom: '30px',
    },


    CardText: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        justifyContent: 'center',
        bottom: 0,
        width: '100%',
        backgroundColor: `rgba(0,0,0,0.6)`,
        color: '#fff',
        padding: '10px',
        boxSizing: 'border-box',
        "& span:nth-child(1)": {
            textAlign: 'center'
        },
        "& span:nth-child(2)": {
            display: 'flex',
            justifyContent: 'space-between'
        },
    },
    homeCardContainer: {
        backgroundColor: '#fff',
        marginBottom: '80px',
        display: "flex",
        flexDirection: 'row',
        padding: '70px 30px 75px 110px',
        boxShadow: '-5px 5px 20px rgba(0, 0, 0, 0.35)',
        columnGap: '40px',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column-reverse',
            padding: '0',
            boxShadow: 'none',
        },
        "& > div:nth-child(1)": {
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            "& > a[id='name']": {
                fontFamily: 'ElMessiriSemiBold',
                color: "#000",
                fontSize: '32px',
                marginBottom: '0',
                textDecoration: 'none',
                [theme.breakpoints.down("sm")]: {
                    display: 'none'
                },
            },
            "& > p[id='symbol']": {
                fontFamily: 'DellaRespiraMedium',
                color: "#676767",
                fontSize: '15px',
                margin: '0',
                marginTop: '-8px',
                [theme.breakpoints.down("sm")]: {
                    display: 'none'
                },
            },
            "& > div[id='desc']": {
                marginTop: '50px',
                [theme.breakpoints.down("sm")]: {
                    order: '2',
                    marginTop: "0"
                },
            },
            "& > div[id='creator']": {
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
                marginBottom: '50px',
                justifyContent: 'space-between',
                [theme.breakpoints.down("sm")]: {
                    order: '1',
                    marginBottom: '0',
                    marginTop: '0'
                },
                "& > div": {
                    display: 'flex',
                    flexDirection: 'row',
                    "& > img": {
                        width: "30px",
                        height: '30px',
                        borderRadius: '50%'
                    },
                    "& > a": {
                        display: 'flex',
                        flexDirection: 'column',
                        fontFamily: 'ElMessiriMedium',
                        color: "#94794f",
                        fontSize: '24px',
                        textDecoration: 'none',
                        alignItems: 'end',
                        "& sub": {
                            fontFamily: 'ElMessiri',
                            color: "#676767",
                            fontSize: '14px',
                            marginTop: '-10px'
                        }
                    }
                },
                "& > p": {
                    fontFamily: 'ElMessiriMedium',
                    color: "#94794f",
                    fontSize: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    "& sub": {
                        fontFamily: 'ElMessiri',
                        color: "#676767",
                        fontSize: '14px',
                        marginTop: '-10px'
                    }
                }
            },
            "& > a[id='discover']": {
                [theme.breakpoints.down("sm")]: {
                    order: '3',
                    marginTop: '20px',
                    width: 'auto'
                },
                width: 'fit-content',
                marginTop: "10px",
                padding: '10px 60px',
                background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%), #373737",
                boxShadow: '0px 5px 2px rgba(103, 103, 103, 0.35)',
                borderRadius: '7px',
                color: "#fff",
                fontFamily: "ElMessiriMedium",
                fontSize: '20px',
                textDecoration: 'none'
            }
        },
        "& > div:nth-child(2)": {
            flex: '2',
            height: "400px",
            "& a[id='name']": {
                display: 'none',
                [theme.breakpoints.down("sm")]: {
                    display: 'block',
                    fontFamily: "ElMessiriSemiBold",
                    fontSize: '18px',
                    color: '#373737',
                    textAlign: 'center',
                    marginBottom: '0',
                    textDecoration: "none"
                },
            }
        }

    },
    slider: {
        "& > div:nth-child(2)": {
            "& > button": {
                backgroundColor: '#94794F !important',
                boxShadow: '0 0 1px 3px #94794f',
                height: '7px',
                width: '7px'
            }
        }
    },
    photoAtag: {
        width: '400px',
        height: '400px',
        "& > img": {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    browseMarket: {
        "& div:nth-child(1)": {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            "& > a[id='button']": {
                fontSize: '17px',
                fontWeight: 700,
                backgroundColor: '#000',
                padding: '10px 35px',
                color: "#fff",
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
                margin: '30px 0 80px 0 ',
                borderRadius: '30px'
            }
            , "& a": {
                textDecoration: 'none',
                fontSize: '20px',
                fontFamily: 'ElMessiriMedium',
                cursor: 'pointer',
                marginBottom: '30px',
                color: theme.palette.primary.main
            }
        },
        "& div:nth-child(2)": {
            display: 'grid',
            width: '100%',
            gridTemplateColumns: 'repeat(3, 1fr)',
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: 'repeat(1, 1fr)',
            },
            "& img": {
                width: "40%",
                height: 'auto',
                marginBottom: '30px'
            },
            "& a": {
                textDecoration: 'none',
                color: '#474747',
                textAlign: 'center',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',

                "& p": {
                    position: "absolute",
                    bottom: 0,
                    textAlign: 'center',
                    fontSize: '18px',
                    marginTop: '30px'
                }
            }
        }
    }
}))


interface ICardProps {
    backgroundColor: string,
    nameColor: string,
    priceColor: string,
    textColor: string,
    ethLogoColor: string,
    auctionTimeColor: string,
}
export const useStyleCard = makeStyles(theme => ({
    lazyCard: {
        width: '100%',
        flexDirection: 'column',
        borderRadius: '0 0 7px 7px',
        boxShadow: '0 4px 3px rgba(0,0,0,0.25)',
        position: 'relative',
        marginBottom: '10px',
        "& > div:nth-child(1)": {
            display: 'none',
            zIndex: '100000',
            "& > div": {
                display: "flex",
                width: '50%',
                padding: '10px',
                flexDirection: 'column',
                background: 'rgba(255, 255, 255, 0.15)',
                borderRadius: '7px',
                boxShadow: '0 4px 24px -1px rgba(0, 0, 0, 0.2)',
                backdropFilter: 'blur(7.5px)',
                webkitBackdropFilter: 'blur(7.5px)',
                marginTop: '17%',
                "& p": {
                    color: '#fff',
                    fontFamily: 'ElMessiri',
                    margin: 0,
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                },
                "& p:nth-child(1)": {
                    fontSize: "28px",
                    fontWeight: 600,
                },
                "& p:nth-child(2)": {
                    fontSize: "24px",
                    fontWeight: 500
                }
            },
            "& div:nth-child(2)": {
                "& a:nth-child(1)": {
                    fontSize: "24px",
                    fontWeight: 600,
                    color: '#fff',
                    fontFamily: 'ElMessiri',
                    margin: 0,
                    textAlign: 'center',
                    cursor: "pointer",
                    textDecoration: 'none',
                    width: '100%',
                    whiteSpace: 'nowrap'
                },
            }
        },
        "& > div:nth-child(2)": {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 10px 0px',
            borderRadius: '0 0 7px 7px',
            minHeight: '500px',
            maxHeight: "550px",
            justifyContent: 'space-between',
            backgroundColor: "#e6e6e6",
            "& .media": {
                width: '100%',
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                "& img": {
                    maxHeight: '400px'
                }
            }
        },
    },
    // Card: (props: any) => ({
    //     backgroundColor: props.backgroundColor
    // }),
    Card: {
        width: 'fit-content',
        flexDirection: 'column',
        borderRadius: '0 0 7px 7px',
        boxShadow: '0 4px 3px rgba(0,0,0,0.25)',
        position: 'relative',
        marginBottom: '10px',
        padding: '0 10px 0px',
        boxSizing: 'border-box',
        "& > div:nth-child(1)": {
            flexDirection: 'column',
            borderRadius: '0 0 7px 7px',
            justifyContent: 'space-between',
            position: 'relative',
            display: 'flex',
            "& > div[id='hover-container']": {
                position: 'relative',
                display: 'flex',
                height: '400px',

                "& > img": {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    aspectRatio: '4/4',
                },
                "&:hover": {
                    "& > div": {
                        display: "flex",
                        height: "100%"
                    }
                },
                "& > div": {
                    display: 'none',
                    position: 'absolute',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '100000',
                    width: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(7.5px)',
                    "& > div": {
                        display: "flex",
                        width: '60%',
                        padding: '10px',
                        flexDirection: 'column',
                        background: 'rgba(255, 255, 255, 0.15)',
                        borderRadius: '7px',
                        marginTop: '12%',
                        "& p": {
                            color: '#fff',
                            fontFamily: 'ElMessiri',
                            margin: 0,
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        },
                        "& p:nth-child(1)": {
                            fontSize: "28px",
                            fontWeight: 600,
                        },
                        "& p:nth-child(2)": {
                            fontSize: "24px",
                            fontWeight: 500
                        }
                    },
                    "& div:nth-child(2)": {
                        "& a:nth-child(1)": {
                            fontSize: "24px",
                            fontWeight: 600,
                            color: '#fff',
                            fontFamily: 'ElMessiri',
                            margin: 0,
                            textAlign: 'center',
                            cursor: "pointer",
                            textDecoration: 'none',
                            width: '100%',
                            whiteSpace: 'nowrap'
                        },
                    }
                }
            }
        }
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: '5px',
        "& > p": {
            textAlign: 'center',
            fontFamily: 'ElMissiri',
            fontWeight: 600,
            fontSize: '20px',
            color: (props: ICardProps) => props.nameColor,
        },
        "& div": {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            "& > div:nth-child(1)": {
                display: 'flex',
                flexDirection: 'column',
                "& p": {
                    margin: "1px 0",
                    textAlign: 'left',
                    fontFamily: 'Raleway',
                    fontSize: "18px",
                    fontWeight: 500,
                    width: '100%',
                    color: (props: ICardProps) => props.textColor
                },
                "&  p:nth-child(2)": {
                    fontWeight: 600,
                    color: (props: ICardProps) => props.priceColor,
                    fontSize: '20px',
                    whiteSpace: 'nowrap',
                    "& span": {
                        marginLeft: '2px',
                        fontSize: '16px',
                        fontWeight: 500,
                        margin: "0 8px 0 0",
                        color: (props: ICardProps) => props.auctionTimeColor
                    }
                },
                "& img[id='eth']": {
                    width: '13px',
                    height: '21px',
                    verticalAlign: 'middle',
                    filter: (props: ICardProps) => props.ethLogoColor
                },
                "& img[id='collection']": {
                    width: '25px',
                    height: '25px',
                    verticalAlign: 'middle',
                    borderRadius: '50%'
                },
            },
            "&  div[id='oneOwner']": {
                display: 'flex',
                flexDirection: 'row',
                alignItems: "flex-end",
                color: (props: ICardProps) => props.textColor,
                fontFamily: 'Raleway',
                fontSize: '18px',
                fontWeight: 600,
                "& > p": {
                    margin: "0 0 2px 0",
                },
                "& > img": {
                    width: '25px',
                    borderRadius: '25px',
                    marginRight: '2px'
                },
                "& > span": {
                    width: '25px',
                    borderRadius: '50%',
                    height: '25px',
                    marginRight: '2px',
                }
            },
            "&  div[id='multipleOwners']": {
                display: 'flex',
                flexDirection: 'row',
                alignItems: "flex-end",
                color: '#676767',
                fontFamily: 'Raleway',
                fontSize: '18px',
                fontWeight: 600,
                "& > span": {
                    width: '25px',
                    borderRadius: '50%',
                    height: '25px',
                    marginRight: '2px',
                },
                "& > img": {
                    width: '25px',
                    borderRadius: '25px',
                    marginLeft: '-5px',
                }
            }
        }
    },
    LazyCardInfo: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        "& p": {
            fontFamily: 'ElMessiri',
            fontWeight: 600,
            fontSize: '18px',
            color: '#000'
        },
        "& > div:nth-child(1)": {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        "& > div:nth-child(2)": {
            "& > p": {
                width: '100%',
                textAlign: 'center'
            }
        }
    }
}))