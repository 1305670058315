import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    contactUs: {
        display: 'flex',
        justifyContent: 'center',
        "& div": {
            width: '80%',
            "& form": {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                "& div": {
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    margin: '10px 0 '
                }
            },
        }
    },
    title: {
        textAlign: 'center',
        fontSize: '40px',
        marginBottom: '60px'
    },
    submitBtn: {
        backgroundColor: '#000',
        color: '#fff',
        border: 'none',
        fontSize: '24px',
        margin: '15px 0',
        padding: '10px 25px',
        borderRadius: '10px'
    },
    errorText: {
        fontFamily: 'plexSansLight',
        marginTop: 0,
        color: "red",
        fontSize: "14px",
        display: 'inline-block'
    }
}))