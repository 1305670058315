import React from 'react'
import { useStyles } from './StyleMarketComponents'
import EthLogo from '../../Assets/Img/EthereumLogo.svg'
import { useQuery } from '@tanstack/react-query'
import API from '../../Utils/Api'
import ENDPOINTS from '../../Utils/Endpoints'

type props = {
    price: number | null,
    title?: string | null
}

function PriceLineBox({ price, title }: props) {
    const classes = useStyles()

    const { data, refetch, isLoading } = useQuery(['EthPrice'], () => {
        return API.get(ENDPOINTS.EthPrice)
    })

    function GetEthPriceInDollor(value: any) {
        const ethereumPrice: number = data?.data?.data?.ethUsd
        let etherValue = value
        const total = etherValue * ethereumPrice
        const threeDigits: any = total.toFixed(3)
        return threeDigits
    }

    return (
        <div className={classes.priceLineBox}>
            <div>
                <p className='title'>{title ? title : 'Price'}</p>
            </div>
            <div>
                <span className="value">{price}</span>
                <span className="symbol">ETH</span>
                {/* <img src={EthLogo} /> */}
                <span className='inDollor'>({GetEthPriceInDollor(price)})</span>
            </div>
        </div>
    )
}

export default PriceLineBox