import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    splitPopup: {
        "& form": {
            width: '600px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: '50px 70px 40px 70px',
            "& > p:first-child": {
                color: theme.palette.primary.main,
                fontFamily: 'ElMessiriBold',
                fontSize: "20px",
            },
            "& p:nth-child(2)": {
                color: "#4A4A4A",
                fontFamily: 'RalewayMedium',
                fontSize: "16px",
                fontWeight: 500,
                marginBottom: '30px'
            },
            "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
                width: '100%',
                marginTop: '10px',
            },
            "& div:nth-child(4)": {
                marginTop: '30px',
                width: '100%',
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'space-between',

            },
            "& #deleteSplitItem": {
                position: 'absolute',
                left: "-35px",
                display: 'none',
            },

            "& #splitItem": {
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                width: '100%',
                margin: '5px',
                "&:hover": {
                    "& #deleteSplitItem": {
                        display: 'flex',
                        alignItems: 'center'
                    },
                },

                "& > div:nth-child(1)": {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    "& img": {
                        width: '30px',
                        height: '30px',
                        marginRight: '5px',
                        borderRadius: "45%",
                    },
                    "& div": {
                        display: 'flex',
                        flexDirection: 'column',

                        "& p": {
                            fontFamily: 'DellaRespira',
                            fontSize: "14px",
                            color: '#000',
                            margin: 0,
                        },
                        "& p:nth-child(1)": {
                            fontSize: '16px',
                            color: "#000"
                        },
                        "& p:nth-child(2)": {
                            fontSize: '12px',
                            color: theme.palette.primary.light
                        }
                    }
                },
                "& div:nth-child(2)": {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    "& p": {
                        margin: 0,
                        fontFamily: 'Raleway'
                    },
                    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                        height: '0.5em'
                    },
                    "& div": {
                        width: '60px',
                        marginTop: 0
                    }
                }
            }
        }

    },
    submitSplit: {
        "&:disabled": {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            cursor: 'default'
        },
        cursor: 'pointer',
        width: '100%',
        border: 'none',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '7px',
        fontFamily: 'Raleway',
        fontSize: "16px",
        lineHeight: '19px',
        padding: '7px 5px',
        fontWeight: 600,
    },
    totalShare: {
        width: '100%',
        "& p": {
            textAlign: 'right',
            color: 'red',
            fontFamily: "Raleway",
        },
        "& p[id='notComplete']": {
            color: 'red',
        },
        "& p[id='complete']": {
            color: 'green',
        }
    },
    splitResult: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        padding: 0,
        "& img": {
            borderRadius: "60px"
        }
    },
    splitResultInfoSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    splitResultName: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        margin: 0,
        padding: 0
    },
    splitResultAddress: {
        fontSize: '12px',
        color: theme.palette.primary.light,
        margin: 0,
        padding: 0
    },
    splitResultAvatar: {
        borderRadius: '80%',
        marginRight: '3px'
    },
    autoCompleteInput: {
        "& input::placeholder": {
            fontFamily: 'DellaRespira !important'
        }
    }
}))