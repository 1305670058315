import React from 'react'
import CardBanUser from './CardBanUser'
import { useStyle } from './StyleBanUsers'

function ActiveItemScreen() {

    const classes = useStyle()

    return (
        <div className={classes.activeItemScreen}>
            <div>
                <p id='head'>Your Offers</p>
                <div>
                    <CardBanUser />
                    {/* <p id='nooffer'>There is no active offer</p> */}
                </div>
            </div>
            <div>
                <p id='head'>Your Listing</p>
                <div>
                    <p>There is listing item</p>
                </div>
            </div>
        </div>
    )
}

export default ActiveItemScreen