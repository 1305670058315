import { AccountCircle, Gavel, NotificationsNone } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Settings from '@mui/icons-material/Settings';
import { Badge } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { GetProfile } from '../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../redux/Hook';
import { useStyle } from './StyleHeader';



export default function AccountMenu(props: any): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { Data } = useAppSelector(state => state.ProfileHeaderReducer)

    const classes = useStyle()
    if (Data?.data) {
        localStorage.setItem("userProfile", JSON.stringify(Data))
    }
    const local: any = localStorage.getItem("userProfile")
    const userProfile: any = JSON.parse(local)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isNotification = userProfile?.data?.data?.numberOfNewNotif

    const navigate = useNavigate()
    const Disconnect = () => {
        localStorage.clear()
        props.setSignLocalStorage(false)
        navigate("/")
    }
    const dispatch = useAppDispatch()
    return (
        <React.Fragment>
            <Box className={classes.menuProfile} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Link id='create' to="/mint">
                    <a >Create</a>
                </Link>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Badge badgeContent={isNotification ? isNotification : null} color="error" anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        >
                            <Avatar sx={{ width: 32, height: 32, marginLeft: '18px' }} src={userProfile?.data?.data?.user?.avatar}>{userProfile?.data?.data?.user?.username?.slice(0, 2)}</Avatar>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.32))',

                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        "& li": {
                            margin: '10px',
                            fontWeight: 600,
                            fontSize: '13px',
                            "& svg:first-child": {
                                marginRight: '8px'
                            },
                            "& a": {
                                textDecoration: 'none',
                                color: '#000',
                                display: "flex",
                                alignItems: 'center',
                                fontSize: '13px'
                            },
                            "& span": {
                                display: 'flex',
                                fontSize: '13px'
                            }
                        }
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem >
                    <Link to="/profile" onClick={() => dispatch(GetProfile({ payload: '' }))}>
                        <AccountCircle fontSize='medium' /> Profile
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to={`/profile/edit/${userProfile?.data?.data?.user?.username ? userProfile?.data?.data?.user?.username : userProfile?.data?.data?.user?.address}`}>
                        <Settings fontSize='medium' /> Setting
                    </Link>
                </MenuItem>



                <Link to={{ pathname: `/profile/edit/${userProfile?.data?.data?.user?.username ? userProfile?.data?.data?.user?.username : userProfile?.data?.data?.user?.address}` }} state={{ value: 'listNotification' }} style={{ textDecoration: 'none', color: "inherit" }}>
                    {
                        isNotification ?
                            <MenuItem sx={{
                                display: 'flex',
                                // justifyContent: 'space-between',
                                alignItems: "center",
                            }} >
                                <Badge overlap="circular" anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }} color="secondary" variant="dot">
                                    <NotificationsIcon fontSize='medium' />
                                </Badge>
                                Notification
                            </MenuItem>
                            :
                            <MenuItem>
                                <span>
                                    <NotificationsNone fontSize='medium' />Notification
                                </span>
                            </MenuItem>
                    }
                </Link>
                <MenuItem onClick={Disconnect}>
                    <Logout fontSize='medium' /> Disconnect
                </MenuItem>
                {/* {
                    isNotification ?
                        <div>
                            <div style={{ margin: '0 10px', padding: '2px 16px', }}>
                                <a style={{ color: '#5d4b87' }}>@testtest</a>
                                <p style={{ color: theme.palette.primary.dark, fontSize: "14px", marginTop: 0 }}>1hour ago</p>
                            </div>
                            <div style={{ margin: '0 10px', padding: '2px 16px', }}>
                                <a style={{ color: "#5d4b87" }}>@testtest</a>
                                <p style={{ color: theme.palette.primary.dark, fontSize: "14px", marginTop: 0 }}>1hour ago</p>
                            </div>
                        </div>
                        :
                        null
                } */}
            </Menu>
        </React.Fragment >
    );
}