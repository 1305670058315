import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import Web3 from "web3";
import { AbiItem } from 'web3-utils';
import API from "./Api";
import ENDPOINTS from "./Endpoints";

export const web3 = new Web3(Web3.givenProvider)
export const account: any = localStorage.getItem('account')

export function ContractInstance(ABI: Object, Address: string | undefined) {
    const ConteactInstance = new web3.eth.Contract(ABI as AbiItem[], Address)
    return ConteactInstance
}


export function PublicKeyReducer(PublicAddress: string): string {
    const ReducedAddress = `${PublicAddress.slice(0, 4) + '...' + PublicAddress.slice(-4)}`
    return ReducedAddress;
}


export function FromWei(wei: string) {
    if (wei === undefined) {
        return null
    } else {
        const result = Number(web3.utils.fromWei(String(wei), 'ether'))
        return result
    }
}


export function ToWei(wei: string) {
    if (wei === undefined) {
        return null
    } else {
        const result = web3.utils.toWei(wei)
        return result
    }
}


export function ConvertTimestampToDate(TimeStamp: number): string {
    const date = new Date(TimeStamp * 1000);
    const fullDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    return fullDate;
}
