import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    main: {
        display: 'grid',
        placeItems: 'center',
        "& div": {
            margin: '100px 0',
            display: 'flex',
            alignItems: "center",
            flexDirection: 'column',
            "& img": {
                width: '100px',
            },
            "& p": {
                fontFamily: 'RalewayMedium',
                color: '#000',
            },
            "& > p[id='acc']": {
                marginTop: ' 30px',
                fontSize: "30px",
                fontFamily: 'ElMessiriSemiBold',
            },
            "& > p[id='tos']": {
                "& > a": {
                    textDecoration: 'none',
                    color: '#000'
                }
            },
            "& > button": {
                padding: '10px 70px',
                background: '#373737',
                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
                borderRadius: '7px',
                fontSize: "24px",
                fontFamily: 'ElMessiriSemiBold',
                color: '#fff',
                border: 'none',
                margin: "40px 0",
                cursor: ' pointer',
                transition: 'all 0.3s ease',
                "&:active": {
                    transform: 'translateY(2px)'
                }
            }
        }
    },
    activeItemScreen: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '80px',
        "& > div": {
            flex: '1 1 50%',
            background: '#e6e6e6',
            padding: '20px',
            "& > p[id='head']": {
                width: '100%',
                textAlign: 'center',
                fontFamily: 'RalewaySemiBold',
                fontSize: '16px',
                color: '#000',
            },
            "& > div": {
                display: 'flex',
                "& >  p[id='nooffer']": {
                    background: 'linear-gradient(88.84deg, rgba(255, 254, 254, 0.4) 3.79%, rgba(92, 92, 92, 0) 99.14%)',
                    boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.2)",
                    borderRadius: '7px',
                    padding: '15px 70px',
                    margin: '120px auto',
                    fontFamily: 'RalewayMedium',
                    fontSize: '18px',
                    color: '#676767',
                }
            }
        }
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        "& > div:nth-child(1)": {
            flex: '1',
            display: 'flex',
            flexDirection: 'row',
            margin: "10px 0",
            "& img": {
                width: '150px',
                height: "150px",
                shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            }
        },
        "& > div:nth-child(2)": {
            flex: '5',
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            padding: '10px',
            width: '400px',
            "& > div[id='firstRow']": {
                display: 'flex',
                flexDirection: 'row',
                "& > div[id='name']": {
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 50%',
                    "& p": {
                        margin: '5px 0',
                        fontFamily: 'RalewayMedium',
                        fontSize: '14px',
                        color: '#676767',
                    },
                    "& div": {
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: 'center',
                        "& img": {
                            width: '18px',
                            height: "18px",
                            borderRadius: '50%',
                            marginRight: '5px'
                        }
                    }
                },
                "& > div[id='offer']": {
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 50%',
                    "& p:nth-child(1)": {
                        margin: '5px 0',
                        fontFamily: 'RalewaySemiBold',
                        fontSize: '15px',
                        color: '#000',
                    },
                    "& p:nth-child(2)": {
                        margin: '5px 0',
                        fontFamily: 'RalewayMedium',
                        fontSize: '12px',
                        color: '#676767',
                    },
                }
            },
            "& div[id='btn']": {
                marginTop: '30px',
                "& > button": {
                    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0385417) 99.99%, rgba(0, 0, 0, 0) 100%), #373737',
                    border: 'none',
                    borderRadius: '7px',
                    boxShadow: '0px 5px 2px #676767',
                    color: '#fff',
                    fontFamily: 'ElMessiriSemiBold',
                    fontSize: '15px',
                    padding: '10px 30px',
                    margin: 'auto',
                    display: 'block'
                }
            }
        }
    }

}))