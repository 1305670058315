import React from 'react'
import { useStyles } from './StyleMarketComponents'

function InputTitle(props: any) {
    const classes = useStyles()
    return (
        <div {...props} className={classes.titleAboveInput}>{props.title}</div>
    )
}

export default InputTitle