import { Grid, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { Backdrop, Box, Button, CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from '@tanstack/react-query';
import jwt from 'jsonwebtoken';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import Logo from '../../Assets/Img/mainLogoHeader.png';
import MetaMask from '../../Assets/Img/metamask.png';
import whiteBanner from '../../Assets/Img/white-banner.png';
import whiteBackground from '../../Assets/Img/white.jpg';
import { ProfileHeaderRequest } from '../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../redux/Hook';
import API from '../../Utils/Api';
import ENDPOINTS from '../../Utils/Endpoints';
import SwitchNetwork from '../../Utils/SwitchNetwork';
import CircularLoading from '../CircularProgress/CircularProgress';
import AccountMenu from './ProfileMenu';
import { useStyle } from './StyleHeader';
import detectEthereumProvider from '@metamask/detect-provider';
import { Provider } from 'react-redux';
import { PopUp } from '../PopUp/PopUp';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { PublicKeyReducer } from '../../Utils/Utils';
import { Close, Dehaze } from '@mui/icons-material';
import { Cross } from 'recharts';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';

declare var window: any
interface Film {
    title: string;
    year: number;
}

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "6A6A6A",
    fontFamily: 'RalewayMedium',
    fontSize: '15px'
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function Header(): JSX.Element {
    const [bgImage, setBgImage] = useState<boolean>(false)
    const [visibleSearchInRes, setVisibleSearchInRes] = useState<boolean>(false)
    const classes = useStyle()
    const location = useLocation()
    const [background, setBackground] = useState<string>('')
    const { GetCollectionData, } = useAppSelector((state) => state.EditCollectionReducer)
    const { DataGetProfile } = useAppSelector(state => state.GetProfileReducer)

    const setLocation = () => {
        if (location.pathname === "/singleCollection") {
            setBgImage(true)
            setBackground(GetCollectionData?.data?.data?.collection?.banner ? GetCollectionData?.data?.data?.collection?.banner : whiteBackground)
        }
        else if (location.pathname.includes("/profile/edit/")) {
            setBgImage(false)
        }
        else if (location.pathname.includes("/profile/")) {
            setBgImage(true)
            setBackground(DataGetProfile?.data?.data?.user?.banner ? DataGetProfile?.data?.data?.user?.banner : whiteBackground)
        }
        else if (location.pathname === "/profile") {
            setBgImage(true)
            setBackground(DataGetProfile?.data?.data?.user?.banner ? DataGetProfile?.data?.data?.user?.banner : whiteBackground)
        }
        else if (location.pathname.includes("/collection/")) {
            setBgImage(true)
            setBackground(GetCollectionData?.data?.data?.collection?.banner ? GetCollectionData?.data?.data?.collection?.banner : whiteBackground)
        }
        else {
            setBgImage(false)
        }
    }
    useEffect(() => {
        setLocation()
    })

    const [list, setList] = useState<any>(null)
    const [timer, setTimer] = useState<any>(null)


    const { data, isLoading, error, mutate, isSuccess } = useMutation(
        async (addToFav: any) => {
            const { data: response } = await API.get(`${ENDPOINTS.Search}?searchItem=${addToFav}`);
            return response.data;
        },
        {
            onSuccess: ((data) => {
                const entries = Object.entries(data)
                const items = entries.map((section: any) => {
                    return section[1].map((item: any) => {
                        if (section[0] === "collections") {
                            return { category: section[0], title: item?.name, img: item?.logo, title2: item?.symbol }
                        }
                        if (section[0] === "items") {
                            return {
                                category: section[0], title: item?.name, img: item?.image_cid, title2: item?.creator, id: item?.tokenId ? item?.tokenId : item?._id,
                                link: item?.category !== '' ? (item?.status === "lazy" ? `/${item?.creator}/${item?.collection?.name}/${item?._id}` : `/${item?.creator}/${item?.collection?.name}/${item?.tokenId}`) : `/${item?.creator}/${item?.tokenId}`
                            }
                        }
                        if (section[0] === "profile") {
                            return { category: section[0], title: item?.name ? item?.name : PublicKeyReducer(item?.address), img: item?.avatar, title2: item?.username }
                        }
                    })
                })
                const flatMap = items.flatMap((item: any) => item)
                setList(flatMap)
            })
        })

    function Search(e: ChangeEvent<HTMLInputElement>) {
        clearTimeout(timer)
        const searchReq = setTimeout(() => { mutate(e.target.value) }, 1000);
        setTimer(searchReq)
    }

    function onErrorBanner() {
        window.document.querySelector("#banner-image").src = whiteBanner
    }

    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const handlePopUp = () => { setOpenPopup(false) }
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate()
    const [artaniomDropdown, setArtaniomDropdown] = React.useState('hiw');

    const handleArtaniomDropDown = (event: SelectChangeEvent) => {
        const selectedItem: string = event.target.value
        setArtaniomDropdown(event.target.value)
        switch (selectedItem) {
            case "aboutArtaniom":
                navigate("/aboutUs")
                return;
            case "hiw":
                return;
            case "faq":
                return;
            case "creating":
                navigate('/applyToJoin')
                return;
            case "collecting":
                return
        }
    };

    const [opens, setOpens] = useState(false);
    const [opt, setOpt] = useState<any>([]);
    const loading = opens && opt.length === 0;

    React.useEffect(() => {
        let active = true;
        if (!isLoading) {
            return undefined;
        }
        return () => {
            active = false;
        };
    }, [isLoading]);

    React.useEffect(() => {
        if (!opens) {
            setList([]);
        }
    }, [opens]);


    function addBodyStyle() {
        document.body.classList.add('bg')
    }
    function removeBodyStyle() {
        document.body.classList.remove('bg')
    }

    const [openPop, setOpenPop] = useState<boolean>(false)
    const [displayForPopup, setDisplayForPopup] = useState<boolean>(false)
    function onClose() {
        setOpenPop(false)
    }

    document.addEventListener('click', function handleClickOutsideBox(event: any) {
        if (displayForPopup) {
            if (event.target.id === "pop") {
                setDisplayForPopup(false)
            }
        }
    });

    return (
        <div className={classes.headerNav}>
            <div>
                <div>
                    {
                        bgImage && <img onError={onErrorBanner} id='banner-image' loading='eager' src={background} alt="banner" width='100%' />
                    }
                </div>
                <div>
                    <Grid xs={6} md={6} item>
                        {
                            !location.pathname.includes('edit') &&
                            <Link to="/">
                                <img src={Logo} alt='artaniom' width='100px' height="auto" />
                            </Link>
                        }
                        <div className={classes.searchBar} >
                            <Autocomplete
                                open={opens}
                                onOpen={() => {
                                    setOpens(true);
                                    addBodyStyle()
                                }}
                                onClose={() => {
                                    setOpens(false);
                                    removeBodyStyle()
                                }}
                                size="small"
                                clearOnEscape={true}
                                isOptionEqualToValue={(option: any, value) => option.title === value.title}
                                getOptionLabel={(option) => option.title}
                                options={list}
                                loading={isLoading}
                                loadingText="Waiting..."
                                noOptionsText="No Options"
                                groupBy={(option) => option.category}
                                renderOption={(props, option) => (
                                    <Box component="li"  {...props}>
                                        <Link className={classes.itemInSearch} to={option.category === "profile" ? `${"/profile/" + option?.title2} ` : option.category === "items" ? `${option.link} ` : `${"/collection/" + option.title} `}>
                                            {
                                                !option.img ?
                                                    <div id={option.category === "profile" ? "avatar" : "logo"} className={classes.ImagePlaceholder}></div>
                                                    :
                                                    <img
                                                        className={classes.imgInItemSearch}
                                                        id={option.category === "profile" ? "avatar" : "logo"}
                                                        loading="lazy"
                                                        src={option.category === "items" ? `${"https://artaniom.art/ipfs/" + option?.img} ` : option?.img}
                                                        alt={option.title}
                                                    />
                                            }
                                            <div>
                                                <p className={classes.titleInItemSearch}> {option.title} </p>
                                                <p className={classes.title2InItemSearch}> {option.title2} </p>
                                            </div>
                                        </Link>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder='Search items, collections and profiles'
                                        {...params}
                                        onChange={Search}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid xs={6} md={6} item>
                        <Link to='/ExploreNFTs'>Marketplace</Link>
                        <a href='/ExploreCollection'>Collection</a>
                        <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.dropDown}>
                            <Select
                                value={artaniomDropdown}
                                onChange={handleArtaniomDropDown}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem className={classes.LinkInDropDown} value="hiw">
                                    <a href={`https://help.artaniom.io/`} target="_blank">How it Works?</a>
                                </MenuItem>
                                <MenuItem value="aboutArtaniom">About Artaniom</MenuItem>
                                <MenuItem value="creating">Creating</MenuItem>
                                <MenuItem className={classes.LinkInDropDown} value="collecting">
                                    <a href={`https://help.artaniom.io/collecting/`} target="_blank">Collecting</a>
                                </MenuItem >
                                <MenuItem className={classes.LinkInDropDown} value="faq">
                                    <a href={`https://help.artaniom.io/faq/`} target="_blank">FAQ</a></MenuItem>
                            </Select >
                        </FormControl >
                        <SimpleDialogDemo />
                    </Grid >
                </div >
            </div >
            <div className={classes.headerResponsive}>
                {
                    visibleSearchInRes ?
                        <div id="search">
                            <Autocomplete
                                open={opens}
                                onOpen={() => {
                                    setOpens(true);
                                    addBodyStyle()
                                }}
                                onClose={() => {
                                    setOpens(false);
                                    removeBodyStyle()
                                }}
                                size="small"
                                clearOnEscape={true}
                                isOptionEqualToValue={(option: any, value) => option.title === value.title}
                                getOptionLabel={(option) => option.title}
                                options={list}
                                loading={isLoading}
                                loadingText="Waiting..."
                                noOptionsText="No Options"
                                groupBy={(option) => option.category}
                                renderOption={(props, option) => (
                                    <Box component="li"  {...props}>
                                        <Link className={classes.itemInSearch} to={option.category === "profile" ? `${"/profile/" + option?.title2} ` : option.category === "items" ? `${option.link} ` : `${"/collection/" + option.title} `}>
                                            {
                                                !option.img ?
                                                    <div id={option.category === "profile" ? "avatar" : "logo"} className={classes.ImagePlaceholder}></div>
                                                    :
                                                    <img
                                                        className={classes.imgInItemSearch}
                                                        id={option.category === "profile" ? "avatar" : "logo"}
                                                        loading="lazy"
                                                        src={option.category === "items" ? `${"https://artaniom.art/ipfs/" + option?.img} ` : option?.img}
                                                        alt={option.title}
                                                    />
                                            }
                                            <div>
                                                <p className={classes.titleInItemSearch}> {option.title} </p>
                                                <p className={classes.title2InItemSearch}> {option.title2} </p>
                                            </div>
                                        </Link>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder='Search items, collections and profiles'
                                        {...params}
                                        onChange={Search}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <IconButton onClick={() => setVisibleSearchInRes(false)} >
                                <Close />
                            </IconButton>
                        </div>
                        :
                        <div id="btn">
                            <Link to="/">
                                <img src={Logo} alt='artaniom' width='100px' height="auto" />
                            </Link>
                            <div id="pop" style={{ display: displayForPopup ? 'flex' : "none" }}>
                                <div id="content_popUp">
                                    <Link to='/ExploreNFTs'>Marketplace</Link>
                                    <Link to="/ExploreCollection">Collection</Link>
                                    <a href={`https://help.artaniom.io/`} target="_blank">How it Works?</a>
                                    {/* <button onClick={() => setOpen(true)}>Connect</button> */}
                                    <a onClick={() => setDisplayForPopup(false)}>
                                        <SimpleDialogDemo />
                                    </a>
                                </div>
                            </div>
                            <div>
                                <PopUp open={openPop} onClose={onClose} >
                                    test
                                </PopUp>
                                <IconButton onClick={() => setVisibleSearchInRes(true)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton id="menu" onClick={() => setDisplayForPopup(true)}>
                                    <Dehaze />
                                </IconButton>
                            </div>
                        </div>
                }
            </div >
        </div >
    )
}

export default Header
export interface ConnectWalletPopupProps {
    open: boolean;
    onClose: () => void;
    setSignLocalStorage: any
}

function ConnectWalletPopup(props: ConnectWalletPopupProps) {
    const { onClose, open, setSignLocalStorage } = props;
    const [alertMeta, setAlertMeta] = useState<boolean>(false)
    const [messageSign, setMessageSign] = useState<boolean>(false)
    const [loaderSign, setLoaderSign] = useState<boolean>(false)
    const acc: any = localStorage.getItem('account')
    const [account, setAccount] = useState<string | null>(acc)
    const [time, setTime] = useState(5)
    const classes = useStyle()
    const { ethereum } = window;
    var web3 = new Web3(Web3.givenProvider)
    const dispatch = useAppDispatch()

    const Disconnected = async () => {
        const accounts = await web3.eth.getAccounts()
        if (account !== null && time === 0) {
            setAccount(null)
            localStorage.clear()
            setSignLocalStorage(false)
            window.location.reload()
        }
    }

    useEffect(() => {
        if (typeof window.ethereum !== 'undefined') {
            time > 0 && setTimeout(() => setTime(time - 1), 1000)
            web3.eth.getAccounts().then(async (result: any) => console.log('result-accounts', result))
            window.ethereum.on('accountsChanged', Disconnected)
            window.ethereum.on('chainChanged', Disconnected)
        }
        else {
            setAlertMeta(true)
        }
        return (() => {
            if (typeof window.ethereum !== 'undefined') {
                ethereum.removeListener('disconnect', Disconnected)
                ethereum.removeListener('chainChanged', Disconnected)
            }
        })
    })

    async function ConnectWalletToMetaMask() {
        const provider = await detectEthereumProvider()
        if (provider) {
            const Account = ethereum.isConnected()
            if (Account === true) {
                try {
                    if (window.ethereum.networkVersion !== process.env.REACT_APP_BLOCKCHAIN_NETWORK) {
                        await SwitchNetwork()
                    }
                    setTime(8)
                    setAccount(null)
                    const ethereumAccount = await ethereum.request({ method: 'eth_requestAccounts' })
                        .then(function (response: string[]) {
                            localStorage.setItem("account", response[0])
                            setAccount(response[0])
                            setMessageSign(true)
                        }).catch((error: any) => {
                            toast.error(error.message)
                            console.error('error', error)
                            setMessageSign(false)
                            onClose()
                        })
                } catch (e: any) {
                    console.error('e connect wallet', e)
                }
            } else {
                setMessageSign(true)
            }
        } else {
            setAlertMeta(true)
        }
    }

    const handleClose = () => {
        onClose()
    };

    const closeAndDisconnect = () => {
        onClose()
        Disconnected()
    };

    const GenerateHashMeassage = async () => {
        setLoaderSign(true)
        const HashedMessage = web3.utils.utf8ToHex("Hello, Welcome to Artaniom In order to do any transaction you need to sign this message: I accept the Artaniom Terms of Service https://artaniom.io/TermsOfServices.")
        web3.eth.personal.sign(HashedMessage, account!, '')
            .then(function (response) {
                let privateKey: any = process.env.REACT_APP_PRIVATE_KEY_SIGNING
                var token = jwt.sign(response!, privateKey, { algorithm: 'HS256' });
                localStorage.setItem("HashSignMessage", token)
                setMessageSign(true)
                setLoaderSign(false)
                dispatch(ProfileHeaderRequest())
                setSignLocalStorage(true)
                onClose()
            })
            .catch((error: any) => {
                console.error('error', error)
                setLoaderSign(false)
            })
    }

    return (
        <Dialog onClose={handleClose} open={open} className={classes.popUp}>
            {
                messageSign && messageSign === true ?
                    <div className={classes.signMsg}>
                        <p >Sign The Message in your Wallet to</p>
                        <p>Artaniom uses this signature to verify <br /> that you're the owner of this Wallet address.</p>
                        <Stack direction="column" className={classes.signMessageSec}>
                            {/* <TextField onChange={SignMessage} placeholder='Message' variant="outlined" size='small'></TextField> */}
                            {
                                loaderSign &&
                                <div id="singning">
                                    Signing
                                    <CircularLoading size={25} />
                                </div>
                            }
                            {
                                !loaderSign &&
                                <div id="buttons">
                                    <button onClick={GenerateHashMeassage}>Continue</button>
                                    <button onClick={closeAndDisconnect}>Disconnect</button>
                                </div>
                            }
                        </Stack>
                    </div>
                    :
                    <div className={classes.selectAWallet}>
                        <p >Get Started</p>
                        <p>Connect Your Wallet</p>
                        <p>Connecting your wallet is like <br />“logging in” to Web3.</p>
                        <Stack direction="column" className={classes.metamaskSec}>
                            <div>
                                {
                                    !alertMeta ?
                                        <>
                                            <a onClick={ConnectWalletToMetaMask}>
                                                <p>MetaMask</p>
                                                <img src={MetaMask} alt="metamask" />
                                            </a>
                                            <p id="tos">By Connecting your Wallet, you agreed to our<br /> <a style={{ textDecoration: 'none', color: '#000', fontWeight: 600 }} href='https://artaniom.io/terms' rel="noreferrer" target="_blank">Terms and Service</a> and Our  <a href='https://artaniom.io/Privacy' rel="noreferrer" style={{ textDecoration: 'none', color: '#000', fontWeight: 600 }} target="_blank">Privacy Policy</a> </p>
                                        </>
                                        :
                                        <>
                                            <a href="https://metamask.io/download/" target="_blank">
                                                <p>Install MetaMask</p>
                                                <img src={MetaMask} alt="metamask" />
                                            </a>
                                            <a href="/" target="_blank" id="setupWallet"> First time setting up a wallet? </a>
                                        </>
                                }
                            </div>
                        </Stack>
                    </div>
            }
        </Dialog >
    );
}
{/* <a href="/">First Time Setting Up a Wallet?</a> */ }

export function SimpleDialogDemo() {
    const [open, setOpen] = useState(false);
    const isLoggedIn = localStorage.getItem("HashSignMessage")
    const [signLocalStorage, setSignLocalStorage] = useState<boolean>(isLoggedIn == null ? false : true)

    const classes = useStyle()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {
                signLocalStorage ?
                    <AccountMenu setSignLocalStorage={setSignLocalStorage} />
                    :
                    <>
                        <a className={classes.connectButton} onClick={handleClickOpen}>Connect Wallet</a>
                        <ConnectWalletPopup
                            open={open}
                            onClose={handleClose}
                            setSignLocalStorage={setSignLocalStorage}
                        />
                    </>
            }
        </div>
    );
}
