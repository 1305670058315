import { Button, Paper } from '@mui/material'
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useStyle } from './CardStyles'
import Carousel from 'react-elastic-carousel';
import { theme } from '../../Utils/Themes';

const dataTest: any = {
    "blocks": [
        {
            "key": "8i090",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 16,
                    "style": "BOLD"
                }
            ],
            "entityRanges": [],
            "data": {}
        },
    ],
    "entityMap": {}
}

function HomeCard(props: any) {
    const classes = useStyle()

    const Description: any = props?.data?.description
    const contentState = convertFromRaw(!Description ? dataTest : JSON.parse(Description))
    const editorState = EditorState.createWithContent(contentState)
    return (
        <div className={classes.homeCardContainer} key={props?.data?.name}>
            <div>
                <a href={`/collection/${props?.data?.name}`} id="name">{props?.data?.name}</a>
                <p id='symbol'>{props?.data?.symbol}</p>
                <div id="desc">
                    <Editor onChange={() => console.log("test Editor")} editorState={editorState} readOnly={true} />
                </div>
                <div id='creator'>
                    <div>
                        <img src={props?.data?.image ? props?.data?.image : props?.data?.ownerData?.avatar} alt="user" />
                        <Link to={`/profile/${props?.data?.ownerData?.username}`}>{props?.data?.ownerData?.name ? props?.data?.ownerData?.name : props?.data?.ownerData?.username}<sub>Creator</sub></Link>
                    </div>
                    <p>{props?.data?.nfts?.length}<sub>NFTs</sub></p>
                </div>
                <Link id="discover" to={`/collection/${props?.data?.name}`}>Discover Now <span>&#8594;</span></Link>
            </div>
            <div>
                <a href={`/collection/${props?.data?.name}`} id="name">{props?.data?.name}</a>
                <Carousel className={classes.slider} showArrows={false} itemPadding={[20, 10]} enableAutoPlay={true} autoPlaySpeed={2500} isRTL itemsToShow={window.innerWidth < 900 ? 1 : 2}>
                    {
                        props?.data?.nfts.map((item: any, i: number) =>
                            <Link className={classes.photoAtag} to={item?.category !== '' ? `/${item?.creator}/${props?.data?.name}/${item?.tokenId}` : `/${item?.creator}/${item?.tokenId}`} >
                                {
                                    item?.typeFile === 'mp4' ?
                                        <video style={{ aspectRatio: '4/3', objectFit: 'contain' }} autoPlay={true} controls controlsList='nodownload' width="100%" >
                                            <source src={item?.image_cid && `${"https://artaniom.art/ipfs/" + item?.image_cid}`} type="video/mp4"></source>
                                        </video>
                                        :
                                        <>
                                            <img key={i} src={item?.image_cid && `${"https://artaniom.art/ipfs/" + item?.image_cid}`} alt="poster" />
                                        </>
                                }
                            </Link>)
                    }
                </Carousel>
            </div>
        </div >
    )
}

export default HomeCard