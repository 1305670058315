declare var window: any;

const SwitchNetwork = async () => {
    const { ethereum } = window;
    try {
        await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: process.env.REACT_APP_BLOCKCHAIN_NETWORK }]
        }).then((res: any) => console.log('res', res)).catch((e: any) => console.log('e', e))
    } catch (addError) {
        console.log(addError);
    }
}

export default SwitchNetwork;