import { Navigate, Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function PrivateRoute(): React.ReactElement {
    const isSigned: any = localStorage.getItem('userProfile')
    const profile = JSON.parse(isSigned)
    const isKYC = profile?.data?.data?.user?.active
    if (!isKYC) {
        toast.warn('you are not registered!')
    }
    return isKYC ? <Outlet /> : <Navigate to="/" />
}


