import React from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from './StyleMarketComponents'

type props = { text: string, to: string }

function BlackLinkButton({ text, to }: props) {
    const classes = useStyles()
    return (
        <Link className={classes.blackLinkButton} to={to}>
            {text}
        </Link>
    )
}

export default BlackLinkButton