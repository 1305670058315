import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import BlackLinkButton from '../../../Components/MarketComponents/BlackLinkButton'
import ConfirmationSection from '../../../Components/MarketComponents/ConfirmationSection'
import ConfirmingText from '../../../Components/MarketComponents/ConfirmingText'
import DescriptionList from '../../../Components/MarketComponents/DescriptionList'
import InputComponent from '../../../Components/MarketComponents/Input'
import InputTitle from '../../../Components/MarketComponents/InputTitle'
import MainMarketLayout from '../../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../../Components/MarketComponents/MainTitle'
import PriceLineBox from '../../../Components/MarketComponents/PriceLineBox'
import ResultTitleText from '../../../Components/MarketComponents/ResultTitleText'
import SubmitButtonGold from '../../../Components/MarketComponents/SubmitButtonGold'
import WalletBalanceWithUi from '../../../Components/MarketComponents/WalletBalanceWithUi'
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'
import { GetNFT } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import { FromWei } from '../../../Utils/Utils'
import { MarketContractInstance } from '../../../Web3/ContractInstances/ContractInstances'
import { useStyles } from './StyleBuyFixedPrice'

function BuyFixedPrice() {
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const [price, setPrice] = useState<number>(0)

    const param = useParams()
    const classes = useStyles()

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.username])

    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : process.env.REACT_APP_NFT
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const nameOfItem: string = DataNFT?.data?.data?.nft?.name
    const royalty: number = DataNFT?.data?.data?.nft?.royalty
    const fixedPrice = FromWei(DataNFT?.data?.data?.nft?.listedData?.fix_price)
    const fixedPriceInWei = DataNFT?.data?.data?.nft?.listedData?.fix_price
    const account: any = localStorage.getItem('account')


    async function BuyFixedPrice() {
        await MarketContractInstance.methods.buyNow(COLLECTION_ADDRESS, tokenId).send({ from: account, value: fixedPriceInWei })
            .once('sending', () => {
                setSteps(1)
                setFunctionOperationSteps("Confirming...")
            })
            .once('transactionHash', () => {
                setFunctionOperationSteps("Waiting...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .then((res: any) => {
                setSteps(2)
                setFunctionOperationSteps("Finalized")
            })
    }


    const List = [
        `This NFT royalty fee is ${royalty ? royalty : "..."}%`,
        "By confirming your order you, indicate that you have read and agree to the Artaniom Terms of Use."
    ]

    return (
        <MainMarketLayout>
            <div>
                {
                    steps >= 0 && steps <= 1 &&
                    <>
                        <MainTitle title="Buy Now" />
                        <InputTitle title="Fixed Price" />
                        <PriceLineBox price={fixedPrice} />
                        <Grid sx={{ marginTop: '20px' }}>
                            <WalletBalanceWithUi />
                        </Grid>
                        <Grid sx={{ marginTop: '280px', marginBottom: '60px' }}>
                            {
                                LoadingNFT ? <SkeletonLoading text={true} picture={false} height={300} shape="rectangular" width='300px' />
                                    :
                                    <>
                                        {
                                            steps === 0 && <SubmitButtonGold onClick={BuyFixedPrice} type="button" color="#000" title="Buy Now" bgColor="#94794F" />
                                        }
                                        {
                                            steps === 1 && <ConfirmingText text={functionOperationSteps} />
                                        }
                                    </>
                            }
                        </Grid>
                        {
                            steps === 0 && <DescriptionList data={List} />
                        }

                    </>
                }
                {
                    steps === 2 &&
                    <>
                        <div className={classes.resultSectionMsg}>
                            <ResultTitleText text="Congratulations!" />
                            <p>Now you are the owner of item {nameOfItem}</p>
                            <BlackLinkButton to={DataNFT?.data?.data?.nft?.category !== '' ? (DataNFT?.data?.data?.nft?.status === "lazy" ? `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?._id}` : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?.tokenId}`) : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT.data?.data?.nft?.tokenId}`} text="Visit Your NFT" />
                        </div>
                    </>
                }
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>

        </MainMarketLayout >
    )
}

export default BuyFixedPrice