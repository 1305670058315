import { Container, TextField } from '@material-ui/core'
import { InputAdornment } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import Web3 from 'web3'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import { GetNFT } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import API from '../../../Utils/Api'
import ENDPOINTS from '../../../Utils/Endpoints'
import { FromWei, ToWei } from '../../../Utils/Utils'
import { useStyle } from './StyleEditLazyPrice'
import EtherLogo from '../../../Assets/Img/EthereumLogo.svg'
import { toast } from 'react-toastify'

function EditLazyPrice() {
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [etherPrice, setEtherPrice] = useState<number>()
    const classes = useStyle()
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm()
    const [steps, setSteps] = useState<number>(1)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<number>(0)
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const param = useParams()
    const priceValue: any = FromWei(DataNFT?.data?.data?.nft?.price)

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [])

    useEffect(() => {
        setValue("price", priceValue)
    }, [LoadingNFT])



    const { data, refetch } = useQuery(['EthPrice'], () => {
        return API.get(ENDPOINTS.EthPrice)
    })

    let minimumPrice;

    async function GetEthPrice(e: React.KeyboardEvent<HTMLInputElement>): Promise<any> {
        await refetch()
        const web3 = new Web3(Web3.givenProvider)
        const ethereumPrice: number = data?.data?.data?.ethUsd
        minimumPrice = 50 / ethereumPrice
        const etherValue: number = Number((e.target as HTMLInputElement).value)
        if (!isNaN(etherValue)) {
            setPrice(Number(web3.utils.toWei(etherValue.toString())))
        }
        const total = etherValue * ethereumPrice
        const threeDigits: any = total.toFixed(3)
        setEthereumExchange(threeDigits)
    }


    const id: number = DataNFT?.data?.data?.nft?._id

    const mutation = useMutation(
        async (newPrice: any) => {
            const { data: response } = await API.put(ENDPOINTS.editLazyPrice, { nft_id: id, price: ToWei(newPrice) });
            return response.data;
        },
        {
            onError: ((error: any) => {
                toast.error(error.message)
            }),
            onSuccess: ((data) => {
                setSteps(3)
            }),
        }
    )

    function OnSubmit(data: any) {
        mutation.mutate(data.price)
    }
    return (
        <div className={classes.mainEditLazyPrice}>
            <Container>
                <div id="leftSide">
                    {steps <= 2 &&
                        <>
                            <p>Edit Minting Price</p>
                            <form onSubmit={handleSubmit(OnSubmit)}>
                                <div id="textField">
                                    <TextField
                                        size="small"
                                        type="number"
                                        id="price"
                                        label={errors?.price?.message}

                                        InputProps={{
                                            inputProps: {
                                                step: 0.1
                                            },
                                            disableUnderline: true,
                                            classes: { root: classes.input },
                                            endAdornment: <InputAdornment sx={{
                                                width: '120px',
                                                fontSize: '24px'
                                            }}
                                                position="start">{ethereumExchange}$</InputAdornment>,
                                        }}
                                        {...register("price", {
                                            min: { value: 0.1, message: `Price must be at least 0.1 ETH` },
                                            required: { value: true, message: 'Enter a value' },
                                            onChange: (e: any) => GetEthPrice(e)
                                        })}
                                        error={Boolean(errors?.price?.message)}
                                    />
                                    <p>ETH</p>
                                    <img src={EtherLogo} alt="etherLogo" />
                                </div>
                                {
                                    !mutation.isLoading &&
                                    <>
                                        <div id='description'>
                                            <ul>
                                                <li>Minimum listing price is 0.05 ETH.</li>
                                                <li>Buyers will not be able to bid below this price.</li>
                                                <li>Buyers will pay for the minting transaction gas fees on the blockchain.</li>
                                                <li>A 10% commission fee will be charged based on the final sale price of the item.</li>
                                                <li>If you don't sell your NFT within 100 days, it will be automatically unlisted (you can list it again).</li>
                                            </ul>
                                        </div>
                                        <button>Update Price</button>
                                    </>
                                }
                                {
                                    mutation.isLoading &&
                                    <div className={classes.confirm}>
                                        <p>Confirmation...</p>
                                        <p>Confirm this transaction with your
                                            wallet to continue.</p>
                                    </div>
                                }
                            </form>
                        </>
                    }
                    {steps === 3 &&
                        <div className={classes.finished}>
                            <p>You successfully update the minting price.</p>
                            <Link to={"/" + `${param.creator}` + "/" + `${param.collection}` + "/" + `${param.id}`}>Visit Your Item</Link >
                        </div>
                    }
                </div>
                <div id="rightSide">
                    <LazyCard data={DataNFT?.data?.data} />
                </div>
            </Container>
        </div>
    )
}

export default EditLazyPrice