import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import CountDownTimer from '../../../Components/CountDownTimer/CountDownTimer'
import { useCountdown } from '../../../Components/Counter/CountDown'
import BidAtLeastPrice from '../../../Components/MarketComponents/BidAtLeatPrice'
import ConfirmingText from '../../../Components/MarketComponents/ConfirmingText'
import DescriptionList from '../../../Components/MarketComponents/DescriptionList'
import InputComponent from '../../../Components/MarketComponents/Input'
import InputWithBackground from '../../../Components/MarketComponents/InputWithBackground'
import MainMarketLayout from '../../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../../Components/MarketComponents/MainTitle'
import PriceLineBox from '../../../Components/MarketComponents/PriceLineBox'
import SubmitButtonGold from '../../../Components/MarketComponents/SubmitButtonGold'
import WalletBalanceWithUi from '../../../Components/MarketComponents/WalletBalanceWithUi'
import { GetNFT } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import { FromWei } from '../../../Utils/Utils'
import { MarketContractInstance } from '../../../Web3/ContractInstances/ContractInstances'

function PlaceBid() {
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")
    const param = useParams()
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm()
    const baseValue: any = FromWei(DataNFT?.data?.data?.nft?.listedData?.base_value)
    const navigate = useNavigate()
    const web3 = new Web3(Web3.givenProvider)
    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const NftId = DataNFT?.data?.data?.nft?._id
    const account: any = localStorage.getItem('account')
    const royalty: number = DataNFT?.data?.data?.nft?.royalty


    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.id])

    const endTime = DataNFT?.data?.data?.nft?.listedData?.time_end * 1000
    const [daysAucDurationCountDown, hoursAucDurationCountDown, minutesAucDurationCountDown, secondsAucDurationCountDown] = useCountdown(endTime)

    var currentPrice: number = Number(FromWei(DataNFT?.data?.data?.nft?.listedData?.suggestions[0]?.bid))

    let minPrice: any;
    if (currentPrice) {
        minPrice = ((currentPrice * (0.1)) + currentPrice).toFixed(3)
    }

    if (!currentPrice) {
        minPrice = (((baseValue * (0.1)) + baseValue).toFixed(3))
    }

    async function PlaceBid(data: any) {
        const toWei = web3.utils.toWei(`${price}`)
        await MarketContractInstance.methods.placeBid(COLLECTION_ADDRESS, tokenId).send({ from: account, value: toWei })
            .once('sending', () => {
                setSteps(2)
                setFunctionOperationSteps("Confirming...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('transactionHash', () => {
                setSteps(3)
                setFunctionOperationSteps("Finalizing...")
            })
            .then((res: any) => {
                setSteps(4)
                setFunctionOperationSteps("Finalized")
                navigate(param.collection ? `/${param.creator}/${param.collection}/${param.id}` : `/${param.creator}/${param.id}`)
            })
    }

    const List = [
        `This NFT royalty fee is ${royalty} % `,
        "Buyers will not be able to bid below Starting price.",
        "Bids placed during an auction cannot be withdrawn.",
        "The new bid must be at least 10% more than the previous one.",
        "When a new bid was placed, the previous one was automatically withdrawn.",
        "When a new bid is placed in the last 15 minutes of the auction duration, 15 minutes will be automatically added to the auction duration time.",
    ]
    return (
        <MainMarketLayout>
            <div>
                <form onSubmit={handleSubmit(PlaceBid)} noValidate>
                    <MainTitle title="Place a Bid" />
                    <CountDownTimer
                        Title="Auction ends in"
                        days={daysAucDurationCountDown}
                        hours={hoursAucDurationCountDown}
                        minutes={minutesAucDurationCountDown}
                        seconds={secondsAucDurationCountDown}
                    />
                    <PriceLineBox title="Starting Price" price={baseValue} />
                    <Grid sx={{ marginTop: '50px' }}>
                        <InputWithBackground
                            errors={errors}
                            setPrice={setPrice}
                            register={register}
                            setEthereumExchange={setEthereumExchange}
                            ethereumExchange={ethereumExchange}
                            LoadingNFT={LoadingNFT}
                            minPrice={minPrice}
                        />
                    </Grid>
                    <Grid sx={{ marginTop: '50px' }}>
                        <WalletBalanceWithUi />
                        <BidAtLeastPrice title="Your must bid at least" value={minPrice} />
                    </Grid>
                    {
                        steps == 0 &&
                        <Grid sx={{ marginTop: '80px' }}>
                            <SubmitButtonGold type="submit" title="Place a Bid" bgcolor="#94794F" />
                        </Grid>
                    }
                    {
                        steps > 1 && steps <= 3 &&
                        <>
                            <Grid sx={{ margin: '100px 0' }}>
                                <ConfirmingText text={functionOperationSteps} />
                            </Grid>
                        </>
                    }
                </form>
                {
                    steps == 0 &&
                    <DescriptionList data={List} />
                }
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>
        </MainMarketLayout>
    )
}

export default PlaceBid