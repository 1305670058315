import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
    main: {
        display: "flex",
        width: "100%",
        alignItems: 'center',
        flexDirection: 'column',

        "& > div": {
            width: '80%',
            "& > div[id='headTitle']": {
                width: "100%",
                "& p:first-child": {
                    fontSize: '36px',
                    marginBottom: "3px",
                    textAlign: 'center'
                },
                "& p:last-child": {
                    fontSize: '19px',
                    marginBottom: "3px",
                    color: theme.palette.primary.dark,
                    marginTop: 0,
                    textAlign: 'center'
                },
                marginBottom: '60px',
            },
            "& > div:nth-child(2)": {
                flexDirection: "row",
                display: "flex",
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: '45px',
                flexWrap: 'wrap',
                "& a": {
                    color: theme.palette.primary.dark,
                    textDecoration: 'none',
                    margin: '0 8px',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    [theme.breakpoints.down('sm')]: {
                        display: 'none'
                    },
                },
            },
            "& > div:nth-child(3)": {
                textAlign: 'right',
                marginTop: '10px'
            },
            "& > div:nth-child(4)": {
                display: 'flex',
                columnGap: '10px',
                "& > div:nth-child(1)": {
                    marginTop: '20px',
                    flex: '1',
                    padding: '10px',
                    "& > div": {
                        boxShadow: '0px 5px 2px rgba(0, 0, 0, 0.25)',
                        marginTop: '35px',
                        borderRadius: '7px',
                        "& > div": {
                            "& > div": {
                                "& > p": {
                                    fontFamily: 'RalewayMedium',
                                    fontSize: '16px',
                                    color: '#141414',
                                    whiteSpace: "nowrap"
                                }
                            }
                        }
                    }
                },
                "& > div:nth-child(2)": {
                    flex: '5',
                    "& > div": {
                        overflow: 'unset !important',
                        margin: '20px 0',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3,1fr)',
                        columnGap: '40px',
                        rowGap: '40px',
                        [theme.breakpoints.down('sm')]: {
                            gridTemplateColumns: 'repeat(1,1fr)',
                        },
                        [theme.breakpoints.down('lg')]: {
                            gridTemplateColumns: 'repeat(2,1fr)',
                        },
                        "& > div:nth-child(1)": {
                            position: 'absolute !important'
                        }
                    }
                }
            }
        }
    },
    dropDownSort: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        "& > div": {
            width: '80%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "end",
            "& > div[id='artworks']": {
                display: 'flex',
                "& > p": {
                    fontFamily: 'RalewayMedium',
                    fontSize: '14px',
                    color: '#373737',
                    marginLeft: '20px'
                }
            },
            "& > div": {
                "& > div": {
                    textAlign: 'center',
                    fontFamily: 'Raleway',
                    fontSize: '16px',
                    color: '#676767                       '
                }
            }
        }
    },
    radioBtn: {
        "& svg": {
            color: '#000'
        }
    }
}))