import { compose, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './Reducers';
import rootSaga from './Saga';
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const sagaMiddleware = createSagaMiddleware()
export const history: any = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const preloadedState = {};

export const store = configureStore({
    middleware: [sagaMiddleware],
    reducer: rootReducer(history),
    enhancers: [composeEnhancers],
    //preloadedState,
});

sagaMiddleware.run(rootSaga)
// routerMiddleware(history)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch