import { put, takeLatest } from "redux-saga/effects"
import { GetCollectionExploreFailed, GetCollectionExploreSuccess, GetHomeFailed, GetHomeSuccess } from "../../redux/Actions"
import { store } from "../../redux/Store"
import API from "../../Utils/Api"
import ENDPOINTS from "../../Utils/Endpoints"
import * as types from '../../redux/Types'
import { toast } from "react-toastify"

function* HomeWorker(action: any) {
    let Response: any
    async function Api() {
        try {
            Response = await API.get(ENDPOINTS.Home)
        } catch (e: any) {
            console.error('e-HOme', e)
            store.dispatch(GetHomeFailed({ payload: e }))
            toast.error(e?.message)
        }
    }
    yield Api()
    if (Response?.data?.success === true) {
        yield put(GetHomeSuccess({ payload: Response }))
    } else {
        yield put(GetHomeFailed({ payload: Response }))
        toast.error(Response?.message)
    }
}


export function* HomeWatcher() {
    yield takeLatest(types.GET_HOME_REQUEST, HomeWorker)
}
