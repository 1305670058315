import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    mainFallback: {
        display: 'block',
        width: '100%',
        height: '100vh',
        backgroundColor: '#94794F',
        position: 'absolute',
        zIndex: 1000,
        top: 0,
        left: 0,
        color: 'white',
        opacity: '1',
        alignItems: 'center',
        justifyContent: 'center',
        "& > p[id='fallback']": {
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            "& > img": {
                width: '150px',
            }
        }
    }
}))