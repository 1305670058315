import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import ConfirmationSection from '../../../Components/MarketComponents/ConfirmationSection'
import ConfirmingText from '../../../Components/MarketComponents/ConfirmingText'
import DescriptionList from '../../../Components/MarketComponents/DescriptionList'
import InputComponent from '../../../Components/MarketComponents/Input'
import InputTitle from '../../../Components/MarketComponents/InputTitle'
import MainMarketLayout from '../../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../../Components/MarketComponents/MainTitle'
import SubmitButtonGold from '../../../Components/MarketComponents/SubmitButtonGold'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import MarketABI from '../../../Web3/ABI/Market.json'
import { AbiItem } from 'web3-utils';
import RegularCollectionABI from '../../../Web3/ABI/regularCollection.json'
import { GetNFT } from '../../../redux/Actions'
import { useParams } from 'react-router-dom'
import ResultTitleText from '../../../Components/MarketComponents/ResultTitleText'
import BlackLinkButton from '../../../Components/MarketComponents/BlackLinkButton'
import { MarketContractInstance } from '../../../Web3/ContractInstances/ContractInstances'
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'

interface ISetFixPrice {
    price?: number
}

function SellFixedPrice() {
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm()
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const [price, setPrice] = useState<number>(0)
    const param = useParams()

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.username])

    const List = [
        "Minimum listing price is 0.05 ETH.",
        "A 10% commission fee will be charged based on the final sale price of the item.",
        "Listing is free. But you should pay the transaction gas fees on Ethereum blockchain.",
        "If you don't sell your NFT within 100 days, it will be automatically unlisted (you can list it again). "
    ]

    const web3 = new Web3(Web3.givenProvider)
    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const NftId = DataNFT?.data?.data?.nft?._id
    const account: any = localStorage.getItem('account')
    const collection = new web3.eth.Contract(RegularCollectionABI as AbiItem[], COLLECTION_ADDRESS)
    const FixContract = new web3.eth.Contract(MarketABI as AbiItem[], process.env.REACT_APP_MARKET)

    const OnSubmit: SubmitHandler<ISetFixPrice> = (data: any) => {
        if (ethereumExchange === 0) {
            toast.warn("Enter a valid price!")
            return
        }
        setSteps(1)
        GetApprove()
    }

    async function GetApprove() {
        console.log("COLLECTION_ADDRESS",COLLECTION_ADDRESS)
        console.log("account",account)
        await MarketContractInstance.methods.checkTransferPerm(COLLECTION_ADDRESS, tokenId).call({ from: account }).then((result: any) => {
            if (result) {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
                FixedPrice()
            } else {
                ApproveCollection()
            }
        }).catch((error: any) => {
            toast.error(error.message)
            setSteps(0)
        })
    }

    async function FixedPrice() {
        const toWei = web3.utils.toWei(`${price}`)
        console.log("COLLECTION_ADDRESS",COLLECTION_ADDRESS)
        console.log("account",account)
        await MarketContractInstance.methods.setSellOrder(COLLECTION_ADDRESS, tokenId, toWei).send({ from: account })
            .once('sending', () => {
                setSteps(2)
                setFunctionOperationSteps("Confirming...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('transactionHash', () => {
                setSteps(3)
                setFunctionOperationSteps("Finalizing...")
            })
            .then((res: any) => {
                setSteps(4)
                setFunctionOperationSteps("Finalized")
            })
    }



    async function ApproveCollection() {
        await collection.methods.approve(process.env.REACT_APP_MARKET, tokenId).send({ from: account })
            .once('sending', () => {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .then((result: any) => {
                FixedPrice()
            })
    }


    return (
        <MainMarketLayout>
            <div>
                {
                    steps === 0 &&
                    <>
                        <MainTitle title="Set a Fixed Price" />
                        <form onSubmit={handleSubmit(OnSubmit)} noValidate>
                            <InputComponent
                                errors={errors}
                                setPrice={setPrice}
                                register={register}
                                setEthereumExchange={setEthereumExchange}
                                ethereumExchange={ethereumExchange}
                                LoadingNFT={LoadingNFT}
                            />
                            <Grid sx={{ marginTop: '280px' }}>
                                {
                                    LoadingNFT ?
                                        <SkeletonLoading text={true} picture={false} shape="rectangular" height={400} width='200px' />
                                        :
                                        <SubmitButtonGold type="submit" title="List Your NFT" bgcolor="#94794F" />
                                }
                            </Grid>
                        </form>
                        <DescriptionList data={List} />
                    </>
                }
                {
                    steps > 0 && steps <= 3 &&
                    <>
                        <ConfirmationSection steps={steps} />
                        <Grid sx={{ margin: '80px 0' }}>
                            <ConfirmingText text={functionOperationSteps} />
                        </Grid>
                    </>
                }
                {
                    steps === 4 &&
                    <>
                        <Grid sx={{ display: "grid", placeItems: 'center', marginTop: '80px' }}>
                            <ResultTitleText text="Your NFT has been listed." />
                            <BlackLinkButton to={DataNFT?.data?.data?.nft?.category !== '' ? (DataNFT?.data?.data?.nft?.status === "lazy" ? `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?._id}` : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?.tokenId}`) : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT.data?.data?.nft?.tokenId}`} text="Visit Your NFT" />
                        </Grid>
                    </>
                }
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>
        </MainMarketLayout>
    )
}

export default SellFixedPrice