import axios from 'axios';
import Sign from './Sign';

const API = axios.create({
    baseURL: process.env.REACT_APP_MAIN_URL,
    headers: Sign()
});

API.interceptors.request.use(
    (config) => {
        config.headers = Sign();
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default API;