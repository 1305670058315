import * as types from '../../../redux/Types';
import { CreateProfile } from '../../../Utils/Interfaces';

const initialState = {
    CreateProfileData: [],
    CreateProfileisLoading: false,
    CreateProfileError: false,
    NotificationData: [],
    NotificationIsLoading: false,
    NofiticationError: false,
    NotificationListData: [],
    NotificationListIsLoading: false,
    NotificationListError: false
}

export default function CreateProfileReducer(state: CreateProfile = initialState, action: any) {
    switch (action.type) {
        case types.CREATE_PROFILE_REQUEST:
            return {
                ...state,
                CreateProfileisLoading: true,
                CreateProfileError: false,
                CreateProfileData: action.payload
            }
        case types.CREATE_PROFILE_SUCCESS:
            return {
                ...state,
                CreateProfileisLoading: false,
                CreateProfileError: false,
                CreateProfileData: action.payload
            }
        case types.CREATE_PROFILE_FAILED:
            return {
                ...state,
                CreateProfileisLoading: false,
                CreateProfileError: true,
                CreateProfileData: action.payload
            }
        case types.SUBMIT_NOTIFICATION_REQUEST:
            return {
                ...state,
                NotificationIsLoading: true,
                NofiticationError: false,
                NotificationData: action.payload
            }
        case types.SUBMIT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                NotificationIsLoading: false,
                NofiticationError: false,
                NotificationData: action.payload
            }
        case types.SUBMIT_NOTIFICATION_FAILED:
            return {
                ...state,
                NotificationIsLoading: false,
                NofiticationError: true,
                NotificationData: action.payload
            }
        case types.NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                NotificationListIsLoading: true,
                NofiticationListError: false,
                NotificationListData: action.payload
            }
        case types.NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                NotificationListIsLoading: false,
                NofiticationListError: false,
                NotificationListData: action.payload
            }
        case types.NOTIFICATION_LIST_FAILED:
            return {
                ...state,
                NotificationListIsLoading: false,
                NofiticationListError: true,
                NotificationListData: action.payload
            }
        default:
            return state
    }
} 