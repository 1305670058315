import { makeStyles } from '@material-ui/core/styles';
import { getPositionOfLineAndCharacter } from 'typescript';

export const useStyles = makeStyles(theme => ({
    aboutUsMain: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    container: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        "& h3": {
            "& small": {
                fontSize: '16px',
                fontWeight: 500
            }
        }
    }
}))