import { Divider, Grid, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { addDays } from "date-fns";
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AbiItem } from 'web3-utils';
import banner from '../../../Assets/Img/banner.png';
import ethIcon from '../../../Assets/Img/eth.png';
import { LazyCard } from '../../../Components/CardSection/CardSection';
import BlackLinkButton from '../../../Components/MarketComponents/BlackLinkButton';
import ConfirmationSection from '../../../Components/MarketComponents/ConfirmationSection';
import ConfirmingText from '../../../Components/MarketComponents/ConfirmingText';
import DescriptionList from '../../../Components/MarketComponents/DescriptionList';
import InputComponent from '../../../Components/MarketComponents/Input';
import InputTitle from '../../../Components/MarketComponents/InputTitle';
import MainMarketLayout from '../../../Components/MarketComponents/MainMarketLayout';
import MainTitle from '../../../Components/MarketComponents/MainTitle';
import ResultTitleText from '../../../Components/MarketComponents/ResultTitleText';
import SubmitButtonGold from '../../../Components/MarketComponents/SubmitButtonGold';
import { PopUp } from '../../../Components/PopUp/PopUp';
import SkeletonLoading from '../../../Components/Skeleton/Skeleton';
import { GetNFT } from '../../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../../redux/Hook';
import API from '../../../Utils/Api';
import ENDPOINTS from '../../../Utils/Endpoints';
import { IAuctionForm, IFixState } from '../../../Utils/Interfaces';
import { ContractInstance, web3 } from '../../../Utils/Utils';
import AuctionABI from '../../../Web3/ABI/Market.json';
import FixContract from '../../../Web3/ABI/Market.json';
import RegularCollectionABI from '../../../Web3/ABI/regularCollection.json';
import { MarketContractInstance } from '../../../Web3/ContractInstances/ContractInstances';
import { useStyles } from './StyleSellInAuction';

function SetStandardAuction(): JSX.Element {

    const classes = useStyles()
    const [startDate, setStartDate] = useState<any>(new Date())
    const [endDate, setEndDate] = useState<any>(new Date())
    const [fixState, setFixState] = useState<IFixState>({ state: 'waiting', level: 0, approveState: 'Approving', confirmState: 'Confirming', finalState: 'Finalizing', status: '' })
    const { handleSubmit, register, formState: { errors } } = useForm<IAuctionForm>()
    const [price, setPrice] = useState<any>(0)
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const { DataSellInAuction, LoadingSellInAuction, ErrorSellInAuction } = useAppSelector(state => state.SellInAuctionReducer)
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")

    const dispatch = useAppDispatch()
    const param = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const CollecName: string = DataNFT?.data?.data?.nftCollection?.name

    useEffect(() => {
        if (!DataSellInAuction?.data?.data?.success) {
            dispatch(GetNFT({ payload: param }))
        }
        // if (DataSellInAuction?.data?.data?.success) {
        //     toast.success("Your NFT is Listed Successfully.")
        //     setFixState((prevState: any) => ({ ...prevState, state: 'done', level: 3, approveState: 'Approved', confirmState: 'Confirmed', finalState: 'Finalized', status: 'Your NFT is Listed.' }))
        // }
        // if (!DataSellInAuction?.data?.data?.success) {
        //     toast.success(`${DataSellInAuction?.data?.data?.message}`)
        //     setFixState((prevState: any) => ({ ...prevState, state: 'done', level: 3, approveState: 'Approved', confirmState: 'Confirmed', finalState: 'Finalized', status: 'Your NFT is Listed.' }))
        // }
    }, [DataSellInAuction?.data?.data])

    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const tokenId = DataNFT?.data?.data?.nft?.tokenId
    const auctionContract = ContractInstance(AuctionABI, process.env.REACT_APP_AUCTION)
    const NftId = DataNFT?.data?.data?.nft?._id
    const account: any = localStorage.getItem('account')

    const collection = new web3.eth.Contract(RegularCollectionABI as AbiItem[], COLLECTION_ADDRESS)
    const fix = new web3.eth.Contract(FixContract as AbiItem[], process.env.REACT_APP_FIXED)

    const [allowPopupClose, setAllowPopupClose] = useState<boolean>(false)

    async function onSubmitGetApprove() {
        setSteps(1)
        await collection.methods.getApproved(tokenId).call({ from: account }).then((result: any) => {
            if (result === process.env.REACT_APP_MARKET) {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
                SetAuction()
            } else {
                ApproveCollection()
            }
        }).catch((error: any) => {
            toast.warning(error.message)
            setSteps(0)
        })
    }

    async function ApproveCollection() {
        await collection.methods.approve(process.env.REACT_APP_MARKET, tokenId).send({ from: account })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('sending', () => {
                setSteps(1)
                setFunctionOperationSteps("Approving...")
            })
            .then((result: any) => {
                SetAuction()
            })
    }

    async function SetAuction() {
        setSteps(2)
        const startDateUnix = Math.round(startDate / 1000)
        const endDateUnix = Math.round(endDate / 1000)
        const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
        const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
        const address = process.env.REACT_APP_NFT
        const COLLECTION_ADDRESS: any = hasCategory ? CollectionAddress : address
        const tokenId = DataNFT?.data?.data?.nft?.tokenId
        const auctionContract = ContractInstance(AuctionABI, process.env.REACT_APP_AUCTION)
        const toWei = web3.utils.toWei(`${price}`)
        const duration = endDateUnix - startDateUnix

        await MarketContractInstance.methods.setStandardAuction(COLLECTION_ADDRESS, tokenId, toWei, startDateUnix, duration).send({ from: account })
            .once('sending', () => {
                setSteps(2)
                setFunctionOperationSteps("Confirming...")
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
            })
            .once('transactionHash', () => {
                setSteps(3)
                setFunctionOperationSteps("Finalizing...")
            })
            .then((res: any) => {
                setSteps(4)
                setFunctionOperationSteps("Finalized")
            })
    }

    const PriceHandler = (e: React.ChangeEvent<any>) => { setPrice(e.target.value) }

    const [open, setOpen] = React.useState(false);

    const handleClickOpenPopUp = () => {
        setOpen(true);
    };

    const handleClosePopUp = () => {
        if (!allowPopupClose) {
            setOpen(false);
        }
    };


    const { data, isLoading, error, refetch } = useQuery(['EthPrice'], () => {
        const response = API.get(ENDPOINTS.EthPrice)
        return response
    })
    const [minPrice, setMinPrice] = useState<number>(0)
    let minimumPrice;

    async function GetEthPrice(e: any) {
        await refetch()
        const ethereumPrice: number = data?.data?.data?.ethUsd
        minimumPrice = 50 / ethereumPrice
        setMinPrice(minimumPrice)
        const etherValue: number = e.target.value
        setPrice(etherValue)
        const total = etherValue * ethereumPrice
        const threeDigits: any = total.toFixed(3)
        setEthereumExchange(threeDigits)
    }

    const [ethereumExchange, setEthereumExchange] = useState<number>(0)

    const startD = (startDate / 1000)

    const List = [
        "Minimum Starting price is 1 ETH.",
        "Maximum duration of an auction is two weeks.",
        "The maximum time interval that can be set for the start of the action is two weeks.",
        "Buyers will not be able to bid below starting price. ",
        "A 10% commission fee will be charged in primary market based on the final sale price of the item.",
        "You can cancel your auction before the first bid, after that, it’s not possible to stop it.",
        "Setting Auction is free. But you should pay the transaction gas fees on Ethereum blockchain."
    ]

    function OnSubmit(data: any) {
        onSubmitGetApprove()
    }

    return (
        <MainMarketLayout>
            <div>
                {
                    steps === 0 &&
                    <>
                        <MainTitle title="Set a Standard Auction" />
                        <form onSubmit={handleSubmit(OnSubmit)}>
                            <InputTitle title="Starting Price" />
                            <InputComponent
                                errors={errors}
                                setPrice={setPrice}
                                register={register}
                                setEthereumExchange={setEthereumExchange}
                                ethereumExchange={ethereumExchange}
                                LoadingNFT={LoadingNFT}
                            />
                            <div className={classes.timePicker}>
                                <InputTitle id="headTitle" title="Starting Time" />
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: any) => setStartDate(date)}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yy h:mm aa"
                                    showTimeInput
                                    minDate={new Date()}
                                    maxDate={addDays(new Date(), 7)}
                                />
                                <InputTitle id="headTitle" title="Ending Time" />
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: any) => setEndDate(date)}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yy h:mm aa"
                                    showTimeInput
                                    minDate={new Date(startDate)}
                                    maxDate={addDays(new Date(), 21)}
                                />
                            </div>
                            <Grid sx={{ marginTop: '80px' }}>
                                <SubmitButtonGold type="submit" title="List Your NFT" bgcolor="#94794F" />
                            </Grid>
                        </form>
                        <DescriptionList data={List} />
                    </>
                }
                {
                    steps > 0 && steps <= 3 &&
                    <>
                        <ConfirmationSection steps={steps} />
                        <Grid sx={{ margin: '80px 0' }}>
                            <ConfirmingText text={functionOperationSteps} />
                        </Grid>
                    </>
                }
                {
                    steps === 4 &&
                    <>
                        <Grid sx={{ display: "grid", placeItems: 'center', marginTop: '80px' }}>
                            <ResultTitleText text="Your NFT has been listed." />
                            <BlackLinkButton to={DataNFT?.data?.data?.nft?.category !== '' ? (DataNFT?.data?.data?.nft?.status === "lazy" ? `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?._id}` : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?.tokenId}`) : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT.data?.data?.nft?.tokenId}`} text="Visit Your NFT" />
                        </Grid>
                    </>
                }
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>
        </MainMarketLayout>
    )
}

export default SetStandardAuction   