import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    mainProfile: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        //margin: '-120px 0 40px 0',
        [theme.breakpoints.down('sm')]: {
            marginTop: '60px',
        },
    },
    container: {
        width: '80%',
        display: 'flex',
        flexDirection: "column",
    },
    userProfile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

    },
    leftSide: {
        width: '100%',
        "& img": {
            borderRadius: "50%",
            width: "135px",
            position: 'relative',
            margin: '-115px 0  0',
        },
        "& p[id='name']": {
            fontSize: "32px",
            fontFamily: 'ElMessiriBold',
            margin: '2px 25px',
        },
        "& > a:nth-child(4)": {
            backgroundColor: '#000',
            border: 'none',
            color: '#fff',
            padding: '7px 20px',
            borderRadius: '6px',
            fontSize: '18px',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        "& div[id='infoSection']": {
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            "& p": {
                margin: '0'
            },
            "& > div[id='first']": {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'baseline',
                "& > p": {
                    margin: 0,
                    fontFamily: 'RalewayMedium',
                    fontSize: "17px",
                    color: "#141414",
                },
                "& > p:nth-child(1)": {
                    fontFamily: 'RalewayMedium',
                    fontSize: "17px",
                    color: "#676767",
                    cursor: 'pointer',
                    marginTop: '18px',
                    "& svg": {
                        width: '0.7em',
                        verticalAlign: 'middle'
                    },
                }
            },
            "& > div[id='second']": {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                "& > p": {
                    margin: 0,
                    fontFamily: 'RalewayMedium',
                    fontSize: "17px",
                    color: "#141414",
                },
                "& > p:nth-child(1)": {
                    fontFamily: 'RalewayMedium',
                    fontSize: "17px",
                    color: "#676767",
                    display: 'flex',
                    cursor: 'pointer',
                    "& svg": {
                        width: '14px'
                    },
                },

            }
        },
    },

    socialMediaSec: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '-70px 0 0',
        borderRadius: '7px',
        backgroundColor: '#fff',
        padding: '5px 7px',
        "& > a": {
            paddingRight: '3px'
        },
        "& svg": {
            color: "#808080",
        },
        "& > div": {
            "& > button": {
                padding: 0,
                marginTop: '-4px'
            }
        }
    },
    ButtonFollowEdit: {
        position: 'relative',
        boxShadow: "0px 3px 0px 0.4px rgba(103,103,103,0.63)",
        padding: '8px 20px',
        borderRadius: "7px",
        color: '#010101',
        textDecoration: 'none',
        fontFamily: 'ElMessiri',
        fontSize: '22px',
        fontWeight: 700,
        transition: "all 0.2s ease-out",
        border: 'none',
        cursor: 'pointer',
        "&:active": {
            boxShadow: "none",
        },
    },
    bioSec: {
        "& span": {
            fontWeight: 600,
            fontSize: "20px"
        },
        fontFamily: 'DellaRespira',
        fontSize: '16px',
        fontWeight: 500,
        overflowWrap: "break-word",
        color: "#676767",
        minHeight: '40px',
        marginTop: '15px',
        marginBottom: '20px'
    },
    cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: '1fr',
        gridColumnGap: '70px',
        gridRowGap: "40px",
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },

    CreateCollection: {
        width: "100%",
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: "#AFAFAF",
        flexDirection: 'column',
        backgroundColor: "#d6d6d6",
        marginBottom: '30px',
        cursor: 'pointer',
        "& svg": {
            color: "#AFAFAF",
            fontSize: '60px'
        },
        "& > p": {
            fontFamily: 'ElMessiriBold',
            fontSize: '18px',
        }
    },
    socialMediaContainer: {
        position: 'relative',
        display: 'flex',
        alignContent: 'space-around',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-end'
    },
    noItemCard: {
        marginTop: '150px',
        display: 'flex',
        flexDirection: 'column',
        margin: "0 auto",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '150px',
        "& p:nth-child(1)": {
            fontFamily: 'ElMessiriBold',
            fontSize: "20px",
            color: '#000',
            margin: '0'
        },
        "& p:nth-child(2)": {
            fontFamily: 'ElMessiriMedium',
            fontSize: "16px",
            color: '#000',
            margin: '0'
        },
        "& a": {
            marginTop: '25px',
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.15) 99.99%, rgba(0, 0, 0, 0) 100%), #604083'
            , boxShadow: '0px 5px 2px #37254B',
            borderRadius: '7px',
            padding: '5px 30px',
            color: '#fff',
            fontFamily: 'ElMessiriMedium',
            fontSize: '18px',
            textDecoration: 'none',
            transition: "all 0.1s ease-out",
            "&:active": {
                boxShadow: "none",
            }
        }
    },
    avatar: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        background: 'linear-gradient(180deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%)',
    },
    createItem: {
        display: 'grid',
        flexDirection: 'column',
        width: '100%',
        height: '400px',
        background: 'linear-gradient(90deg, rgba(191, 191, 191, 0.4) 97.75%, rgba(92, 92, 92, 0) 99.14%)',
        placeItems: 'center',
        margin: '20px 0',
        "& > a": {
            background: 'rgba(142, 141, 141, 0.15)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '7px',
            padding: "5px 15px",
            textDecoration: 'none',
            fontFamily: 'ElMessiriBold',
            fontSize: '18px',
            color: '#000',
        }
    },
    shareButtons: {
        "& li": {
            "& svg": {
                marginRight: '5px'
            }
        }
    },
    tooltipEns: {
        display: "flex",
        flexDirection: 'column',
        width: '220px',
        "& > div": {
            display: 'flex',
            flexDirectin: 'row',
            alignItems: "center",
            justifyContent: 'space-between',
            "& > img:nth-child(1)": {
                width: '20px',
            },
            "& p": {
                fontFamily: 'RalewayMedium',
                fontSize: '17px',
                color: '#676767',
                margin: '0',
                display: 'flex',
                alignItems: 'center'
            },
            "& svg": {
                width: '14px',
                marginLeft: '15px',
                right: '0'
            }
        }
    }
}))