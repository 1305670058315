import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DoneIcon from '@mui/icons-material/Done'
import { IconButton, LinearProgress, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import AutocompleteInput from './AutoCompleteInput'
import { useStyles } from './Styles'

type Item = {
    share: number
}

function SplitPopup({ setCollaborators, setIsPopupOpen, Collaborators, setCreateSplit }: any) {
    const [editPrecent, setEditPrecent] = useState<string>('')
    const [share, setShare] = useState<number>(0)
    const [totalShare, setTotalShare] = useState<number>(0)
    const classes = useStyles()
    const { handleSubmit, formState: { errors } } = useForm()
    const userInfo: any = localStorage.getItem('userProfile')
    const user = JSON.parse(userInfo)
    const [users, setUsers] = useState<string[]>([])
    function submitSplit() {
        setIsPopupOpen(false)
        setCreateSplit(true)
        setCollaborators(users)
    }

    useEffect(() => {
        const userInformation: any = {
            _id: user.data.data.user._id,
            username: user.data.data.user.username,
            avatar: user.data.data.user.avatar,
            address: user.data.data.user.address,
            share: 5
        }
        if (Collaborators.length !== 0) {
            setUsers(Collaborators)
        }
        if (Collaborators.length === 0) {
            setUsers([userInformation])
        }
    }, [])

    function removeUser(props: any) {
        if (props._id == user.data.data.user._id) {
            toast.warn("You can't omit yourself.")
            return
        }
        setUsers((users: any) => users.filter((user: any) => user?._id !== props?._id))
    }



    const AllUsers: any = users;

    useEffect(() => {
        const sum = AllUsers.reduce((accumulator: number, item: Item) => accumulator + item.share, 0);
        setTotalShare(sum)
    }, [users])

    function AddShare({ e, person }: any): void {
        const share = e.target.value;
        if (share % 5 !== 0) {
            toast.warn('share amount must be divisible by 5.')
            return
        }
        if (share > 95) {
            toast.warn("share amount must be less than 95.")
            return
        }
        if ((100 - (totalShare - person.share)) < share) {
            toast.warn("your total is above 100%")
            return
        }
        if (share < 5) {
            toast.warn('share amount must be at least 5%.')
            return
        }
        if (share % 5 === 0) {
            setShare(e.target.value)
            const item = AllUsers.findIndex((user: any) => user._id == person._id)
            AllUsers[item].share = Number(share)
            //setEditPrecent('')
            const sum = AllUsers.reduce((accumulator: number, item: Item) => accumulator + item.share, 0);
            setTotalShare(sum)
        }
    }

    document.addEventListener('click', function handleClickOutsideBox(event: any) {
        if (event.target.id !== "paragraphShare" && event.target.id !== "inputShare") {
            setEditPrecent('')
        }
    });

    function handleEditPrecent(props: any): void {
        setEditPrecent(props._id)
        setShare(props?.share === undefined ? 5 : props.share)
    }


    return (
        <div className={classes.splitPopup}>
            <form onSubmit={handleSubmit(submitSplit)} >
                <p>Determine how your earnings are split</p>
                <p>Primary market earnings and royalty payments for secondary market will be automatically deposited into each recipient’s wallet.</p>
                <AutocompleteInput setUsers={setUsers} users={users} totalShare={totalShare} />
                <div>
                    <p>Recipients</p>
                    <p>Shares: ({AllUsers?.length}/5)</p>
                </div>

                {
                    AllUsers?.map((person: any) =>
                        <>
                            <div id="splitItem" key={person?._id}>
                                <div>
                                    <IconButton id="deleteSplitItem" onClick={() => removeUser(person)}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                    <img src={person?.avatar} alt="avatar-split" width="30px" height="20px" />
                                    <div>
                                        <p>{person?.username && person?.username?.toLowerCase()}</p>
                                        <p>{person?.address}</p>
                                    </div>

                                </div>
                                <div >

                                    {
                                        editPrecent == person?._id ?
                                            <>
                                                <TextField
                                                    inputProps={{
                                                        step: 5,
                                                        min: 5
                                                    }}
                                                    onKeyDown={(e: any) => { if (e.code === "Enter" || e.code === "NumpadEnter") { AddShare(person) } }}
                                                    label={errors?.person?.message}
                                                    onChange={(e: any) => AddShare({ person, e })}
                                                    error={Boolean(errors?.name?.message)}
                                                    size='small'
                                                    value={share}
                                                    type='number'
                                                    id="inputShare"
                                                />
                                            </>
                                            :
                                            <p id="paragraphShare" onClick={() => handleEditPrecent(person)}>{person?.share !== undefined ? person?.share : 5}%</p>
                                    }
                                </div>
                            </div>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress variant="determinate" value={person?.share} />
                            </Box>
                        </>
                    )
                }
                <div className={classes.totalShare}>
                    <p id={totalShare === 100 ? `complete` : `notComplete`}>Total: {totalShare}%</p>
                </div>
                <button className={classes.submitSplit} disabled={totalShare !== 100} type="submit">Split</button>
            </form >
        </div >
    )
}

export default SplitPopup