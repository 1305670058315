import { Skeleton, Stack } from '@mui/material'
import { ISkeletonProps } from '../../Utils/Interfaces'

function SkeletonLoading({ text, shape, width, picture, key, height }: ISkeletonProps): JSX.Element {
    return (
        <Stack direction="column" sx={{ marginY: '20px' }}>
            {
                picture && <Skeleton variant={shape} animation="wave" height={height ? height : 118} width={width} />

            }

            {
                text &&
                <>
                    <Skeleton animation="wave" height={10} width={width} style={{ marginTop: 6 }} />
                    <Skeleton animation="wave" height={10} width={width} style={{ marginTop: 6 }} />
                </>
            }
        </Stack>
    )
}

export default SkeletonLoading