import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export interface ICircularLoading {
    size: number
}

export default function CircularLoading({ size }: ICircularLoading) {
    return (
        <Box sx={{ display: 'flex', marginLeft: 2 }}>
            <CircularProgress size={size} color="inherit" />
        </Box>
    );
}