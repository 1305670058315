
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import DrawerAppBar from './Components/AppBar/AppBar';
import Layout from './Components/Layout/Layout';
import PageNotFound from './Pages/404/404';
import AboutUs from './Pages/About Us/AboutUs';
import BuyInAuction from './Pages/BuyAuction/BuyAuction';
import BuyInLazy from './Pages/Lazy/BuyInLazy/BuyInLazy';
import AddCollection from './Pages/Collections/AddCollection/AddCollection';
import Collection from './Pages/Explore/ExploreCollections/ExploreCollections';
import SingleCollection from './Pages/Collections/SingleCollection/SingleCollection';
import ContactUs from './Pages/ContactUs/ContactUs';
import Faq from './Pages/FAQ/Faq';
import JoinArtaniom from './Pages/JoinArtaniom/JoinArtaniom';
import Mint from './Pages/Mint/MintPage';
import Notification from './Pages/Notification/Notification';
import Privacy from './Pages/PrivacyPolicy/Privacy';
import EditProfile from './Pages/Profile/EditProfile/EditProfile';
import Profile from './Pages/Profile/Profile';
import SellFixedPrice from './Pages/FixedPriced/SetFixedPrice/SellFixedPrice';
import SellInAuction from './Pages/Auction/SellStandardAuction/SellInAuction';
import TermsOfServices from './Pages/TOS/TermsOfServices';
import { history } from './redux/Store';
import CustomRouter from './Utils/CustomRouter';
import PrivateRoute from './Utils/PrivateRoute';
import EditCollection from './Pages/Collections/EditCollection/EditCollection';
import { lazy, Suspense } from 'react';
import Fallbackpage from './Components/FallbackPage/Fallbackpage';
import 'react-toastify/dist/ReactToastify.css';
import SettleAuction from './Pages/Auction/SettleAuction/SettleAuction';
import SellReservedAuction from './Pages/Auction/SellReservedAuction/SellReservedAuction';
import EditLazyPrice from './Pages/Lazy/EditLazyPrice/EditLazyPrice';
import Offers from './Pages/Offers/Offers';
import BuyStandardAuction from './Pages/BuyStandardAuction/BuyStandardAuction';
import BuyFixedPrice from './Pages/FixedPriced/BuyFixedPrice/BuyFixedPrice';
import CancelFixedPrice from './Pages/FixedPriced/CancelFixedPrice/CancelFixedPrice';
import SetStandardAuction from './Pages/Auction/SellStandardAuction/SellInAuction';
import PlaceBid from './Pages/Auction/PlaceBid/PlaceBid';
import { ManageHistory } from '@mui/icons-material';
import ManageAuction from './Pages/Auction/ManageAuction/ManageAuction';
import CancelReservedAuction from './Pages/Auction/ManageAuction/ManageReservedAuction';
import PlaceBidReservedAuction from './Pages/Auction/PlaceBid/PlaceBidReservedAuction';
import AcceptOffer from './Pages/Offers/AcceptOffer';
import BanUsers from './Pages/BanUsers/BanUsers';

function App() {

  const Explore = lazy(() => import('./Pages/Explore/ExploreNFTs/ExploreNFTs'));
  const NFT = lazy(() => import('./Pages/NFTItem/NFT'));
  const HomePage = lazy(() => import('./Pages/Home/HomePage'));

  return (
    <>
      <CustomRouter history={history}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='dark'
        />
        <Layout>
          {/* <DrawerAppBar /> */}
          <Suspense fallback={<Fallbackpage />}>
            <Routes >
              <Route path='/' element={<HomePage />} />
              <Route path="/fallback" element={<Fallbackpage />} />
              <Route path='/mint' element={<PrivateRoute />}>
                <Route path="/mint" element={<Mint />} />
              </Route>
              <Route path='/aboutUs' element={<AboutUs />} />
              <Route path='/privacyAndPolicy' element={<Privacy />} />
              <Route path='/contactUs' element={<ContactUs />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/profile/:username' element={<Profile />} />
              <Route path='/profile' >
                <Route path="/profile/edit/:username" element={<EditProfile />} />
              </Route>
              <Route path='/:creator/:id' element={<NFT />} />
              <Route path='/:creator/:collection/:id' element={<NFT />} />
              <Route path='/FixedPrice/set/:creator/:collection/:id' element={<SellFixedPrice />} />
              <Route path='/FixedPrice/set/:creator/:id' element={<SellFixedPrice />} />
              <Route path='/FixedPrice/buy/:creator/:id' element={<BuyFixedPrice />} />
              <Route path='/FixedPrice/buy/:creator/:collection/:id' element={<BuyFixedPrice />} />
              <Route path='/FixedPrice/manage/:creator/:id' element={<CancelFixedPrice />} />
              <Route path='/FixedPrice/manage/:creator/:collection/:id' element={<CancelFixedPrice />} />
              <Route path='/Auction/settle/:creator/:id' element={<SettleAuction />} />
              <Route path='/Auction/settle/:creator/:collection/:id' element={<SettleAuction />} />
              <Route path='/Auction/setStandard/:creator/:collection/:id' element={<SetStandardAuction />} />
              <Route path='/Auction/setStandard/:creator/:id' element={<SetStandardAuction />} />
              <Route path='/Auction/setReserved/:creator/:collection/:id' element={<SellReservedAuction />} />
              <Route path='/Auction/setReserved/:creator/:id' element={<SellReservedAuction />} />
              <Route path='/Auction/placeBid/:creator/:collection/:id' element={<PlaceBid />} />
              <Route path='/Auction/placeBid/:creator/:id' element={<PlaceBid />} />
              <Route path='/Auction/placeBidReservedAuction/:creator/:collection/:id' element={<PlaceBidReservedAuction />} />
              <Route path='/Auction/placeBidReservedAuction/:creator/:id' element={<PlaceBidReservedAuction />} />
              <Route path='/Auction/manage/:creator/:collection/:id' element={<ManageAuction />} />
              <Route path='/Auction/manage/:creator/:id' element={<ManageAuction />} />
              <Route path='/Auction/manageReserved/:creator/:collection/:id' element={<CancelReservedAuction />} />
              <Route path='/Auction/manageReserved/:creator/:id' element={<CancelReservedAuction />} />
              <Route path='/notification' element={<PrivateRoute />}>
                <Route path='/notification' element={<Notification />} />
              </Route>
              <Route path='/buyInAuction' element={<PrivateRoute />}>
                <Route path='/buyInAuction' element={<BuyInAuction />} />
              </Route>
              <Route path='/ExploreNFTs' element={<Explore />} />
              <Route path='/collection'>
                <Route path='/collection/:username' element={<SingleCollection />} />
              </Route>
              <Route path='/collections'>
                <Route path='/collections/edit/:username' element={<EditCollection />} />
              </Route>
              <Route path='/FAQ' element={<Faq />} />
              <Route path='/TermsOfServices' element={<TermsOfServices />} />
              <Route path='/singleCollection' element={<SingleCollection />} />
              <Route path='/applyToJoin' element={<JoinArtaniom />} />
              <Route path='*' element={<PageNotFound />} />
              <Route path='/lazyMint/buy/:creator/:collection/:id' element={<BuyInLazy />} />
              <Route path='/lazyMint/changePrice/:creator/:collection/:id' element={<EditLazyPrice />} />
              <Route path='/offer/:creator/:collection/:id' element={<Offers />} />
              <Route path='/offer/:creator/:id' element={<Offers />} />
              <Route path='/acceptOffer/:creator/:id' element={<AcceptOffer />} />
              <Route path='/acceptOffer/:creator/:collection/:id' element={<AcceptOffer />} />
              <Route path='/auction/buyStandard/:creator/:collection/:id' element={<BuyStandardAuction />} />
              <Route path='/banned' element={<BanUsers />} />
              <Route path='/ExploreCollection' element={<Collection />} />
            </Routes>
          </Suspense>
        </Layout>
      </CustomRouter>
    </>
  );
}

export default App;
