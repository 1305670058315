import { Container } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyle } from './StyleBanUsers'
import sign from '../../Assets/Img/sign.svg'
import WarnMessage from './WarnMessage'
import ActiveItemScreen from './ActiveItemScreen'

function BanUsers() {
    const classes = useStyle()
    const [switchScreen, setSwitchScreen] = useState<boolean>(true)

    function toggleScreen() {
        setSwitchScreen(true)
    }
    return (
        <Container>
            {
                switchScreen ?
                    <ActiveItemScreen />
                    :
                    <WarnMessage toggleScreen={toggleScreen} />
            }

        </Container >
    )
}

export default BanUsers