import React from 'react'
import { GetUserWalletBalance } from '../../Utils/GetUserWalletBalance'
import { useStyles } from './StyleMarketComponents'

type Props = {
    value: string | null,
    title: string | null
}

function BidAtLeastPrice({ value, title }: Props) {
    const balance = GetUserWalletBalance()
    const classes = useStyles()
    return (
        <div className={classes.walletBalance}>
            <span>{title}</span><span>{value} ETH</span>
        </div>
    )
}

export default BidAtLeastPrice