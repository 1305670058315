import { useState } from "react";
import Web3 from "web3"

const web3 = new Web3(Web3.givenProvider)

export function GetUserWalletBalance(): number {
    const [balance, setBalance] = useState<number>(0)
    const account = localStorage.getItem('account')
    let userBalance: number = 0;
    if (account !== null) {
        web3.eth.getBalance(account).then((balance) => {
            userBalance = Number(web3.utils.fromWei(balance, 'ether'))
            setBalance(userBalance)
        })
    }
    return balance;
}
