import React from 'react'
import { useStyles } from './StyleMarketComponents'

type props = { text: string }

function ResultTitleText({ text }: props) {
    const classes = useStyles()
    return (
        <p className={classes.resultTitleText}>
            {text}
        </p>
    )
}

export default ResultTitleText