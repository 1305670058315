import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useStyle } from './Styles';
import Ellipse from "../../../Assets/Img/Ellipse.svg"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import API from '../../../Utils/Api';
import ENDPOINTS from '../../../Utils/Endpoints';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import checkmark from '../../../Assets/Img/checkmark.svg'
interface IReport {
    reason: string,
    email: string,
    description: string,
}
export default function Report() {
    const [steps, setSteps] = React.useState<string>("reason")
    const [header, setHeader] = React.useState<number>(1)
    const [reason, setReason] = React.useState<string>('')
    const [isSucceed, setIsSucceed] = React.useState<boolean>(false)
    const { handleSubmit, register, formState: { errors } } = useForm<IReport>({ mode: 'onSubmit' })
    const queryClient = useQueryClient()

    const SubmitApi = async (data: IReport) => {
        const { data: response } = await API.post(ENDPOINTS.Search, data);
        return response.data;
    }

    const { mutate, isLoading } = useMutation(SubmitApi, {
        onSuccess: (data: string[]) => {
            const message = "success"
            alert(message)
        },
        onError: () => {
            alert("there was an error")
        },
    });


    const Submit: SubmitHandler<any> = (data: IReport) => {
        const Report = { ...data }
        mutate(Report)
    }

    function selectReason(e: React.ChangeEvent<any>) {
        setReason(e.target.value)
        setHeader(2)
    }

    function Continue() {
        if (reason === '') {
            toast.warn("Please, select a reason.")
        }
        if (reason !== '') {
            setSteps("submit")
            setHeader(2)
        }
    }

    const classes = useStyle()
    return (
        <div className={classes.Report}>
            {
                !isSucceed ?
                    <>
                        <div id="head">
                            {
                                header === 1 ?
                                    <>
                                        <p>Report an Issue</p>
                                        <p>
                                            If you believe there's been a violation of
                                            Artaniom's terms of service, please complete
                                            this report.
                                        </p>
                                    </>
                                    :
                                    <p>{reason}</p>
                            }
                        </div>
                        <form onSubmit={handleSubmit(Submit)}>
                            {
                                steps === "reason" ?
                                    <>
                                        <FormControl>
                                            <FormLabel id="demo-radio-buttons-group-label">Reason</FormLabel>
                                            <RadioGroup
                                                {...register("reason", {
                                                    required: { value: true, message: 'select a Reason' },
                                                    onChange: (e: any) => setReason(e.target.value)
                                                })
                                                }
                                            >
                                                <FormControlLabel value="Intellectual Property" control={<Radio icon={<AccessTimeIcon sx={{ color: "#fff", borderRadius: '25px', width: "20px", height: '20px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.3)" }} />} />} label="Intellectual Property" />
                                                <FormControlLabel value="Counterfeit Artwork" control={<Radio icon={<AccessTimeIcon sx={{ color: "#fff", borderRadius: '25px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.3)" }} />} />} label="Counterfeit Artwork" />
                                                <FormControlLabel value="Graphic Sexual Content" control={<Radio icon={<AccessTimeIcon sx={{ color: "#fff", borderRadius: '25px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.3)" }} />} />} label="Graphic Sexual Content" />
                                                <FormControlLabel value="Hateful or Abusive Content" control={<Radio icon={<AccessTimeIcon sx={{ color: "#fff", borderRadius: '25px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.3)" }} />} />} label="Hateful or Abusive Content" />
                                                <FormControlLabel value="Other Suspicious Activity" control={<Radio icon={<AccessTimeIcon sx={{ color: "#fff", borderRadius: '25px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.3)" }} />} />} label="Other Suspicious Activity" />
                                            </RadioGroup>
                                        </FormControl>
                                        <button id={reason === '' ? "disabled" : ''} onClick={Continue}>Continue</button>
                                    </>

                                    :
                                    <div className={classes.email}>
                                        <div>
                                            <p>Your Email</p>
                                            <TextField
                                                fullWidth
                                                variant='outlined'
                                                label={errors?.email?.message}
                                                error={Boolean(errors?.email?.message)}
                                                {...register('email', {
                                                    required: {
                                                        value: true,
                                                        message: 'Email is required.'
                                                    },
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Invalid email address"
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div>
                                            <p>Issue</p>
                                            <TextField
                                                fullWidth
                                                variant='outlined'
                                                label={errors?.description?.message}
                                                error={Boolean(errors?.description?.message)}
                                                {...register('description', {
                                                    required: {
                                                        value: true,
                                                        message: 'Description is required'
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: 'description must be at least 10 characters.'
                                                    },
                                                    maxLength: {
                                                        value: 500,
                                                        message: 'description must be 500 characters maximum.'
                                                    }
                                                })}
                                                multiline
                                                rows={5}
                                                placeholder="Describe why this page is being reported"
                                            />
                                        </div>
                                        <button type='submit' >Submit</button>
                                    </div>
                            }
                        </form>
                    </>
                    :
                    <div className={classes.successSection}>
                        <img src={checkmark} alt="succeed" />
                        <p>Your report has been registered.</p>
                        <p>We will follow and check it and take the necessary action.</p>
                    </div>
            }

        </div>
    );
}