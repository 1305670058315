import { Grid } from '@mui/material';
import { memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import card from "../../Assets/Img/featured.png";
import { GetHomeRequest } from '../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../redux/Hook';
import { FromWei, PublicKeyReducer } from '../../Utils/Utils';
import BrowseMarket from './BrowseMarket';
import { useStyle, useStyleCard } from './CardStyles';
import HomeCard from './HomeCard';
import cardPhoto from '../../Assets/Img/card3.png'
import EthLogo from '../../Assets/Img/EthereumLogo.svg'
import { Component } from 'react'
import SkeletonLoading from '../Skeleton/Skeleton';
import { useLayoutEffect } from 'react';
import CountDownInCard from '../MarketComponents/CountDownInCard';
import { useCountdown } from '../Counter/CountDown';
interface CardTypes {
    title: string,
    more?: string
}

function CardSection(props: CardTypes): JSX.Element {
    const classes = useStyle()
    const dispatch = useAppDispatch()
    const { Data } = useAppSelector((state) => state.HomeReducer)

    document.addEventListener('contextmenu', (event: any) => {
        if (event?.target?.tagName === 'IMG' || event?.target?.tagName === 'VIDEO' || event?.target?.tagName === 'LI') {
            event.preventDefault()
        }
    });

    useEffect(() => {
        dispatch(GetHomeRequest())
    }, [])
    return (
        <Grid container className={classes.bannerContainer} key={props.title}>
            <Grid className={classes.banner}  >
                <Grid className={classes.head} item>
                    <p></p>
                    <p></p>
                </Grid>
                <Grid >
                    {Data?.data?.data?.collections?.map((item: any, i: number) => {
                        return <HomeCard data={item} key={item?._id} />
                    }
                    )}
                </Grid>
                <BrowseMarket />
            </Grid>
        </Grid >
    )
}

export default CardSection


export const Card = memo((props: any): JSX.Element => {

    const auction = {
        backgroundColor: "#000",
        nameColor: "#fff",
        priceColor: "#eeeeee",
        textColor: '#eeeeee',
        ethLogoColor: "invert(100%) sepia(0%) saturate(7490%) hue-rotate(223deg) brightness(103%) contrast(98%)",
        auctionTimeColor: '#c2c2c2',
        hoverDivDisplay: 'flex'
    }

    const fix = {
        backgroundColor: "#E6E6E6",
        nameColor: "#000",
        priceColor: "#4a4a4a",
        textColor: '#676767',
        ethLogoColor: "invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(97%) contrast(104%)",
        auctionTimeColor: '#000',
        hoverDivDisplay: 'flex'
    }

    const notListed = {
        backgroundColor: "blue",
        nameColor: "#000",
        priceColor: "#4a4a4a",
        textColor: '#676767',
        ethLogoColor: "invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(97%) contrast(104%)",
        auctionTimeColor: '#000',
        hoverDivDisplay: 'flex'
    }

    let style: any;
    const listedData = props.data.listed
    const endTime = props.data.listed?.time_end * 1000
    const [daysAucDurationCountDown, hoursAucDurationCountDown, minutesAucDurationCountDown, secondsAucDurationCountDown] = useCountdown(endTime)
    const NowTime = new Date().getTime()

    useLayoutEffect(() => {
        style = props?.data?.status === "minted" ? notListed : (props?.data?.status === "auction" ? auction : fix)
    })
    const state = props?.data?.status
    const classes = useStyleCard(auction)
    const tokenId = props?.data?.tokenId
    const setAvatarOnError = () => {
        var img: any = document.getElementById('avatar')
        if (img.src) {
            img.src = card
        }
    }

    let rand: any

    function generateRandomNum() {
        rand = Math.random() * 180
        return rand
    }

    useEffect(() => {
        generateRandomNum()
    }, [props])

    return (
        <div className={classes.Card} style={{ backgroundColor: state === "minted" ? "#fff" : (state === "fix" ? "#E6E6E6" : (state === "lazy" ? "#fff" : (state === "auction" ? "#000" : "#fff"))) }}>
            <div>
                <div id="hover-container">
                    {
                        props?.data?.typeFile === 'mp4' ?
                            <video width="100%" height="300px" style={{ aspectRatio: '4/3', objectFit: 'cover' }} muted autoPlay controlsList='nodownload'>
                                <source src={`${"https://artaniom.art/ipfs/" + props?.data?.image_cid}`} type="video/mp4"></source>
                            </video>
                            :
                            <img id="cardItem" onError={setAvatarOnError} src={props?.data?.image_cid ? `${"https://artaniom.art/ipfs/" + props?.data?.image_cid}` : card} alt="nft" />
                    }
                    <div>
                        <div>
                            <p >{props?.data?.name}</p>
                            <p>{props?.data?.category === undefined ? "Artaniom" : props?.data?.collection?.name}</p>
                        </div>
                        <div>
                            <Link to={props?.data?.category !== '' ? (props?.data?.status === "lazy" ? `/${props?.data?.creator}/${props?.data?.collection?.name}/${props.data?._id}` : `/${props?.data?.creator}/${props?.data?.collection?.name}/${props.data?.tokenId}`) : `/${props?.data?.creator}/${props.data?.tokenId}`} >
                                {/* {props?.data?.status === "minted" ? "Make an Offer" : (props?.data?.status === "auction" ? "Place a Bid" : "Buy Now")} */}
                                View Item
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={classes.cardContainer}>
                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}></p>
                    <div>
                        <div>
                            {
                                props?.data?.listed?.status === "active" && props?.data?.listed?.base_value &&
                                <>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>Price</p>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>{FromWei(props?.data?.listed?.base_value)} <img src={EthLogo} id='eth' alt="eth" /> {!isNaN(endTime) && endTime > NowTime && <span>Ends in {daysAucDurationCountDown}d {hoursAucDurationCountDown}h {minutesAucDurationCountDown}m {secondsAucDurationCountDown}s</span>}</p>
                                </>
                            }
                            {
                                props?.data?.listed?.status === "active" && props?.data?.listed?.fix_price &&
                                <>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>Price</p>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>{FromWei(props?.data?.listed?.fix_price)} <img src={EthLogo} id='eth' alt="eth" /> {!isNaN(endTime) && endTime > NowTime && <span>Ends in {daysAucDurationCountDown}d {hoursAucDurationCountDown}h {minutesAucDurationCountDown}m {secondsAucDurationCountDown}s</span>}</p>
                                </>
                            }
                            {
                                (props?.data?.listed?.status !== "active" && (props?.data?.offers[0]?.status === "active")) &&
                                <>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>Offer</p>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>{FromWei(props?.data?.offers[0]?.price)} <img src={EthLogo} id='eth' alt="eth" /> {!isNaN(endTime) && endTime > NowTime && <span>Ends in {daysAucDurationCountDown}d {hoursAucDurationCountDown}h {minutesAucDurationCountDown}m {secondsAucDurationCountDown}s</span>}</p>
                                </>
                            }
                            {
                                (props?.data?.listed?.status !== "active" && (props?.data?.offers[0]?.status !== "active")) &&
                                <>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>Last Price</p>
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>{FromWei(props?.data?.price)} <img src={EthLogo} id='eth' alt="eth" /> {!isNaN(endTime) && endTime > NowTime && <span>Ends in {daysAucDurationCountDown}d {hoursAucDurationCountDown}h {minutesAucDurationCountDown}m {secondsAucDurationCountDown}s</span>}</p>
                                </>
                            }
                        </div>
                        {
                            props?.data?.collaborators?.length <= 1 ?
                                <div id='oneOwner'>
                                    {
                                        props?.data?.creatorData?.avatar ?
                                            <img onError={setAvatarOnError} src={props?.data?.creatorData?.avatar} alt="card" id="avatar" />
                                            :
                                            <span style={{ background: `linear-gradient(180deg, rgba(${generateRandomNum()},${generateRandomNum() + 5},${generateRandomNum() + 45},1) 0%, rgba(${generateRandomNum() + 6},${generateRandomNum() + 20},${generateRandomNum() + 45},1) 100%)` }}></span>

                                    }
                                    <p style={{ color: state === "minted" ? "#000" : (state === "fix" ? "#000" : (state === "lazy" ? "#000" : (state === "auction" ? "#fff" : "#000"))) }}>@{props?.data?.creator.length === 42 ? PublicKeyReducer(props?.data?.creator) : props?.data?.creator}</p>
                                </div>
                                :
                                <div id='multipleOwners'>
                                    {
                                        props?.data?.collaborators?.map((item: any) =>
                                            <img src={item?.avatar === undefined ? card : item?.avatar} alt="card" />

                                        )
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
})

export const LazyCard = (props: any): JSX.Element => {
    const { LoadingNFT } = useAppSelector(state => state.NFTReducer)

    const notListed = {
        backgroundColor: "#E6E6E6",
        nameColor: "#000",
        priceColor: "#4a4a4a",
        textColor: '#676767',
        ethLogoColor: "invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(97%) contrast(104%)",
        auctionTimeColor: '#000',
        hoverDivDisplay: 'none'
    }

    const classes = useStyleCard(notListed)
    const setAvatarOnError = () => {
        var img: any = document.getElementById('cardItem')
        img.src = card
    }

    const CollectionName = props?.data?.nftCollection?.name

    return (
        <div className={classes.lazyCard}  >
            <div>
                {/* this div must be empty because i used nesting in styles */}
            </div>
            <div>
                {
                    LoadingNFT ? <SkeletonLoading picture={true} width="100%" shape="rectangular" text={true} /> :
                        props?.data?.nft?.typeFile === 'mp4' ?
                            <div className='media'>
                                <video width="100%" height="300px" style={{ aspectRatio: '4/3', objectFit: 'cover' }} muted autoPlay controlsList='nodownload'>
                                    <source src={`${"https://artaniom.art/ipfs/" + props?.data?.nft?.image_cid}`} type="video/mp4"></source>
                                </video>
                            </div>
                            :
                            <div className='media'>
                                <img onError={setAvatarOnError} id='cardItem' src={props?.data?.nft?.image_cid ? `${"https://artaniom.art/ipfs/" + props?.data?.nft?.image_cid}` : card} alt="nft" width="100%" height="auto" />
                            </div>
                }
                <div className={classes.LazyCardInfo}>
                    <div>
                        <p>{props?.data?.nft?.name}</p>
                        <p>{CollectionName ? CollectionName : 'Artaniom'}</p>
                    </div>
                    {/* <div>
                        <p>{FromWei(props?.data?.nft?.price)} Collection floor price??</p>
                    </div> */}
                </div>
            </div >
        </div >
    )
}



export const CardItemInCollection = (props: any): JSX.Element => {

    const notListed = {
        backgroundColor: "blue",
        nameColor: "#000",
        priceColor: "#4a4a4a",
        textColor: '#676767',
        ethLogoColor: "invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(97%) contrast(104%)",
        auctionTimeColor: '#000',
        hoverDivDisplay: 'flex'
    }

    const setAvatarOnError = () => {
        var img: any = document.getElementById('cardItem')
        img.src = card
    }
    const classes = useStyleCard(notListed)
    return (
        <Grid className={classes.Card} key={props?.data?._id} xs={12}>
            <div>
                <div id="hover-container">
                    {
                        props?.data?.typeFile === 'mp4' ?
                            <video key={props?.data?.image_cid} width="100%" height="300px" style={{ aspectRatio: '4/3', objectFit: 'cover' }} muted autoPlay controlsList='nodownload'>
                                <source src={`${"https://artaniom.art/ipfs/" + props?.data?.image_cid}`} type="video/mp4"></source>
                            </video>
                            :
                            <img id="cardItem" onError={setAvatarOnError} key={props?.data?.image_cid} src={props?.data?.image_cid ? `${"https://artaniom.art/ipfs/" + props?.data?.image_cid}` : card} alt="nft" />
                    }
                    <div>
                        <div key={props?.data?.name}>
                            <p>{props?.data?.name}</p>
                            <p>{props?.data?.category === undefined ? "Artaniom" : props?.data?.collection?.name}</p>
                        </div>
                        <div>
                            <Link key={props?.data?.creator} to={props?.data?.category !== '' ? (props?.data?.status === "lazy" ? `/${props?.data?.creator}/${props?.data?.collection?.name}/${props.data?._id}` : `/${props?.data?.creator}/${props?.data?.collection?.name}/${props.data?.tokenId}`) : `/${props?.data?.creator}/${props.data?.tokenId}`} >
                                {/* {props?.data?.status === "minted" ? "Make an Offer" : (props?.data?.status === "auction" ? "Place a Bid" : "Buy Now")} */}
                                View Item
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={classes.cardContainer}>
                    <p>{props?.data?.name}</p>
                    <div>
                        <div>
                            <p>Price</p>
                            <p key={props?.data?.price}>{props?.data?.price && FromWei(props?.data?.price)} <img src={EthLogo} id='eth' alt="eth" /><span> </span></p>
                        </div>
                        {
                            props?.data?.collaborators?.length <= 1 ?
                                <div id='oneOwner'>
                                    <img onError={setAvatarOnError} src={props?.data?.creatorData?.avatar} alt="card" />
                                    <p>@{props?.data?.creator.length === 42 ? PublicKeyReducer(props?.data?.creator) : props?.data?.creator}</p>
                                </div>
                                :
                                <div id='multipleOwners'>
                                    {
                                        props?.data?.collaborators?.map((item: any) =>
                                            <img src={item?.avatar === undefined ? card : item?.avatar} alt="card" key={item?.name} />
                                        )
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </Grid >
    )
}


export const CardCollectionInProfile = (props: any): JSX.Element => {

    const notListed = {
        backgroundColor: "#fff",
        nameColor: "#000",
        priceColor: "#4a4a4a",
        textColor: '#676767',
        ethLogoColor: "invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(97%) contrast(104%)",
        auctionTimeColor: '#000',
        hoverDivDisplay: 'flex'
    }

    const classes = useStyleCard(notListed)
    const setAvatarOnError = () => {
        var img: any = document.getElementById('cardItem')
        img.src = card
    }

    return (
        <div className={classes.Card} key={props?.data?._id} >
            <div>
                <div id="hover-container">
                    <img id="cardItem" onError={setAvatarOnError} key={props?.data?.image_cid} src={props?.data?.logo ? props?.data?.logo : card} alt="collection" />
                    <div>
                        <div key={props?.data?.name}>
                            <p>{props?.data?.name}</p>
                            <p>{props?.data?.category === undefined ? "Artaniom" : props?.data?.collection?.name}</p>
                        </div>
                        <div>
                            <Link key={props?.data?.creator} to={`/collection/${props?.data?.name}`} >
                                View Collection
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={classes.cardContainer}>
                    <p>{props?.data?.name}</p>
                    <div>
                        <div>

                            <p key={props?.data?.price}>{props?.data?.symbol}<span> </span></p>
                        </div>
                        <div id='oneOwner'>
                            <img onError={setAvatarOnError} src={props?.data?.ownerData?.avatar} alt="card" />
                            <p>@{props?.data?.ownerData?.name ? props?.data?.ownerData?.name : PublicKeyReducer(props?.data?.owner)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

