import * as types from '../../../redux/Types'

export interface ISellInAuction {
    DataSellInAuction: string[] | null,
    LoadingSellInAuction: boolean,
    ErrorSellInAuction: boolean,
}

const initialState = {
    DataSellInAuction: [],
    LoadingSellInAuction: false,
    ErrorSellInAuction: false
}

export default function SellInAuctionReducer(state: ISellInAuction = initialState, action: any) {
    switch (action.type) {
        case types.FIXED_PRICE_REQUEST:
            return {
                ...state,
                LoadingSellInAuction: true,
                ErrorSellInAuction: false,
                DataSellInAuction: action.payload
            }
        case types.FIXED_PRICE_SUCCESS:
            return {
                ...state,
                LoadingSellInAuction: false,
                ErrorSellInAuction: false,
                DataSellInAuction: action.payload
            }
        case types.FIXED_PRICE_FAILED:
            return {
                ...state,
                LoadingSellInAuction: false,
                ErrorSellInAuction: true,
                DataSellInAuction: action.payload
            }
        default:
            return state
    }
} 