import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import { LazyCard } from '../../../Components/CardSection/CardSection'
import BlackLinkButton from '../../../Components/MarketComponents/BlackLinkButton'
import ConfirmingText from '../../../Components/MarketComponents/ConfirmingText'
import InputComponent from '../../../Components/MarketComponents/Input'
import MainMarketLayout from '../../../Components/MarketComponents/MainMarketLayout'
import MainTitle from '../../../Components/MarketComponents/MainTitle'
import ResultTitleText from '../../../Components/MarketComponents/ResultTitleText'
import SubmitButtonGold from '../../../Components/MarketComponents/SubmitButtonGold'
import SkeletonLoading from '../../../Components/Skeleton/Skeleton'
import { GetNFT } from '../../../redux/Actions'
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import { FromWei } from '../../../Utils/Utils'
import MarketABI from '../../../Web3/ABI/Market.json'
import { useStyles } from './StyleCancelFixedPrice'


function CancelFixedPrice() {
    const [makeSureToCancel, setMakeSureToCancel] = useState<boolean>(false)
    const [ethereumExchange, setEthereumExchange] = useState<number>(0)
    const [steps, setSteps] = useState<number>(0)
    const [functionOperationSteps, setFunctionOperationSteps] = useState<string>("")
    const dispatch = useAppDispatch()
    const { DataNFT, LoadingNFT, ErrorNFT } = useAppSelector(state => state.NFTReducer)
    const [price, setPrice] = useState<number>(0)
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm()

    const param = useParams()
    const classes = useStyles()

    useEffect(() => {
        dispatch(GetNFT({ payload: param }))
    }, [param.username])


    const web3 = new Web3(Web3.givenProvider)
    const hasCategory: boolean = Boolean(DataNFT?.data?.data?.nft?.category)
    const CollectionAddress = DataNFT?.data?.data?.nftCollection?.contract_address
    const address = process.env.REACT_APP_NFT
    const COLLECTION_ADDRESS = hasCategory ? CollectionAddress : address
    const tokenId = DataNFT?.data?.data?.nft?.tokenId

    const itemPrice = DataNFT?.data?.data?.nft?.listedData?.fix_price

    const account: any = localStorage.getItem('account')
    const FixContract = new web3.eth.Contract(MarketABI as AbiItem[], process.env.REACT_APP_MARKET)

    async function FixedPrice() {
        await FixContract.methods.cancelSellOrder(COLLECTION_ADDRESS, tokenId).send({ from: account })
            .once('sending', () => {
                setSteps(1)
                setFunctionOperationSteps("Confirming...")
            })
            .once('transactionHash', (error: any) => {
                setSteps(2)
            })
            .on('error', (error: any) => {
                toast.error(error.message)
                setSteps(0)
                setMakeSureToCancel(false)
            })
            .then((res: any) => {
                setSteps(3)
                setFunctionOperationSteps("Finalized")
            })
    }

    return (
        <MainMarketLayout>
            <div>
                {
                    steps === 0 &&
                    <>
                        <MainTitle title="Fixed Price" />
                        <InputComponent
                            setPrice={setPrice}
                            errors={errors}
                            register={register}
                            setEthereumExchange={setEthereumExchange}
                            ethereumExchange={ethereumExchange}
                            LoadingNFT={true}
                            defaultValue={FromWei(itemPrice)}
                            setValue={setValue}
                        />
                        {
                            makeSureToCancel ?
                                <div className={classes.makeSureContainer}>
                                    <p>Are you going to unlist your item?</p>
                                    <div>
                                        <SubmitButtonGold onClick={FixedPrice} type="button" title="Yes" bgcolor="#000" />
                                        <SubmitButtonGold onClick={() => setMakeSureToCancel(false)} type="button" title="Keep it in market" bgcolor="#94794F" />
                                    </div>
                                </div>
                                :
                                <div className={classes.unlistButtonSec}>
                                    {
                                        LoadingNFT ?
                                            <SkeletonLoading text={true} picture={false} height={300} shape="rectangular" width='300px' />
                                            :
                                            <SubmitButtonGold onClick={() => setMakeSureToCancel(true)} type="submit" title="Unlist" bgcolor="#000" />
                                    }
                                </div>
                        }
                    </>
                }
                {
                    steps >= 1 && steps <= 2 &&
                    <div className={classes.confirmSection}>
                        <ResultTitleText text={steps === 1 ? "Unlisting" : "Waiting"} />
                        <Grid sx={{ margin: '80px 0' }}>
                            {steps === 1 && <ConfirmingText text={functionOperationSteps} />}
                        </Grid>
                    </div>
                }
                {
                    steps === 3 &&
                    <>
                        <Grid sx={{ display: "grid", placeItems: 'center', marginTop: '80px' }}>
                            <ResultTitleText text="Your NFT has been unlisted." />
                            <BlackLinkButton to={DataNFT?.data?.data?.nft?.category !== '' ? (DataNFT?.data?.data?.nft?.status === "lazy" ? `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?._id}` : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT?.data?.data?.nftCollection?.name}/${DataNFT.data?.data?.nft?.tokenId}`) : `/${DataNFT?.data?.data?.nft?.creator}/${DataNFT.data?.data?.nft?.tokenId}`} text="Visit Your NFT" />
                        </Grid>
                    </>
                }
            </div>
            <div>
                <LazyCard data={DataNFT?.data?.data} />
            </div>
        </MainMarketLayout>
    )
}

export default CancelFixedPrice