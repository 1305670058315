import { FormControlLabel } from '@material-ui/core';
import { LaunchOutlined, OutboxOutlined } from '@mui/icons-material';
import { Checkbox, FormControl, FormGroup, MenuItem, Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import jwt from 'jsonwebtoken';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import checkmark from '../../Assets/Img/checkmark.svg';
import etherscan from '../../Assets/Img/Etherscan.png';
import loadingGif from '../../Assets/Img/loading.gif';
import { ChangeWeb3State, CreateCollection, FetchCollectionRequest, GetNftTypeFB } from '../../redux/Actions';
import { useAppDispatch, useAppSelector } from '../../redux/Hook';
import Alerts from '../Alert/Alert';
import CircularLoading from '../CircularProgress/CircularProgress';
import { useStyles } from './StyleCreateCollection';


/**
    lazy Collection drop down appear only user have permission
    if doesn't have drop down disappear and generally can create normal collection
 */
export default function CreateCollections({ open, setOpen, categoryOfArtwork, setNftType, setWeb2Category, GetCollectionByName }: any) {
    const classes = useStyles()
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const { nftTypeData, nftTypeLoading, } = useAppSelector((state) => state.MintReducer)
    const { Data, Error, isLoading, isLoadingWEB3, DataWEB3, web3, steps } = useAppSelector((state) => state.CreateCollectionReducer)
    const [catWeb2, setCatWeb2] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [symbol, setSymbol] = useState<string>('')
    const [categoryName, setCategoryName] = useState<string>('')
    const SubmitCreateColleciton: SubmitHandler<any> = (data: any) => {
        dispatch(CreateCollection({ payload: data }))
    }
    if (open && Data?.name === "AxiosError") {
        setOpen(false)
    }
    React.useEffect(() => {
        dispatch(GetNftTypeFB())
        dispatch(ChangeWeb3State({ payload: '' }))
        setCategoryName(categoryOfArtwork?.title)
        reset()
        setName('')
        setSymbol('')
    }, [open])

    function FinishUp() {
        if (location.pathname.includes('mint')) {
            GetCollectionByName(name)
        }
        reset()
        setName('')
        setSymbol('')
        setCategoryName('')
        setOpen(false)
    }

    const userInfo: any = localStorage.getItem('userProfile')
    const parseUserInfo = JSON.parse(userInfo)
    const userPermission = parseUserInfo?.data?.data?.permision
    const permission: any = jwt.decode(userPermission)
    const lazyCollectionPermission = permission?.collection?.lazyCollection

    function handleCategoryWeb2(props: any) {

        if (location.pathname.includes('mint')) {
            setCatWeb2(props)
            setNftType(props)
            setWeb2Category(props)
        }
        dispatch(FetchCollectionRequest({ payload: props }))
    }

    return (
        <div>
            <Dialog open={open} onClose={!isLoading && !isLoadingWEB3 && setOpen} className={classes.CreateCollection}>
                {
                    web3 ?
                        <div className={classes.web3Collection} >
                            {
                                steps === 1 &&
                                <div className={classes.loadingCreateCollection}>
                                    <img src={loadingGif} alt="loading..." width="100px" height="100px" />
                                    <div>
                                        <p className={classes.web3Collection} >Waiting for confirmation...</p >
                                        <p>Confirm this transaction in your wallet.</p>
                                    </div>
                                    <div>
                                        <p>{name}</p>
                                        <p>{symbol}</p>
                                    </div>
                                    <div>
                                        <p>{categoryName}</p>
                                    </div>
                                </div>
                            }
                            {
                                steps === 2 &&
                                <div className={classes.loadingCreateCollectionStep2}>
                                    <img src={loadingGif} alt="loading..." width="100px" height="100px" />
                                    <p>Your smart contract is being generated...</p>
                                    <p>The smart contract is being deployed to the Ethereum mainnet.</p>
                                </div>
                            }
                            {
                                steps === 3 &&
                                <p>
                                    {
                                        !Error && (
                                            <div className={classes.collecCreated}>
                                                <img src={checkmark} alt="loading..." width="75px" height="75px" />
                                                <p>Congratulations!</p>
                                                <p>Your smart contract was deployed.<a href={`${process.env.REACT_APP_ETHERSCAN}${DataWEB3?.transactionHash}`} target="_blank"><LaunchOutlined sx={{ height: "0.85em", filter: "invert(41%) sepia(11%) saturate(16%) hue-rotate(60deg) brightness(95%) contrast(96%)" }} /></a></p>
                                                <button onClick={FinishUp}>
                                                    {
                                                        location.pathname.includes('mint') ? "Continue" : "Done"
                                                    }
                                                </button>
                                            </div>
                                        )
                                    }
                                </p>
                            }
                        </div>
                        :
                        <>
                            <DialogTitle>Create New Collection</DialogTitle>
                            <form onSubmit={handleSubmit(SubmitCreateColleciton)}>
                                <DialogContent>
                                    <p className={classes.headerText}>
                                        When creating a new collection, you are deploying your own contract to the blockchain. Artaniom charges zero transaction fees, so you only need to pay for the network gas fees.
                                    </p>
                                    {Error && <Alerts message={Data?.data?.message} severity="error" />}
                                    <label htmlFor="name">
                                        <p>Name</p>
                                        <TextField
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: classes.inputCounterStyle
                                                }
                                            }}
                                            sx={{
                                                "& input": {
                                                    fontFamily: 'DellaRespira !important'
                                                }
                                            }}
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            size='small'
                                            type="text"
                                            fullWidth
                                            error={Boolean(errors?.name?.message)}
                                            label={errors?.name?.message}
                                            variant="outlined"
                                            helperText={`${name.length}/50`}
                                            value={name}
                                            {...register("name", {
                                                required: 'Required',
                                                maxLength: {
                                                    value: 50,
                                                    message: 'Name must be 50 characters maximum.'
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9$@$!%*?&#^-_. +-]+$/,
                                                    message: 'Please Type in English',
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: 'Name must be at least 3 characters.'
                                                },
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)
                                            })}
                                        />
                                    </label>
                                    <label htmlFor="symbol">
                                        <p style={{ marginTop: '5px' }}>Symbol</p>
                                        <TextField
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: classes.inputCounterStyle
                                                }
                                            }}
                                            sx={{
                                                "& input": {
                                                    fontFamily: 'DellaRespira !important'
                                                }
                                            }}
                                            margin="dense"
                                            id="symbol"
                                            size='small'
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            helperText={`${symbol.length}/8`}
                                            error={Boolean(errors?.symbol?.message)}
                                            label={errors?.symbol?.message}
                                            value={symbol}
                                            {...register("symbol", {
                                                required: 'Required',
                                                maxLength: {
                                                    value: 8,
                                                    message: 'Symbol must be 8 characters maximum.'
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9$@$!%*?&#^-_. +-]+$/,
                                                    message: 'Please Type in English',
                                                },
                                                minLength: {
                                                    value: 1,
                                                    message: 'Symbol must be at least 1 characters.'
                                                },
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSymbol(e.target.value)

                                            })}
                                        />
                                    </label>

                                    {
                                        lazyCollectionPermission >= 1 &&
                                        <label htmlFor="collectionType">
                                            <p className={classes.titleCollection} >Collection Type {nftTypeLoading && <CircularLoading size={20} />}</p>
                                            <FormControl fullWidth size='small'>
                                                <Select
                                                    {...register('collectionType', {
                                                        required: " select a category"
                                                    })}
                                                    sx={{
                                                        fontFamily: 'DellaRespira !important'
                                                    }}
                                                >
                                                    <MenuItem sx={{ fontFamily: 'DellaRespira !important' }} value="standard" key="standard" >Standard</MenuItem>
                                                    <MenuItem sx={{ fontFamily: 'DellaRespira !important' }} value="lazy" key="lazy" >Lazy</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </label>
                                    }

                                    <label htmlFor="categoryOfArtwork">
                                        <p className={classes.title}>Select Category {nftTypeLoading && <CircularLoading size={20} />}</p>
                                        <FormControl fullWidth size='small'>
                                            <Select
                                                {...register('categoryOfArtwork', {
                                                    required: " select a category",
                                                    onChange: (e: any) => handleCategoryWeb2(e.target.value)
                                                })}
                                                defaultValue={categoryOfArtwork?._id}
                                                sx={{ fontFamily: 'DellaRespira !important' }}
                                            >
                                                {
                                                    nftTypeData?.status && nftTypeData?.data?.data?.category_web2.map((item: any) => (
                                                        <MenuItem sx={{ fontFamily: 'DellaRespira !important' }} value={item?._id} onClick={() => setCategoryName(item.title)} key={item._id}>{item?.title}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </label>
                                    <FormGroup>
                                        <FormControlLabel
                                            className={classes.title}
                                            {...register("rights", {
                                                required: 'Required',
                                            })}
                                            control={<Checkbox defaultChecked={false} sx={{
                                                paddingRight: '2px', "& svg": {
                                                    color: "#000"
                                                }
                                            }} />}
                                            label={<span>I approve Artaniom's Terms & Conditions. {errors.rights && <span className={classes.errorText}>{errors?.rights?.message}</span>}</span>} />

                                    </FormGroup>
                                </DialogContent>
                                <DialogActions>
                                    {isLoading ? <CircularLoading size={30} /> : <input type='submit' value="Create Collection" />}
                                </DialogActions>
                            </form>
                        </>
                }
            </Dialog >
        </div >
    );
}